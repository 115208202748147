.result {
  background-color: color(white);
  border-radius: 20px;
  @include pad(23px 38px 22px 38px);

  &-heading {
    font-size: rem(15);
    font-weight: 500;
    margin-bottom: 9px;
    letter-spacing: -0.03em;
  }

  &-meta {
    font-size: rem(11);
    margin-bottom: 5px;
    display: block;
  }

  &-desc {
    font-size: rem(13);
    margin-bottom: 0;
  }

  &-read-more {
    color: color(combiz_orange);
    font-size: rem(12);
  }
}
.locations {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/build/globe.jpg');
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(color(combiz_light_gray), 0.7);
    content: '';
    width: 100%;
    height: 100%;
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    gap: 0 20px;
    position: relative;
    z-index: 1;
    @include pad(87px 0 122px 0);

    @include media($tablet) {
      @include pad(37px 0 18px);
    }

    @include media($tablet) {
      flex-direction: column;
      align-items: center;
      text-align: center
    }

    @include media($mobile) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &-title {
    @include media($tablet) {
      line-height: 1.3;
      margin-bottom: 12px;
    }
  }

  &-par {
    max-width: 603px;
    width: 100%;
    // flex-shrink: 0;

    p {
      font-size: rem(15px);
      color: color(combiz_black);
      line-height: 1.5;

      @include media($mobile) {
        font-size: rem(12px);
      }
    }
  }
}

.contact {

  &-details {
    background-color: color(gray_lighter_blue);
    @include pad(33px 0);

    &-inner {
      background-color: color(white);
      border-radius: 20px;
      @include pad(28px 107px);
      min-height: 355px;

      @include media($desktop) {
        @include pad(28px 30px);
      }

      @include media($mobile) {
        @include pad(24px 16px 32px 16px);
      }
    }

    &-title {
      font-size: rem(24px);
      font-weight: 400;
      color: color(combiz_blue);
      letter-spacing: -0.03em;
      margin-bottom: 43px;
    }
  }

  &-locations {
    display: flex;
    gap: 10px 229px;

    @include media($tablet) {
      gap: 10px 70px;
    }

    @include media($mobile) {
      display: block;
    }
  }

  &-location {
    @include media($tablet) {
      width: 50%;
    }

    @include media($mobile) {
      width: 100%;
    }
  }

  &-info {

    &-item {
      color: color(dark_very_grayish_red);
      font-weight: 500;
      line-height: 1.45;
      letter-spacing: -0.03em;
      padding-left: 20px;
      position: relative;
      font-size: rem(14px);
      margin-bottom: 6px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(16px);
        font-family: inherit;
        color: color(combiz_orange);
        font-weight: 500;
      }

      a {
        color: inherit;
      }
    }

    &-number {
      font-size: rem(14px);
    }

    &-address {
      margin-bottom: 32px;

      @include media($overmobile) {
        min-height: rem(108);
      }

      &::before {
        content: url('../../images/build/address.svg');
        left: -5px;
        top: 1px;
        color: color(combiz_orange);
        font-weight: 300;
      }
    }

    &-tel {
      &::before {
        content: 'T';
        left: -5px;
        top: -1px;
      }
    }

    &-fax {
      &::before {
        content: 'F';
        left: -5px;
        top: -2px;
      }
    }

    &-map {
      display: block;
      position: relative;
      margin-top: 6px;
      font-size: rem(12px);
      letter-spacing: normal;
      padding-left: 7px;

      &::before {
        position: absolute;
        content: '';
        height: 10px;
        width: 2px;
        top: 3px;
        left: 0;
        background-color: color(combiz_orange);
      }
    }
  }

  &-message {
    background-color: color(combiz_light_gray);
    @include pad(44px 0);

    @include media($tablet) {
      @include pad(24px 0)
    }

    &-inner {
      background-color: color(white);
      border-radius: 20px;
      overflow: hidden;
      display: flex;
    }

    &-title {
      font-size: rem(24px);
      color: color(combiz_blue);
      letter-spacing: normal;
      font-weight: 400;
      letter-spacing: -0.027em;

      @include media($tablet) {
        margin-bottom: 5px;
      }
    }
  }

  &-image {
    // flex-shrink: 0;
    max-width: 504px;
    width: 100%;
    background-image: url('../../images/build/contact-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media($tablet) {
      display: none;
    }
  }

  &-content {
    flex-grow: 1;
    @include pad(40px 111px 78px 114px);

    @include media($desktop) {
      @include pad(32px 40px 16px);
      min-width: 532px;
    }

    @include media($tablet) {
      min-width: 100%;
      @include pad(30px 19px 16px);
    }
  }

  &-form {

    #rhm-helpers-entityform-form {
      &>div {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 0 19px;
        grid-template-areas:
          "first last"
          "email number"
          "subject subject"
          "message message"
          "action action";

        @include media($mobile) {
          display: block;
        }
      }

      label {
        padding-left: 0;
      }
    }



    #edit-field-first-name {
      grid-area: first;
    }

    #edit-field-last-name {
      grid-area: last;
    }

    #edit-field-email {
      grid-area: email;
    }

    #edit-field-contact-number {
      grid-area: number;
    }

    #edit-field-first-name,
    #edit-field-last-name,
    #edit-field-email,
    #edit-field-contact-number {
      @include media($mobile) {
        @include pad(0 71px 0 0)
      }
    }

    #edit-field-subject {
      grid-area: subject;

      .select2-container {
        width: 100% !important;

        @include media($mobile) {
          left: 0 !important;
        }
      }
    }

    #edit-field-message {
      grid-area: message;
    }

    #edit-actions {
      grid-area: action;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 37px;
      margin: 0;

      .captcha {
        margin: 0;
      }

      .form-submit {
        background-color: color(combiz_blue);
        background-image: url("../../svg/angle-right.svg");
        background-position: right 20px bottom 50%;
        background-repeat: no-repeat;
        min-width: rem(92);
        padding-right: rem(40);
      }

      @include media($mobile) {
        flex-direction: column;

        .form-submit {
          margin-top: rem(32);
          width: rem(120);
        }
      }
    }

    #edit-submit {
      border: none;
      color: color(white);
      border-radius: 50px;
      position: relative;
      @include pad(12px 14px);
      font-weight: 300;
      background-color: color(combiz_blue);
      font-size: rem(12.8px);
    }
  }
}
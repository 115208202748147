//homepage-trigger
.homepage-trigger {
  @include position(fixed, 0 null 0 0);
  @include size(100% 5px);
  margin: auto;
  background: transparent;
  z-index: 100;
}

//homepage-wrapper
.homepage-wrapper {
  padding: 88px 0;
  position: relative;
  @include clearfix();
  @include outer-container(1200px);

  .homepage-row {
    position: relative;
  }

  @include media($large_desktop) {
    @include outer-container(960px);
  }

  @include media($desktop) {
    padding: 122px 0 85px;
    @include outer-container(800px);
  }

  @include media($tablet) {
    padding: 65px 0;
    @include outer-container(680px);
  }

  @include media($mobile) {
    overflow: hidden;
    padding: 90px 0 25px;
    width: 100%;
  }
}

//homepage-row
.homepage-row {

  &#row-1 {

    .circle-content {

      &:after {
        content: '';
        @include size(97px);
        @include transform(scale(0.5));
        background: $orange_bright_2;
        opacity: 0.53;
        border-radius: 50%;
        @include position(absolute, 63px 59px null null);
        @include transition(all 333ms ease-out);
      }
    }

    @include media($large_desktop) {

      .circle-image {
        left: -140px;
      }

      .circle-content {
        @include shift(1.9);
        padding: 158px 94px 124px 206px;
      }
    }

    @include media($desktop) {

      .circle-image {
        top: -71px;
        left: -172px;
      }

      .circle-content {
        @include shift(0.5);
      }
    }

    @include media($tablet) {

      .circle-image {
        left: -19px;
        top: inherit;
        bottom: -160px;
      }

      .circle-content {
        margin-left: -42px;
        padding: 158px 94px 110px 154px;

        &:after {
          right: 47px;
        }
      }
    }

    @include media($mobile) {
      margin-bottom: 390px;

      .circle-image {
        left: -42px;
        bottom: inherit;
        top: 87%;
      }

      .circle-content:after {
        @include size(58px);
        top: 20px;
        right: initial;
        left: 87%;
      }
    }
  }

  &#row-2 {

    .circle-content {
      margin-top: -85px;
    }

    @include media($large_desktop) {

      .circle-content {
        margin: -55px 0 0 -91px;
      }
    }

    @include media($desktop) {

      .circle-content {
        margin: -55px 0 0 -88px;
      }

      .circle-image {
        right: -90px;
      }
    }

    @include media($tablet) {

      .circle-content {
        margin: 281px 0 0 -25px;

        &:after {
          top: 588px;
          left: 65px;
        }
      }

      .circle-image {
        top: -195px;
        right: 40px;
      }
    }

    @include media($mobile) {
      margin-bottom: 265px;

      .circle-image {
        left: inherit;
        right: -11px;
        top: -220px;
      }

      .circle-content:after {
        @include size(31px);
        top: 94%;
        left: 40px;
      }
    }
  }

  &#row-3 {
    margin-top: 41px;

    @include media($large_desktop) {

      .circle-content {
        @include shift(1.9);
        padding: 158px 104px 124px 206px;
      }

      .circle-image {
        left: -140px;
      }
    }

    @include media($desktop) {
      margin-top: 80px;

      .circle-content {
        @include shift(0.5);
        padding: 158px 104px 124px 226px;
      }
    }

    @include media($tablet) {
      margin-top: 165px;

      .circle-content {
        margin-left: -25px;
        padding: 154px 144px 114px 159px;

        &:after {
          top: 30px;
          right: 110px;
        }
      }

      .circle-image {
        top: -186px;
        left: 46px;
      }
    }

    @include media($mobile) {
      margin-top: 0;
      margin-bottom: 265px;

      .circle-image {
        left: -11px;
        top: -220px;
      }

      .circle-content:after {
        @include size(58px);
        top: 20px;
        right: initial;
        left: 87%;
        opacity: 0.53;
      }
    }
  }

  &#row-4 {
    margin-top: 33px;

    .circle-image {
      bottom: -43px;
    }

    .circle-content:after {
      @include size(76px);
      @include position(absolute, 119px inherit inherit 35px);
    }

    @include media($large_desktop) {
      margin-top: 0;

      .circle-image {
        right: -51px;
      }

      .circle-content {
        margin-left: -55px;
      }
    }

    @include media($desktop) {
      margin-top: -55px;

      .circle-image {
        right: -183px;
        bottom: 148px;
      }

      .circle-content {
        margin-left: -88px;
      }
    }

    @include media($tablet) {
      margin-top: 150px;

      .circle-image {
        right: 40px;
        top: -180px;
        bottom: inherit;
      }

      .circle-content {
        margin-left: -33px;
        padding: 154px 144px 144px 169px;

        &:after {
          top: 86px;
          left: 45px;
        }
      }
    }

    @include media($mobile) {
      margin-top: 0;
      margin-bottom: 265px;

      .circle-image {
        right: -11px;
        top: -210px;
      }

      .circle-content:after {
        @include size(31px);
        top: -15px;
        left: 10%;
        opacity: 0.53;
      }
    }
  }

  &#row-5 {
    margin-top: 20px;

    .circle-image {
      top: 30px;

      &:after {
        content: '';
        border-radius: 50%;
        @include size(85px);
        background: rgba(252, 104, 45, 0.9);
        @include position(absolute, null null 17px 2px);
      }
    }

    .circle-content:after {
      opacity: 0.7;
      @include size(31px);
      @include position(absolute, 563px inherit inherit -36px);
    }

    @include media($large_desktop) {
      margin-top: 68px;

      .circle-image {
        left: -92px;
      }

      .circle-content {
        @include shift(2.5);
        padding: 158px 114px 131px 216px;
      }
    }

    @include media($desktop) {
      margin-top: 31px;

      .circle-image {
        top: 101px;
        left: -183px;

        &:after {
          bottom: -60px;
          left: 96px;
        }
      }

      .circle-content {
        @include shift(0.5);

        &:after {
          top: 555px;
          left: 25px;
        }
      }
    }

    @include media($tablet) {
      margin-top: 150px;

      .circle-image {
        left: 40px;
        top: -180px;

        &:after {
          top: 0;
          right: -26px;
          left: initial;
          bottom: initial;
        }
      }

      .circle-content {
        margin-left: -35px;
        padding: 154px 144px 114px 159px;

        &:after {
          top: -46px;
          left: initial;
          right: 275px;
        }
      }
    }

    @include media($mobile) {
      margin-top: 0;
      margin-bottom: 100px;

      .circle-image {
        left: -11px;
        top: -220px;

        &:after {
          top: 5px;
          right: 22px;
          opacity: 0.9;
          @include size(35px);
        }
      }

      .circle-content:after {
        @include size(13px);
        top: -70px;
        left: 80%;
        opacity: 0.71;
      }
    }
  }

  &.image-left {

    .circle-image {
      @include position(absolute, 0 null null 0);
    }

    .circle-content {
      @include shift(3);
      @include pad(158px 95px 124px 226px);

      &:after {
        content: '';
        @include size(79px);
        background: $orange_bright_2;
        border-radius: 50%;
        @include position(absolute, 63px 73px null null);
        @include transition(all 333ms ease-out);
      }
    }
  }

  &.image-right {

    .circle-image {
      @include position(absolute, null 0 0 null);
    }

    .circle-content {
      @include shift(1);
      @include pad(157px 158px 128px 172px);

      .subject {
        max-width: 400px;
      }

      &:after {
        content: '';
        border-radius: 50%;
        @include size(58px);
        background: $orange_bright_2;
        @include position(absolute, 540px null null 10px);
        @include transition(all 333ms ease-out);
      }
    }
  }
}

//circle-image
.circle-image {
  @include size(456px);
  @include transform(scale(0.8));
  opacity: 0.8;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  background-size: cover;
  background-position: center;
  @include transition(all 1500ms ease-out);
  box-shadow: inset 0 0 0 20px rgba(250, 248, 247, 0.6);

  .fadeIn & {
    animation-name: circle-image-fadeIn;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }

  .fadeOut & {
    animation-name: circle-image-fadeOut;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }

  @include media($desktop) {
    @include size(366px);
  }

  @include media($tablet) {
    @include size(300px);
  }

  @include media($mobile) {
    @include size(247px);
  }

  @keyframes circle-image-fadeIn {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
      @include transform(scale(1.4));
    }
    100% {
      opacity: 1;
      @include transform(scale(1));
      box-shadow: inset 0 0 0 20px rgba(250, 248, 247, 0.6);
    }
  }

  @keyframes circle-image-fadeOut {
    0%, 15% {
      opacity: 1;
      @include transform(scale(1));
      box-shadow: inset 0 0 0 20px rgba(250, 248, 247, 0.6);
    }
    50% {
      opacity: 0.8;
      box-shadow: none;
      @include transform(scale(1.3));
    }
    100% {
      opacity: 0.5;
      @include transform(scale(0.5));
    }
  }
}

//circle-content
.circle-content {
  position: relative;
  width: 800px;
  min-height: 800px;

  .animate-content {
    opacity: 0;
    @include transform(translateY(80%));
    @include transition(all 777ms ease-out 777ms);
  }

  .fadeIn & {

    .animate-content {
      opacity: 1;
      @include transform(translateY(0));

      @include media($mobile) {
        top: 50%;
        @include transform(translateY(-50%));
      }
    }

    &:before {
      animation-name: circle-content-before-fadeIn;
      animation-duration: 1.2s;
      animation-delay: 444ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
    }

    &:after {
      animation-name: circle-content-after-fadeIn;
      animation-duration: 1s;
      animation-delay: 222ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
    }
  }

  .fadeOut & {

    .animate-content {
      @include transform(translateY(30%));
      opacity: 0;
      @include transition(all 888ms ease-out);
    }

    &:before {
      animation-name: circle-content-before-fadeOut;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
    }

    &:after {
      animation-name: circle-content-after-fadeOut;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
    }
  }

  &:before {
    content: '';
    border-radius: 50%;
    @include position(absolute, 0 null null 0);
    @include size(800px);
    background: $gary_light_orange;
    z-index: -2;
  }

  .title {
    font-size: rem(46);
    line-height: 0.9;
    letter-spacing: -1.37px;
    margin-bottom: 3px;
    font-weight: bold;
    text-transform: capitalize;
    color: $dark_grayish_red;
  }

  .sub-title {
    font-size: rem(42);
    font-weight: 500;
    color: $dark_very_grayish_red_3;
    letter-spacing: -1.17px;
    line-height: 1.07;
    margin-bottom: 18px;
  }

  .details {
    font-size: rem(15);
    line-height: 1.6;
    color: $dark_very_grayish_red;
    margin-bottom: 25px;
  }

  .subject-title {
    font-size: rem(11);
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 2.8px;
    line-height: 1.1;
    color: $dark_very_grayish_red_3;
    margin: 40px 0 23px;
  }

  .subject {
    margin-bottom: 25px;

    .icon, .content {
      display: inline-block;
      vertical-align: top;
    }

    .icon {
      @include size(35px);
      margin-right: 19px;
      background: url('../../images/build/home_icon_event.png') no-repeat center;
      background-size: cover;

      &.news_publication {
        background-image: url('../../images/build/home_icon.png');

      }
    }

    .content {
      @include size(calc(100% - 60px) auto);
    }

    .text {
      font-size: rem(13);
      font-weight: 500;
      color: $dark_very_grayish_red_3;
      letter-spacing: -0.4px;
      line-height: 1.31;
      margin: 1px 0 4px;
    }

    .information {
      font-size: rem(11);
      color: $dark_very_grayish_red_2;
      line-height: 1.45;
    }

    .tertiary-call-action {
      text-transform: lowercase;
    }
  }

  @include media($large_desktop) {

    .subject-wrapper {
      width: 350px;
    }
  }

  @include media($tablet) {
    width: 748px;
    min-height: 748px;

    &:before {
      @include size(748px);
    }

    .title {
      font-size: rem(44);
    }

    .sub-title {
      font-size: rem(40);
      margin-bottom: 3px;
    }

    .details {
      margin-bottom: 25px;
    }

    .subject-title {
      margin: 40px 0 22px;
    }

    .subject {
      margin-bottom: 25px;
    }
  }

  @include media($mobile) {
    margin: 0 !important;
    padding: 20px !important;
    width: 100vw !important;
    height: 100vw !important;
    min-height: inherit;

    .animate-content {
      width: calc(100% - 40px);
      @include position(absolute, 70% null null 20px);
    }

    &:before {
      @include size(120vw);
      min-width: 320px;
      min-height: 320px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: -12%;
    }

    .title {
      font-size: rem(24);
      margin-bottom: 5px;
    }

    .sub-title {
      font-size: rem(24);
      margin-bottom: 15px;
    }

    .details {
      font-size: rem(13);
      letter-spacing: -0.34px;
      line-height: 1.6;
    }

    .subject,
    .subject-title {
      display: none;
    }
  }

  @keyframes circle-content-before-fadeIn {
    0% {
      @include transform(scale(0.8));
    }
    50% {
      @include transform(scale(1.4));
    }
    100% {
      opacity: 1;
      @include transform(scale(1));
    }
  }

  @keyframes circle-content-before-fadeOut {
    0%, 25% {
      opacity: 0.8;
      @include transform(scale(1));
    }
    50% {
      @include transform(scale(1.6));
    }
    100% {
      opacity: 0;
      @include transform(scale(0.1));
    }
  }

  @keyframes circle-content-after-fadeIn {
    0% {
      @include transform(scale(0.5));
    }
    50% {
      @include transform(scale(1.5));
    }
    100% {
      @include transform(scale(1));
    }
  }

  @keyframes circle-content-after-fadeOut {
    0%, 25% {
      @include transform(scale(1));
    }
    50% {
      @include transform(scale(1.5));
    }
    100% {
      @include transform(scale(0.5));
    }
  }
}

.event-spotlight{
  margin-top: 60px;
  .spotlight-top{
    margin-bottom: 23px;
  }

  @include media($mobile) {
    margin-top: 0;
  }
}
.event-spotlight{
  .spotlight-desc{
    color: color(combiz_black);
  }
}
.event-spotlight-banner{
  background-color: color(combiz_blue);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 50% auto;
  padding: 93px 0;

  @media(max-width: 1720px){
    background-size: contain;
  }
  @include media($mobile) {
    background-size: contain;
    background-position: top center;
    padding-top: 180px;
    padding-bottom: 50px;
  }
}
.event-spotlight-event{
  background: rgba(255,255,255,0.9);
  border-radius: 15px;
  padding: 24px;
  max-width: 621px;
  margin-left: auto;


  .topic-category{
    background: transparent;
  }

  @include media($mobile) {
    padding: 18px;
    background: #fff;
  }
}
.event-spotlight-date{
  font-size: rem(24);
  font-weight: 500;
  color: color(combiz_blue);
  margin: 15px 0;
  background: url('../../images/build/calendar_clock.svg') left center no-repeat;
  padding-left: 38px;
  line-height: 1.2;

  @include media($mobile) {
    margin-bottom: 10px;
    font-size: rem(20);
  }
}
.event-spotlight-event-title{
  font-size: rem(35);
  color: color(combiz_blue);
  margin-bottom: 20px;
  line-height: 1.2;

  @include media($mobile) {
    font-size: rem(28);
  }
}
.event-spotlight-event-text p{
  color: color(combiz_black);
  font-size: 20px;
  margin-right: 50px;
  margin-bottom: 50px;

  a{
    color: color(combiz_orange);
    text-decoration: underline;
  }

  @include media($mobile) {
    font-size: rem(16);
    margin-right: 0;
  }
}
.event-spotlight-event-buttons{
  display: flex;
  gap: 14px;

  @include media($mobile) {
    display: block;

    a:not(:last-child){
      margin-bottom: 14px;
    }
  }
}

/** ==== PERSONAL DETAILS ===== **/
.page-profile {

  .main-with-sidebar {
    @include span-columns(8);
    float: right;

    @include media($mobile) {
      float: none;
      width: 100%;
    }
  }

  .user-edit-form-wrap {
    padding: 20px 0;
    @include span-columns(6 of 8);
    border-bottom: 1px solid $gray_very_light;
    overflow: hidden;
    @include transition(all 333ms linear);

    .user-edit-form-summary {
      overflow: hidden;
      padding: 0;
    }

    .name {
      float: left;
      font-size: rem(13);
      color: $dark_very_grayish_red_3;
      font-weight: 500;
    }

    button {
      float: right;
      font-size: em(12);
    }

    &:last-child {
      border-bottom: 0;
    }

    .profile-pages-personal-form {
      display: none;
      margin-top: 17px;
    }

    &.open {
      padding: 20px 0;

      .profile-pages-personal-form {
        display: block;
        margin-top: 17px;
      }
    }

    label {
      font-size: em(11);
      font-weight: 400;
    }

    label[for=change_password],
    label[for=change_password_] {
      position: absolute;
      right: 0;
      cursor: pointer;
      color: color(combiz_orange);

      #change_password,
      #change_password_ {
        display: none;
      }
    }

    .password-wrap.hidden {
      display: none;
    }

    .form-item {
      position: relative;
      margin-top: 0;

      &.half {
        @include span-columns(6);
      }

      &.omega {
        @include omega();
      }

      &.clear {
        clear: both;
      }
    }

    .user-picture-label {
      display: block;
      margin-bottom: 5px;
    }

    .user-picture {
      width: 83px;
      height: 83px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 20px;
      float: left;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .supported-formats {
      color: $dark_very_grayish_red_2;
      font-size: rem(11);
      margin-top: 5px;
    }

    input[name="picture"] {
      display: none;
    }

    input[type="file"] {
      font-size: em(13);
    }

    .form-actions {
      clear: both;
      text-align: center;
      margin: 0 0 7px;
      padding-top: 22px;

      input {
        display: inline-block;
        margin: 0 15px;

        &[type="submit"] {
          background-color: color(combiz_blue);
          box-shadow: none;
        }
      }
    }

    .form-item-error {

      input {
        border-color: $orange_bright;
      }
    }

    .error-message {
      font-size: em(13);
      color: $orange_bright;
      line-height: 1.2;
      margin-top: 4px;
    }

    .picture-placeholder {
      @include size(78px);
      background: $gary_light_orange;
      border: 1px solid $gray_very_light;
      border-radius: 3px;
      text-align: center;
      cursor: pointer;
      float: left;
      margin-right: 20px;

      span {
        font-size: em(28);
        color: $gray_light;
        display: inline-block;
        height: 25px;
        width: 25px;
        text-align: center;
        border: 1px solid $gray_light;
        line-height: 1;
        border-radius: 50%;
        margin-top: 25px;
        padding-top: 3px;
      }
    }

    @include media($tablet) {
      width: 100%;
      margin-right: 0;
    }

    @include media($mobile) {
      padding: 14px 0;

      &.open {
        padding: 30px 0;
      }

      .form-item {
        width: 100%;

        &.half {
          width: 100%;
        }
      }
    }
  }

  .profile,
  .profile-landing {
    padding: 0;
  }



  .field-name-field-title,
  .field-name-field-external-link,
  .field-name-field-video,
  .field-type-soundcloud,
  .field-name-field-is-archived {
    .field-label {
      display: none;
    }
  }

  .field-name-field-title {
    margin-top: 20px;
  }

  .field-name-field-title .field-item {
    font-weight: 600;
  }

  .field-name-field-is-archived {
    .field-items {
      display: none;
    }
  }

  .field-name-field-resource-date {
    margin-bottom: 100px;
    margin-top: -10px;

    .field-label {
      display: none;
    }
  }

  .profile-landing-coming-up {

    .profile-item {
      padding: 0;

      h3 {
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

    }
  }

  /** Profile General Item **/
  .profile {

    section {
      margin-bottom: 100px;
    }

    p,
    .text {
      font-size: em(13);
      color: $dark_very_grayish_red_2;
    }

    .icon-title {
      font-size: rem(24px);
      font-weight: 400;
      text-transform: capitalize;
      line-height: 1.25;
      letter-spacing: -0.7px;
      margin-bottom: 30px;
      overflow: hidden;
      display: flex;
      align-items: center;

      a {
        font-size: rem(16px);
        display: block;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.005em;
        position: relative;
        text-transform: lowercase;
        margin-left: auto;
        background-color: color(combiz_blue);
        border-radius: 50px;
        color: color(white);
        @include pad(11px 23px 11px 15px);
        @include caret(color(white));
        border: 1px solid color(combiz_blue);
        transition-property: color, background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &:hover {
          text-decoration: none;
          background-color: color(white);
          color: color(combiz_blue);

          &::after {
            box-shadow: 1px -1px 0 0 color(combiz_blue);
          }
        }

        &::after {
          margin-left: 6px;
          transition: box-shadow 0.3s ease-in-out;
        }
      }

      .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $dark_grayish_red;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50%;

        &.icon-upcoming {
          background-image: url(../../images/build/icon-upcoming-event-dark.png);
        }

        &.icon-calendar {
          background-image: url(../../images/build/icon-calendar-dark.png);
        }

        &.icon-publication {
          background-image: url(../../images/build/icon-publication-dark.png);
        }

        &.icon-note {
          background-image: url(../../images/build/icon-note-dark.png);
        }

        &.icon-featured {
          background-image: url(../../images/build/icon-featured.png);
          background-size: 47%;
        }
      }
    }

    @include media($tablet) {

      .icon-title {
        font-size: rem(20);
        margin-bottom: 20px;
      }
    }
  }

  /** Profile Booked Event **/
  .profile-booked-event {
    a {
      margin-top: 15px;
    }
  }

  .booked-event {
    @include media($overmobile) {
      h3 {
        margin-right: 100px;
      }
    }
  }

  .profile-item {

    .secondary-call-action {
      // margin-left: 15px;
      position: relative;
    }

    &.complete {
      .secondary-call-action {
        top: 11px;
      }
    }

    border: 0;
    position: relative;
    margin-bottom: 30px;
    @include transition(all 333ms linear);
    padding-left: 0;

    .item-wrapper {
      padding: 5px 0;
      @include transition(all 333ms linear);
    }

    // &:before {
    //   content: '';
    //   width: 1px;
    //   height: calc(100% - 20px);
    //   background: $gray_grayish_violet;
    //   @include position(absolute, 20px null null 0);
    // }

    // &:hover {
    //   background: rgba(234, 233, 236, 0.26);
    // }

    .text {
      clear: both;
    }

    .booked-event-actions {
      @include position(absolute, 0 15px null null);

      li {
        float: left;
        margin-left: 10px;

        &:first-of-type {
          margin-left: 0;
        }
      }

      a {
        color: $orange_bright_2;
        font-size: rem(12);
        text-transform: lowercase;
        font-weight: 400;
        line-height: 1.25;
        transition: all 333ms linear;
      }
    }

    .profile-item-title {
      position: relative;
      font-size: em(15);
      color: $dark_grayish_red;
      font-weight: 500;
      line-height: 1.73;
      letter-spacing: -0.44px;
      border: 0;
      margin-bottom: 2px;
      float: left;
      max-width: calc(100% - 188px);

      // &:after {
      //   content: '';
      //   width: 3px;
      //   height: 15px;
      //   @include position(absolute, 4px null null -16px);
      //   background: $orange_bright_2;
      // }
    }

    .profile-item-meta {
      clear: both;
      font-size: em(11);
      text-transform: uppercase;
      font-weight: normal;
      color: $dark_very_grayish_red_2;
      margin: 5px 0 6px;
    }

    .profile-item-summary {
      font-size: em(13);
      font-weight: normal;
      line-height: 1.61;
      margin-bottom: 10px;
      color: $dark_very_grayish_red_2;
      word-break: break-word;
    }

    .file>a,
    .ticket-call-action {
      margin-top: 15px;
      display: inline-flex;
    }

    .ticket-call-action {
      margin-left: 15px;
    }

    .booked-event-close {
      display: none;
    }

    &.open {
      border: 1px solid $gray_very_light;
      position: relative;

      h3:after {
        content: none;
      }

      .item-wrapper {
        padding: 16px 19px 16px 29px;
        background: $orange_bright;
      }

      .booked-event-close {
        position: absolute;
        top: 5px;
        right: 10px;
        display: block;
        color: $white;
        font-size: em(38);
        line-height: 1;
      }

      .profile-item-title,
      .profile-item-meta,
      .profile-item-summary {
        color: $white;
      }

      .profile-item-summary {
        margin-bottom: 0;
      }

      &:before,
      .booked-event-actions,
      .ticket-call-action {
        display: none;
      }
    }

    .form-wrap {
      padding: 24px 20px 20px 17px;
      border: 1px solid $blue_light_grayish_2;
      border-top: 0;
      background: $white;
      overflow: hidden;

      h3 {
        font-size: em(11);
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: $spaced-letters;
        border: 0;
        margin-bottom: 0;
        padding-left: 5px;
      }

      h4 {
        font-size: em(13);
        font-weight: 500;
        overflow: hidden;
        margin-bottom: 0;

        button {
          float: right;
          font-size: rem(12);
          font-weight: 400;
        }
      }

      .guest {
        border-bottom: 1px solid $gray_very_light;
        padding: 20px 0 20px 10px;
        overflow: hidden;

        &:last-child {
          border-bottom: 0;
        }
      }

      .fields-wrap {
        display: none;
        margin-top: 12px;
        max-width: 500px;
      }

      .input-wrap {
        margin-bottom: 10px;

        &.half {
          @include span-columns(6);
        }

        &.omega {
          @include omega();
        }
      }

      label {
        font-size: em(11);
        color: $dark_very_grayish_red_2;
        font-weight: 400;
        text-indent: 10px;
      }

      .additional-info {
        margin-top: 28px;

        h3 {
          font-size: rem(11);
          line-height: 2.8;
          margin-bottom: 15px;
        }

        textarea {
          max-width: 500px;
          height: 135px;
          background: rgba(234, 233, 236, 0.26);
          border: 1px solid $gray_grayish_violet;
          border-radius: 3px;
          font-size: rem(14px);
          color: $dark_very_grayish_red;
        }
      }

      .message {
        font-size: rem(14);
        color: $orange_bright;
        border: 1px solid $orange_bright;
        padding: 5px;
        margin: 15px 0;
        font-weight: 600;
      }

      .form-actions {
        margin: 0;
        text-align: center;

        input[type="reset"] {
          margin-left: 22px;
        }
      }
    }
  }

  button.booked-event-add-details {
    color: $orange_bright_2;
    font-size: em(12);
    text-transform: lowercase;
    font-weight: 400;
    line-height: 1.25;
    padding: 0;
    @include transition(all 333ms linear);
  }

  /** Profile Your Meetings **/
  .profile-meeting {

    @include media($mobile) {
      padding: 25px 0 45px;
    }
  }

  /** Profile Personal Details **/
  .profile-pages-personal-details {
    @include clearfix();
    position: relative;

    h3 {
      text-transform: uppercase;
      font-size: em(11);
      font-weight: 300;
      border-bottom: 1px solid $gray_dark;
      letter-spacing: $spaced-letters;
      padding-bottom: 3px;
      margin-bottom: 0;
    }

    @include media($mobile) {
      padding: 25px 0 45px;

      h3 {
        font-size: rem(10);
        padding-bottom: 0;
      }
    }
  }

  /** Profile Memberships **/
  .profile-memberships {

    .membership {
      margin-bottom: 80px;
    }

    section {
      margin-bottom: 50px;
    }

    h3,
    h4 {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: rem(18);
      line-height: 1.28;
      letter-spacing: -0.53px;
      color: $dark_grayish_red;
    }

    .membership-meta {
      font-size: rem(11);
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 10px;
      color: $dark_very_grayish_red_2;
    }

    .text {
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.42;
      color: $dark_very_grayish_red;
      margin-bottom: 17px;
    }

    .file-downloads {
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        text-align: center;
        margin-right: 15px;
      }

      .file-info {
        margin-top: -3px;
        font-size: em(11);
        text-transform: uppercase;
        color: $dark_very_grayish_red_2;
      }
    }

    @include media($mobile) {
      padding: 25px 0 45px;
      position: relative;

      h2 {
        font-size: rem(20);
        margin-bottom: 25px;
      }

      section {
        margin-bottom: 45px;
      }
    }
  }

  /** Profile Your Tickets **/
  .profile-your-tickets {

    section {
      margin-bottom: 100px;
    }

    .ticket-allowance-summary {
      // background: $gary_light_orange;
      @include clearfix();

      .inner {
        @include media($overtablet) {
          // @include span-columns(10);
        }
      }

      h2 {
        font-size: rem(17);
        font-weight: 600;
        border-bottom: 1px solid $gray_dark;
        color: $dark_very_grayish_red;
        padding-bottom: 3px;
        letter-spacing: -0.4px;
        margin-bottom: 8px;
      }

      p {
        font-size: rem(14);
        color: $gray_dark;
        line-height: 1.57;
        margin-bottom: 43px;
      }
    }

    .ticket-allowance-summary-item {
      border-bottom: 1px solid $gray_grayish_violet;
      font-size: em(13);
      padding: 10px 0;
      @include clearfix();

      .event-type {
        float: left;
        font-weight: 500;
      }

      .benefit {
        float: right;
        font-size: em(14);
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    @include media($mobile) {
      padding: 25px 0 45px;

      section {
        margin-bottom: 50px;
      }

      h2 {
        margin-bottom: 25px;
      }

      .profile-item {
        display: block;
      }

      .ticket-allowance-summary {
        padding: 20px;

        @include media($mobile) {
          @include pad(20px 0)
        }

        h2 {
          font-size: rem(15);
        }

        p {
          font-size: rem(13);
          margin-bottom: 20px;
        }
      }
    }
  }

  /** Profile Company Information **/
  .profile-company-information {
    @include clearfix();

    .profile-pages-company-form {
      @include span-columns(9);
    }

    input:not([type="submit"]) {
      background: $gary_light_orange;
    }

    label {
      padding-left: 15px;

      @include media($mobile) {
        padding-left: 0;
      }
    }

    label,
    p {
      font-size: rem(11);
      font-weight: 400;
      color: $dark_very_grayish_red_2;
    }

    .form-item {
      margin: 0 0 15px;

      &:first-of-type,
      &:nth-of-type(2) {
        display: inline-block;
        width: calc((100% / 2) - 14px);
      }

      &:first-of-type {
        margin-right: 23px;
      }
    }

    .select2-container {
      display: block;
      font-size: rem(14);
      width: 100% !important;
      outline: none;
      margin-bottom: 5px;
      @include transition(all 333ms linear);

      .select2-selection--multiple {
        border: 1px solid $gray_grayish_violet !important;
        padding: 5px 15px 0;
        border-radius: 3px;
        background: $gary_light_orange;
      }

      .select2-selection__rendered {
        padding: 0;

        li {
          margin: 3px 5px;
          background: $white;
          border-radius: 40px;
          border: 0;
          font-size: rem(11);
          text-transform: uppercase;
          color: $dark_very_grayish_red_3;
          letter-spacing: 2px;
          padding: 7px 10px 5px 13px;

          .select2-selection__choice__remove {
            float: right;
            font-size: 13px;
            font-weight: normal;
            margin: 0 0 0 3px;
            line-height: 1.5;
          }
        }

        .select2-search--inline {
          display: none;
        }
      }

      &.select2-container--focus .select2-selection--multiple {
        background: $gary_light_orange;
      }
    }

    #field_summary {
      padding: 20px;
      height: 150px;
      background: $gary_light_orange !important;
    }

    #body {
      padding: 20px;
      height: 380px;
      background: $gary_light_orange !important;
    }

    .form-actions {
      text-align: center;

      input {
        margin: 0 10px;
      }
    }

    @include media($desktop) {

      .profile-pages-company-form {
        width: 100%;
        margin-right: 0;
      }
    }

    @include media($mobile) {
      padding: 25px 0 35px;

      .form-item {

        &:first-of-type,
        &:nth-of-type(2) {
          width: 100%;
        }

        &:first-of-type {
          margin-right: 0;
        }
      }

      .select2-container {
        left: 0 !important;
      }
    }
  }

  /** Profile Company Representatives **/
  .profile-company-representatives {
    position: relative;

    p {
      clear: both;
      color: $dark_very_grayish_red_2;
      font-size: rem(11);
      margin-bottom: 5px;
    }

    .company-representative {
      overflow: hidden;
      border-bottom: 1px solid $gray_very_light;
      padding: 23px 0 30px;

      &:last-child {
        border-bottom: 0;
      }

      .picture {
        display: inline-block;
        vertical-align: middle;
        width: 115px;
        margin-right: 30px;

        img {
          width: 100%;
          height: auto;
          overflow: hidden;
          border-radius: 50%;
        }
      }

      .text {
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 150px);
      }

      h3 {
        font-size: rem(17);
        margin-bottom: 5px;
        font-weight: 600;
        color: $dark_very_grayish_red;
      }

      .job-title {
        font-size: rem(14);
        color: $dark_very_grayish_red_2;
        margin-bottom: 13px;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: rem(12);
      }

      li {
        margin-bottom: 6px;
        color: $dark_very_grayish_red_2;
      }
    }

    @include media($mobile) {
      padding: 25px 0 45px;

      h2 {
        margin-bottom: 15px;
      }

      .company-representative {
        padding: 15px 0;

        .picture {
          vertical-align: top;
          width: 84px;
          margin-right: 15px;
        }

        .text {
          vertical-align: top;
          width: calc(100% - 105px);
        }

        h3 {
          font-size: rem(15);
        }

        .job-title {
          font-size: rem(12);
          margin-bottom: 5px;
        }

        li {
          margin-bottom: 0;
        }
      }
    }
  }

  .main-wrapper.no-membership,
  .main-wrapper.reps-count-0 {

    .sidebar-menu.sidebar .menu #public-profile {
      display: none;
    }
  }

  .profile-page-mobile-current-section {
    display: none;
  }

  /** Mobile Version **/
  @include media($mobile) {

    .sidebar-menu {
      display: inline-block;
      margin-right: 0;
      padding: 13px 21px 11px;
      background: rgba(251, 249, 248, 0.96);
      z-index: 2;

      .profile-page-mobile-current-section {
        position: relative;
        display: inline-block;
        font-size: rem(12);
        color: $dark_very_grayish_red_3;
        font-weight: 600;

        &:after {
          content: '';
          @include size(14px 2px);
          @include position(absolute, null null -11px 0);
          background: $orange_bright_2;
        }
      }

      .btn-more {
        display: block;
      }

      ul.menu {
        display: none;
        padding: 15px 0 5px;

        li {
          width: 100%;
          max-width: inherit;
        }
      }

      li .menu {
        display: block;
        padding: 0;
        border: 0;
        margin-bottom: 10px;

        li {
          margin: 0;
          padding: 8px 0;
          font-size: rem(13);

          a {
            @include transition(all 111ms linear);
          }

          &.active-trail {
            border: 0;
          }

          &:hover {

            a {
              font-weight: 600;
            }
          }
        }
      }
    }

    .main-with-sidebar {
      @include pad(0);

      @include media($tablet) {
        @include pad(20px);
      }
    }

    .profile {
      margin: 0;

      section {
        margin-bottom: 0;
        border-bottom: 1px solid $gray_very_light;
        @include pad(12px 26px 10px 21px);

        @include media($mobile) {
          @include pad(12px 0 10px);
        }

        &.toggled {
          @include pad(15px 26px 25px 21px);

          @include media($mobile) {
            @include pad(15px 0 25px);
          }

          .icon-title {
            background: url('../../images/build/profile-arrow_close.svg') no-repeat right center;
            background-size: 10px auto;
            margin-bottom: 15px;
          }

          .profile-item,
          p {
            display: block;
          }
        }
      }

      .icon-title {
        cursor: pointer;
        font-size: rem(15);
        font-weight: 600;
        line-height: 1.67;
        letter-spacing: -0.44px;
        margin-bottom: 0;
        background: url('../../images/build/profile-arrow_open.svg') no-repeat right center;
        background-size: 10px auto;

        .icon,
        a {
          display: none;
        }
      }

      h3 {
        font-size: rem(16);
        margin-bottom: 8px;
      }

      .text {
        font-size: rem(12);
      }

      p {
        display: none;
      }
    }

    .profile-item {
      display: none;
      padding: 0;

      .profile-item-title {
        font-size: rem(14);
        margin-bottom: 3px;
        line-height: 1.35;
      }

      .item-wrapper {
        padding: 0 19px 0 16px;
      }

      .profile-item-meta {
        font-size: rem(11);
        line-height: 1.45;
        margin: 0 0 7px;
      }

      .profile-item-summary {
        color: $gray_dark;
        font-size: rem(11);
        margin-bottom: 0;
      }

      .booked-event-actions {
        width: 100%;
        margin: 10px 0;
        @include clearfix();
        @include position(relative, inherit);
      }

      .ticket-call-action {
        margin-top: 0;
      }

      &.open {
        padding: 0;

        .item-wrapper {
          padding: 12px 13px 14.5px 16px;
        }

        .profile-item-title {
          margin-bottom: 3px;

          &:after {
            content: none;
          }
        }

        .profile-item-meta {
          margin-bottom: 0;
        }

        .profile-item-summary {
          display: none;
        }

        .booked-event-close {
          top: 25px;
          font-size: rem(17);
          font-weight: 500;
        }

        &:before {
          display: none;
        }
      }

      .form-wrap {
        background: $white;
        padding: 14px 14px 20px;

        h3 {
          font-size: rem(10);
        }

        h4 {
          font-size: rem(13);
        }

        .guest {
          padding: 20px 0;
        }

        .fields-wrap {
          margin-top: 8px;
          max-width: 100%;
        }

        .input-wrap {
          width: 100%;
          margin-bottom: 15px;

          &.half {
            width: 100%;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .additional-info {
          margin-top: 8px;

          h3 {
            font-size: rem(10);
            line-height: 1.4;
            margin-bottom: 13px;
          }

          textarea {
            margin-top: 4px;
            max-width: 100%;
            font-size: rem(13px);
          }
        }

        .form-actions {
          input[type="reset"] {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.profile-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  .profile-column-heading {
    margin-bottom: 0;
  }
}

h2.profile-column-heading {
  font-size: rem(24px);
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: -0.03em;
  line-height: 1.25;
  margin-bottom: 34px;
}

/** Profile Your Publications **/
.profile-your-publications {

  .profile-item {
    @include media($mobile) {
      display: block;
    }

    .item-wrapper {
      @include media($mobile) {
        padding: 0;
      }
    }
  }

  &-cta {
    font-size: rem(16);
    color: color(white);
    @include caret(color(white));
    @include pad(8px 22px 7px 16px);
    display: none;

    &::after {
      margin-left: 10px;
    }

    .page-profile-your-publications & {
      display: inline-block;

      @include media($mobile) {
        display: none;
      }
    }
  }
}

.profile-booked-events {

  &-cta {
    font-size: rem(16);
    color: color(white);
    @include caret(color(white));
    @include pad(8px 22px 7px 16px);

    @include media($mobile) {
      display: none;
    }

    &::after {
      margin-left: 10px;
    }
  }
}

.profile-personal-details {}

.section-network-message {
  background: #FAF9FA;
  padding: 30px 60px;
  border-left: 3px solid $orange_bright;

  @include media($tablet) {
    display: none;
  }

  h2 {
    color: $orange_bright;
    text-transform: uppercase;
    font-size: rem(17);
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  .text p {
    font-size: rem(14);
    color: $dark_grayish_red;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: $orange_bright;
  }

}

div.addeventatc {
  border: 0;
  background: none;
  font-family: $font__main;
  color: $orange_bright_2 !important;
  font-size: em(12);
  text-transform: lowercase;
  font-weight: 400;
  line-height: 1.25;
  padding: 0;
  @include transition(all 111ms linear);

  &:hover {
    font-size: em(12);
    outline: none;
    border: 0;
  }

  .addeventatc_icon {
    display: none;
  }

  &:active {
    top: 0;
  }

  &:focus {
    border: 0;
  }

  .addeventatc_dropdown {
    top: 23px !important;

  }

  .copyx {
    display: none !important;
  }
}

/** Profile Tickets History **/
.profile-tickets-history {
  display: none;

  h2 {
    margin-bottom: 24px;
  }

  .view-filters {
    margin: 0 0 36px;
    width: 285px;
    float: right;
    position: relative;
  }

  .views-exposed-widgets {
    margin: 0;

    label {
      display: none;
    }
  }

  .views-widget-filter-title_field_value {
    padding: 0;
    width: 100%;
    background: rgba(234, 233, 236, 0.26);

    input[type="text"] {
      border: 0;
      background: transparent;
      padding-left: 35px;
    }
  }

  .views-submit-button {
    @include size(11px 100%);
    padding: 0;
    margin: auto;
    @include position(absolute, 0 null 0 13px);

    input[type="submit"] {
      margin: auto 0;
      border: 0;
      padding: 0;
      font-size: 0;
      @include size(11px);
      @include position(absolute, 0 null 0 0);
      background: url('../../images/build/search.svg') no-repeat center;
      background-size: 100% auto;
      border-radius: inherit;
    }
  }

  table {
    width: 100%;

    th {
      font-size: rem(11);
      text-transform: uppercase;
      letter-spacing: 2.8px;
      color: $dark_very_grayish_red_3;
      text-align: center;
      padding: 0 0 10px;
      border-bottom: 1px solid $gray_dark;

      &:first-of-type {
        text-align: left;
      }
    }

    td {
      text-align: center;
      border-bottom: 1px solid $gray_very_light;

      &:first-of-type {
        font-size: rem(13);
        font-weight: 500;
        padding: 10px 0 5px;
        text-align: left;
      }

      &:nth-child(2) {
        font-size: rem(11);
        color: $dark_very_grayish_red_2;
      }

      &:last-of-type {

        a {
          font-size: rem(12);
        }
      }
    }
  }
}

.email-exists-message {
  padding: 40px !important;

  button {
    margin-right: 10px;
  }
}

.page-profile-meeting-notes {

  .select2-container .select2-dropdown.select2-dropdown--above {
    margin-top: 0;
  }

  @include media($mobile) {
    .main-with-sidebar {
      padding: 0;

      >.profile-meeting {
        display: flex;
        flex-direction: column;
        padding-top: 0;

        .profile-column-heading-wrapper {
          order: 2;
          padding: 20px;
        }

        .meeting-note-network-filter-form {
          order: 1;
          padding: 24px 20px;
          background-color: color(combiz_light_gray);
        }

        .meeting-note-wrapper {
          order: 3;
          padding: 20px;
        }
      }

      h2.profile-column-heading {
        margin-bottom: 0;
      }
    }

    .select2-container .select2-dropdown:not(.select2-dropdown--above)::before {
      left: calc(75% - 50px);
    }

    .field-type-youtube .field-items>div {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    /* Then style the iframe to fit in the container div with full height and width */
    .youtube-field-player {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .select2 {
    &-dropdown {
      min-width: 320px;
    }

    &-container--default {
      .select2-selection--single {
        border: none;
      }
    }
  }

  .meeting-note {
    &.profile-item {
      @include media($mobile) {
        display: block;
      }
    }

    &-network-filter-form {
      >div {
        display: flex;
        border: 1px solid $gray_very_light;
        justify-content: space-between;
        width: 230px;
        margin-bottom: 56px;

        @include media($mobile) {
          margin-bottom: 0;
          background-color: $gray_very_light;
          width: 100%;
          align-items: center;
        }
      }
    }

    &-filter {

      &-fieldwrapper {
        width: calc(100% - 60px);
        border-right: 1px solid $gray_very_light;
        padding: 0 28px;
        display: flex;
        flex-direction: column;

        label {
          order: 1;
        }

        span {
          order: 2;
        }

        select {
          order: 3;
        }

        @include media($mobile) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .select2-container--default .select2-selection--single {
            background-color: transparent;
          }
        }

        #select2-meeting_notes_filter_network-container {
          padding-left: 0;

          @include media($mobile) {
            font-size: 13px;
          }
        }

        label {
          color: $dark_very_grayish_red_3;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          margin-top: 8px;

          @include media($mobile) {
            margin-top: 0;
          }
        }
      }

      .select2-container {
        width: auto !important;
      }
    }

    &-submit {
      background: url("../../images/build/search.svg") no-repeat center;
      background-size: 11px;
      font-size: 0;
      width: 60px;
      height: 60px;
      border: none;
      padding: 0;
    }
  }

}
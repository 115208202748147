.league-table-header {
  overflow: hidden;
  font-size: rem(10);
  color: #999;
  text-transform: uppercase;
  margin-bottom: 17px;

  & > div {
    float: left;
  }

  .rank {
    width: 65px;
    text-align: center;
  }

  .name {
    width: calc(100% - 300px);
    padding-left: 70px;
  }

  .new {
    width: 74px;
    text-align: center;
  }

  .percent {
    width: 150px;
    padding-left: 50px;
  }

  @include media($tablet) {
    display: none;
  }
}

.league-table-item {
  position: relative;
  margin-bottom: 5px;
  background: $white;
  font-weight: 600;
  cursor: pointer;
  @include row();
  padding: 18px 0 17px;
  border-radius: 3px;

  .rank {
    overflow: hidden;
    @include size(65px 100%);
    @include position(absolute, 0 null null 0);

    &:before {
      content: '';
      display: block;
      @include size(188px);
      background: $orange_bright_2;
      @include position(absolute, 50% 0 null null);
      @include transform(translateY(-50%));
      border-radius: 50%;
      z-index: 0;
    }

    .value {
      z-index: 1;
      font-size: rem(20);
      font-weight: 600;
      color: #fff;
      text-align: center;
      width: 100%;
      @include position(absolute, 50% null null 0);
      @include transform(translateY(-50%));
    }
  }

  .movement-and-name, .new, .percent,
  .movement, .name {
    display: inline-block;
    vertical-align: middle;
  }

  .movement-and-name {
    width: calc(100% - 245px);
    padding-left: 77px;
  }

  .movement {
    color: #999;
    width: 42px;
    font-size: rem(13);
    line-height: 1;

    &.up {
      color: $orange_bright_2;
    }

    &.down {
      color: $dark_very_grayish_red_3;
    }
  }

  .name {
    font-size: rem(17);
    line-height: 1;
    padding-left: 23px;
    width: calc(100% - 47px);
  }

  .new {
    width: 74px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .percent {
    width: 150px;
    padding: 0 42px;
    font-size: rem(22);
    color: $orange_bright;
    letter-spacing: -0.52px;
  }

  .arrow {
    @include size(32px);
    border-radius: 50%;
    @include position(absolute, 0 -16px 0 null);
    margin: auto;
    background: $dark_very_grayish_red_3;
    text-align: center;
    padding-top: 3px;
  }

  @include media($mobile) {
    padding: 37px 0 34px;
    border: 1px solid #eaeaea;

    .rank {
      width: 30px;

      .value {
        font-size: rem(14);
      }
    }

    .movement-and-name {
      width: calc(100% - 110px);
      padding-left: 46px;
    }

    .movement {
      display: block;
      width: 100%;
      font-size: rem(12);
      margin-bottom: 4px;
    }

    .name {
      display: block;
      width: 100%;
      font-size: rem(12);
      padding: 0;
    }

    .new {
      width: 34px;
      padding: 0 10px;
    }

    .percent {
      width: 68px;
      padding: 0 25px 0 0;
      font-size: rem(16);
    }
  }
}

.leaague-table-popup {
  display: none;
}

.fancybox-slide > .leaague-table-popup-inner,
.fancybox-slide > .analysis-popup-inner {
  background-color: $gray_grayish_blue;
  padding: 0;

  @include media($overtablet) {
    width: 750px;
  }

  .analysis-popup-text {
    padding: 43px 74px;
    font-size: rem(14);

    p {
      font-size: rem(14);
    }
  }

  .header {
    background: $white;
    text-align: center;
    position: relative;
    padding: 30px 120px 30px 120px;
  }

  .name {
    font-size: rem(24);
    font-weight: 600;
    line-height: 1.3;
  }

  .rank-wrap {
    position: absolute;
    left: 0;
    top: 20px;
    overflow: hidden;
    width: 105px;
    color: $white;
    height: 60px;
    padding-top: 13px;

    &:before {
      content: '';
      display: block;
      width: 188px;
      height: 188px;
      background: $orange_bright_2;
      position: absolute;
      top: -64px;
      right: 0;
      border-radius: 188px;
      z-index: 1;
    }
    span {
      position: relative;
      z-index: 2;
    }
  }
  .rank {
    font-size: rem(20);
    font-weight: 600;
    padding: 0 5px;
  }
  .movement {
    font-size: rem(13);
    font-weight: 600;
    color: #ffd1ba;
  }

  dl.info {
    text-align: center;
    margin: 40px 0;

    & > div {
      display: inline-block;
      margin: 0 25px;
    }
  }

  dl.people {
    text-align: center;

    dd {
      margin-bottom: 25px;
    }
  }

  dt {
    color: #999;
    font-size: rem(10);
    text-transform: uppercase;
  }

  dd {
    color: $dark_very_grayish_red_3;
    font-size: rem(19);
    margin: 0;
    padding: 0;
    font-weight: 600;
  }

  @include media($mobile) {

    .header {
      padding: 60px 20px 18px;
    }

    .name {
      font-size: rem(18);
      line-height: 1.4;
    }

    .rank-wrap {
      right: 0;
      margin: auto;
      top: 0;
      width: 110px;
      height: 55px;
      padding-top: 10px;

      &:before {
        @include position(absolute, initial initial 0 -50%);
        @include transform(translateX(9%));
        margin: auto;
      }
    }

    dl.info {
      margin: 30px 0 0;
      padding: 0 20px;
      @include row();

      & > div {
        float: left;
        width: 50%;
        padding: 0 15px;
        margin: 0 0 35px;
      }
    }

    dt {
      margin-bottom: 2px;
    }

    dd {
      font-size: rem(16);
    }
  }
}

.fancybox-slide > .leaague-table-popup-inner {

  .movement {
    top: -1px;
  }
}

.most-eventful {

  .league-table-item {
    padding: 32px 0 33px;
    margin: 0 30px 20px;
    border: 1px solid #eaeaea;
  }

  .movement-and-name {
    width: calc(100% - 125px);
  }

  .name {
    font-size: 16px;
    padding-left: 11px;
  }

  .new {
    @include size(25px);
    border-radius: 50%;
    @include position(absolute, -12.5px -12.5px null null);

    &.is-new {
      //background: url('../../images/build/Star.png') no-repeat center 5px #fc682d;
      //background-size: 15px auto;
    }
  }

  .percent {
    width: 120px;
    padding: 0 27px;
    font-size: 27px;
  }

  @include media($mobile) {

    .league-table-item {
      padding: 18px 0 17px;
      margin: 0 23px 15px;
    }

    .movement-and-name {
      width: calc(100% - 60px);
      padding-right: 10px;
    }

    .name {
      font-size: 12px;
      padding-left: 0;
    }

    .percent {
      width: 52px;
      padding: 0;
      font-size: 20px;
    }
  }
}

.report-section-top-10 {

  .league-table-item {
    padding: 10px 0;
    margin: 0 30px 5px;
    border: 1px solid #eaeaea;
  }

  .movement-and-name {
    width: calc(100% - 110px);
  }

  .name {
    font-size: 14px;
    padding-left: 11px;
  }

  .new {
    width: 24px;
    padding: 0 5px;
  }

  .percent {
    width: 75px;
    padding: 0 15px 0 10px;
    font-size: 16px;
  }

  @include media($mobile) {

    .league-table-item {
      padding: 18px 0 17px;
      margin: 0 23px 15px;
    }

    .movement-and-name {
      width: calc(100% - 90px);
      padding-right: 10px;
    }

    .name {
      font-size: 12px;
      padding-left: 0;
    }

    .percent {
      width: 52px;
      padding: 0;
      font-size: 16px;
    }
  }
}
// Border Radius (Shorthand)
// Provides a shorthand syntax to target and add border radii to both corners on one side of a box

@mixin border-top-radius($radii) {
  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-right-radius($radii) {
  border-bottom-right-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-bottom-radius($radii) {
  border-bottom-left-radius: $radii;
  border-bottom-right-radius: $radii;
}

@mixin border-left-radius($radii) {
  border-bottom-left-radius: $radii;
  border-top-left-radius: $radii;
}

@mixin word-wrap($wrap: break-word) {
  overflow-wrap: $wrap;
  word-wrap: $wrap;

  @if $wrap == break-word {
    word-break: break-all;
  } @else {
    word-break: $wrap;
  }
}

.organization {

  &-inner {
    @include pad(69px 0 56px);

    @include media($tablet) {
      @include pad(21px 0 56px);
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 15px;
    margin-bottom: 70px;

    @include media($tablet) {
      display: block;
    }
  }

  &-title {
    font-size: rem(25px);
    line-height: 1.5;

    @include media($tablet) {
      margin-bottom: 13px;
    }
  }

  &-desc {
    max-width: 796px;
    width: 100%;
    color: color(combiz_black);
    font-size: rem(18);
    margin-bottom: 0;
    line-height: 1.5;

    @include media($tablet) {
      font-size: rem(16);
    }

    strong {
      font-size: inherit;
      display: block;
      font-weight: 500;
      line-height: 1.52;
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 27px;

    &[data-count="1"] {
      grid-template-columns: 1fr;

      .organization-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    @include media($tablet) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-card {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f7f7f7;
  }

  &-frame {
    // width: 314px;
    width: 53.58%;
    flex-shrink: 0;
    min-height: 312px;

    @include media($tablet) {
      min-height: 245px;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: color(gray_light_300);
  }

  &-content {
    @include pad(25px 30px 25px 19px);

    @include media($desktop) {
      @include pad(15px 10px 15px 15px)
    }

    @include media($tablet) {
      @include pad(11px 10px 15px 15px)
    }
  }

  &-heading {
    font-size: rem(25px);
    color: color(combiz_blue);
    margin-bottom: 3px;

    @include media($desktop) {
      font-size: rem(20);
    }

    @include media($mobile) {
      font-size: rem(18);
      margin-bottom: 12px;
    }
  }

  &-par {
    font-size: rem(16px);
    color: color(combiz_black);
    font-weight: 400;
    margin-bottom: 14px;
    letter-spacing: normal;
    line-height: 1.5;

    @include media($desktop) {
      font-size: rem(13);
      margin-bottom: 11px;
    }

    @include media($mobile) {
      font-size: rem(10);
    }
  }

  &-cta {
    font-size: rem(12.8px);
    @include pad(7px 23px 6px 15px);
    @include caret(color(white));

    @include media($desktop) {
      @include pad(7px 22px 7px 16px);

      .is-firefox & {
        @include pad(7px 17px 7px 10px)
      }
    }

    &::after {
      margin-left: 9px;
      top: -1px;
    }
  }
}

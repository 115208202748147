.not-found {
  position: relative;
  background-color: color(combiz_light_gray);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 619px;

  @include media($mobile) {
    @include pad(0 20px);
    min-height: 462px;
  }

  &-inner {
    max-width: 630px;
    width: 100%;
  }

  &-heading {
    font-size: rem(49);
    font-weight: 600;
    margin-bottom: 13px;
    color: color(combiz_blue);
    letter-spacing: -0.03em;
    margin-bottom: 3px;
  }

  &-desc {
    font-size: rem(14);
    margin-bottom: 30px;
    line-height: 1.7;
  }

  &-cta {
    font-size: rem(12);
    @include pad(9px 23px 9px 16px);
    letter-spacing: 0.04em;
    position: relative;
    @include caret(color(white));

    &::after {
      top: -1px;
      margin-left: 9px;
    }
  }

  &-search-form {
    position: relative;
    margin-top: 38px;

    &-field[type="text"] {
      @include pad(21px 40px 16px);
      font-size: rem(17);
      font-weight: 600;
      color: color(gray);
      letter-spacing: -0.01em;
    }

    &-button {
      position: absolute;
      top: 0;
      right: 0;
      background: url(../../images/build/search.svg) center center no-repeat;
      border: 0;
      text-indent: -9999px;
      overflow: hidden;
      width: 63px;
      height: 64px;
      outline: none;
    }
  }

  &-submit {
    margin-top: 20px;
  }

  @include media($mobile) {

    &:before {
      @include size(135px);
      @include position(absolute, -50px 25px null null);
    }

    &:after {
      content: none;
    }

    .inner {
      padding: 0 20px;
      margin: 90px auto 105px;
    }

    h1 {
      font-size: rem(22);
      margin-bottom: 4px;
    }

    .text {
      font-size: rem(13);
      margin-bottom: 15px;
    }

    .primary-call-action {
      margin-left: 0;
    }

    form {
      margin-top: 20px;

      input[type="text"] {
        padding: 10px 20px;
      }

      input[type="submit"] {
        @include size(45px);
      }
    }
  }
}
#comments {
  @include pad(47px 48px 43px 45px);
  background: $gary_light_orange;
  border-radius: 3px;

  .comment-title {
    font-size: em(17);
    font-weight: 600;
    line-height: 1.29;
    margin-bottom: 10px;
    letter-spacing: -0.4px;
    color: $dark_very_grayish_red;
  }

  .text {
    color: $dark_very_grayish_red_2;
    font-size: em(11);
    line-height: 1.45;
    margin-bottom: 11px;
  }

  .form-type-textarea {
    margin: 0 0 11px;

    .text-full {
      border-color: $gray_grayish_violet;
      background: $gary_light_orange;
    }
  }

  .form-actions {
    margin: 11px 0 30px;

    input[type="submit"] {
      font-size: em(12);
      font-weight: 500;
      line-height: 1.16;
      @include pad(8px 13px 6px 14px);
    }
  }

  .comment {
    @include pad(20px 0 0);

    &:first-of-type {
      border-top: 1px solid $gray_very_light;
    }

    .submitted {
      margin-bottom: 9px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .created {
        margin-left: 5px;
        font-size: 11px;
        line-height: 1.45;
        color: $dark_very_grayish_red_2;
      }

      .name {
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: $dark_very_grayish_red;
      }
    }

    .content {
      font-size: 12px;
    }
  }

  .form-item-name,
  .grippie, .links,
  .title, label,
  .warning {
    display: none;
  }

  @include media($mobile) {
    margin-top: 0;
    @include pad(40px 20px);

    .comment-title {
      margin-bottom: 0;
    }

    .text {
      margin-bottom: 15px;
    }

    .form-item {
      margin: 10px 0;

      .username {
        font-size: rem(14);
      }
    }

    .form-type-textarea {
      margin: 0 0 15px;
    }

    .form-actions {
      margin: 15px 0;
    }

    .comment {

      .submitted {
        margin-bottom: 9px;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .created {
          margin-left: 5px;
          font-size: 11px;
          line-height: 1.45;
          color: $dark_very_grayish_red_2;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          color: $dark_very_grayish_red;
        }
      }

      .content {
        font-size: 12px;
      }
    }
  }
}
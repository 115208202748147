#{$all-text-inputs},
textarea,
.select2-container .select2-selection--multiple {
  border: 1px solid $gray_grayish_violet;
  border-radius: 3px;
  background: $white;
  color: $dark_very_grayish_red;
  padding: 7px 13px;
  font-size: 14px;
  width: 100%;
  outline: none;
  @include transition(all 333ms linear);

  &:focus,
  &.not-empty {
    background: $gary_light_orange;
  }
}

textarea {
  resize: none;
  font-size: 14px;
  @include pad(10px 13px);
  background: $white;
  border: 1px solid $gray_grayish_violet;
  outline: none;
  @include transition(all 333ms linear);

  &:focus {
    background: $gary_light_orange;
  }
}

.form-submit {
  text-align: center;
  border: 1px solid color(combiz_blue);
  border-radius: 40px;
  @include pad(12px 22px 11px);
  background: color(combiz_blue);
  font-size: em(14);
  font-weight: 600;
  color: color(white);
  letter-spacing: -0.44px;
  line-height: 1;
  @include transition(all 333ms linear);

  &:hover {
    background: $orange_bright_3;
    border: 1px solid $orange_bright_3;
    color: $white;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
  }

  @include media($tablet) {
    @include pad(12px 18px 10px);
    font-size: em(12);
  }
}

select {
  visibility: hidden;
}

.select2-container {
  display: inline-block !important;

  .select2-selection {
    outline: none;
  }
}

//basic-sort-select
.basic-sort-select {
  position: relative;
  background: $white;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  min-width: 200px;
  @include pad(19px 26px 10px);
  @include transition(all 333ms ease-out);

  &:before {
    content: '';
    @include position(absolute, -5px null null 0);
    @include size(100% 3px);
    @include transition(all 222ms ease-out);
    background: $orange_bright_2;
  }

  &.focus {
    background: $white !important;

    &:before {
      top: 3px;
    }

    .sort-container {
      opacity: 0.6;
    }
  }

  &:hover {
    background: $gray_grayish_blue;
  }

  .title {
    font-size: em(10);
    letter-spacing: 1.6px;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0 0 2px;
  }

  .sort-container {
    font-size: em(13);
    line-height: 1.9;
    letter-spacing: 0.38px;
    font-weight: 500;
    background: url('../../html_prototype/images/sort-arrow.svg') no-repeat right center;
    background-size: 6.5px auto;
    cursor: pointer;
    @include pad(2px 24px 2px 2px);
  }

  .sort-select {
    position: relative;
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: em(12);
      line-height: 1.25;
      margin: 10px 0;
      cursor: pointer;
    }
  }
}

//basic-sort-input
.basic-sort-input {
  position: relative;
  display: inline-block;
  background: $white;
  overflow: hidden;
  @include pad(24px 19px 21px 29px);
  @include transition(all 333ms ease-out);

  &:before {
    content: '';
    @include position(absolute, -5px null null 0);
    @include size(100% 3px);
    @include transition(all 222ms ease-out);
    background: $orange_bright_2;
  }

  &.focus {
    background: $white !important;

    &:before {
      top: 3px;
    }
  }

  &:hover {
    background: $gray_grayish_blue;
  }

  input,
  .search {
    display: inline-block;
    vertical-align: middle;
  }

  input {
    font-size: em(13);
    font-weight: 500;
    line-height: 1.9;
    letter-spacing: -0.38px;
    min-width: 200px;
  }

  .search {
    cursor: pointer;
    @include size(11px);
    background: url('../../html_prototype/images/search.svg') no-repeat center;
    background-size: 11px;
  }
}

//search-input
.search-input {
  position: relative;
  background: $gary_light_orange;
  border: 1px solid $gray_very_light;
  border-radius: 3px;
  display: inline-block;
  @include pad(11px 19px 11px 16px);

  input,
  .search {
    display: inline-block;
    vertical-align: middle;
  }

  input {
    min-width: 200px;
    line-height: 1.7;
    font-size: em(13);
    font-weight: 600;
    color: $dark_very_grayish_red;

    @include placeholder {
      font-size: em(15);
      color: $dark_very_grayish_red_2;
    }
  }

  .search {
    cursor: pointer;
    @include size(18px);
    background: url('../../html_prototype/images/search.svg') no-repeat center;
    background-size: 15px;
    @include transition(all 222ms ease-out);

    &:hover {
      @include transform(scale(1.1));
    }
  }
}

//login-form
.login-form {
  text-align: left;

  label {
    font-size: em(12);
    color: $dark_very_grayish_red;
    padding-left: 10px;
    line-height: 1.25;
  }

  .login-input {
    width: 100%;
    border-radius: 3px;
    font-size: em(12);
    color: $dark_grayish_violet;
    @include pad(7px 19px);
    border: 1px solid $gray_grayish_violet;
    margin-bottom: 33px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .btn-login {
    display: block;
    text-align: center;
    width: 90px;
    margin: 72px auto 19px;
  }
}

//entityform
.entityform {

  .select2-container {

    &.select2-container--focus {

      .select2-selection {
        background: $gary_light_orange;
      }
    }

    .select2-selection {
      outline: none;
      @include pad(9px 14px 6px 11px);
      background: $white;
      height: auto;
      border: 1px solid $gray_grayish_violet;

      .select2-selection__rendered {
        font-size: em(14);
        line-height: 1.64;
        padding: 0;
        color: $dark_very_grayish_red_2;
      }

      .select2-selection__arrow {
        @include position(absolute, 0 13px 0 null);
        margin: auto;
      }
    }
  }

  label {
    padding-left: 10px;
    font-size: em(11);
    color: $dark_very_grayish_red_2;
    font-weight: normal;

    .form-required {
      color: $dark_very_grayish_red_2;
    }
  }

  .grippie {
    display: none;
  }

  .form-actions {
    text-align: center;
    margin: 30px 0 0;
  }
}

form {
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: $white;
    border-radius: 22px;
    border: 0;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 5px 12px;
    margin-right: 10px;
    letter-spacing: $spaced-letters;
  }
}

.captcha {
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;

  .g-recaptcha {
    display: inline-block;
  }
}
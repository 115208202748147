.kard {
  display: block;
  border-radius: 20px;
  overflow: hidden;
  background-color: color(white);
  filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.05));
  min-height: 327px;

  &:hover {
    img {
      scale: 1;
    }
  }

  &-frame {
    height: 142px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      scale: 1.05;
      transition: scale 0.5s ease-in-out
    }
  }

  &-content {
    @include pad(25px 23px);
  }

  &-categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    gap: 0 13px;
  }

  &-category {
    font-size: rem(10px);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.172em;
    position: relative;

    &:not(:last-of-type) {
      &::after {
        position: absolute;
        display: inline-block;
        content: '|';
        color: inherit;
        font-size: inherit;
        right: -9px;
        top: 0;
      }
    }
  }

  &-time {
    font-size: rem(11px);
    color: color(dark_very_grayish_red_2);
    font-weight: 300;
    letter-spacing: 0.03em;
    display: block;
    margin-bottom: 2px;
  }

  &-title {
    font-size: rem(15px);
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: -0.03em;
    margin-bottom: 0;
    box-sizing: border-box;
    height: calc(1.33 * 3 * 1em);
    overflow: hidden;
    position: relative;

    // &::after {
    //   display: inline;
    //   content: '...'
    // }
  }

}
.featured {
  background-color: color(combiz_orange);

  &-inner {
    @include pad(39px 0 0 0);
  }

  &-content {
    text-align: center;
    margin-bottom: 34px;
  }

  &-title {
    color: color(white);
    margin-bottom: 25px;

    @include media($mobile) {
      text-align: left;
      font-size: rem(28);
      line-height: 1.3;
      margin-bottom: rem(16);
    }
  }

  &-desc {
    color: color(white);
    font-size: rem(15);
    letter-spacing: -0.03em;
    margin-bottom: 0;

    @include media($mobile) {
      font-size: 12px;
      line-height: 1.92;
      text-align: left;
    }
  }

  &-cards {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 20px;
    max-width: 965px;
    width: 100%;
    margin: 0 auto;

    &.count-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.count-2 {
      grid-template-columns: repeat(2, 1fr);
      max-width: 600px;
    }

    &.count-1 {
      grid-template-columns: 1fr;
      max-width: 300px;
    }

    @include media($mobile) {
      margin-bottom: rem(96) !important;

      .slick-dots {
        bottom: rem(-75);
      }
    }

    .networks-landing & {
      grid-template-columns: repeat(3, 225px);
    }

    .slick-dots {
      button:before {
        content: '';
      }
    }
  }

  &-card {
    text-align: center;
    background-color: color(white);
    border-radius: 20px;
    transition: translate 0.3s ease-in-out;
    flex-shrink: 0;
    // width: 225px;
    @include pad(24px 22px);
    display: block;

    &:hover {
      translate: 0 -10px;
    }

    &-link {
      display: block;
      min-height: 221px;
    }

    &-content {
      box-sizing: border-box;
      height: 100%;
    }

    &-icon {
      width: 25px;
      margin: 0 auto 13px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
      }
    }

    &-title {
      font-size: rem(18);
      font-weight: 600;
      line-height: 1.55;
      margin-bottom: 16px;
      letter-spacing: -0.03em;
      color: $dark;
    }

    &-desc {
      font-size: rem(12);
      margin-bottom: 0;
      line-height: 1.58;
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    @include pad(25px 0 40px);
  }

  &-cta {
    @include caret(color(combiz_orange));
    @include pad(6px 22px 6px 15px);
    font-size: rem(16);
    font-weight: 400;

    &::after {
      margin-left: 8px;
    }

    &.is-white {
      color: color(combiz_orange);
    }
  }

  &.orange-overlay {
    background-image: none !important;
    color: inherit;

    &::after {
      display: none;
    }
  }
}
//header
header {
  z-index: 20;
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;

  &.fixed {

    .content-wrapper {
      // @include pad(15px 0 10px);
    }

    .desktop-menu {

      .top {
        padding-bottom: 8px;
      }

      .logo {
        width: 340px;
      }

      .bottom ul li {
        padding-top: 12px;
      }
    }

    @include media($tablet) {

      .content-wrapper {
        // @include pad(15px 35px);
      }
    }

    @include media($mobile) {

      .content-wrapper {
        // @include pad(15px);
      }
    }
  }

  .content-wrapper {
    position: relative;
    // @include pad(25px 0 20px);
    @include transition(all 333ms linear);
    box-shadow: 0 0 60px 0 rgba(234, 233, 236, 0.40);

    ul {
      list-style: none;
      @include pad(0);
      @include margin(0);
    }

    @include media($tablet) {
      @include pad(13px 20px);
    }
  }

  .mobile-menu {

    .top {
      @include clearfix();
    }

    .menu-toggle {
      // cursor: pointer;
      // @include size(21px 12px);
      // @include position(absolute, null 0 null null);
      // @include transition(all 444ms ease-out);

      // @include media($mobile) {
      //   top: rem(24);
      //   right: rem(24);
      // }

      div {
        position: relative;
        @include margin(6px 0);
        @include size(100% 1.5px);
        @include transition(all 444ms ease-out);
        background: $dark_very_grayish_red;
        border-radius: 3px;

        &:before,
        &:after {
          content: '';
          border-radius: 3px;
          @include size(100% 1.5px);
          background: $dark_very_grayish_red;
          @include transition(all 444ms ease-out);
        }

        &:before {
          @include position(absolute, -6px null null 0);
        }

        &:after {
          @include position(absolute, null null -6px 0);
        }
      }
    }


    .aside {
      z-index: 10;
      @include position(fixed, 0 -500px null null);
      @include transition(all 444ms ease-out);
      @include size(262px 100%);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
      min-height: 100vh;
      overflow: scroll;
      background: $white;
      padding-bottom: 35px;
    }

    .menu-search {
      @include size(100% auto);
      @include pad(12px 20px 9px 40px);
      background: $gray_grayish_blue;

      input {
        display: inline-block;
        vertical-align: middle;
        outline: none;
        -webkit-appearance: inherit;
      }

      input[type="search"] {
        background: transparent;
        border: 0;
        box-shadow: none;
        font-size: em(11);
        letter-spacing: 2.8px;
        line-height: 1;
        @include pad(8px 6px);
        color: $color__input;
        width: calc(100% - 50px);
        margin-right: 10px;

        @include placeholder {
          text-transform: uppercase;
        }
      }

      input[type="submit"] {
        font-size: 0;
        border: 0;
        @include size(15px);
        cursor: pointer;
        background: url('../../html_prototype/images/search.svg') no-repeat center;
        background-size: 100% auto;
      }
    }

    .main-menu {
      @include pad(30px 8px 29.5px 34px);
      background: $white;
      margin-bottom: 46.5px;

      li:not(.extra) {
        position: relative;
        cursor: pointer;
        line-height: 0.9;
        margin-bottom: 25px;
        @include pad(2px 0 1px 11px);
        @include transition(all 333ms ease-out);

        &:before {
          content: '';
          opacity: 0;
          background: $orange_bright;
          @include size(2px 100%);
          @include position(absolute, 0 null null 0);
          @include transition(opacity 333ms ease-out);
        }

        a {
          font-size: rem(13.5);
          color: $dark_very_grayish_red;
          font-weight: 500;
        }

        &:hover,
        &.active-trail {

          &:before {
            opacity: 1;
          }

          a {
            font-weight: 600;
            color: $dark_very_grayish_red;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .extra-menu {
      font-size: em(11);
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      padding: 13.5px 8px 13.5px 34px;

      &.have-sub-menu {

        .extra-menu-text {
          display: inline-block;
          padding-right: 12px;
          background: url('../../images/build/sub-menu-arrow.svg') no-repeat right 6px;
          background-size: 6.5px auto;
        }
      }

      &:hover:not(.sub-menu) {
        color: $orange_bright_2;
      }

      &.e-login {
        background: url('../../html_prototype/images/profile.svg') no-repeat 34px 17px;
        background-size: 11px auto;
      }

      &.e-lang {
        background: url('../../html_prototype/images/language.svg') no-repeat 34px 17px;
        background-size: 12px auto;
      }

      &.e-access {
        background: url('../../html_prototype/images/accessibility.svg') no-repeat 34px 17px;
        background-size: 9px auto;

        .sub-menu {

          li {
            display: inline-block;
            margin-right: 30px;
            color: $dark_very_grayish_red;
            @include transition(all 333ms linear);

            &:hover {
              color: $orange_bright_2;
            }
          }

          #default {
            font-size: 14px;
          }

          #bigger {
            font-size: 16px;
          }
        }
      }

      &.have-sub-menu {
        cursor: pointer;
      }

      li {
        padding-left: 30px;
      }

      .sub-menu {
        display: none;
        padding: 20px 0 0;

        li {
          padding-left: 0;
          margin-bottom: 13px;
          font-size: rem(12);
          line-height: 2;
          letter-spacing: 0;
          text-transform: capitalize;

          a {
            color: $dark_very_grayish_red
          }

          &.active {
            font-weight: 500;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    &.toggled {

      .menu-toggle {
        right: 260px;

        div {
          background: transparent;

          &:before {
            @include transform(rotate(45deg) translateX(4px) translateY(4px));
          }

          &:after {
            @include transform(rotate(-45deg) translateX(4px) translateY(-5px));
          }
        }
      }

      .logo {
        // margin-left: -500px;
        // opacity: 0;
      }

      .aside {
        right: 0;
      }
    }
  }

  .desktop-menu {
    display: block;
    @include header-container();

    @include media($tablet) {
      display: none;
    }

    .top {
      @include clearfix();
      padding-bottom: 16px;
      @include transition(all 333ms linear);
    }

    .logo {
      float: left;
      width: 406px;
      @include transition(all 333ms linear);

      img {
        width: 100%;
        display: block;
      }
    }

    .extra {
      float: right;

      li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 31px;
        @include pad(1px 0 0 18px);
        font-size: rem(12);
        text-transform: uppercase;
        letter-spacing: 2.8px;
        cursor: pointer;
        @include transition(all 113ms linear);

        a {
          font-size: rem(12);
          @include transition(all 113ms linear);
        }

        &.profile {
          background: url('../../images/build/profile.svg') no-repeat left 3px;
          background-size: auto 68%;
        }

        &.language {
          background: url('../../images/build/language.svg') no-repeat left 3px;
          background-size: auto 68%;
        }

        &.search {
          background: url('../../images/build/search.svg') no-repeat left 3px;
          background-size: auto 68%;
        }

        &:hover {
          color: $orange_bright_2;

          a {
            color: $orange_bright_2;
          }

          &.profile {
            background: url('../../images/build/profile-active.svg') no-repeat left 3px;
            background-size: auto 68%;

            .sub-menu {
              opacity: 1;
              visibility: visible;
            }
          }

          &.language {
            background: url('../../images/build/language-active.svg') no-repeat left 3px;
            background-size: auto 68%;
            z-index: 15;

            .sub-menu {
              opacity: 1;
              visibility: visible;
            }
          }

          &.search {
            background: url('../../images/build/search-active-2.png') no-repeat left 3px;
            background-size: auto 68%;
          }
        }
      }

      .sub-menu {
        opacity: 0;
        visibility: hidden;
        min-width: 118px;
        @include position(absolute, 35px null null 0);
        @include transition(all 333ms linear);
        background: $gary_light_orange;
        border: 1px solid $gray_grayish_violet;
        border-radius: 3px;
        z-index: 10;
        padding: 1px;

        &:after {
          content: '';
          background: url('../../images/build/lang-arrow.svg') no-repeat center;
          background-size: 20px auto;
          @include size(20px 13px);
          @include position(absolute, -12.5px 0 null 0);
          margin: auto;
        }

        li {
          width: 100%;
          text-align: center;
          margin: 0;
          color: $dark_very_grayish_red_3;
          text-transform: capitalize;
          letter-spacing: 0;
          @include pad(9px 0);
          @include transition(all 333ms linear);

          a {
            font-size: rem(12);
            color: $dark_very_grayish_red;
            @include transition(all 333ms linear);
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &.current-lang {
            font-weight: bold;
          }

          &:hover {
            background: $orange_bright;

            a {
              color: $white;
              @include transition(all 333ms linear);
            }
          }
        }
      }
    }

    .bottom {

      ul {
        position: relative;
        height: rem(45);
        text-align: justify;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        &:before {
          content: '';
          @include position(absolute, 0 null null 0);
          @include size(100% 2px);
          background: $gray_grayish_violet;
        }

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 0;
        }

        li {
          display: inline-block;
          position: relative;
          padding-top: 18px;
          overflow: hidden;
          @include transition(all 333ms linear);

          &:hover,
          &.active-trail {

            a:after {
              left: 0;
            }
          }
        }

        a {
          font-size: rem(14);
          color: $dark_grayish_red;

          &:after {
            content: '';
            @include size(20px 2px);
            background: $orange_bright;
            @include position(absolute, 0 null null -20px);
            @include transition(all 111ms linear);
          }

          @include media($desktop) {
            font-size: rem(13);
          }
        }
      }
    }
  }

  .accessibility {
    height: 31px;
    display: block;
    position: relative;
    @include position(absolute, null 0 -31px 0);
    margin: auto;
    padding: 0;

    .locked & {
      opacity: 0;
      pointer-events: none;
    }

    // .node-type-homepage & {
    //   bottom: 18px;

    //   @include media($tablet) {
    //     bottom: -31px
    //   }
    // }

    @include media($desktop) {
      //@include outer-container(960px);
    }

    @include media($tablet) {
      // display: none;
    }

    &.cart-visible {
      background: $gary_light_orange;
    }

    .accessibility-inner {
      @include header-container();
    }

    &.have-cart-quantity {

      &:before {
        content: '';
        z-index: -1;
        @include size(9999px 31px);
        @include position(absolute, 0 null null -50%);
        background: rgba(255, 255, 255, 0.88);
      }

      .accessibility-menu {
        background: none;
      }
    }

    .accessibility-menu {
      z-index: 10;
      float: right;
      position: relative;
      background: rgba(255, 255, 255, 0.88);
      @include pad(3px 12px);
      display: flex;
      gap: 0 10px;
      align-items: center;
      border-radius: 0 0 5px 5px;

      li {
        display: inline-flex;
        align-items: center;
        text-align: center;
        // margin-right: em(12);

        &:last-of-type {
          margin-right: 0;
        }

        &:hover a {
          color: $color__access;
        }
      }

      a {
        color: $gray_dark;
        line-height: 1;
        cursor: pointer;
        @include transition(all 333ms linear);

        //-webkit-touch-callout: none; /* iOS Safari */
        //-webkit-user-select: none; /* Safari */
        //-khtml-user-select: none; /* Konqueror HTML */
        //-moz-user-select: none; /* Firefox */
        //-ms-user-select: none; /* Internet Explorer/Edge */
        //user-select: none; /* Non-prefixed version currently supported by Chrome and Opera */

        &#fontSmall {
          font-size: 13px;
          font-weight: 600;
        }

        &#fontDefault {
          font-size: 18px;
          font-weight: 600;
        }

        &#fontLarge {
          font-size: 23px;
          font-weight: 600;
        }

        &.current-access {
          color: $color__access;
        }
      }
    }
  }
}

//campaign-header
.campaign-header {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    border-radius: 50%;
    z-index: -1;
    @include size(80vw);
    background: rgba(255, 255, 255, 0.7);
    @include position(absolute, 0 null null 0);
    @include transform(translateX(-25%) translateY(-33%));
  }

  .background {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    @include clearfix();
    z-index: -2;
    overflow: hidden;

    .flipped,
    .unflipped {
      float: left;
      z-index: -3;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .unflipped {
      @include size(40% 100%);
    }

    .flipped {
      margin: -60px;
      @include transform(scaleX(-1));
      @include filter(blur(42px));
      @include size(calc(60% + 120px) calc(100% + 120px));
    }

    @include media($mobile) {

      .flipped {
        display: none;
      }

      .unflipped {
        width: 100%;
      }
    }
  }

  .wrapper {
    @include pad(20px 0 154px);
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include pad(20px 20px);
      @include outer-container(100%);
    }
  }

  .content {
    @include span-columns(6);
  }

  h1 {
    font-size: em(49px);
    line-height: 1.06;
    letter-spacing: -1.46px;
    margin: 70px 0 20px;
    text-transform: capitalize;
  }

  .category {
    color: $gray_dark;
    font-size: em(15);
    line-height: 1.67;
    letter-spacing: -0.35px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .logo {
    @include size(160px);
    @include position(absolute, 0 null 0 55%);
    background: $white;
    border-radius: 50%;
    margin: auto;

    img {
      @include position(absolute, 0 0 0 0);
      margin: auto;
      max-width: 95%;
    }
  }

  @include media($tablet) {

    &:before {
      @include size(85vw);
      min-width: 800px;
      min-height: 800px;
    }
  }

  @include media($mobile) {

    &:before {
      @include size(836px);
      @include transform(translateX(-30%) translateY(5%));
    }

    .content {
      width: 100%;
    }

    .breadcrumbs {
      display: none;
    }

    h1 {
      font-size: em(26px);
      line-height: 1.15;
      letter-spacing: -0.87px;
      margin: 104px 0 15px;
    }

    .category {
      font-size: em(13);
    }

    .logo {
      @include size(91px);
      @include position(absolute, 20px 20px inherit inherit);

      img {
        max-width: 70%;
      }
    }
  }
}

//partner-header
// .partner-header {
//   position: relative;
//   @include pad(20px 0 80px);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   z-index: 1;
//   overflow: hidden;

//   &:after {
//     content: '';
//     @include position(absolute, 0 null null 0);
//     @include size(100%);
//     background: rgba(242, 116, 58, 0.94);
//     z-index: -1;
//   }

//   &:before {
//     content: '';
//     @include size(624px);
//     @include position(absolute, 0 0 null null);
//     background: rgba(255, 255, 255, 0.17px);
//     border-radius: 50%;
//     @include transform(translateX(15%) translateY(-5%));
//   }

//   .wrapper {
//     @include outer-container();

//     @include media($large_desktop) {
//       @include outer-container(960px)
//     }

//     @include media($desktop) {
//       @include outer-container(800px)
//     }

//     @include media($tablet) {
//       @include outer-container(680px)
//     }

//     @include media($mobile) {
//       @include outer-container(100%);
//     }
//   }

//   .content {
//     @include span-columns(7);
//     margin-top: 85px;
//     color: $white;
//   }

//   h1 {
//     font-size: em(49);
//     letter-spacing: -1.46px;
//     line-height: 1.06;
//     margin-bottom: 10px;
//   }

//   p {
//     font-size: em(15);
//     line-height: 1.6;
//     color: $white;
//   }

//   @include media($mobile) {
//     @include pad(20px 20px 25px);

//     &:before {
//       content: '';
//       @include size(162px);
//       @include transform(translateX(30%) translateY(50%));
//     }

//     .content {
//       margin-top: 10px;
//       width: 100%;
//     }

//     h1 {
//       font-size: em(24);
//       letter-spacing: -0.8px;
//       line-height: 1.25;
//       margin-bottom: 3px;
//     }

//     p {
//       font-size: em(12);
//       font-weight: 500;
//       line-height: 1.5;
//       letter-spacing: -0.28px;
//       margin-bottom: 0;
//       max-width: 285px;
//     }
//   }
// }

//homepage-header
.homepage-header {
  position: relative;
  margin-bottom: 89px;

  &.animation {

    .left {

      .heading,
      .sub-heading {
        opacity: 1;
        @include transform(translateY(0));
      }
    }

    .right {

      &:after {
        opacity: 1;
        @include transform(scale(55));

        @include media($desktop) {
          @include transform(scale(50));
        }

        @include media($mobile) {
          @include transform(scale(2));
        }
      }

      .container {
        opacity: 1;
        @include transform(translateY(0));
      }
    }

    .btn-arrow {
      opacity: 1;
      @include transform(translateY(0));

      .arrow {
        animation-name: header-arrow-animation;
        animation-duration: 2s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }

      .background {
        animation-name: header-arrow-bg-animation;
        animation-duration: 2s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
      }
    }
  }

  .bg-wrapper {
    overflow: hidden;
    @include position(absolute, 0 0 0 0);
    @include size(100%);

    .background {
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      filter: blur(6px);
      z-index: -1;
      background-size: cover;
      background-position: center;

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .wrapper {
    overflow: hidden;
    @include pad(146px 0 101px);

    @include media($desktop) {
      @include pad(104px 0 80px);
    }

    @include media($tablet) {
      @include pad(69px 0 92px);
    }

    @include media($mobile) {
      @include pad(15% 0 0);
    }
  }

  .content {
    @include outer-container(1200px);

    @include media($large_desktop) {
      @include outer-container(960px);
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include outer-container(100%);
    }
  }

  .left {
    @include span-columns(7);
    padding-top: 18px;

    .heading {
      font-size: em(62);
      line-height: 1.08;
      letter-spacing: -1.66px;
      margin-bottom: 16px;
      opacity: 0;
      @include transform(translateY(15%));
      @include transition(all 555ms ease-out 111ms);
    }

    .sub-heading {
      font-size: em(22);
      line-height: 1.36;
      letter-spacing: -0.23px;
      color: $dark_very_grayish_red_3;
      font-weight: normal;
      opacity: 0;
      @include transform(translateY(15%));
      @include transition(all 555ms ease-out 555ms);
    }

    @include media($large_desktop) {

      .heading {
        font-size: em(55);
        margin-bottom: 12px;
      }

      .sub-heading {
        font-size: em(20);
      }
    }

    @include media($desktop) {
      @include span-columns(8);

      .heading {
        font-size: em(47);
        line-height: 1.19;
        letter-spacing: -1.26px;
        margin-bottom: 12px;
      }

      .sub-heading {
        font-size: em(17);
        line-height: 1.47;
        letter-spacing: -0.18px;
      }
    }

    @include media($tablet) {
      @include span-columns(7.5);

      .heading {
        font-size: em(45);
        line-height: 1.06;
        letter-spacing: -1.21px;
        margin-bottom: 13px;
      }

      .sub-heading {
        font-size: em(16);
        line-height: 1.56;
        letter-spacing: -0.17px;
      }
    }

    @include media($mobile) {
      width: 100%;
      margin: 0;
      padding: 0 20px 15%;

      .heading {
        font-size: em(29);
        line-height: 1.03;
        letter-spacing: -0.97px;
        margin-bottom: 12px;
      }

      .sub-heading {
        font-size: em(12);
        line-height: 1.125;
        font-weight: 500;
      }
    }
  }

  .right {
    position: relative;
    @include shift(2);
    @include span-columns(3);

    &:after {
      content: '';
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.63);
      margin: auto;
      opacity: 0;
      z-index: -1;
      @include size(20px);
      @include position(absolute, 0 -45% 0 null);
      @include transition(all 666ms ease-out 777ms);

      @include media($desktop) {
        right: -55%;
      }

      @include media($mobile) {
        opacity: 1;
        @include size(414px);
        @include transform(scale(2));
        @include position(absolute, inherit 0 -414px 250px);
      }
    }

    .container {
      opacity: 0;
      @include transform(translateY(20%));
      @include transition(all 555ms ease-out 1221ms)
    }

    .nav-title {
      font-size: em(11);
      text-transform: uppercase;
      letter-spacing: 2.8px;
      line-height: 1.1;
      margin-bottom: 18px;
      font-weight: normal;
    }

    @include media($desktop) {
      @include shift(1);
      @include span-columns(3);

      .nav-title {
        margin-bottom: 21px;
      }
    }

    @include media($tablet) {
      @include shift(0.5);
      @include span-columns(4);

      .nav-title {
        margin-bottom: 20px;
      }
    }

    @include media($mobile) {
      width: 100%;
      margin: 0;
      @include pad(13px 20px 8px);
      background: $gary_light_orange;

      .nav-title {
        display: none;
      }
    }
  }

  .header-nav {

    .header-current-option {
      position: relative;
      font-size: em(12);
      font-weight: 600;
      color: $dark_very_grayish_red_3;
      letter-spacing: -0.35px;
      text-transform: capitalize;
      display: none;

      &:before {
        content: '';
        @include position(absolute, null null -10px 0);
        @include size(14px 2px);
        background: $orange_bright_2;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .nav-item {
      cursor: pointer;
      position: relative;
      margin-bottom: 29px;
      padding-left: 9px;

      &:before {
        content: '';
        border-radius: 30px;
        @include size(2px 100%);
        @include position(absolute, 0 null null 0);
        background: $orange_bright_2;
      }

      h3 {
        font-size: em(17);
        font-weight: 600;
        line-height: 1.23;
        margin-bottom: 0;
        letter-spacing: -0.47px;
        color: $dark_very_grayish_red_3;
      }

      h4 {
        font-size: em(16);
        font-weight: 500;
        line-height: 1.31;
        margin-bottom: 0;
        letter-spacing: -0.47px;
        color: $dark_very_grayish_red_3;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($desktop) {

      .nav-item {
        margin-bottom: 21px;

        h3 {
          font-size: em(16);
          line-height: 1.31;
          letter-spacing: -0.47px;
        }

        h4 {
          font-size: em(15);
          line-height: 1.4;
        }
      }
    }

    @include media($tablet) {

      .nav-item {
        margin-bottom: 26px;

        h3 {
          font-size: em(15);
          line-height: 1.2;
        }

        h4 {
          font-size: em(14);
          line-height: 1.29;
        }
      }
    }

    @include media($mobile) {

      ul {
        display: none;
        padding: 25px 0;
      }

      .nav-item {
        margin-bottom: 12px;

        &:before {
          height: 15px;
          top: 6px;
        }

        h3,
        h4 {
          display: inline-block;
          font-size: em(12);
          font-weight: 600;
          letter-spacing: -0.35px;
          line-height: 1.25;
        }
      }

      .btn-more,
      .header-current-option {
        display: inline-block;
      }
    }
  }

  .btn-arrow {
    opacity: 0;
    margin: auto;
    @include size(51.7px);
    @include position(absolute, null 0 -25.85px 0);
    @include transform(translateY(40%));
    @include transition(all 444ms ease-out 1.5s);

    .arrow {
      @include size(47px);
      @include position(absolute, 0 0 0 0);
      margin: auto;
      background: url('../../html_prototype/images/arrow-header.svg') no-repeat center $orange_bright_2;
      background-size: 18px auto;
      border-radius: 47px;
      cursor: pointer;
    }

    .background {
      @include size(10px);
      border-radius: 10px;
      background: rgba(252, 104, 45, 0.5);
      @include position(absolute, 50% null null 50%);
      @include transform(translateX(-50%) translateY(-50%));
      margin: auto;
      z-index: -1;
    }

    @include media($mobile) {
      @include size(50px);
      bottom: -70px;

      .arrow {
        @include size(39px);
        border-radius: 39px;
        background-size: 15px auto;
      }

      .background {
        @include size(8px);
        border-radius: 8px;
      }
    }
  }

  @keyframes header-arrow-animation {

    50%,
    100% {
      @include transform(scale(1.15));
    }
  }

  @keyframes header-arrow-bg-animation {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      @include transform(translateX(-50%) translateY(-50%) scale(10));
    }
  }
}

//focus-area-header
.focus-area-header {
  min-height: 400px;
  position: relative;
  @include pad(12px 0 41px);
  overflow: hidden;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    z-index: -1;
  }

  &:before {
    @include size(1176px);
    background: rgba(255, 255, 255, 0.7);
    @include position(absolute, 75% null null -175px);
    @include transform(translateY(-75%));

    @include media($desktop) {
      @include size(900px);
    }

    @include media($tablet) {
      @include size(850px);
    }

    @include media($mobile) {
      @include size(650px);
      top: 50%;
      left: inherit;
      right: 20%;
      @include transform(translateY(-50%));
      background: rgba(255, 255, 255, 0.7);
    }
  }

  &:after {
    @include size(85px);
    background: rgba(242, 116, 58, 0.7);
    @include position(absolute, null -42.5px 77.5px null);

    @include media($mobile) {
      @include size(37px);
      bottom: inherit;
      top: 50%;
      right: -18.5px;
    }
  }

  .background {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    @include clearfix();
    z-index: -2;

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: rgba(255, 255, 255, 0.5);
    }

    .flipped,
    .unflipped {
      float: left;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -3;
    }

    .unflipped {
      @include size(50% 100%);
    }

    .flipped {
      @include transform(scaleX(-1));
      @include filter(blur(11px));
      overflow: hidden;
      margin: -12px;
      @include size(calc(50% + 24px) calc(100% + 24px));
    }

    @include media($mobile) {

      .flipped {
        display: none;
      }

      .unflipped {
        width: 100%;
      }
    }
  }

  .container {
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include outer-container(100%);
      @include size(100%);
      //@include position(absolute, null null 20px 20px);
    }
  }

  .content {
    @include span-columns(7);
    margin-top: 60px;

    @include media($desktop) {
      @include span-columns(7.5);
    }

    @include media($tablet) {
      @include span-columns(9);
    }
  }

  h1 {
    font-size: em(49);
    line-height: 1.2;
    letter-spacing: -1.46px;
    margin: 0 0 15px;
    max-width: 500px;
  }

  p {
    font-size: em(14);
    font-weight: 500;
    letter-spacing: -0.41px;
    line-height: 1.42;
    max-width: 390px;
    margin-bottom: 0;

    a {
      color: $orange_bright;
    }
  }

  @include media($tablet) {
    min-height: 300px;

    h1 {
      font-size: em(35);
    }
  }

  @include media($mobile) {
    @include pad(20px);
    min-height: inherit;

    .btn-back {
      position: absolute;
      //@include position(absolute, 30px null null 20px);
    }

    .breadcrumbs {
      display: none;
    }

    .content {
      float: none;
      width: 70%;
      min-width: 185px;
      margin: 0;
      padding: 60px 0;
    }

    h1 {
      font-size: em(24);
      line-height: 1.25;
      letter-spacing: -0.8px;
      margin-bottom: 5px;
    }

    p {
      display: none;
    }
  }
}

//about-us-header
.about-us-header {
  min-height: 520px;
  position: relative;
  @include pad(233px 0 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: rgba(255, 255, 255, 0.2);
    z-index: -1;
  }

  .wrapper {
    position: relative;
    background: url('../../images/build/header-overlay.svg') no-repeat center top;
    background-size: cover;

    @include media($tablet) {
      background: url('../../images/build/header-overlay-tablet.png') no-repeat center top;
      background-size: cover;
    }

    @include media($overtablet) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:after {
      content: '';
      @include position(absolute, 0 null null 15%);
      @include size(50px);
      background: rgba(251, 118, 63, 0.89);
      border-radius: 50%;

      @include media($desktop) {
        top: -10px;
      }

      @include media($mobile) {
        @include size(32.5px);
        top: 0;
      }
    }
  }

  .container {
    @include pad(85px 0 40px);
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px);
    }

    @include media($desktop) {
      @include pad(92.5px 0 40px);
      @include outer-container(800px);
    }

    @include media($tablet) {
      @include pad(90px 0 54px);
      @include outer-container(680px);
    }

    @include media($mobile) {
      @include pad(65.5px 20px 38px);
      @include outer-container(100%);
    }
  }

  h1 {
    font-size: em(47);
    line-height: 1.125;
    letter-spacing: -1.96px;
    margin: 0;
    @include span-columns(4);
    text-transform: capitalize;
  }

  p {
    font-size: em(15);
    line-height: 1.6;
    @include shift(1);
    @include span-columns(7);
    color: $dark_very_grayish_red;
    margin-right: 0;
    margin-bottom: 0;

    strong {
      font-weight: normal;
    }
  }

  .header-nav {
    width: 100%;
    display: inline-block;
    margin-top: 45px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      position: relative;
      font-size: em(15);
      line-height: 1.4;
      letter-spacing: -0.4px;
      font-weight: 500;
      color: $dark_very_grayish_red;
      text-transform: capitalize;
      margin-right: 6.5%;
      padding-left: 9px;
      display: inline-block;

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        content: '';
        @include position(absolute, 3px null null 0);
        @include size(2px 14px);
        margin: auto;
        background: $orange_bright_2;
        border-radius: 30px;
      }
    }
  }

  @include media($large_desktop) {
    min-height: 440px;

    .header-nav li {
      margin-right: 4%;
    }
  }

  @include media($desktop) {

    h1 {
      font-size: em(42);
    }

    p {
      font-size: em(14);
    }

    .header-nav {
      margin-top: 41px;

      li {
        font-size: em(13);
        margin-right: 3%;

        &:after {
          top: 0;
        }
      }
    }
  }

  @include media($tablet) {
    min-height: inherit;

    h1 {
      font-size: em(35);
      @include span-columns(4.5);
    }

    p {
      font-size: em(13);
      @include shift(0.5)
    }

    .header-nav {
      display: none;
    }
  }

  @include media($mobile) {
    padding: 113px 0 0;

    h1 {
      float: none;
      width: 100%;
      font-size: em(29);
      margin-bottom: 10px;
    }

    p {
      float: none;
      width: 90%;
      margin: 0;
      font-size: em(12);
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.28px;
    }
  }
}

//event-header
.event-header {
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;

  &.no-slider {

    &:after {
      background: rgba(234, 233, 236, 0.58);
    }

    &:before {
      content: '';
      z-index: -1;
      border-radius: 50%;
      @include size(80vw);
      background: rgba(255, 255, 255, 0.7);
      @include position(absolute, 50% 0 null null);
      @include transform(translateY(-50%) translateX(-40%));
    }

    .container {
      padding: 100px 0;
    }

    .content {
      @include position(relative, inherit);
      @include transform(translateY(0));
    }

    @include media($mobile) {

      .container {
        padding: 0;
      }

      &:before {
        content: none;
      }
    }
  }

  &:after {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: rgba(234, 233, 236, 0.9);
    z-index: -1;
  }

  .container {
    @include clearfix();
    position: relative;
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include outer-container(100%);
    }
  }

  .content {
    @include position(absolute, 50% null null 0);
    @include transform(translateY(-50%));
    @include span-columns(5);

    .title {
      font-size: rem(56);
      line-height: 1;
      letter-spacing: -1.96px;
      margin-bottom: 8px;
    }

    .text {
      font-size: rem(14);
      line-height: 1.43;
      letter-spacing: -0.41px;
      font-weight: 500;
      color: $dark_very_grayish_red;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .slider {
    position: relative;
    @include shift(2);
    @include span-columns(5);
    float: right;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .slick-list,
    .slick-track {
      height: 100% !important;
    }

    .header-slick {
      @include size(100%);
      @include position(absolute, 0 null null 0);
    }

    .item {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 1;
      @include transition(all 333ms linear);
      display: inline-block;
      @include size(100%);

      &.image-only {
        @include pad(30px);
      }

      &.image-with-content {
        @include pad(70px 30px);

        &:after {
          content: '';
          @include position(absolute, 0 null null 0);
          @include size(100%);
          background: rgba(242, 116, 58, 0.9);
          z-index: -1;
        }
      }

      .detail {
        width: 80%;
        margin: auto;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
      }

      .date {
        font-size: em(20);
        font-weight: 600;
        letter-spacing: -0.47px;
        line-height: 1.25;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      .category {
        font-size: em(11);
        letter-spacing: 2.8px;
        line-height: 1.1;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 2px;
      }

      .title {
        font-size: em(24);
        letter-spacing: -0.7px;
        line-height: 1.25;
        color: $white;
        text-transform: capitalize;
        margin-bottom: 19px;
      }

      p {
        font-size: em(12);
        letter-spacing: -0.3px;
        line-height: 1.58;
        color: $white;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .slick-slider {
      margin-bottom: 0 !important;
    }

    .slick-list {
      @include transition(all 333ms linear);
    }

    .slick-dots {
      bottom: 19px;

      li {
        @include size(6.25px);
        @include pad(0);
        margin: 0 9px 0 0;

        button {
          @include size(6.25px);
          background: rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          padding: 0;

          &:before {
            content: none;
          }
        }

        &.slick-active {

          button {
            background: $white;
          }
        }
      }
    }

    .slick-arrow {
      @include size(30px);
      border-radius: 50%;
      z-index: 1;

      &:before {
        content: none;
      }

      &:hover {
        background: $white;
      }

      &.slick-next {
        right: -15px;
        background: url('../../html_prototype/images/slide-arrow-next-2.png') no-repeat center $white;
        background-size: 7.7px auto;
      }

      &.slick-prev {
        left: -15px;
        background: url('../../html_prototype/images/slide-arrow-prev-2.png') no-repeat center $white;
        background-size: 7.7px auto;
      }
    }
  }

  .header-nav {
    margin-top: 45px;
    @include clearfix();

    .nav-item {
      position: relative;
      width: 50%;
      float: left;
      margin-bottom: 7px;
      padding-left: 8px;

      &:before {
        content: '';
        @include position(absolute, 5px null null 0);
        @include size(2px 14px);
        background: $orange_bright_2;
        border-radius: 30px;
      }

      a {
        color: $dark_very_grayish_red_3;
        font-size: rem(15);
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.42px;
      }
    }
  }

  @include media($desktop) {

    .content {
      @include span-columns(6);
    }

    .slider {
      @include shift(1);
      @include span-columns(5);
      float: right;

      .item .title {
        font-size: rem(20);
        margin-bottom: 8px;
      }
    }

    .header-nav {
      margin-top: 20px;
    }
  }

  @include media($tablet) {

    .content {
      @include span-columns(6);

      .title {
        font-size: rem(40);
      }
    }

    .header-nav {
      display: none;
    }
  }

  @include media($mobile) {

    &:before {
      content: none;
    }

    .wrapper {
      min-height: inherit;
    }

    .content {
      margin: 0;
      float: none;
      width: 100%;
      overflow: hidden;
      @include pad(139px 20px 25px);
      @include transform(translateY(0));
      @include position(relative, inherit);

      .title {
        font-size: em(26);
        line-height: 1.15;
        letter-spacing: -0.87px;
        max-width: 320px;
      }

      .text {
        font-size: em(12);
        font-weight: 500;
        letter-spacing: -0.28px;
        line-height: 1.5;
        max-width: 85%;
        min-width: 320px;
      }

      &:before {
        content: '';
        z-index: -1;
        border-radius: 50%;
        @include size(180vw);
        @include position(absolute, 22% null null 75%);
        @include transform(translateX(-75%) translateY(0));
        background: rgba(255, 255, 255, 0.7);
      }
    }

    .slider {
      float: none;
      width: 100%;
      margin: 0;
      @include pad(8.5px 0 17.5px);

      &:before {
        padding-top: 75%;
      }

      .item {
        margin: 0;
        opacity: 0.2;
        @include transform(scale(0.9));

        .title {
          font-size: em(15);
          font-weight: 600;
          margin-bottom: 5px;
        }

        &.image-only,
        &.image-with-content {
          @include pad(20px);
        }

        &.slick-current {
          opacity: 1;
          @include transform(scale(1));
        }
      }

      .slick-arrow {

        &.slick-next {
          right: 10px;
        }

        &.slick-prev {
          left: 10px;
        }
      }
    }
  }
}

//event-single-header
.event-single-header {
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-height: 400px;
  @include pad(106px 0 119px);

  &:after {
    content: '';
    border-radius: 50%;
    @include size(384px);
    z-index: -1;
    background: rgba(242, 116, 58, 0.5);
    @include position(absolute, 88% 20% null null);

    @include media($tablet) {
      right: 13%;
    }

    @include media($mobile) {
      content: none;
    }
  }

  .btn-back {
    color: $color__main;
  }

  .circle {
    z-index: 0;
    border-radius: 50%;
    @include size(80vw);
    background: rgba(255, 255, 255, 0.85);
    @include position(absolute, 50% 0 null null);
    @include transform(translateY(-50%) translateX(-40%));

    @include media($mobile) {
      @include size(1200px);
      background: rgba(255, 255, 255, 0.85);
      @include position(absolute, 20% null null 40%);
      @include transform(translateY(0%) translateX(-50%));

      &:before {
        content: '';
        border-radius: 50%;
        @include size(30px);
        background: rgba(242, 116, 58, 0.65);
        @include position(absolute, 5px 36% null null);
      }
    }
  }

  .container {
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include outer-container(100%);
    }
  }

  .background {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    @include clearfix();
    z-index: -2;
    overflow: hidden;

    .flipped,
    .unflipped {
      float: left;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .unflipped {
      @include size(50% 100%);
    }

    .flipped {
      margin: -60px;
      @include transform(scaleX(-1));
      @include filter(blur(42px));
      @include size(calc(50% + 120px) calc(100% + 120px));
    }

    @include media($mobile) {

      .flipped {
        display: none;
      }

      .unflipped {
        width: 100%;
      }
    }
  }

  .content {
    position: relative;
    @include span-columns(6 of 12);

    .date {
      font-size: rem(18);
      font-weight: 600;
      color: $gray_dark;
      letter-spacing: -0.42px;
      line-height: 1.39;
      margin-bottom: 3px;
    }

    .title {
      font-size: rem(49);
      font-weight: bold;
      line-height: 1.06;
      letter-spacing: -1.49px;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    p {
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.54;
      letter-spacing: -0.38px;
      margin: 0;
      color: $dark_very_grayish_red;
    }

    .sponsors-title {
      font-size: rem(10);
      text-transform: uppercase;
      letter-spacing: $spaced-letters;
      margin-top: 35px;
      margin-bottom: 5px;
      font-weight: 500;
    }

    .sponsors-logos {
      margin-bottom: -55px;

      img {
        display: inline-block;
        max-height: 150px;
      }

      &.two-lines {

        .sponsors-logo {
          display: block;
          text-align: left;
        }
      }

      @include media($mobile) {
        margin: 0;
      }
    }

    .sponsors-logo {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-right: 30px;
      margin-top: 20px;
    }

    .sponsor-logo-title {
      font-size: rem(10);
      text-transform: uppercase;
      letter-spacing: $spaced-letters;
    }

    @include media($large_desktop) {
      @include span-columns(8 of 12);
    }

    @include media($desktop) {

      .title {
        font-size: rem(36);
      }
    }

    @include media($tablet) {

      .title {
        font-size: rem(30);
      }
    }

    @include media($mobile) {
      width: 100%;

      .date {
        font-size: em(13);
        letter-spacing: -0.31px;
        line-height: 1.15;
        margin-bottom: 7px;
      }

      p {
        margin-top: 7px;
      }

      .title {
        font-size: em(26);
        line-height: 1.15;
        letter-spacing: -0.87px;
        margin-bottom: 0;
        max-width: 350px;
      }
    }
  }

  @include media($desktop) {
    min-height: inherit;
  }

  @include media($mobile) {
    @include pad(145px 20px 30px);

    .btn-back {
      position: absolute;
      top: 15px;
    }
  }
}

//news-single-header
.news-single-header {
  @include pad(15px 0 70px);
  min-height: 400px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @include media($mobile) {
    min-height: inherit;
    @include pad(30px 25px);

    .btn-back {
      margin-bottom: 15px;
    }
  }

  .container {
    position: relative;
    padding-top: 45px;
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      padding-top: 0;
      @include outer-container(100%);
    }
  }

  .content {
    position: relative;
    @include span-columns(6 of 12);

    .date {
      font-size: rem(15);
      font-weight: 600;
      color: $gray_dark;
      text-transform: uppercase;
      letter-spacing: -0.35px;
      line-height: 1.67;
      margin-bottom: 15px;
    }

    .title {
      font-size: rem(35);
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: -1.23px;
      margin-bottom: 15px;
      min-height: 150px;
    }

    p {
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: -0.41px;
      color: $dark_very_grayish_red;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($large_desktop) {
      @include span-columns(6 of 12);
    }

    @include media($desktop) {

      .title {
        font-size: rem(25);
        min-height: inherit;
      }
    }

    @include media($mobile) {
      width: 100%;
      margin-top: 100px;

      .date {
        font-size: em(13);
        letter-spacing: -0.31px;
        line-height: 1.15;
        margin-bottom: 5px;
      }

      .title {
        font-size: em(22);
        line-height: 1.15;
        letter-spacing: -0.87px;
        margin-bottom: 10px;
        min-height: inherit;
        max-width: 250px;
      }

      p {
        width: 90%;
      }
    }
  }

  &.circle-overlay {

    &:before,
    &:after {
      content: '';
      border-radius: 50%;
      z-index: -1;
    }

    &:before {
      @include size(80vw);
      background: rgba(255, 255, 255, 0.9);
      @include position(absolute, 50% 0 null null);
      @include transform(translateY(-50%) translateX(-40%));
    }

    &:after {
      @include size(384px);
      background: rgba(242, 116, 58, 0.65);
      @include position(absolute, 85% 22% null null);
    }

    @include media($mobile) {

      &:before {
        @include size(180vw);
        @include position(absolute, 20% -15% null null);
        @include transform(translateY(0) translateX(0));
      }

      &:after {
        content: none;
      }
    }

    .background {
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      @include clearfix();
      z-index: -2;

      .flipped,
      .unflipped {
        float: left;
        z-index: -3;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .unflipped {
        @include size(50% 100%);
      }

      .flipped {
        margin: -60px;
        @include transform(scaleX(-1));
        @include filter(blur(42px));
        @include size(calc(50% + 120px) calc(100% + 120px));
        overflow: hidden;
      }

      @include media($mobile) {

        .flipped {
          display: none;
        }

        .unflipped {
          width: 100%;
        }
      }
    }
  }

  &.no-overlay {
    background: $blue_light_grayish_2;

    &:before {
      content: '';
      z-index: -1;
      border-radius: 50%;
      @include size(770px);
      background: rgba(242, 116, 58, 0.13);
      @include position(absolute, null -200px -535px null);
    }

    .wrapper {
      @include clearfix();

      &:before {
        content: '';
        z-index: -1;
        border-radius: 50%;
        @include size(468px);
        background: rgba(255, 255, 255, 0.36);
        @include position(absolute, -432px null null 33%);
      }
    }

    .image {
      float: right;
      height: 228px;
      width: 400px;
      margin: 30px 0 0 0;
      //@include span-columns(3);
      //width: 40%;
      //@include position(absolute, 65px 0 null null);

      img {
        height: 100%;
        margin: auto;
        display: block;
      }
    }

    @include media($desktop) {

      .image {
        position: relative;
        width: 40%;
        height: 300px;
        max-width: 300px;
        margin: 0;
        @include clearfix();

        img {
          width: 75%;
          height: auto;
          @include position(absolute, 50% 0 null 0);
          @include transform(translateY(-50%));
        }
      }
    }

    @include media($mobile) {

      &:before {
        @include size(360px);
        background: rgba(255, 255, 255, 0.9);
        @include position(absolute, null inherit -168px -65px);
      }

      .wrapper:before {
        @include size(305px);
        background: rgba(252, 104, 45, 0.08);
        @include position(absolute, -240px -60px null null);
      }

      .image {
        float: none;
        width: 85%;
        height: auto;
        margin: 20px auto 21px;
        text-align: center;
        @include position(relative, inherit);

        img {
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
          @include position(relative, inherit);
          @include transform(translateY(0%));
        }
      }

      .content {
        margin-top: 0;
        width: 100%;

        .title {
          margin-bottom: 5px;
          max-width: 100%;
        }

        p {
          width: 100%;
        }
      }
    }
  }
}

//network-application-header
.network-application-header {
  z-index: 2;
  position: relative;
  min-height: 400px;
  overflow: hidden;
  @include pad(21px 0 80px);

  &:before,
  &:after {
    content: '';
    z-index: 0;
    border-radius: 50%;
  }

  &:before {
    @include size(80vw);
    background: rgba(255, 255, 255, 0.9);
    @include position(absolute, 50% 0 null null);
    @include transform(translateY(-50%) translateX(-45%));
  }

  &:after {
    @include size(318px);
    background: rgba(252, 104, 45, 0.4);
    @include position(absolute, null 25% -215px null);
  }

  .background {
    position: relative;
    overflow: hidden;
    @include position(absolute, 0 null null 0);
    @include size(100%);
    opacity: 0.94;
    z-index: -1;

    .image {
      margin: -6px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include filter(blur(5px));
      @include position(absolute, 0 null null 0);
      @include size(calc(100% + 12px));
    }
  }

  .wrapper {
    z-index: 1;
    position: relative;
    padding-top: 60px;
    @include span-columns(9);

    .text-wrap {
      @include span-columns(6 of 9);

      h1 {
        font-size: em(49);
        line-height: 1.1;
        letter-spacing: -1.71px;
        margin: 0 0 14px;
      }

      .text {
        font-size: em(14);
        color: $dark_very_grayish_red;
        font-weight: 500;
        line-height: 1.42;
      }
    }

    .logo-wrap {
      @include position(absolute, 50% 10px null null);
      @include transform(translateY(-50%));
      @include size(160px);
      border-radius: 50%;
      background: $white;
      overflow: hidden;

      img {
        width: 90%;
        height: auto;
        margin: auto;
        @include transform(translateY(-50%));
        @include position(absolute, 50% 0 null 0);
      }

      @media(min-width: 1290px) {
        right: -50px;
      }

    }
  }

  @include media($mobile) {
    padding-bottom: 50px;
    min-height: 280px;

    .btn-back {
      position: absolute;
      top: 0;
      left: 20px;
      z-index: 5;
    }

    .wrapper {
      padding-left: 20px;

      .text-wrap {
        @include span-columns(10);

        h1 {
          font-size: rem(34);
        }

      }

      .logo-wrap {
        width: 90px;
        height: 90px;
        top: 27%;
        right: -22%;
      }
    }

    &:before {
      height: 150vw;
      width: 150vw;
      right: -200px;
    }

    &:after {
      right: -45%;
    }
  }

}

//network-details-header
.network-details-header {
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-height: 400px;
  @include pad(119px 0 123px);

  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    z-index: -1;
  }

  &:before {
    @include size(595px);
    background: rgba(252, 104, 45, 0.4);
    @include position(absolute, null 14% -450px null);
  }

  &:after {
    @include size(80vw);
    background: rgba(255, 255, 255, 0.7);
    @include position(absolute, 50% 0 null null);
    @include transform(translateY(-50%) translateX(-40%));
  }

  .wrapper {
    @include outer-container();

    @include media($large_desktop) {
      @include outer-container(960px)
    }

    @include media($desktop) {
      @include outer-container(800px)
    }

    @include media($tablet) {
      @include outer-container(680px)
    }

    @include media($mobile) {
      @include outer-container(100%);
    }
  }

  .breadcrumbs {
    @include position(absolute, 12px null null 0);
    width: 100%;

    span,
    a {
      text-transform: lowercase;
    }
  }

  .background {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  .content {
    position: relative;
    @include span-columns(8 of 12);

    .title {
      font-size: rem(49);
      font-weight: bold;
      line-height: 1.06;
      letter-spacing: -1.49px;
      margin-bottom: 10px;
      max-width: 620px;
    }

    .sub-title {
      margin: 0;
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.54;
      letter-spacing: -0.38px;
      color: $dark_very_grayish_red;
      max-width: 470px;
    }

    @include media($tablet) {

      .title {
        font-size: em(35);
      }
    }

    @include media($mobile) {
      float: none;
      min-width: 195px;
      padding: 35px 0 31px;

      .title {
        font-size: em(24);
      }

      .sub-title {
        font-size: em(12);
      }
    }
  }

  @include media($tablet) {

    &:before {
      @include size(400px);
      bottom: -300px;
      right: 10%;
    }
  }

  @include media($mobile) {
    @include pad(20px);
    min-height: inherit;

    &:before {
      @include size(235px);
      bottom: -150px;
      right: -60px;
    }

    &:after {
      @include size(650px);
      top: 50%;
      left: inherit;
      right: 20%;
      @include transform(translateY(-50%));
      background: rgba(255, 255, 255, 0.7);
    }
  }
}

//mobile-header-nav
.mobile-header-nav {
  display: none;
  background: rgba(251, 249, 248, 0.96);
  @include pad(8px 0 6px);
  z-index: 10;

  .header-current-option {
    display: inline-block;
    position: relative;
    font-size: em(12);
    font-weight: 600;
    color: $dark_very_grayish_red_3;
    letter-spacing: -0.35px;
    text-transform: capitalize;

    &:before {
      content: '';
      @include position(absolute, null null -8px 0);
      @include size(14px 2px);
      background: $orange_bright_2;
    }
  }

  ul {
    display: none;
    padding: 0;
    margin: 15px 0 5px;

    li {
      padding: 5px 0;
      font-size: em(13);

      &:hover,
      &.active {
        font-weight: 500;
      }
    }
  }

  @include media($tablet) {
    display: block;

    .btn-more {
      display: inline-block;
    }
  }

  @include media($mobile) {
    @include pad(8px 20px 6px);
  }
}

//desktop-fixed-nav
.desktop-fixed-nav {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 35px;
  padding: 5px 0 0;
  background: rgba(251, 249, 248, 0.97);
  @include position(fixed, -50px null null 0);
  @include transition(all 666ms ease-out);

  &.trigger {
    z-index: 5;
    opacity: 1;
    visibility: visible;
  }

  .nav-item {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    margin-right: 73px;
    overflow: hidden;

    &:last-of-type {
      margin-right: 0;
    }

    &:before {
      content: '';
      @include position(absolute, null null 0 -30px);
      @include size(15px 2px);
      background: $orange_bright_2;
      @include transition(all 113ms linear);
    }

    &:hover,
    &.active {

      &:before {
        left: 0;
      }

      a {
        font-weight: 600;
        color: $dark_grayish_red;
      }
    }
  }

  a {
    font-size: rem(13);
    color: $gray_dark;
    font-weight: 500;
    @include transition(all 113ms linear);
  }

  @include media($tablet) {
    display: none !important;
  }
}

#content-header {
  position: relative;
  z-index: 2;
}

#content-area {
  position: relative;
  z-index: 1;
}

.breadcrumb-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
}


.breadcrumb {
  z-index: 1;
  //margin-bottom: 25px;
  padding: 0;
  font-size: 12px;
  text-transform: lowercase;
  color: $dark_very_grayish_red_2;
  position: relative;
  top: 5px;
  float: left;
  margin-left: 100px;

  a,
  span {
    font-size: 12px;
    color: $dark_very_grayish_red_2;
  }

  a {
    margin-right: 4px;
  }

  span {
    margin: 0 4px;
  }

  @include media($mobile) {
    display: none;
  }
}

.cart-visible {
  .breadcrumb {
    margin-left: 0;
  }
}

//cart-quantity
.cart-quantity {
  height: 31px;
  float: left;

  a {
    font-size: rem(14);
  }
}

#edit-save-selected-currency {
  display: none;
}

.block-commerce-multicurrency {
  float: left;
  margin-right: 15px;
  line-height: 1.8;

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: -1px;
  }

  #commerce-multicurrency-selector-form {
    .select2-selection {
      background: none;
      border: 0;
      padding: 0;
      font-size: rem(12);
    }

    .form-item {
      margin: 0;
    }
  }

}

.cart-quantity-inner {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    font-size: rem(12);
    margin-right: 15px;
    text-transform: capitalize;
  }
}

.header {

  &-flag {
    text-align: center;
    background-color: color(combiz_blue);
    @include pad(5px 0 4px);
    display: none;

    @include media($tablet) {
      display: none;
    }
  }

  &-desc {
    color: color(white);
    font-size: rem(12);
    font-weight: 300;
    margin-bottom: 0;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include pad(14px 40px 14px);

    .logged-in & {
      @include pad(40px 40px 14px);
    }

    @include media($desktop) {
      @include pad(14px 15px 14px);
    }

    @include media($tablet) {
      display: none;
    }
  }

  &-logo {
    width: 120px;
    flex-shrink: 0;

    img {
      width: 100%;
      display: block;
    }
  }

  &-buttons {
    display: flex;
    gap: rem(10);

    @include media($desktop) {
      gap: rem(2)
    }

    .cta {
      @include media($desktop) {
        font-size: rem(12);
        @include pad(3px 8px)
      }
    }
  }

  &-action {

    &-list {
      display: flex;
      align-items: center;
      gap: 0 16px;

      @include media($desktop) {
        gap: 0 5px;
      }

      &-inner {
        position: absolute;
        bottom: -97px;
        right: 0;
        z-index: 11;
        white-space: nowrap;
        text-align: right;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
        background-color: color(white);
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.15);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      @include pad(5px 0);

      &:hover {
        .header-action-list-inner {
          opacity: 1;
          pointer-events: auto;
        }
      }

      a {
        display: flex;
        // align-items: center;
      }

      &-inner {
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid color(combiz_light_gray);
        }

        a {
          display: block;
          width: 121px;
          @include pad(12px 19px);
          text-align: left;
          font-size: rem(16);
          transition: color 0.3s ease-in-out;

          &:hover {
            color: color(combiz_blue);
          }
        }
      }
    }

    &-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-mobile {
    display: none;
    position: relative;

    @include media($tablet) {
      display: block;
    }

    &.toggled {
      .header-mobile-logo {
        opacity: 0;
        translate: -20px 0;
      }
    }

    &-logo {
      width: 106px;
      transition-property: opacity, translate;
      transition-duration: 444ms;
      transition-timing-function: ease-in-out;

      img {
        width: 100%;
        display: block;
      }
    }

    &-toggle {
      cursor: pointer;
      height: 12px;
      width: 21px;
      position: absolute;
      right: 0;
      top: 50%;
      translate: 0 -50%;
      @include transition(all 444ms ease-out);

      &.toggled {
        right: 260px;
      }
    }
  }

  &-section {

    &-anchor {
      background-color: color(combiz_light_gray);
      display: none;

      // .node-type-homepage & {
      //   display: block;

      //   @include media($tablet) {
      //     display: none;
      //   }
      // }
    }

    &-inner {
      text-align: center;
      @include pad(11px 0 14px)
    }

    &-link {
      display: inline-block;
      font-size: rem(14);
      font-weight: 300;
      margin: 0 12px;
      color: color(combiz_blue);
      position: relative;

      &:hover {
        &::before {
          opacity: 1;
          translate: 0 0;
        }
      }

      &::before {
        pointer-events: none;
        opacity: 0;
        translate: 0 10px;
        position: absolute;
        width: 100%;
        content: '';
        left: 0;
        bottom: 3px;
        height: 1px;
        background-color: color(combiz_blue);
        transition-property: translate, opacity;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }
}

#content-header > .tabs{
  margin: 42px 0 0;
}

.page-checkout-complete {

  .checkout-completed-checkout-pane {
    @include outer-container();
    @include pad(70px 0 147px);

    @include media($large_desktop) {
      @include outer-container(960px);
    }

    @include media($tablet) {
      @include outer-container(680px);
    }

    @include media($mobile) {
      @include outer-container(100%);
      @include pad(50px 20px 61px);
    }
  }

  .view-header {

    h1 {
      margin-bottom: 30px;
      max-width: 490px;
      font-size: rem(24);
      font-weight: bold;
      color: $dark_grayish_red;
      line-height: 1.25;
      letter-spacing: -0.7px;
    }

    p {
      @include span-columns(7);
      margin-bottom: 8px;
      font-size: em(14);
      font-weight: normal;
      color: $dark_very_grayish_red_2;
      line-height: 1.71;
    }

    @include media($mobile) {

      h1 {
        margin-bottom: 14px;
        font-size: rem(19);
      }

      p {
        width: 100%;
        margin-right: 0;
        margin-bottom: 3px;
      }
    }
  }

  .view-content {
    @include span-columns(7);

    ul {
      padding: 0;
      margin: 0 0 45px;
    }

    li {
      list-style: none;
      color: $dark_very_grayish_red;
      font-size: em(17);
      font-weight: 600;
      margin: 0 0 7px;
      line-height: 1.29;
      letter-spacing: -0.4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($mobile) {
      width: 100%;
      margin-right: 0;

      li {
        font-size: em(15);
      }
    }
  }

  .view-footer {
    @include span-columns(7);

    p {
      font-size: rem(12);
      color: $dark_very_grayish_red_3;
      margin: 0 0 5px;

      a {
        font-size: 12px;
        background: $orange_bright_2;
        color: $white;
        margin-top: 5px;
      }
    }

    @include media($mobile) {
      width: 100%;
      margin-right: 0;

      p {
        font-size: rem(11);
      }
    }
  }
}
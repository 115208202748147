.node-type-news-publication {

  .main-with-sidebar {
    @include shift(4);
    margin-left: 0;
  }

  .sidebar {
    img {
      width: 80%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }

  .ticket:first-of-type {
    padding-top: 0;
  }

  .paragraph:first-child {
    h2 {
      display: none;
    }
  }

  .paragraph,
  .purchase-report,
  .paragraph:first-of-type {
    padding-bottom: 50px;
  }

  .purchase-report {

    .ticket {
      padding: 20px 0;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .ticket h2 {
      font-size: rem(15);
      font-weight: 500;
      color: $dark_grayish_red;
      line-height: 1.47;
    }

    .price {
      font-size: rem(17);
      font-weight: 600;
      color: $dark_very_grayish_red;
      line-height: 1.29;
      letter-spacing: -0.4px;
      margin-bottom: 0;
    }
  }

  @include media($tablet) {
    .main-with-sidebar {
      @include shift(0);
      @include span-columns(12);
    }
  }

  @include media($mobile) {

    .main-with-sidebar {
      padding: 0;
    }

    .paragraph,
    .purchase-report,
    .paragraph:first-of-type {
      padding: 22.5px 20px;
    }

    .buy-tickets-form {

      .ticket .text-wrap {
        width: 100%;
        margin-bottom: 15px;
      }

      .ajax-response {
        display: none;
      }

      .primary-call-action {
        margin-left: 0;
      }
    }
  }

  .field-name-field-audio {
    @include media($tablet) {
      @include pad(0 20px)
    }
  }

  .podcast-host-speaker {
    display: flex;
    gap: 38px;
    flex-wrap: wrap;
    margin-bottom: 15px;

    @include media($tablet) {
      @include pad(0 20px);
      display: block;
    }

    .host-speaker {

      &-wrap {
        display: inline-block;

        @include media($tablet) {
          display: block;
        }

        &:first-of-type {
          @include media($tablet) {
            margin-bottom: 20px;
          }
        }
      }

      &-title {
        font-size: rem(24px);
        letter-spacing: -0.03em;
        margin-bottom: rem(25);
      }

      &-list {
        display: flex;
        gap: 38px;
        flex-wrap: wrap;

        @include media($tablet) {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(218px, 218px));
          gap: 15px;
        }

        &-content {
          display: block;
        }

        &-item {
          // width: rem(218);
          min-height: rem(312);

          img {
            border-top-left-radius: rem(8);
            border-top-right-radius: rem(8);
            min-width: rem(218);
            height: rem(191);
          }

          .text-wrap {
            background-color: color(gray_lighter_blue);
            border-bottom-left-radius: rem(8);
            border-bottom-right-radius: rem(8);
            padding: 8px 16px 16px 16px;

            .fancybox {
              color: color(combiz_orange);
              font-size: 12px;
              line-height: 1.25;
            }
          }
        }
      }

      &-department {
        color: $dark_very_grayish_red_3;
        font-size: rem(10);
        line-height: 1.4;
      }

      &-name {
        margin-bottom: rem(8);
        font-size: rem(15);
        line-height: 1.33;
        color: $dark_grayish_red;
      }

      &-salutation {
        font-size: rem(12);
        line-height: 1.5;
      }

      &-job-title {
        font-size: rem(12);
        line-height: 1.5;
        color: dark_very_grayish_red_2;
      }
    }
  }
}
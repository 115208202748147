.footer {
  background-color: color(white);
  @include pad(60px 0 16px);

  @include media($mobile) {
    @include pad(32px 0 16px);
  }

  &-top {
    display: flex;
    gap: 0 135px;
    margin-bottom: 82px;

    @include media($mobile) {
      flex-direction: column;
      margin-bottom: rem(40);
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 53px 0;

      @include media($mobile) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: baseline;
      }
    }
  }

  &-nav {
    margin-top: -3px;

    @include media($tablet) {
      margin-top: 0;
    }

    >ul {
      column-count: 2;
      column-gap: 156px;

      @include media($mobile) {
        column-gap: 20px;
      }

      li {
        margin-bottom: 15px;
        list-style: none;

        @include media($tablet) {
          margin-bottom: 5px;
          margin-left: 0;
        }

        @include media($mobile) {
          line-height: 1.18;
        }
      }

      a {
        font-size: rem(18);
        font-weight: 600;
        color: color(combiz_blue);
        position: relative;

        @include media($mobile) {
          font-size: rem(14);
          line-height: inherit;
        }

        &.active,
        &:hover {
          &::before {
            opacity: 1;
            translate: 0 0;
          }
        }

        &::before {
          pointer-events: none;
          opacity: 0;
          content: '';
          width: 100%;
          height: 1px;
          background-color: color(combiz_blue);
          position: absolute;
          bottom: 3px;
          left: 0;
          translate: 0 5px;
          transition-property: opacity, translate;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }

  }

  &-logo {
    width: 170px;
    display: block;
    flex-shrink: 0;

    @include media($tablet) {
      width: 137px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &-bottom {

    #block-bean-social-links-block {
      display: none;
    }
  }

  &-useful-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;
    margin-bottom: 6px;

    @include media($mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-label {
      color: color(combiz_blue);
      font-weight: 900;
      font-size: rem(14);
    }

    ul {
      display: flex;
      align-items: center;
      gap: 0 17px;

      li {
        list-style: none;
        margin: 0;
        @include pad(0);
      }

      a {
        color: color(combiz_blue);
        font-size: rem(12);
        font-weight: 300;
        text-decoration: underline;
      }

      @include media($mobile) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: rem(36);

        li {
          margin-left: 0;
        }
      }
    }
  }

  .block-copyright-block {
    text-align: center;

    p {
      text-transform: uppercase;
      color: color(combiz_blue);
      font-size: rem(12);
      margin-bottom: 0;
    }

    @include media($mobile) {
      text-align: left;

      p {
        font-size: rem(10);
      }
    }

    a {
      color: color(combiz_blue);
    }
  }
}

//mobile-overlay
.mobile-overlay {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  background: rgba(71, 69, 69, 0.7);
  z-index: 1;
  display: none;
}
@font-face {
  font-family: "iconfont";
  src: url('iconfont.eot');
                                                      src: url("iconfont.ttf") format("truetype"),
       url("iconfont.eot?#iefix") format("embedded-opentype"),
       url("iconfont.woff") format("woff"),
       url("iconfont.svg#svgiconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}
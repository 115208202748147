// Set `width` and `height` in a single statement

@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;

  @if length($value) > 1 {
    $height: nth($value, 2);
  }

  @if is-size($height) {
    height: $height;
  }

  @else {
    @warn "`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
  }

  @if is-size($width) {
    width: $width;
  }

  @else {
    @warn "`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
  }
}

.principal-partners {
  background-color: color(combiz_light_gray);

  &-inner {
    @include pad(73px 0 75px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media($tablet) {
      flex-direction: column;
      @include pad(32px 0);
    }
  }

  &-content {
    flex-shrink: 0;
    
    @include media($tablet) {
      margin-bottom: rem(16);
    }
  }

  &-title {

    @include media($tablet) {
      font-size: rem(28);
      line-height: 1.3;
    }
  }

  &-text {}

  &-list {
    background-color: color(white);
    border-radius: 20px;
    width: 385px;
    flex-shrink: 0;
    @include pad(35px 54px);

    @include media($tablet) {
      width: 100%;
      @include pad(32px 16px);
    }
  }

  &-heading {
    font-size: rem(15px);
    font-weight: 500;
    color: color(combiz_blue);
    margin-bottom: 33px;
    letter-spacing: normal;
    line-height: normal;
  }

  &-item {

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  &-frame {
    max-width: 100%;
    display: inline-block;
    margin-bottom: 4px;

    img {
      display: block;
      width: 50%;
      object-fit: contain;
    }
  }

  &-desc {
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
  }
}
.area-chart {
  width: 100%;
  height: 490px;
}

.report-percent-page {

  .title-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h1 {
    text-align: center;
    font-size: rem(28);
    margin: 0;
  }
  .subtitle {
    text-align: center;
    font-size: rem(13);
    margin-top: 10px;
  }

  &.grid {
    background: $gary_light_orange;

    .charts {
      @include outer-container();
      padding: 50px 0;
    }

    .chart-wrap {
      padding: 10px;
      background: $white;
      margin-bottom: 20px;

      @include media($overtablet) {
        @include span-columns(6);
        @include omega(2n);
      }
    }

  }

  &.full {
    margin-bottom: 60px;
  }

  @include media($tablet) {

    h1 {
      font-size: rem(22);
    }
  }

  @include media($mobile) {

    .title-wrap {
      padding-top: 30px;
      padding-bottom: 25px;
    }

    h1 {
      font-size: rem(18);
    }
  }

}

/*** LEAGUE TABLE ***/
.report-league-page {

  .title-wrap {
    padding-bottom: 40px;
  }

  h1 {
    text-align: center;
    font-size: rem(28);
    margin: 0;
  }

  @include media($tablet) {

    .title-wrap {
      padding: 25px 20px 40px;
    }

    h1 {
      font-size: rem(22);
    }

    .league-table {
      padding: 0 20px;
    }
  }

  @include media($mobile) {

    .title-wrap {
      padding-bottom: 25px;
    }

    h1 {
      font-size: rem(18);
    }
  }
}

.male-boards-companies {
  border-top: 1px solid #eaeaea;
  padding: 30px;

  h3 {
    font-size: rem(16);
    margin-bottom: 15px;
    text-align: center;
  }

  .male-boards-companies-header {
    overflow: hidden;
    margin-bottom: 13px;

    & > div {
      width: 50%;
      float: left;
      text-align: center;
      font-size: 12px;
      color: #999;
    }

  }

  .male-boards-companies-company {
    @include clearfix();
    border: 1px solid #eaeaea;
    display: flex;
    margin-bottom: 3px;

    & > div {
      text-align: center;
      font-size: 12px;
      color: #999;
      flex: 0 0 50%;
      padding: 12px;
      line-height: 1.5;
      align-self: center;
    }

    .name {
      color: $orange_bright_2;
      font-size: rem(14);
      font-weight: 600;
      line-height: 1.3;
    }
    .stock {
      font-size: rem(11);
      color: #999;
      font-weight: 500;
    }

    .industry {
      background: #f3f3f4;
      font-size: rem(14);
      font-weight: 600;
      color: #666262;
      align-self: center;
      padding: 21px;
      overflow: hidden;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 100px 0 100px 25px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        left: -14px;
        /* top: 50%; */
        margin-top: -90px;
      }
    }

    &.flagged{
      position: relative;


      .company{
        padding-left: 40px;
      }

      &:before{
        content: url(../../svg/exclamation.svg);
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 15px;
      }
    }
  }
  .flag-legend{
    color: #666262;
    font-size: rem(11);
    line-height: 1.3;
    text-align: center;
    margin: 20px 0 35px;

    &:before{
      content: url(../../svg/exclamation.svg);
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }

  }
}

.bar-line-summary {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding-bottom: 15px;

  .data {
    overflow: hidden;
    border-radius: 3px;
    margin: 30px 30px 10px;

    & > div {
      float: left;
      color: #fff;
      text-align: center;
      padding: 23px 0;
      line-height: 1.2;
    }

    .data-1 {
      background: $orange_bright_2;
      position: relative;
      z-index: 1;
      min-width: 120px;
      max-width: calc(100% - 120px);

      & > div {
        position: relative;
        left: 12px;
      }

    }
    .data-2 {
      background: #664fa3;
      position: relative;
      overflow: hidden;
      min-width: 120px;
      max-width: calc(100% - 120px);

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 100px 0 100px 40px;
        border-color: transparent transparent transparent $orange_bright_2;
        position: absolute;
        left: 0;
        top: -46px;
      }

    }

    .number {
      font-size: rem(26);
      font-weight: 600;
      margin-bottom: -3px;
    }
    .percent {
      font-size: rem(15);
      font-weight: 600;
    }
    .label {
      font-size: rem(12);
    }
  }

  .message {
    text-align: center;
    font-size: rem(13);
    color: #3b3535;
    font-weight: 600;

    .total {
      font-size: rem(26);
      padding: 0 3px;
    }
  }

  @include media($mobile) {

    .data {
      margin: 20px 20px 10px;

      .data-1 {
        min-width: 80px;
        max-width: calc(100% - 80px);
      }
      .data-2 {
        min-width: 80px;
        max-width: calc(100% - 80px);
      }

      .number {
        font-size: rem(20);
      }
      .percent {
        font-size: rem(14);
      }
    }

    .message {

      .total {
        font-size: rem(20);
      }
    }
  }
}

.male-boards-stats {
  overflow: hidden;

  & > div {
    width: 50%;
    float: left;
    display: flex;
    padding: 20px;
    align-items: center;
    position: relative;
  }

  .value {
    font-size: rem(45);
    font-weight: 600;
    margin-right: 5px;
  }
  .label {
    font-size: rem(12);
    font-weight: 600;
    color: #545151;
  }

  .left {
    padding-left: 50px;
    .value {
      color: $orange_bright_2;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 12px;
      border-color: transparent transparent transparent $orange_bright_2;
      position: absolute;
      left: 0;
      top: 27px;
    }

  }
  .right {
    padding-right: 50px;
    .value {
      color: #664fa3;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 12px;
      border-color: transparent transparent transparent #664fa3;
      position: absolute;
      right: 0;
      top: 27px;
      transform: rotate(180deg);
    }
  }

  @include media($tablet) {
    padding: 30px 25px;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 0 25px 12px;
      border-color: transparent transparent transparent $orange_bright_2;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
      transform: rotate(180deg);
    }

    & > div {
      width: 100%;
      float: none;
      padding: 0;
    }

    .value {
      font-size: rem(25);
      margin-right: 15px;
    }

    .left {
      padding: 0;

      &:before {
        content: none;
      }

    }
    .right {
      padding: 0;

      &:before {
        content: none;
      }
    }

  }
}

.report-methodology-page {

  .node-inner-inner {
    max-width: 650px;
    margin: 50px auto;
  }

  h1 {
    text-align: center;
    font-size: rem(24);
  }

  .body {
    ul, p {
      font-size: rem(14);
      color: $dark_very_grayish_red;
    }

    ul {
      margin: 20px 0;
    }

    li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 10px;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        background: $orange_bright_2;
      }
    }
  }
}

.report-header {
  background: $gray_grayish_blue;
  padding: 60px 0 35px;

  .report-link {
    line-height: 1.2;
    display: inline-block;
    padding-left: 40px;

    span {
      display: block;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 31px;
      height: 31px;
      background: url(../../svg/arrow-right.svg) center center no-repeat #545151;
      border-radius: 31px;
      vertical-align: middle;
      transform: rotate(180deg);
      position: absolute;
      left: 0;
      top: 1px;
    }
  }

  .link-title {
    font-size: 17px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 12px;
  }

  @include media($tablet) {
    padding: 30px 20px 15px;

    .report-link {
      padding-left: 30px;

      &:before {
        @include size(20px);
        background: url(../../svg/arrow-right.svg) center center no-repeat #545151;
        background-size: 10px auto;
        border-radius: 20px;
      }
    }

    .link-title {
      font-size: 14px;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.report-sub-links {
  margin-bottom: 50px;

  .container {
    position: relative;
    border-bottom: 2px solid #f4f4f5;
  }

  ul {
    @include clearfix();
  }

  li {
    float: left;
    margin-right: 25px;
  }

  a {
    font-size: rem(14);
    display: block;
    padding: 23px 5px 23px 0;
    position: relative;

    &.active:after {
      content: '';
      display: block;
      width: 15px;
      height: 2px;
      background: $orange_bright_2;
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }

  .mobile-more {
    display: none;
  }

  @include media($tablet) {
    margin-bottom: 25px;
    padding: 0 20px;

    ul {
      width: calc(100% - 60px);
    }

    li {
      float: none;

      &:not(.active) {
        display: none;
      }
    }

    a {
      font-size: rem(12);
      padding: 14px 0 9px;
    }

    .mobile-more {
      display: block;
      cursor: pointer;
      @include position(absolute, 14px 0 null null);
      font-size: rem(12);
      line-height: 1.25;
      letter-spacing: -0.3px;
      color: #545151;
      padding-right: 10px;

      &:before {
        content: '';
        margin: auto;
        @include size(6px);
        @include position(absolute, 6px 0 null null);
        background: url('../../images/build/header-aroow.png') no-repeat center;
        background-size: 100% auto;
      }
    }
  }
}

.report-page {
  background: #fbf9f8;

  .programme-header {

    h1 {
      margin-top: 34px;
      margin-bottom: 0;
    }
    .subtitle {
      font-size: rem(31);
    }
  }

  .report-sub-links {
    background: $white;
    margin: 0;
  }

  .report-message {
    background: $white;
    font-size: rem(28);
    font-weight: 700;
    padding: 43px 0 60px;
    position: relative;

    p {
      font-size: rem(28);
      color: $dark_very_grayish_red_3;
      line-height: 1.2;
    }
  }

  .report-message-inner {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 23px;
      height: 46px;
      background-color: #ffd1ba;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border-left: 0;
      position: absolute;
      left: -40px;
      top: 6px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .report-section {
    margin-bottom: 60px;
    background: $white;

    .header {
      padding: 30px 23px;
      position: relative;
      border-bottom: 1px solid #eaeaea;

      .data-link {
        position: absolute;
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background: #474545;
        text-align: center;
        line-height: 2.9;
        top: 80px;
        right: -21px;

        svg {
          width: 15px;
          height: auto;
        }
      }

      .report-share-link {
        position: absolute;
        top: 20px;
        right: 30px;
        color: $orange_bright_2;
        font-size: rem(12);
        display: inline-block;
        padding-right: 20px;
        background: url(../../svg/share.svg) right center no-repeat;
      }

    }

    .key-data {
      font-size: rem(13);
      font-weight: 600;
    }

    .hsi-woman-wrapper {
      position: relative;
      padding: 25px 20px 0;

      &:before {
        content: '';
        @include size(0);
        border-style: solid;
        border-width: 13px 72.5px 0 72.5px;
        border-color: #fc682d transparent transparent transparent;
        @include position(absolute, 0 0 null 0);
        margin: auto;
      }

      .last-point {
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      .point {
        font-size: 45px;
        font-weight: 600;
        color: #fc682d;
        line-height: 1;
        letter-spacing: -1.04px;

        span {
          font-size: 22px;
        }
      }

      .text {
        font-size: 13px;
        font-weight: 600;
        color: #545151;
      }
    }

    .gauge-chart {
      border-bottom: 1px solid #eaeaea;
    }

    .gaugeContent {
      text-align: center;

      .data {
        @include row();
        margin-bottom: 10px;

        .woman, .man {
          width: 50%;
          float: left;
        }

        .woman {
          color: #fc682d;
        }

        .man {
          color: #664fa3;
        }

        .percentage {
          font-size: 21px;
          font-weight: 600;
          letter-spacing: -0.48px;
          line-height: 1;
          margin: 5px 0 3px;

          span {
            font-size: 18px;
          }
        }

        .number {
          font-size: 15px;
          font-weight: 600;
          line-height: 1;
        }
      }

      .total {
        font-size: 12px;
        font-weight: 500;
        color: #999;
        letter-spacing: -0.28;
        line-height: 2;
      }
    }

    .analysis {
      display: inline-block;
      font-size: rem(15);
      font-weight: 600;
      color: $dark_very_grayish_red_2;
      margin: 30px;
      border-top: 1px solid #999;
      border-bottom: 1px solid #999;
      padding: 10px 0 25px;
      cursor: pointer;

      .analysis-popup {
        display: none;
      }

      .analysis-link {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background: #474545;
        position: relative;
        top: 2px;
        left: 2px;

        svg {
          width: 9px;
          height: auto;
          position: relative;
          top: -2px;
          left: 3px;
        }
      }
    }

    .analysis-header {
      color: #474545;
      font-size: rem(11);
      letter-spacing: 2.8px;
      line-height: 1;
      text-transform: uppercase;
      position: relative;
      top: -16px;

      span {
        display: inline-block;
        padding: 2px 8px 2px 21px;
        background: url(../../images/build/search.svg) left center no-repeat $white;
        background-size: 11px auto;
      }
    }

    .analysis-text {
      color: #666262;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.3;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media($tablet) {

      .gaugeContent {

        .data {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }

  .most-eventful {
    & > h3 {
      text-align: center;
      font-size: rem(16);
      margin-top: 40px;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: rem(11);
      text-align: center;
      margin-bottom: 30px;
    }
    .league-table-header, .arrow {
      display: none;
    }
  }

  .report-section-top-10 {
    & > h3 {
      text-align: center;
      font-size: rem(16);
      margin-top: 40px;
    }

    .league-table-header, .arrow {
      display: none;
    }
  }

  .horizontal-chart {
    padding: 39px 34px;

    h3 {
      color: #3b3535;
      font-size: 16px;
      font-weight: 600;
      padding: 0 10px;
    }

    .value-text {
      display: none;
    }

    .bars {
      position: relative;
      padding: 10px 10px 14px;
      border-left: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;

      * {
        z-index: 1;
        position: relative;
      }

      &:before {
        z-index: 0;
        content: '';
        @include size(1px 100%);
        @include position(absolute, 0 0 null 0);
        margin: 0 auto;
        background: #eaeaea;
      }
    }

    .bar-1 {
      background: #ffd1ba;
      border-radius: 3px;
      overflow: hidden;
      margin-bottom: 7px;
      min-height: 38px;
      position: relative;

      .value-text {
        display: block;
        padding-top: 2px;
        padding-bottom: 0;
      }

      .value {
        background: $orange_bright_2;
        color: $white;
        text-align: center;
        font-size: rem(13);
        font-weight: 600;
        padding: 8px 10px 9px;
        min-width: 60px;
        position: relative;
        min-height: 38px;

        .percent {
          color: #ffd1ba;
          font-size: rem(12);
        }
      }

      &.show-outside {
        .percent {
          position: absolute;
          left: calc(100% + 20px);
          color: $orange_bright_2;
          width: 100px;
          text-align: left;
          top: 3px;
        }
      }

    }

    .bar-2 .value {
      background: #664fa3;
      height: 14px;
      border-radius: 2px;
    }
    .bar-3 .value {
      background: #ab9ecc;
      height: 14px;
      border-radius: 2px;
    }
    .bar-4 .value {
      background: #e3dcf2;
      height: 14px;
      border-radius: 2px;
    }

    .years {
      margin-top: 5px;
      padding: 0 10px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        float: left;
        color: #474545;
        font-size: rem(11);
        line-height: 1.72;
        position: relative;
        padding: 0 0 0 16px;
        margin-right: 10px;

        &:before {
          content: '';
          display: block;
          width: 11px;
          height: 11px;
          border-radius: 11px;
          background: $orange_bright_2;
          position: absolute;
          left: 0;
          top: 3px;
        }

        &:nth-child(2) {
          &:before {
            background: #664fa3;
          }
        }
        &:nth-child(3) {
          &:before {
            background: #ab9ecc;
          }
        }
        &:nth-child(4) {
          margin-right: 0;
          &:before {
            background: #e3dcf2;
          }
        }
      }
    }
  }

  .progress-items {
    padding: 30px 23px;
  }
  .progress-item {
    margin-bottom: 25px;

    img {
      float: left;
      width: 25px;
      height: auto;
      padding: 2px;
      border: 1px solid #dadcea;
      margin-right: 5px;
      position: relative;
      top: 8px;
    }
    .text-wrap {
      overflow: hidden;
    }
    .progress-item-header {
      overflow: hidden;
      padding-bottom: 7px;
    }
    h3 {
      font-size: rem(16);
      margin: 0 0 -3px;
    }
    .subtitle {
      font-size: rem(12);
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .bar {
      background: #ffd1ba;
      border-radius: 3px;
      overflow: hidden;
    }
    .bar-inner {
      background: $orange_bright_2;
      text-align: center;
      color: $white;
      font-size: rem(13);
      font-weight: 600;
      padding: 8px;
      min-width: 50px;
    }

  }

  .report-analysis-wrap {
    background: $white;
    padding: 25px 20px 40px;
  }

  .report-analysis-intro {
    background: #474545;
    padding: 25px 25px 35px;
    border-radius: 4px;
    color: $white;
    margin-bottom: 35px;

    .label {
      font-size: rem(13);
      font-weight: 600;
      margin-bottom: 15px;
    }

    .text {
      color: $white;
      font-size: rem(26);
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .report-analysis-main {
    padding: 0 13px;
    font-size: rem(14);
  }

  @include media($tablet) {

    .report-message {
      font-size: rem(22);
      padding: 46px 0 32px;

      p {
        font-size: rem(22);
        line-height: 1.6;
      }
    }

    .report-message-inner {

      &:after {
        width: 16px;
        height: 32px;
        left: -27px;
      }
    }

    .main-wrapper {
      padding: 32px 0;
    }
  }

  @include media($mobile) {

    .report-message {
      font-size: rem(16);
      padding: 20px 22px 22px;

      p {
        font-size: rem(16);
        line-height: 1.7;
      }
    }

    .report-message-inner {

      &:after {
        width: 10px;
        height: 20px;
        left: -15px;
      }
    }

    .main-wrapper {
      padding: 27px 0;
    }

    .main-with-sidebar {
      padding: 0;
    }

    .report-section {
      margin-bottom: 25px;

      .header {
        padding: 20px 21px;

        .report-share-link {
          right: 21px;
        }

        .data-link {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          top: 42px;
          right: 21px;

          svg {
            width: 11px;
            @include transform(translateY(-7px));
          }
        }
      }

      .key-data {
        font-size: rem(12);
        padding-right: 80px;
      }

      h2 {
        font-size: 18px;
        line-height: 1.39;
        letter-spacing: -0.5px;
        padding-right: 75px;
        margin-bottom: 5px;
        min-height: 30px;
      }

      .hsi-woman-wrapper {
        padding: 20px 20px 0;

        &:before {
          border-width: 6px 33.5px 0 33.5px;
        }

        .point {
          font-size: 25px;
          letter-spacing: -0.6px;

          span {
            font-size: 12px;
          }
        }

        .text {
          font-size: 12px;
        }
      }

      .chart-wrap {
        overflow: hidden;
      }

      .single-area-chart {
        margin-top: -75px;
      }

      .gaugeContent {
        display: block;
        @include transform(translateY(30px));

        .data {
          width: 80%;
          margin: 0 auto 20px;

          img {
            width: 7px;
          }

          .percentage {
            font-size: 14px;
            letter-spacing: -0.3px;
            line-height: 0.93;
            margin: 0;

            span {
              font-size: 12px;
            }
          }

          .number {
            font-size: 12px;
          }
        }
      }

      .analysis {
        font-size: rem(12);
        margin: 20px;
        padding: 13px 0 13px;

        .analysis-link {
          width: 12px;
          height: 12px;
          border-radius: 12px;
          top: 3px;

          svg {
            width: 5px;
            top: -6px;
          }
        }
      }

      .analysis-text {
        font-size: 12px;
      }
    }

    .horizontal-chart {
      padding: 20px 23px;

      h3 {
        font-size: 14px;
        padding: 0;
      }

      .bars {
        padding: 10px 3.8px;
      }

      .bar-1 {
        margin-bottom: 4px;

        .value {
          font-size: rem(12);
          padding: 7px 10px 5px;
        }
      }

      .years {
        margin-top: 10px;
        padding: 0;
      }
    }

    .most-eventful {
      & > h3 {
        font-size: rem(14);
        margin-top: 30px;
      }
      .subtitle {
        font-size: rem(12);

        svg {
          display: inline-block;
          margin-right: 4px;
          @include transform(translateY(1px));
        }
      }
      .league-table-item {
        margin-left: 23px;
        margin-right: 23px;
        margin-bottom: 15px;
      }
    }

    .report-analysis-intro {
      padding: 14px 22px 21px;
      margin-bottom: 25px;

      .label {
        font-size: rem(12);
      }

      .text {
        font-size: 16px;
      }
    }

    .report-analysis-main {
      padding: 0;
    }
  }
}

.report-list-header {
  padding: 30px 22px 0;
  text-align: center;
  @include row();

  .header-item {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    padding-left: 16px;
    margin: 0 5px;
    line-height: 1;

    &.ft-director {
      background: url('../../images/build/Star-2.png') no-repeat left center;
      background-size: 12px auto;
    }

    &.ft-corp-director {
      background: url('../../images/build/Star-3.png') no-repeat left center;
      background-size: 12px auto;
    }
  }

  @include media($mobile) {
    text-align: left;

    .header-item {
      margin: 0 0 10px;
      display: block;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.report-list {
  padding: 30px 22px;
  margin: 0;

  li {
    @include clearfix();
    border: 1px solid $gray_very_light;
    margin-bottom: 15px;
    border-radius: 3px;
    padding: 15px;
    position: relative;

    &.first-time-director-on-hsi-board, &.first-time-appointed-corporate-director-in-hong-kong {

      &:before {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        position: absolute;
        top: -11px;
        right: -11px;
        background-position: center 4px;
        border: 1px solid $orange_bright_2;
        background-size: 15px auto;
      }
    }

    &.first-time-director-on-hsi-board:before {
      background: url(../../images/build/Star-2.png) center 4px no-repeat $orange_bright_2;
      background-size: 15px auto;
    }
    &.first-time-appointed-corporate-director-in-hong-kong {

      &:before {
        background: url(../../images/build/Star-3.png) center 4px no-repeat $white;
        background-size: 15px auto;
      }
    }
  }

  .image {
    width: 94px;
    height: 94px;
    float: left;
    margin-right: 15px;
    border-radius: 94px;
    overflow: hidden;
    background: url(../../images/build/default-avatar-female.png) center center no-repeat;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text-wrap {
    overflow: hidden;
  }

  h3 {
    font-size: rem(16);
    margin: 0;
  }
  .role {
    font-size: rem(13);
    color: #666262;
    font-weight: 500;
  }
  .company {
    font-size: rem(13);
  }

  a {
    font-size: rem(13);
    color: $orange_bright_2;

    svg {
      background: $orange_bright_2;
      padding: 2px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

}

.chart-header {
  @include row();
  margin-bottom: 20px;
  padding: 15px 15px 0 15px;

  .flag, .info {
    float: left;
  }

  .flag {
    width: 31px;
    border-radius: 2px;
    border: 1px solid #dadcea;
    padding: 3px;

    img {
      width: 100%;
      display: block;
    }
  }

  .info {
    padding-left: 9px;
    width: calc(100% - 40px);

    .chart-title {
      color: #3b3535;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.38px;
      line-height: 1.1;
    }

    .chart-subtitle {
      color: #545151;
      font-size: 12px;
    }
  }
}

.report-new-appointments {

  min-height: 600px;

  h1 {
    font-size: rem(28);
    text-align: center;
    margin-bottom: 0;
  }

  .body {
    text-align: center;
    margin-top: 30px;
  }

  .report-list {
    padding-bottom: 80px;
    @include clearfix();

    @include media($overtablet) {
      li {
        @include span-columns(6);
        @include omega(2n);
      }
    }
  }

  @include media($tablet) {

    h1 {
      font-size: rem(22);
    }
  }

  @include media($mobile) {

    h1 {
      font-size: rem(18);
    }
  }
}

.share-popup {
  display: none;
}

.analysis-popup-inner.share {
  .name {
    font-size: rem(19);
    color: #545151;
    margin-bottom: 25px;
  }
}

.node-report-methodology {

  h1 {
    text-align: center;
    font-size: rem(28);
    margin: 0 0 30px;
  }

  @include media($tablet) {

    h1 {
      font-size: rem(22);
    }

    .body {
      padding: 0 22px;
    }
  }

  @include media($mobile) {

    h1 {
      font-size: rem(18);
    }
  }
}

.node-report {
  .chart-wrap {
    .highcharts-scrollbar {
      display: none;
    }
  }
}

.node-report-percentage.full .area-chart {
  .highcharts-data-labels {
    .highcharts-data-label:first-child {
      margin-left: 35px !important;
    }
    .highcharts-data-label:last-child {
      margin-left: -35px !important;
    }

    &.highcharts-series-0 {
      .highcharts-data-label * {
        color: $white !important;
      }
    }
  }
}

.report-partners-page {
  background: $gary_light_orange;
}

.view-report-partners {

  margin-bottom: 50px;
  @include clearfix();

  .partner {

    position: relative;
    margin-bottom: 24px;

    @include media($overtablet) {
      @include span-columns(3);
      @include omega(4n);
    }

    &:before {
      content: '';
      height: 160px;
      width: 160px;
      background: rgba(0, 0, 0, 0.06);
      filter: blur(15px);
      position: absolute;
      bottom: -25px;
      left: calc(50% - 80px);
      z-index: 1;
    }

  }

  .partner-inner {
    background: $white;
    padding: 20px;
    z-index: 2;
    position: relative;
    overflow: hidden;
  }

  h2 {
    font-size: rem(15);
    letter-spacing: 0;
    margin-bottom: 60px;
  }
  .partner {
    a {
      color: $orange_bright_2;
      font-size: rem(12);
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }

  img {
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto 20px;

  }

  .pager {
    padding-top: 50px;
  }

  @include media($tablet) {
    padding-left: 20px;
    padding-right: 20px;

    .partner {
      margin-bottom: 15px;
    }

    .text-wrap {
      overflow: hidden;
      padding-top: 20px;
    }

    h2 {
      margin: 0 0 5px;
    }
    .image-wrap {
      float: left;
      position: relative;
      padding-right: 15px;
      margin-right: 15px;

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 0;
        border-right: 1px solid $gray_very_light;
      }
    }
    img {
      width: 85px;
      height: auto;
      margin: 0;
    }
    .partner {
      a {
        position: static;
      }
    }

  }

}

.node-type-report {

  .programme-header {
    padding: 115px 0 50px;

    .sponsors {
      margin-top: 50px;
    }

    .lead-sponsor, .gold-sponsor {
      display: inline-block;
      vertical-align: top;
    }

    .logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;

      img {
        width: auto;
        height: auto;
        max-width: 120px;
        max-height: 100px;
        display: block;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .headline {
      display: block;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 1.4;
      letter-spacing: 1.6px;
      margin-bottom: 10px;
    }

    .lead-sponsor {
      margin-right: 50px;

      //.logo {
      //  max-width: 90px;
      //}
    }

    //.gold-sponsor .logo {
    //  max-width: 70px;
    //}
  }

  @include media($tablet) {

    .programme-header {
      padding: 115px 20px 50px;

      .sponsors {
        margin-top: 25px;
      }

      .lead-sponsor, .gold-sponsor {
        display: block;
      }

      .logo {

        img {
          max-width: 80px;
          max-height: 65px;
        }
      }

      .lead-sponsor {
        margin: 25px 0;

        //.logo {
        //  max-width: 70px;
        //}
      }

      //.gold-sponsor .logo {
      //  max-width: 70px;
      //}
    }
  }
}

.node-type-report-partners {

  .partners {
    @include row();
    margin-bottom: 60px;

    .partners-title {
      text-align: center;
      margin-bottom: 47px;
    }
  }

  .partner {
    @include row();

    .partner-wrapper {
      @include row();
    }

    .partner-title {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
      margin-bottom: 45px;
    }
  }

  @include media($tablet) {

    .partner {

      .partner-title {
        margin-bottom: 5px;
      }
    }
  }
}

.orange-banner {
  padding: 75px 0;

  &-inner {
    position: relative;
    z-index: 2;
  }

  &-title {
    font-size: rem(35px);
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.5;
    letter-spacing: normal;
  }

  &-text {
    max-width: 883px;
    font-size: rem(15);
    margin-bottom: 15px;
  }

  .secondary-call-action {
    background: transparent;
    border-color: $white;
    color: $white;
  }

  @include media($tablet) {
    padding: 45px 0;
  }

  @include media($mobile) {
    padding: 40px 20px 35px;
    text-align: left;

    h2 {
      font-size: rem(21px);
      margin-bottom: 5px;
      line-height: 1.2;
      letter-spacing: -0.61px;
    }

    .text {
      margin-bottom: 47px;
      font-size: rem(14);
      font-weight: 500;
    }

    .secondary-call-action {
      padding: 9px 24px 7px;
    }
  }
}
.values {
  background-color: color(combiz_light_gray);
  text-align: center;

  &-inner {
    @include pad(61px 0 103px);
    font-size: rem(35px);
    letter-spacing: normal;
    margin-bottom: 10px;

    @include media($tablet) {
      @include pad(38px 0 103px);
    }
  }

  &-heading {
    font-size: rem(35px);
    font-weight: 600;
    margin-bottom: 23px;
    letter-spacing: normal;
    color: color(combiz_blue);

    @include media($tablet) {
      font-size: rem(28);
    }

    @include media($mobile) {
      text-align: left;
      line-height: 1.3;
      margin-bottom: rem(16);
    }
  }

  &-desc {
    font-size: rem(15);
    margin-bottom: 45px;
    color: color(combiz_black);

    @include media($mobile) {
      font-size: rem(12);
      line-height: 1.5;
      margin-bottom: rem(24);
      text-align: left;
    }
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  &-card {
    width: 260px;
    flex-shrink: 0;
    @include pad(37px 22px 24px);
    background-color: color(white);
    border-radius: 20px;

    @include media($tablet) {
      margin: 0 auto;
    }
  }

  &-circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: color(gray_light_300);
    margin-bottom: -2px;
    display: inline-block;
  }

  &-icon {

    >img {
      display: block;
      margin: 0 auto 24px auto;
      max-width: rem(80);
      max-height: rem(80);

      @include media($mobile) {
        max-width: rem(60);
        max-height: rem(60);
      }
    }
  }

  &-label {
    font-size: rem(30px);
    color: color(dark_very_grayish_red_3);
    margin-bottom: 3px;
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  &-par {
    font-size: rem(18px);
    margin-bottom: 0;
    line-height: 1.4;
  }

  .slick-dots {
    bottom: rem(-75);

    button::before {
      content: '';
    }
  }
}

.node-type-events-landing-page {

  .section-key-events {
    background: $gary_light_orange;
    @include pad(60px 0);

    .key-events {
      @include clearfix();
    }

    .card-image-left {
      @include pad(0 8px 0 24px);

      .title {
        font-size: rem(16);
        margin-bottom: 6px;
      }
    }

    @include media($desktop) {

      .card-image-left {
        @include pad(0);
      }
    }

    @include media($mobile) {
      @include pad(25px 20px);

      h2 {
        margin-bottom: 0;
      }

      .text {
        font-size: em(14);
        line-height: 1.5;
        letter-spacing: -0.41px;
        margin-bottom: 30px;
      }
    }
  }

  .section-upcoming-events {
    position: relative;
    background: $gary_light_orange;
    @include pad(40px 0);
    text-align: center;

    &:after {
      content: '';
      @include size(100% 215px);
      @include position(absolute, null null 0 0);
      background: $white;
      z-index: 0;
    }

    .container {
      z-index: 1;
    }

    h2 {
      text-align: left;
      margin-bottom: 38px;
    }

    .upcoming-events {
      text-align: left;
    }

    .card {
      margin-bottom: 45px;

      .title {
        margin-bottom: 7px;
      }
    }

    .primary-call-action {
      margin: 45px auto 0;
    }

    @include media($tablet) {

      .card {
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 25px;
      }
    }

    @include media($mobile) {
      @include pad(25px 20px);

      .card {
        @include omega-reset(2n);
        @include span-columns(12);
      }

      .primary-call-action {
        margin: 0 auto;
      }
    }
  }

  .section-about-events {
    @include pad(50px 0 90px);
    text-align: center;

    h2 {
      margin-bottom: 25px;
    }

    .text {
      max-width: 700px;
      margin: 0 auto;
      line-height: 1.64;
    }

    @include media($mobile) {
      @include pad(40px 25px 45px);

      h2 {
        margin-bottom: 10px;
      }
    }
  }
}
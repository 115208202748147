.mv {
  margin-bottom: 76px;

  @include media($tablet) {
    margin-bottom: 10px;
  }

  &-image {
    height: 586px;
    width: 100%;
    background-image: url('../../images/build/mv-image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media($tablet) {
      height: 430px;
    }
  }

  &-content {
    margin-top: -122px;
  }

  &-inner {
    background-color: color(white);
    border-radius: 18px;
    @include pad(22px 40px);

    @include media($tablet) {
      @include pad(24px 21px);
    }
  }

  &-title {
    font-size: rem(48.83px);
    color: color(combiz_blue);
    margin-bottom: 7px;
    line-height: 1.2;

    @include media($tablet) {
      font-size: rem(32);
    }
  }

  &-subtitle {
    font-size: rem(20px);
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;

    @include media($tablet) {
      font-size: rem(16px);
    }
  }

  &-desc {
    font-size: rem(20px);
    color: color(combiz_black);

    @include media($tablet) {
      font-size: rem(16px);
    }
  }
}

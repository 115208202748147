.get-involved {
  background-color: color(combiz_light_gray);

  &-section {
    @include pad(74px 0 73px);
  }

  &-content {
    max-width: 955px;
    width: 100%;
    margin: 0 auto;
  }

  &-title {
    font-size: rem(49);
    color: color(combiz_blue);
    letter-spacing: normal;
    margin-bottom: 25px;
    text-align: center;
    padding-right: 22px;

    @include media($tablet) {
      padding-right: 0;
      font-size: rem(32);
      font-weight: 700;
      line-height: 1.2;
    }
  }

  &-text {
    text-align: center;

    p {
      font-size: rem(20);
      margin: 0;
      color: color(combiz_blue);
      line-height: 1.5;

      @include media($tablet) {
        font-size: rem(16);
        margin-bottom: 15px;
      }
    }
  }

  &-grid {
    margin-top: 45px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main two three"
      "main main four five"
      "six seven eight nine";
    gap: 18px;

    @include media($tablet) {
      margin-top: 15px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto repeat(4, 1fr);
      grid-template-areas:
        "main main"
        "two three"
        "four five"
        "six seven"
        "eight nine";
      gap: 15px;
    }

    .grid {
      background-color: color(combiz_blue);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:first-of-type {
        grid-area: main;
        min-height: 460px;

        @include media($tablet) {
          min-height: 327px;
        }
      }

      &:nth-of-type(2) {
        grid-area: two;
      }

      &:nth-of-type(3) {
        grid-area: three;
      }

      &:nth-of-type(4) {
        grid-area: four;
      }

      &:nth-of-type(5) {
        grid-area: five;
      }

      &:nth-of-type(6) {
        grid-area: six;
      }

      &:nth-of-type(7) {
        grid-area: seven;
      }

      &:nth-of-type(8) {
        grid-area: eight;
      }

      &:nth-of-type(9) {
        grid-area: nine;
      }

      &-anchor {
        display: block;
        height: 100%;
        position: relative;
      }

      &-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        @include pad(21px 25px 16px);

        @include media($desktop) {
          @include pad(21px 15px 16px);
        }

        @include media($tablet) {
          @include pad(7px 15px 16px);
        }
      }

      &-title {
        font-size: rem(18);
        font-weight: 500;
        color: color(white);
        letter-spacing: -0.03em;
        margin-bottom: 10px;
        line-height: 1.3;

        @include media($desktop) {
          font-size: rem(16);
        }
      }

      &-par {
        margin: 0;
        font-size: rem(11);
        color: color(white);
        line-height: 1.5;

        @include media($desktop) {
          font-size: rem(8);
        }
      }

      &-link {
        margin-top: auto;
        color: color(white);
        font-size: rem(13);

        @include media($desktop) {
          font-weight: 500;
        }

        &.cta {
          margin-top: 0
        }
      }

      &__main {
        .grid-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .grid-content {
          position: relative;
          width: 199px;
          @include pad(0);
          margin: 45px 24px 0 auto;

          @include media($tablet) {
            margin: 27px 43px 0 auto;
          }
        }

        .grid-title {
          color: color(dark_grayish_red);
          font-size: rem(24px);

          @include media($tablet) {
            font-weight: 400;
          }
        }

        .grid-par {
          color: color(dark_grayish_red);
          margin-bottom: 30px;

          @include media($tablet) {
            font-size: rem(11);
            margin-bottom: 18px;
          }
        }

        .grid-link {
          align-self: baseline;

          &.cta {
            @include pad(10px 30px 7px);
            font-weight: 500;
            font-size: rem(14);

            @include media($tablet) {
              font-weight: 600;
              @include pad(4px 10px);
            }
          }
        }
      }

      &__social {
        padding-bottom: 23px
      }
    }

    .social-media {
      @include pad(0 25px);
      display: flex;
      justify-content: space-between;

      &-item {
        margin: 0;
        background-color: color(white);
        box-shadow: none;
        height: 35px;
        width: 35px;

        @include media($desktop) {
          width: 25px;
          height: 25px;
        }

        span {
          display: none;
        }

        g,
        path {
          fill: color(combiz_blue);
        }
      }
    }
  }
}
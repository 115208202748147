.gallery {

  .item {
    position: relative;
    float: left;
    margin: 0 11px 11px 0;
    width: calc((100% - 22px) / 3);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    cursor: pointer;

    &:before {
      content: '';
      @include position(absolute, 0 0 0 0);
      @include size(100%);
      background: $white;
      opacity: 0.3;
      z-index: -1;
      @include transition(all 333ms linear);
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:hover {

      &:before {
        opacity: 0;
      }
    }
  }
}


.gallery-slick-wrapper {
  @include outer-container(960px);
  @include pad(20px 19px 0);
  @include position(fixed, null 0 0 0);
  @include transform(translateY(-50%));
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(all 333ms linear);

  .close {
    cursor: pointer;
    background: url('../../html_prototype/images/close.svg') no-repeat center;
    background-size: 100%;
    @include size(10px);
    @include position(absolute, 0 0 null null);
  }

  .gallery-slick-for {
    margin-bottom: 7px;

    .item {
      width: 100%;
      position: relative;
      @include clearfix();
      background: $orange_bright;

      img {
        @include span-columns(9,block-collapse);
      }

      .content {
        position: absolute;
        @include span-columns(3,block-collapse);
        float: none;
        right: 0;
        height: 100%;
        @include pad(0 21px 23px);

        .text {
          @include position(absolute, null null 23px 21px);
          color: $white;
          font-size: em(12);
          line-height: 1.25;
          width: calc(100% - 42px);
        }
      }
    }
  }

  .gallery-slick-nav {

    .item {
      position: relative;
      float: left;
      width: calc((100% - 44px) / 11);
      margin-right: 4px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 1;
      cursor: pointer;
      outline: none;

      &:before {
        content: '';
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        background: $white;
        opacity: 0.3;
        z-index: -1;
        @include transition(all 333ms linear);
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      &.slick-current {

        &:before {
          opacity: 0;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 20;
    @include position(fixed, 50% 0 null 0);
  }

  .slick-arrow {
    @include size(30px);
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: none;
    }

    &:hover {
      background: $white;
    }

    &.slick-next {
      right: -15px;
      background: url('../../html_prototype/images/slide-arrow-next-2.png') no-repeat center $white;
      background-size: 7.7px auto;
    }

    &.slick-prev {
      left: -15px;
      background: url('../../html_prototype/images/slide-arrow-prev-2.png') no-repeat center $white;
      background-size: 7.7px auto;
    }
  }
}
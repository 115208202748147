.bean-our-partners {
  position: relative;
  @include pad(104px 0 88px);
  background: $gary_light_orange;

  .text-wrap {
    @include span-columns(6);
  }

  .partners-wrap {
    @include shift(2);
    @include span-columns(3);
  }

  .partners-list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 34px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .image {
      margin-bottom: 11px;

      img {
        zoom: 50%;
      }
    }

    .text {
      font-size: 12px;
      color: $dark_very_grayish_red_2;
      line-height: 1.5;
    }
  }

  strong {
    font-size: 14px;
    color: $dark_very_grayish_red;
    line-height: 1.43;
  }

  h3 {
    font-size: 11px;
    color: $dark_very_grayish_red_3;
    margin-bottom: 37px;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    font-weight: normal;
  }

  .secondary-call-action {
    margin-top: 10px;
  }

  @include media($tablet) {
    @include pad(60px 0 46px);

    .text-wrap {
      @include span-columns(7);
      margin-bottom: 35px;
    }

    .partners-wrap {
      @include shift(0);
      @include span-columns(8);
    }

    .partners-list {

      li {
        position: relative;
        @include span-columns(4 of 8);
      }

      .image {
        height: 40px;
      }
    }

    p {
      margin-bottom: 6px;
    }

    h3 {
      margin-bottom: 15px;
    }
  }

  @include media($mobile) {
    @include pad(40px 20px);

    &:before {
      content: '';
      @include size(84px);
      @include position(absolute, -42px -42px null null);
      background: rgba(252, 104, 45, 0.13);
      border-radius: 50%;
    }

    .text-wrap {
      float: none;
      width: 100%;
      margin: 0 0 30px;
    }

    .partners-wrap {
      float: none;
      width: 100%;
    }

    .partners-list {

      li {
        display: inline-block;
        width: auto;
        margin: 0 26px 0 0;

        &:first-of-type {
          margin-left: 6px;
        }
      }

      .image {
        margin-bottom: 0;
      }

      .text {
        display: none;
      }
    }
  }
}
//card
.card {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: color(white);
  overflow: hidden;
  border-radius: 20px;
  height: 100%;
  transition: translate 0.3s cubic-bezier(0.38, 0.18, 0.43, 1);

  &:hover {
    translate: 0 -10px;

    .card-picture {
      scale: 1;
    }
  }

  &-content {
    @include pad(11px 14px 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &-frame {
    overflow: hidden;
    flex-shrink: 0;
    position: relative;

    .image {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 169px;
    }

    .price {
      position: absolute;
      top: 0;
      right: 0;
      background: $orange_bright;
      color: $white;
      z-index: 2;
      font-size: rem(12);
      padding: 6px 10px 3px;
      font-weight: 500;
      letter-spacing: 0.92px;
      line-height: 1.41;
      text-transform: capitalize;
    }
  }

  &-picture {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 169px;
    scale: 1.05;
    transition: scale 0.3s cubic-bezier(0.38, 0.18, 0.43, 1);
  }

  .topic {
    position: absolute;
    top: 8px;
    right: 0;
    background: $orange_bright;
    color: $white;
    padding: 3px 10px;
    font-size: rem(10);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    z-index: 5;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-weight: 500;
  }

  &-title {
    font-size: rem(15px);
    letter-spacing: -0.03em;
    line-height: 1.33;
    font-weight: 600;
    margin-bottom: 9px;
    color: color(combiz_blue)
  }

  &-date {
    display: block;
    font-size: rem(12px);
    letter-spacing: 0.16em;
    line-height: normal;
    font-weight: 600;
    color: color(dark_very_grayish_red_3);
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .created-date {
    font-size: em(11);
    font-weight: normal;
    margin-bottom: 5px;
    color: $dark_very_grayish_red_2;
  }

  .category-wrapper {
    width: 100%;
    margin-bottom: 15px;
  }

  .category {
    font-size: 0;
    margin-bottom: 8px;

    span {
      font-size: rem(11px);
      text-transform: uppercase;
      font-weight: 400;
      color: color(dark_very_grayish_red_2);
      position: relative;
      display: inline-block;
      line-height: 1.5;

      &:not(:last-of-type) {
        margin-right: 10px;

        &::before {
          position: absolute;
          top: 0;
          right: -7px;
          content: '|';
        }
      }
    }
  }

  .more-category {
    float: left;
    font-size: em(10);
    letter-spacing: 1.6px;
    line-height: 1.4;
    margin: 0 7px 3px 0;
    font-weight: 500;
    color: $gray_dark;
  }

  &-desc {
    margin: 0 0 18px;
    font-size: rem(12px);
    letter-spacing: -0.3px;
    line-height: 1.58;
    color: color(dark_very_grayish_red_2);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-cta {
    font-size: rem(16px);
    font-weight: 400;
    @include pad(8px 24px 6px 16px);
    @include caret(color(white));
    margin-top: auto;

    &::after {
      margin-left: 8px;
    }
  }

}

//card-horizontal
.card-horizontal {
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 385px;
  margin: 30px 0;
  background: $gary_light_orange_2;
  @include transition(all 333ms ease-out);

  .date-wrapper {
    overflow: hidden;
    @include position(absolute, 0 null null 0);
    @include size(40px 100%);

    .date {
      font-size: em(18);
      font-weight: 600;
      @include transform(translateX(-50%) translateY(-50%) rotate(-90deg));
      margin: 0 0 0 20px;
      position: absolute;
      top: 50%;
      text-align: center;
      color: $gray_dark;
      min-width: 100px;
      text-transform: uppercase;
    }
  }

  .content {
    position: relative;
    margin-left: 40px;
    min-height: 180px;
    @include pad(26px 44px 18px 24px);
  }

  .image-wrapper {
    overflow: hidden;
    @include position(absolute, 0 null null 0);
    @include size(100%);

    .image {
      position: relative;
      @include size(100%);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include transition(all 333ms ease-out);

      &:after {
        content: '';
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        background: $white;
        opacity: 0.95;
        @include transition(all 333ms ease-out);
      }
    }
  }

  .network {
    margin-bottom: 6px;
    font-size: em(10);
    text-transform: uppercase;
    letter-spacing: 1.6px;
    line-height: 1.4;
    position: relative;
    color: $dark_very_grayish_red_3;
  }

  .title {
    font-size: em(18);
    font-weight: 600;
    letter-spacing: -0.53px;
    line-height: 1.3;
    margin-bottom: 12px;
    position: relative;
    color: $dark_grayish_red;
  }

  p {
    font-size: em(12);
    letter-spacing: -0.3px;
    line-height: 1.58;
    color: $dark_very_grayish_red_2;
    margin-bottom: 11px;
    position: relative;
  }

  &:hover {
    @include transform(scale(1.05));
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.12);

    a {
      text-decoration: none !important;
    }

    .image {
      @include transform(scale(1.2));

      &:after {
        opacity: 0.9;
      }
    }
  }

  @include media($mobile) {
    margin: 15px 0;

    .content {
      @include pad(16px 20px 15px);
    }

    .network {
      margin-bottom: 3px;
    }

    .title {
      font-size: em(15);
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 27px;
    }
  }
}

//card-square
.card-square {
  position: relative;
  @include span-columns(3 of 12);
  text-align: center;
  //background: $gary_light_orange;
  //border: 1px solid $white;
  margin-bottom: 20px;
  @include transition(all 333ms ease-out);
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    padding-top: 100%;
    background: $gary_light_orange;
  }

  .content {
    @include pad(25px);
    margin: auto;
    @include position(absolute, 50% 0 null 0);
    @include transform(translateY(-50%));
  }

  .icon {
    width: 25px;
    margin: 0 auto 20px;

    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      @include transition(all 333ms ease-out);
    }
  }

  .title {
    font-size: rem(18);
    font-weight: 500;
    letter-spacing: -0.53px;
    line-height: 1.3;
    margin-bottom: 17px;
  }

  p {
    font-size: rem(12);
    color: $dark_very_grayish_red_2;
    letter-spacing: 0;
    line-height: 1.58;
    margin-bottom: 0;
  }

  &:hover {
    @include transform(scale(1.05));
    box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.08);
  }

  @include media($desktop) {

    .content {
      @include pad(12px);
    }

    .icon {
      width: 20px;
      margin: 0 auto 7px;
    }

    .title {
      font-size: rem(15);
      letter-spacing: -0.44px;
      line-height: 1.6;
      margin-bottom: 10px;
    }

    p {
      font-size: rem(11);
      line-height: 1.45;
    }
  }

  @include media($tablet) {

    .content {
      @include pad(25px);
    }

    .icon {
      width: 30px;
      margin-bottom: 17px;
    }

    .title {
      font-size: em(15);
      margin-bottom: 0;
      height: 45px;
    }

    p {
      display: none;
    }
  }

  @include media($mobile) {
    @include omega-reset(4n);
    @include span-columns(6 of 12);
    @include omega(2n);
    margin-bottom: 15px;

    .title {
      font-size: rem(14);
    }
  }
}

//card-login
.card-login {
  cursor: pointer;
  width: 225px;
  position: relative;
  @include pad(34px 20px 33px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  @include transition(all 333ms ease-out);

  &:after {
    content: '';
    @include position(absolute, 0 0 0 0);
    background: $orange_bright;
    opacity: 0.93;
    z-index: -1;
  }

  .title {
    font-size: em(18);
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.53px;
    color: $white;
  }

  .sub-title {
    font-size: em(11);
    color: $white;
    text-transform: lowercase;
  }

  &:hover {
    @include transform(scale(1.05));
    box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.08);
  }
}

//email-subscription
.email-subscription {
  position: relative;
  @include pad(64px 21px 100px 31px);
  overflow: hidden;
  margin-bottom: 25px;
  @include transition(all 333ms ease-out);

  .background {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    background: url('../../images/build/email-subscription.jpg') no-repeat center;
    background-size: cover;
    z-index: 0;
    @include transition(all 333ms ease-out);

    &:after {
      content: '';
      @include position(absolute, 0 0 0 0);
      background: $orange_bright;
      opacity: 0.9;
      z-index: -1;
      @include transition(all 333ms ease-out);
    }
  }

  .title {
    font-size: em(21);
    line-height: 1.2;
    margin-bottom: 8px;
    color: $white;
    text-transform: capitalize;
    position: relative;
  }

  p {
    font-size: em(14);
    line-height: 1.5;
    color: $white;
    position: relative;
  }

  .primary-call-action {
    display: inline-table;
    @include position(absolute, null null 44px 50%);
    @include transform(translateX(-50%));
  }

  &:hover {
    @include transform(scale(1.05));

    .background {
      @include transform(scale(1.2));

      &:after {
        opacity: 0.95;
      }
    }
  }

  @include media($mobile) {
    @include pad(43px 26px 38px);

    .title {
      width: 280px;
    }

    p {
      font-size: em(13);
      font-weight: 500;
      margin-bottom: 15px;
    }

    .primary-call-action {
      @include position(relative, inherit);
      @include transform(translateX(0));
    }
  }
}

//card-team
.card-team {
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: translate 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    translate: 0 -15px;
    box-shadow: 0px 0px 24px rgba(25, 32, 56, 0.08);

    .card-team-content {
      background-color: color(combiz_blue);
    }

    .card-team-desc,
    .card-team-salutation,
    .card-team-position,
    .card-team-name {
      color: color(white)
    }
  }

  &-figure {
    overflow: hidden;
    height: 191px;
    flex-shrink: 0;
  }

  &-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-content {
    @include pad(10px 20px);
    transition: background-color 0.3s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-position {
    font-size: rem(10px);
    color: color(dark_very_grayish_red_3);
    text-transform: uppercase;
    line-height: 1.4;
    letter-spacing: 1.6px;
    margin-bottom: 10px;
    font-weight: normal;
    transition: color 0.3s ease-in-out;
  }

  &-name {
    font-size: rem(15px);
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.44px;
    margin-bottom: 3px;
    transition: color 0.3s ease-in-out;
  }

  &-desc {
    font-size: em(12);
    color: $dark_very_grayish_red_2;
    line-height: 1.58;
    letter-spacing: -0.3px;
    margin-bottom: -5px;
    transition: color 0.3s ease-in-out;
  }

  &-salutation {
    font-size: em(12);
    color: $dark_very_grayish_red_2;
    line-height: 1.58;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    transition: color 0.3s ease-in-out;
  }

  &-link {
    margin-top: auto;
    font-size: rem(12px);
    color: color(combiz_orange);
    display: inline-block;
  }
}

//card-team-detail
.card-team-detail {
  width: 80%;
  max-width: 755px;
  display: none;
  padding: 60px 75px !important;

  .team-image {
    position: relative;
    @include size(188px);
    margin-right: 35px;
    display: inline-block;
    vertical-align: middle;

    .team-image-wrapper {
      border-radius: 50%;
      @include size(100%);
      margin: 0 auto;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .image {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;

        &:after {
          content: '';
          @include size(35px);
          border-radius: 50%;
          background: rgba(252, 104, 45, 0.75);
          @include position(absolute, null 20px 5px null);
        }

        .node-type-networks-landing & {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .content {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 185px - 45px);

    .salutation {
      color: #666262;
      font-size: 0.875rem;
      line-height: 1.65;
      margin-bottom: 2px;
    }

    .title {
      color: #666262;
      font-size: 0.875rem;
      line-height: 1.65;
      font-weight: normal;
    }

    .name {
      font-size: em(17);
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: -0.44px;
      margin-bottom: 3px;
    }

    p {
      font-size: em(14);
      color: $dark_very_grayish_red_2;
      line-height: 1.58;
      letter-spacing: -0.3px;
      margin-bottom: -5px;
    }
  }

  .text {
    margin-top: 35px;

    p {
      margin-bottom: 10px;
    }
  }

  .link {
    text-align: center;
    padding-top: 20px;
  }

  @include media($tablet) {
    padding: 40px !important;
  }

  @include media($mobile) {
    width: 100%;
    margin: 0 !important;
    padding: 20px !important;

    .team-image {
      width: 100%;
      margin: 0 0 25px;

      .team-image-wrapper .image {
        @include size(188px);
        right: 0;
        margin: auto;
      }
    }

    .content {
      width: 100%;

      p {
        font-size: em(13);
        margin-bottom: 0;
      }
    }

    .text {
      margin-top: 15px;
    }
  }
}

//card-team-slick-wrapper
.card-team-slick-wrapper {
  position: fixed !important;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 15;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  @include transform(translateY(-50%));
  @include size(755px auto);
  @include transition(all 333ms linear);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .close {
    cursor: pointer;
    background: url('../../html_prototype/images/close.svg') no-repeat center;
    background-size: 100%;
    @include size(10px);
    @include position(absolute, 0 -20px null null);
  }

  .team-slick {

    .item {
      @include pad(60px 82px);
      background: $white;
    }

    .information {
      margin-bottom: 35px;
    }

    .team-image,
    .content {
      display: inline-block;
      vertical-align: middle;
    }

    .team-image {
      position: relative;
      @include size(188px);
      margin-right: 34px;

      &:after {
        content: '';
        @include size(35px);
        border-radius: 50%;
        background: rgba(252, 104, 45, 0.75);
        @include position(absolute, null 20px 5px null);
      }

      .team-image-wrapper {
        @include size(100%);
        border-radius: 50%;
        margin: 0 auto;
        background-color: rgba(208, 207, 209, 0.44);
        overflow: hidden;

        .image {
          @include size(100%);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
        }
      }
    }

    .content {

      .title {
        font-size: em(10);
        color: $dark_very_grayish_red_3;
        text-transform: uppercase;
        line-height: 1.4;
        letter-spacing: 1.6px;
        margin-bottom: 5px;
      }

      .name {
        font-size: em(15);
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.44px;
        margin-bottom: 2px;
      }

      p {
        font-size: em(12);
        color: $dark_very_grayish_red_2;
        line-height: 1.58;
        letter-spacing: -0.3px;
        margin-bottom: 2px;
      }
    }

    .text {
      font-size: em(13);
      line-height: 1.6;
      color: $dark_very_grayish_red_2;
    }
  }

  .slick-arrow {
    @include size(42px);
    border-radius: 50%;

    &:before {
      content: none;
    }

    &:hover {
      background: $white;
    }

    &.slick-next {
      right: -92px;
      background: url('../../html_prototype/images/slide-arrow-next.png') no-repeat center $white;
      background-size: 7.7px auto;
    }

    &.slick-prev {
      left: -92px;
      background: url('../../html_prototype/images/slide-arrow-prev.png') no-repeat center $white;
      background-size: 7.7px auto;
    }
  }
}

//text-group
.text-group {
  position: relative;
  background: $white;
  cursor: pointer;
  max-width: 500px;
  @include pad(10px 9px 20px 16px);
  @include transition(all 333ms ease-out);

  * {
    @include transition(all 333ms ease-out);
  }

  &:hover {
    @include transform(scale(1.05));
    background: rgba(234, 233, 236, 0.26);
  }

  &.active {
    background: $orange_bright_2;

    .link-wrapper {
      opacity: 0;
      visibility: hidden;
    }

    .close {
      opacity: 1;
      visibility: visible;
    }

    h3,
    .information,
    p {
      color: $white;
    }
  }

  .link-wrapper {
    @include position(absolute, 10px 9px null null);

    a {
      display: inline-block;
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .close {
    @include position(absolute, 20px 19px null null);
    @include size(13px 3px);
    background: $white;
    opacity: 0;
    visibility: hidden;
  }

  h3 {
    position: relative;
    font-size: em(15);
    line-height: 1.73;
    margin-bottom: 5px;
    display: inline-block;

    &:before {
      content: '';
      @include position(absolute, 0 null 0 -16px);
      @include size(3px 15px);
      margin: auto 0;
      background: $orange_bright_2;
    }
  }

  .information {
    font-size: em(11);
    line-height: 1.45;
    color: $dark_very_grayish_red_2;
    margin-bottom: 6px;
  }

  p {
    font-size: em(13);
    line-height: 1.6;
    color: $dark_very_grayish_red_2;
  }
}
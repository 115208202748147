.newsletter {
  background-color: color(combiz_blue);

  &-inner {
    display: flex;
    justify-content: center;
    gap: 0 36px;
    @include pad(57px 0 68px);

    @include media($tablet) {
      @include pad(23px 0 8px);
    }

    @include media($mobile) {
      display: block;
    }
  }

  &-content {
    max-width: 491px;
    width: 100%;

    @include media($mobile) {
      max-width: 100%;
      margin-bottom: 46px;
    }
  }

  &-title {
    color: color(white);
    font-size: rem(25);
    margin-bottom: 10px;
    letter-spacing: normal;
    line-height: 1.5;

    @include media($mobile) {
      line-height: 1.1;
    }
  }

  &-desc {
    color: color(white);
    font-size: rem(15);
    margin-bottom: 0;
    line-height: 1.53;

    @include media($mobile) {
      font-size: rem(14);
    }
  }

  &-subscription {
    // flex-shrink: 0;
    max-width: 50%;

    @include media($mobile) {
      max-width: 100%;
    }

    .newsletter-signup-form {
      display: block;
    }

    .modal-header,
    .mailchimp-signup-subscribe-form-description {
      display: none;
    }

    .block {
      margin-left: 0;
      padding: 0;
    }

    .block-title {
      font-size: rem(24px);
      margin-bottom: 29px;
      letter-spacing: normal;
      color: color(white);

      @include media($mobile) {
        font-size: rem(18);
      }
    }

    .form-submit {
      display: block;
      width: 100%;
      background-color: color(combiz_orange);
      color: color(white);
      border: none;
      font-size: rem(15px);
      font-weight: 400;
      letter-spacing: normal;
      @include pad(12px 22px 12px)
    }

    input[type="text"] {
      border-radius: 8px;
    }

    .mailchimp-newsletter-mergefields {
      display: grid;
      // grid-auto-columns: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "first second"
        "third third"
        "fourth fourth";
      gap: 18px 20px;
      margin-bottom: 15px;
    }

    .form-actions#edit-actions {
      @include media($tablet) {
        margin-top: 0;
      }
    }

    .nl-mailing-list-select {
      cursor: pointer;
      @include pad(9px 13px);
    }

    .nl-mailing-list-instructions {
      font-size: 12px;
      color: #727272;
    }

    .form-type-textfield {
      margin: 0;

      input {
        @include pad(7px 9px 9px);
        font-family: $font__main;
        border-width: 2px;
        border-color: transparent;
        font-size: 12px;

        &::placeholder {
          font-size: 10px;
          color: #727272;
        }

        &:focus {
          border-color: color(combiz_orange);
        }
      }
    }

    label {
      display: none;
    }

    .form-actions {
      margin-top: 34px;
    }

    .form-item-mergevars-FNAME {
      grid-area: first;

      &.form-item {
        float: none;
        width: 100%;
      }
    }

    .form-item-mergevars-LNAME {
      grid-area: second;
      float: none;
      width: 100%;

      &.form-item {
        float: none;
        width: 100%;
      }
    }

    .form-item-mergevars-EMAIL {
      grid-area: third;
    }

    .form-item-mergevars-MMERGE5 {
      grid-area: fourth;
    }

    .nl-mailing-list-select {
      border-radius: 8px;
    }
  }

  .form-item-mailchimp-lists-interest-groups-7fadcbf75e-f1cf76debd,
  .form-item-mailchimp-lists-interest-groups-7fadcbf75e-9262f6af0f {
    position: relative;
  }

  .form-info-icon {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background-image: url('../../images/build/info.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 5px;
    position: relative;

    &:hover {
      .form-tooltip {
        opacity: 1;

      }
    }
  }

  .form-tooltip {
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: -5px;
    left: 24px;
    background-color: color(white);
    font-size: rem(12px);
    z-index: 1;
    border-radius: 5px;
    padding: 5px 10px;
    width: 200px;
    box-shadow: 0px 1px 4px 0px rgba(25, 32, 56, 0.14);

    @include media($mobile) {
      width: 145px;
      font-size: rem(11px);
    }

    &::before,
    &::after {
      width: 0;
      height: 0;
      border-width: 5px 7px 5px 0;
      border-style: solid;
      position: absolute;
      content: '';
      top: 6px;
    }

    &::before {
      border-color: transparent rgba(#000, 0.14) transparent transparent;
      filter: blur(1px);
      left: -8px;

    }

    &::after {
      border-color: transparent #fff transparent transparent;
      left: -7px;
    }
  }
}

.sitemap-banner {

  h1 {
    font-size: rem(24);
    margin: 0;
  }

  @include media($mobile) {
    padding: 40px 20px 34px;
  }
}

.sitemap-main {
  padding: 58px 0;
  // background: url('../../images/build/bubble.png') repeat-y;
  background-size: 100% auto;

  h2 {
    font-size: rem(18);
    font-weight: 600;
    margin-bottom: 15px;
  }

  .sitemap-block {
    margin-bottom: 50px;

    @include media($overtablet) {
      @include span-columns(4);
      @include omega(3n);
    }
  }

  li {
    margin-bottom: 8px;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray_light;
      vertical-align: middle;
      margin-right: 10px;
      display: inline-block;
    }
  }

  a {
    text-decoration: underline;
    font-size: rem(14);
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }

  @include media($mobile) {
    padding: 25px 20px 45px;

    h2 {
      font-size: rem(15);
      font-weight: 600;
    }

    .sitemap-block {
      margin-bottom: 45px;
    }

    li {
      margin-bottom: 10px;
    }
  }
}
/* ----- PUT ALL YOUR AMAZING MIXINS IN HERE ----- */

// Image replacement
@mixin replace() {
  display: block;
  overflow: hidden;
  height: 0;
}

// Resets the last column number
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter();
  }

  &:nth-child(#{$nth}+1) {
    clear: none
  }
}

@mixin bre-transition() {
  transition: all 0.3s ease-in-out;
}

@mixin header-container() {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  clear: both;

  @include clearfix();
}

@mixin bullet-points() {
  font-size: rem(14);
  margin: 25px 0;
  list-style-type: none;
  list-style-image: none;

  li {

    margin-bottom: 12px;
    padding-left: 20px;
    font-weight: 500;
    position: relative;
    list-style-type: none;
    list-style-image: none;
    background: none;

    &:before {
      content: '';
      background: $gray_grayish_blue_2;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}

@mixin caret($color) {
  &::after {
    top: -2px;
    right: 0;
    position: relative;
    content: '';
    width: 8px;
    height: 8px;
    rotate: 45deg;
    box-shadow: 1.5px -1.5px 0 0 $color;
    display: inline-block;
  }
}

@mixin viewport($size, $type: max) {
  @if $type ==min {
    $size: $size +1
  }

  @media all and (#{$type}-width: $size) {
    @content
  }
}
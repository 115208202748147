.area-chart, .single-area-chart,
.line-chart,
.bar-line-chart, .bar-chart {

  .highcharts-tick,
  .highcharts-axis-line:last-of-type {
    display: none;
  }

  .mainData {
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2px;
  }

  .subData {
    font-size: 12px;
    text-align: center;
    line-height: 1;
  }

  .column-number {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
  }

  .plotline-label {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    background: #fff;
    @include transform(translateY(-4px));

    &:before {
      content: '';
      @include size(16px);
      @include position(absolute, 12px null null -30px);
    }

    &:after {
      z-index: -1;
      content: '';
      @include size(10px 2px);
      @include position(absolute, 19px null null -20px);
    }

    &.quote {

      &:before {
        background: url('../../images/build/quote-plotline.png') no-repeat center;
        background-size: 16px auto;
      }

      &:after {
        background: #664fa3;
      }
    }

    &.target {

      &:before {
        background: url('../../images/build/target-plotline.png') no-repeat center;
        background-size: 16px auto;
      }

      &:after {
        background: #ab9ecc;
      }
    }
  }

  .highcharts-scrollbar-thumb {
    height: 15px;
    @include transform(translateY(-1px));
  }
}
.area-chart {

  .highcharts-yaxis-grid {
    display: none;
  }
}
.single-area-chart {

  .highcharts-xaxis-grid,
  .highcharts-yaxis-grid {
    display: none;
  }
}
.gauge-chart {

  .highcharts-yaxis-labels {
    display: none;
  }
}
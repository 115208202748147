.orange-text-block {

  .top {
    border-bottom: 1px solid $white;
    padding-bottom: 36px;
    margin-bottom: 24px;
    @include clearfix();
  }

  .left {

    @include media($overtablet) {
      @include span-columns(4);
    }
  }

  .right {
    font-size: rem(13);
    padding-top: 20px;

    @include media($overtablet) {
      @include span-columns(8);
    }
  }

  h2 {
    font-size: rem(28);
    line-height: 1.5;
    letter-spacing: -0.98px;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: -0.41px;
  }

  @include media($mobile) {
    @include pad(40px 20px 37px);

    .top {
      padding: 0;
      border: 0;
      margin-bottom: 15px;
    }

    h2 {
      font-size: rem(22);
      line-height: 1.36;
    }

    .right {
      display: none;
    }
  }
}
.slick-dots {
  >li {

    >button {
      background: #B6B6B6 !important;
      border-radius: 50%;
    }

    &.slick-active {
      >button {
        background: color(combiz_blue) !important;
      }
    }
  }
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  margin-bottom: 0;
  object-fit: cover;
}
.card-image {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  background-color: color(white);

  @include media($tablet) {
    border-radius: 10px;
  }

  &-frame {
    // width: 214px;
    width: 40.9%;
    flex-shrink: 0;

    @include media($mobile) {
      width: rem(179);
    }

    &-img {
      display: block;
      width: 100%;
      min-height: 358px;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media($tablet) {
        min-height: 245px;
        height: 100%;
      }
    }
  }

  &-content {
    @include pad(38px 45px 61px 18px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media($desktop) {
      @include pad(20px 45px 20px 18px);
    }

    @include media($mobile) {
      @include pad(8px 8px 24px 16px);
    }
  }

  &-title {
    color: #323232;
    font-size: rem(31px);
    margin-bottom: 22px;

    @include media($desktop) {
      font-size: rem(18);
    }
  }

  &-desc {
    font-size: rem(12px);
    letter-spacing: -0.025em;
    line-height: 1.6;
  }

  &-cta {
    margin-top: auto;
    font-size: rem(16px);
    @include pad(7px 23px 7px 15px);
    @include caret(color(white));
    text-transform: capitalize;

    &::after {
      margin-left: 8px;
    }
  }
}
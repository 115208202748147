.social-media__icon {
  position: relative;
  @include size(33px);
  display: inline-block;
  margin: 2px 16px 0 0;
  cursor: pointer;
  z-index: 1;
  box-sizing: border-box;
  background: color(combiz_blue);
  border-radius: 33px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include media($tablet) {
    @include size(24px);
    border-radius: 33px;
    margin-right: 11px;
    box-shadow: none;
    z-index: initial;

    &:hover {

      span {
        @include size(28px);
        border-radius: 33px;
        margin: -14px;
      }
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  span {
    border-radius: 0;
    display: block;
    margin: 0;
    z-index: -1;
    background: color(combiz_orange);
    @include size(0);
    @include position(absolute, 50% null null 50%);
    @include transition(all 0.3s);
  }

  path {
    fill: color(combiz_light_gray);
  }

  svg {
    margin: auto;
    @include position(absolute, 0 0 0 0);


    @include media($tablet) {
      width: 40%;
    }
  }

  &:hover {

    span {
      @include size(33px);
      border-radius: 33px;
      margin: -16.5px;
    }
  }
svg{
  width: 15px;
  height: 15px;
}
  &.twitter{
    svg{
      width:20px;
      height: 20px;
    }
  }

  &.instagram{
    svg{
      width:18px;
      height: 18px;
    }
  }
  &.youtube{
    svg{
      width:21px;
      height: 21px;
    }
  }
}

.profile {
  @include outer-container();
  padding: 100px 0 65px;
  margin: 0 auto;

  .field {
    margin-bottom: 20px;
  }

  .field-label {
    font-size: rem(16);
    font-weight: 500;
  }

  .field-item {
    font-size: rem(15);
  }

  h3 {
    font-size: rem(16);
    font-weight: 500;
    margin-bottom: 8px;
    padding-bottom: 3px;
  }

  dt {
    font-size: rem(14);
    font-weight: 500;
    margin-bottom: 0;
  }

  dd {
    font-size: rem(13);
  }

  .user-picture {
    position: relative;
    @include size(150px);
    margin: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  @include media($mobile) {
    padding: 45px 20px;

    .field {
      margin-bottom: 10px;
    }

    .field-label,
    h3 {
      font-size: rem(14);
    }

    .field-item {
      font-size: rem(13);
    }

    dt,
    dd {
      font-size: rem(12);
    }

    .user-picture {
      float: none;
      margin: auto;
    }
  }
}

#ajax-register-user-login-wrapper,
#user-pass-reset {
  // @include outer-container();
  // padding: 80px 0 45px;

  .password-strength,
  div.password-confirm {
    display: none;
  }

  .form-item {
    // margin: 0 0 50px;
  }

  .form-type-password-confirm {
    margin-bottom: 0;
  }

  .description {
    font-size: rem(13);
    font-weight: normal;
  }

  @include media($large_desktop) {
    // @include outer-container(960px);
  }

  @include media($desktop) {
    // @include outer-container(800px);
  }

  @include media($tablet) {
    // @include outer-container(680px);
  }

  @include media($mobile) {
    // padding: 25px 20px 45px;
    // @include outer-container(100%);

    .form-item {
      margin: 0 0 25px;
    }

    .description {
      font-size: rem(12);
    }
  }
}

.credentials {
  background-color: color(combiz_light_gray);
  overflow: hidden;

  @include media($mobile) {
    &-media {
      display: none;
    }
  }

  &-reset-password {
    .credentials-form p {
      display: none;
    }
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    min-height: 694px;
  }

  &-content {
    max-width: 609px;
    width: 100%;
    @include pad(92px 15px 0 0);

    @include media($tablet) {
      max-width: 100%;
      @include pad(34px 0 0);
    }

    .credentials-register & {
      @include media($tablet) {
        @include pad(34px 0);
      }
    }

    .credentials-pass &,
    .credentials-reset-password & {
      max-width: rem(634);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid color(combiz_blue);
    padding-bottom: 10px;
    margin-bottom: 50px;
  }

  &-heading {
    font-size: rem(24px);
    margin-bottom: 0;
    font-weight: 300;
    letter-spacing: 0.01em;
    color: color(combiz_blue)
  }

  &-title {
    font-size: rem(38);
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.16;
    margin-bottom: rem(26);
    color: color(combiz_blue)
  }

  &-desc {
    color: color(combiz_black);
    font-size: rem(16);
    line-height: 1.44;

    a {
      text-decoration: underline;
      color: color(combiz_blue);
    }
  }

  &-action {
    font-weight: 700;
    font-size: rem(18px);
    text-transform: uppercase;
    color: color(combiz_blue);
    display: inline-flex;
    align-items: center;

    .credentials-login &,
    .credentials-register & {
      &::before {
        display: inline-block;
        width: 20px;
        height: 19px;
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../images/build/icon-user.png');
        margin-right: 8px;
      }
    }

    .credentials-register & {
      &::before {
        background-image: url('../../images/build/ph_sign-in.png');
      }
    }
  }

  &-form {
    max-width: 307px;
    width: 100%;

    .credentials-register & {
      max-width: 390px;

      @include media($desktop) {
        max-width: 100%;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 10px;

      @include media($mobile) {
        display: block;
      }
    }

    label {
      font-size: rem(11px);
      font-weight: normal;
      color: color(combiz_blue);
    }

    .form-required {
      color: color(combiz_blue)
    }

    input[type="text"],
    input[type="password"] {
      border-color: color(combiz_blue);
    }

    .description {
      display: none;
    }

    .form-submit {
      border: none;
      color: color(white);
      background-color: color(combiz_blue);
      background-image: url("../../svg/angle-right.svg");
      background-position: right 20px bottom 50%;
      background-repeat: no-repeat;
      font-size: rem(16px);
      font-weight: 400;
      min-width: rem(112);
      padding-right: rem(40);
    }

    .form-item {
      margin-bottom: 29px;

      .credentials-register & {
        margin-bottom: rem(20);
        margin-top: 0;
      }

      .credentials-pass & {
        margin-bottom: rem(37);
        margin-top: 0;
        max-width: rem(307);
      }

      .credentials-reset-password & {
        display: block !important;
        margin-left: 0 !important;
      }

    }
  }

  &-link {
    font-size: rem(11px);
    text-decoration: underline;
  }

  &-media {
    // flex-shrink: 0;
    max-width: 500px;
    width: 100%;
    position: relative;

    @include media($tablet) {
      display: none;
    }
  }

  &-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/build/credentials-img.png");
    height: 100%;
    width: 45vw;
    position: absolute;
    top: 0;
    left: 0;

    @include media($desktop) {
      width: 100%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(color(combiz_blue), 0.5);
    }
  }

  &-back {
    font-weight: 600;
    font-size: rem(18);
    display: block;
    margin-bottom: 25px;
    color: color(combiz_blue);

    span {
      font-weight: normal;
    }
  }
}

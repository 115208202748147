.press-page-body {
  padding: 74px 0;

  .inner {

    p {
      font-size: rem(15);
      font-weight: 500;
      color: $dark_grayish_red;
    }

    @include media($overtablet) {
      @include span-columns(9);
    }
  }

  @include media($mobile) {
    padding: 25px 20px;
  }
}

.press-page-main {
  background: $gary_light_orange;
}

.press-page {

  &-feature {
    background-color: color(combiz_blue);
    @include pad(46px 0 103px);

    &-title {
      color: color(white);
      margin-bottom: 20px;
    }

    &-text {
      margin-bottom: 17px;

      p {
        color: color(white);
        font-size: rem(15);
        letter-spacing: -0.025em;
        margin: 0;
      }
    }

    &-cta {
      font-size: rem(16);
      font-weight: 500;
      @include pad(7px 23px 7px 15px);
      @include caret(color(combiz_blue));

      &::after {
        margin-left: 8px;
      }
    }
  }

  &-items {
    background-color: color(combiz_light_gray);
    @include pad(34px 0 58px);

    &-title {
      margin-bottom: 28px;
    }

    &-cards {
      margin-bottom: 36px;
      display: none;

      @include media($overtablet) {
        &.desktop {
          display: block;
        }
      }

      @include media($tablet) {
        &.tablet {
          display: block;
        }
      }

      .kard {
        @include media($tablet) {
          max-width: 276px;
          margin: 0 auto;
        }
      }
    }

    &-button {
      display: flex;
      justify-content: center
    }

    &-cta {
      @include pad(8px 24px 8px 16px);
      @include caret(color(white));
      font-size: rem(16px);
      font-weight: 400;

      &::after {
        margin-left: 8px;
      }
    }

    .view-block {
      background-color: color(combiz_light_gray);
    }

    .view-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
      gap: 23px 32px;

      @include media($tablet) {
        display: initial;

        >.slick-dots {
          position: initial;
          margin-top: rem(50);

          button::before {
            content: '';
          }
        }


      }
    }
  }
}
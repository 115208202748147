.node-type-about-us {
  overflow-x: hidden;
}

.focus-areas {
  @include pad(68px 0 124px);
  background-color: color(gray_lighter_blue);

  @include media($tablet) {
    @include pad(27px 0 29px);
  }

  &-intro {
    display: flex;
    justify-content: space-between;
    padding-right: 71px;
    margin-bottom: 56px;

    @include media($tablet) {
      flex-direction: column;
      margin-bottom: 28px;
      padding-right: 0;
    }
  }

  &-title {}

  &-text {
    max-width: 603px;
    width: 100%;
    padding-top: 3px;

    P {
      font-size: rem(15px);
      font-weight: 400;
      color: color(combiz_black);
      line-height: 1.5;
      margin-bottom: 0;

      @include media($tablet) {
        font-size: rem(12px);
      }

      strong {
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
        line-height: inherit;
      }
    }

    ul {
      display: none
    }
  }

  &-cards {
    clear: both;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 64px;
    gap: 30px 87px;

    @include media($desktop) {
      gap: 30px 20px;
      padding-right: 0;
    }

    @include media($tablet) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }
}

.what-we-do {
  background-color: color(white);

  &-block {
    @include pad(59px 0 75px);
    text-align: center;

    @include media($tablet) {
      @include pad(16px 0 29px);
    }

    @include media($mobile) {
      text-align: left;
    }
  }

  &-title {
    margin-bottom: 25px;

    @include media($tablet) {
      margin-bottom: 8px;
    }

    &.title {
      @include media($mobile) {
        font-size: rem(28);
        line-height: 1.14;
      }
    }
  }

  &-desc {
    max-width: 867px;
    width: 100%;
    margin: 0 auto;

    p {
      font-size: rem(15px);
      margin-bottom: 0;
      color: color(combiz_black);
      line-height: 1.5;
      letter-spacing: 0.006em;

      @include media($mobile) {
        font-size: rem(12);
      }
    }
  }
}

.who-we-are {
  background-color: color(combiz_light_gray);

  &-inner {
    @include pad(48px 0px 46px);
    position: relative;

    @include media($tablet) {
      @include pad(26px 0px 35px);
    }
  }

  &-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 691px;
    width: 100%;
    height: 519px;
    border-radius: 20px;
    margin-bottom: 64px;
    margin-left: -6px;

    @include media($tablet) {
      margin-left: 0;
      height: 250px;
      margin-bottom: -58px;
    }
  }

  &-content {
    position: absolute;
    bottom: 49px;
    right: 0;
    max-width: 547px;
    width: 100%;
    background-color: color(white);
    border-radius: 15px;
    @include pad(18px 20px);

    @include media($tablet) {
      @include pad(7px 12px 21px);
    }

    @include media($tablet) {
      position: static;
      max-width: 100%;
      min-height: auto;
    }
  }

  &-title {
    margin-bottom: 19px;

    @include media($tablet) {
      margin-bottom: 3px;
    }
  }

  &-par {
    p {
      color: color(combiz_black);
      font-size: rem(15px);
      margin-bottom: 0;
      letter-spacing: normal;
      line-height: 1.5;

      &:not(:last-of-type) {
        margin-bottom: 28px;

        @include media($tablet) {
          margin-bottom: 8px;
        }
      }

      @include media($mobile) {
        font-size: rem(12);
        line-height: 1.5;
      }

      strong {
        font-size: rem(20px);
        font-weight: 700;
        line-height: 1.5;

        @include media($mobile) {
          font-size: 16px;
        }
      }
    }
  }

  &-cta {
    display: none;
  }
}

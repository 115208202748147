.node-type-faq-landing {

  .content {
    // background: url('../../images/build/bubble.png') repeat-y;
    background-size: 100% auto;
  }

  .faq-page .container {
    @include pad(80px 0 165px);

    @include media($tablet) {
      @include pad(50px 0 60px);
    }

    @include media($mobile) {
      padding: 0;
    }
  }

  // .partner-header {
  //   @include pad(20px 0 100px);

  //   .content {
  //     background: none;
  //   }

  //   @include media($mobile) {
  //     @include pad(30px 20px 25px);
  //   }
  // }

  .faq-nav {
    @include span-columns(3);

    @include media($mobile) {
      display: none;
    }
  }

  .faq-wrapper {
    @include shift(1);
    @include span-columns(8);

    @include media($mobile) {
      @include shift(0);
      width: 100%;
      @include pad(25px 20px 40px);
    }
  }

  .field-label {
    display: none;
  }
}
body {
  padding-top: 66px;
  @include transition(all 333ms linear);

  &.thinner {
    padding-top: 98px;
  }

  &.node-type-homepage {
    padding-top: 66px;

    @include media($tablet) {
      padding-top: 60px;
    }
  }

  @include media($overtablet) {

    &.have-cart-quantity {
      padding-top: 161px;
    }

    &.thinner.have-cart-quantity {
      padding-top: 128px;
    }
  }

  @include media($tablet) {
    padding-top: 60px;

    &.thinner {
      padding-top: 44px;

      .mobile-menu .menu-search {
        @include pad(7px 20px 4px 40px);
      }
    }
  }

}

html,
body {
  height: 100%;

  #page>#main {
    position: relative;
  }
}

.container {
  position: relative;
  @include outer-container();

  @include media($large_desktop) {
    @include outer-container(960px);
  }

  @include media($desktop) {
    @include outer-container(800px);
  }

  @include media($tablet) {
    @include outer-container(680px);
  }

  @include media($mobile) {
    @include outer-container(100%);
  }
}

.header-container {
  @include header-container();
}

.main-container {
  // background: url('../../images/build/bubble.png') repeat-y;
  background-size: 100% auto;

  &.main-container-consulting {
    background: none
  }
}

.main-wrapper {
  @include outer-container(1230px);
  @include pad(105px 30px 100px);

  .page-profile & {
    @include pad(105px 15px 100px);

    @include media($tablet) {
      @include pad(0 0 100px);
    }
  }

  &.main-wrapper-consulting {
    @include outer-container(1230px);
    @include pad(66px 15px 100px);

    @include media($mobile) {
      @include pad(0);
    }
  }

  @include media($large_desktop) {
    // @include outer-container(960px);
  }

  @include media($desktop) {
    // @include outer-container(800px);
  }

  @include media($tablet) {
    @include outer-container(680px);
    @include pad(40px 0);
  }

  @include media($mobile) {
    @include pad(0);
    @include outer-container(100%);
  }

  h1 {
    font-size: em(12);
    text-transform: uppercase;
  }

  .row {
    @include clearfix();
    border-bottom: 1px dashed $orange_bright_3;

    &.full {

      .desc-area,
      .container-white,
      .container-dark {
        height: auto;
      }

      .container-white {
        width: 80%;
      }
    }
  }

  .desc-area,
  .container-white,
  .container-dark {
    float: left;
    height: 180px;

    &.desc-area {
      @include pad(30px 5px 20px 0);
      background-color: $white;
      width: 20%;
    }

    &.container-white {
      @include pad(30px 5px 20px 20px);
      background: $white;
      border-left: 1px solid $gray_dark;
      width: 40%;
    }

    &.container-dark {
      @include pad(30px 5px 20px 20px);
      background: $color__main;
      width: 40%;
    }
  }
}

.overlay {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  @include transition(all 333ms ease-out);
  background: rgba(71, 69, 69, 0.9);
  z-index: 10;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.current-width {
  @include position(fixed, null 0 0 0);
  margin: auto;
  background: rgba(0, 0, 255, 0.5);
  padding: 10px;
  z-index: 9999;
  font-size: 18px;
  text-align: center;
  width: 100px;
}

#map {
  @include size(800px 600px);
}

#atlwdg-trigger {
  z-index: 100;
}

.archive-page-trigger,
.main-with-sidebar-trigger {
  @include position(fixed, 0 null 0 0);
  @include size(100% 1px);
  background: transparent;
  z-index: 100;
  margin: auto;
}

.sidebar {
  top: 155px;
  min-height: 20px;
  @include span-columns(3);

  .main-wrapper-consulting & {
    width: 24%;
  }

  &:not(.not-sticky) {
    position: sticky;
  }

  li {
    font-size: rem(15);
    color: $dark_very_grayish_red;
  }

  .sidebar-remark {
    font-size: em(11);
    text-transform: uppercase;
    margin-bottom: 42px;
    color: $dark_very_grayish_red_3;
    letter-spacing: 2.8px;
  }

  @include media($tablet) {
    top: 85px;

    li {
      font-size: rem(14);
      max-width: 185px;
    }
  }

  @include media($mobile) {
    float: none;
    width: 100%;
    display: none;
  }
}

.mobile-sidebar {
  display: none;
  background: rgba(251, 249, 248, 0.96);
  @include pad(8px 20px 6px);
  z-index: 10;

  .header-current-option {
    display: inline-block;
    position: relative;
    font-size: em(12);
    font-weight: 600;
    color: $dark_very_grayish_red_3;
    letter-spacing: -0.35px;
    text-transform: capitalize;

    &:before {
      content: '';
      @include position(absolute, null null -8px 0);
      @include size(14px 2px);
      background: $orange_bright_2;
    }
  }

  ul {
    display: none;
    padding: 0;
    margin: 15px 0 5px;

    li {
      padding: 5px 0;
      font-size: em(13);

      &:hover {
        font-weight: 500;
      }
    }
  }

  @include media($mobile) {
    display: block;

    .btn-more {
      display: inline-block;
    }
  }
}

.main-with-sidebar {
  @include span-columns(9);

  .main-wrapper-consulting & {
    width: 70.5%;

    @include media($mobile) {
      width: 100%;
      @include pad(40px 20px 45px)
    }
  }

  // to fix the overlapping sidebar on some browsers
  @include media($overmobile) {
    float: right;
  }

  .text {
    font-size: em(14);
    color: $dark_very_grayish_red_2;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: rem(24px);
    line-height: 1.25;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .card-horizontal-wrapper {
    @include clearfix();
  }

  @include media($mobile) {
    float: none;
    width: 100%;
    @include pad(40px 20px 45px);

    .text {
      font-size: rem(13);
    }

    h2 {
      font-size: rem(20);
    }

    .paragraph {
      @include pad(27px 0 18px);

      &:first-of-type {
        padding: 0 0 18px;
      }
    }
  }
}

.border-left {
  border-left: 1px solid $gray_grayish_violet;
  padding-left: 15px;
  position: relative;

  &:before {
    content: '';
    width: 3px;
    height: 15px;
    background: $orange_bright;
    position: absolute;
    top: 0;
    left: -1.5px;
    display: none;
  }

}

.sidebar-menu {

  .menu {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      list-style-image: none;
      margin: 0;
      padding: 0;
    }

    ul {
      margin-bottom: 50px;
      // border-left: 3px solid $gray_grayish_violet;

      li {

        // padding-left: 15px;
        margin: 22px 0;
        line-height: 1.2;

        &.active,
        &.active-trail {
          // border-left: 3px solid $orange_bright_2;
          // margin-left: -3px;

          a {
            font-weight: 600;
            color: $dark_very_grayish_red_3;
          }
        }
      }
    }

    .nolink {
      font-size: rem(11);
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.2em;
    }

  }
}

.orange-overlay {
  position: relative;
  color: $white;
  padding: 55px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:after {
    content: '';
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: rgba(242, 116, 58, 0.94);
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.my-cart-section {
  @include pad(9px 0 7px);
  background: $gary_light_orange;

  // .wrapper {
  //   font-size: em(12);
  //   @include outer-container(1200px);

  //   @include media($desktop) {
  //     @include outer-container(960px);
  //   }

  //   @include media($tablet) {
  //     @include pad(0 35px);
  //     @include outer-container(100%);
  //   }
  // }
}

.box-link {
  display: block;
}

.wrapper {
  @include pad(0 20px);
  max-width: $breakpoint-desktop + 20;
  width: 100%;
  margin: 0 auto;

  @include media($tablet) {
    @include pad(0 30px);
  }

  .is-firefox & {
    max-width: 1080px + 40px;
  }
}
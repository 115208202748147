.node-programmes-landing-page {
  .our-program {
    @include pad(75px 0);
    background: $gary_light_orange;
  }

  .our-program,
  .our-campaigns {

    h2 {
      margin-bottom: 20px;

      @include media($mobile) {
        margin-bottom: 14px;
      }
    }

    .text {
      @include span-columns(9);
      margin-bottom: 25px;
      font-weight: 500;
      font-size: em(15);
      color: $dark_grayish_red;
    }

    .campaigns-programmes {
      @include clearfix();

      .card {

        &.normal {
          @include span-columns(3);
        }

        &.big {
          @include span-columns(6);
        }

        @include media($overtablet) {
          &.omega {
            @include omega();

            &+.card {
              clear: both;
            }
          }
        }

        @include media($tablet) {

          &.normal,
          &.big {
            @include span-columns(6);
            @include omega(2n);
          }
        }

        @include media($mobile) {

          &.normal,
          &.big {
            width: 100%;
          }
        }
      }
    }


    @include media($tablet) {
      // @include pad(60px 0);

      .text {
        width: 100%;
        margin-right: 0;
      }
    }

    @include media($mobile) {
      // @include pad(25px 20px);
    }

    .past-link {
      width: 180px;
      margin: 40px auto 20px;
      display: block;
      text-align: center;
    }
  }
}

.featured-card {
  height: 100%;
  @include media($desktop) {
    max-width: 225px;
    margin: 0 auto;
  }

  h2 {
    font-size: em(28);
    line-height: 1.5;
    letter-spacing: -0.98px;
    color: $dark_grayish_red;
    margin-bottom: 4px;
  }

  .sub-title {
    font-size: em(15);
    margin-bottom: 30px;
  }

  &.orange-overlay {
    @include pad(65px 0 115px);

    h2 {
      color: $white;
      font-size: rem(24);
      line-height: 1.25;
      margin: 0 auto 5px;
      max-width: 600px;
    }

    .sub-title {
      font-size: rem(14);
      line-height: 1.5;
      margin: 0 auto 44px;
      max-width: 600px;
    }

    .wrapper {
      margin-bottom: 0;
    }

    .card-square {
      margin-bottom: 0;

      .content {
        min-height: 205px;
      }

      .title {
        color: $dark_grayish_red;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .link-area {
        width: 100%;
        height: 28px;
        text-align: center;
        position: absolute;
        bottom: 25px;
        left: 0;

        a {
          display: inline-block;
          margin: 0 5px;
        }
      }

      @include media($desktop) {

        .content {
          min-height: 145px;
        }

        .link-area {
          bottom: 20px;
        }
      }

      @include media($tablet) {
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 20px;

        h3 {
          font-size: rem(18);
        }

        p {
          display: block;
          font-size: rem(15);
        }
      }

      @include media($mobile) {

        .content {
          padding: 14px;
          min-height: inherit;
        }

        .title {
          font-size: rem(14);
        }

        p {
          display: none;
        }

        .link-area {
          display: none;
        }
      }
    }

    @include media($mobile) {
      @include pad(25px 20px 5px);

      h2 {
        font-size: rem(21);
        margin-bottom: 6px;
      }

      .sub-title {
        font-size: rem(13);
        margin-bottom: 21px;
      }
    }
  }

  @include media($desktop) {
    // @include pad(70px 0);
  }

  @include media($tablet) {
    // @include pad(59px 0);
  }

  @include media($mobile) {
    @include pad(40px 20px);

    h2 {
      font-size: em(22);
      line-height: 1.36;
      letter-spacing: -0.77px;
      margin-bottom: 0;
    }

    .sub-title {
      font-size: em(14);
    }

    .wrapper {
      margin-bottom: 15px;
    }
  }

  .count-3 {
    .card-square:first-child {
      @include media($overtablet) {
        @include shift(1.5);
      }
    }
  }

}

.our-campaigns {
  background-color: color(gray_lighter_blue);
  @include pad(63px 0 37px);

  @include media($mobile) {
    @include pad(27px 0 37px);
  }

  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 85px;
    gap: 0 20px;
    margin-bottom: 27px;

    @include media($desktop) {
      @include pad(0);
    }

    @include media($tablet) {
      display: block;
      margin-bottom: 20px;
    }
  }

  &-title {
    margin-bottom: 0;
    line-height: 1.3;

    @include media($tablet) {
      font-size: rem(28);
    }
  }

  &-desc {
    font-size: rem(20px);
    color: color(combiz_black);
    max-width: 603px;
    width: 100%;
    line-height: 1.5;

    @include media($tablet) {
      font-size: rem(12);
    }
  }

  &-cards {
    .card-campaign {
      &:not(:last-of-type) {
        margin-bottom: 34px;
      }
    }
  }

  .slick-dots {
    button {
      &::before {
        content: '';
      }
    }
  }
}

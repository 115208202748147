.cta {
  color: color(white);
  font-size: rem(14);
  border-radius: 50px;
  @include pad(3px 14px);
  border-width: 1px;
  border-style: solid;
  font-weight: 300;
  display: inline-block;
  position: relative;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &::after {
    transition-property: box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  &.is-blue {
    background-color: color(combiz_blue);
    border-color: color(combiz_blue);

    @include media($overdesktop) {
      &:hover {
        background-color: color(white);
        color: color(combiz_blue);

        &::after {
          box-shadow: 1.5px -1.5px 0 0 color(combiz_blue);
        }
      }
    }
  }

  &.is-orange {
    background-color: color(combiz_orange);
    border-color: color(combiz_orange);

    @include media($overdesktop) {
      &:hover {
        background-color: color(white);
        color: color(combiz_orange);

        &::after {
          box-shadow: 1.5px -1.5px 0 0 color(combiz_orange);
        }
      }
    }
  }

  &.is-white {
    background-color: color(white);
    color: color(combiz_blue);
    border-color: color(white);

    @include media($overdesktop) {
      &:hover {
        color: color(white);
        background-color: color(combiz_blue);
        border-color: color(white);

        &::after {
          box-shadow: 1.5px -1.5px 0 0 color(white);
        }
      }
    }
  }

  &.is-orange-border {
    background-color: transparent;
    border-color: color(combiz_orange);
    color: color(combiz_orange);

    &:after{
      box-shadow: 1.5px -1.5px 0 0 color(combiz_orange);
    }

    &:hover{
      background-color: color(combiz_orange);
      color: color(white);
      &:after{
        box-shadow: 1.5px -1.5px 0 0 color(white);
      }
    }
  }


}

.card-member {
  cursor: pointer;
  position: relative;
  // @include span-columns(3);
  // @include omega(4n);
  // margin-bottom: 20px;
  @include transition(all 333ms linear);

  .content {
    position: relative;
    background: $white;
    height: 100%;
    @include pad(27px 20px 40px);
    // background-color: teal;
  }

  .image-wrap {
    @include transition(all 333ms linear);

    img {
      height: 100px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .member-networks {
    margin: 0 0 8px;
    padding: 0;

    li {
      list-style: none;
      color: $dark_very_grayish_red_3;
      font-size: rem(11);
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }
  }

  h2 {
    font-size: rem(15);
    font-weight: 600;
    color: $dark_grayish_red;
    margin-bottom: 9px;
  }

  .text {
    font-size: rem(12);
    color: $color__main;
    line-height: 1.58;
    letter-spacing: -0.3px;
    margin-bottom: 35px;
  }

  .link-normal {
    @include position(absolute, null null 20px 19px);
  }

  &:hover {
    @include transform(scale(1.05));
    box-shadow: 0 50px 40px 0 rgba(0, 0, 0, 0.12);

    .image-wrap {
      @include transform(scale(1.1));
    }
  }

  @include media($tablet) {
    @include span-columns(4);
  }

  @include media($mobile) {
    @include span-columns(12);
    margin-right: 0;

    .content {
      @include pad(20px 13px 13px);
    }

    .image-wrap {
      width: 75px;
      // display: inline-block;
      // vertical-align: middle;

      img {
        width: 100%;
        height: auto;
      }
    }

    // .detail {
    //   width: calc(100% - 90px);
    //   display: inline-block;
    //   vertical-align: middle;
    //   margin: 0 0 0 6px;
    //   padding-left: 9px;
    //   border-left: 1px solid $gray_grayish_violet;
    // }

    .member-networks {

      li {
        font-size: rem(10);
      }
    }

    h2 {
      margin-bottom: 15px;
      font-size: rem(14);
    }

    .text {
      display: none;
    }

    .link-normal {
      @include position(relative, inherit);
    }
  }
}
.event-featured {
  background-color: color(white);

  &-inner {
    @include pad(45px 0 51px);
  }

  &-title {
    margin-bottom: 25px;

    @include media($mobile) {
      font-size: rem(28);
      line-height: 1.3;
    }
  }

  &-text {}

  &-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
    gap: 25px;

    @include media($mobile) {
      display: block;
    }
  }
}
.banner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include media($tablet) {
    display: block;
  }

  &-col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 325px;

    @include media($tablet) {
      @include pad(32px 24px 24px 24px);
    }

    &:hover {
      .banner-image {
        scale: 1;
      }
    }
  }

  &-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 1;

      .is-blue-filter & {
        background-color: rgba(color(combiz_blue), 0.8);
      }

      .is-white-filter & {
        background-color: rgba(#979797, 0.8);
      }
    }
  }

  &-image {
    display: block;
    width: 100%;
    height: 325px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    scale: 1.1;
    transition: scale 0.5s ease-in-out;
  }

  &-content {
    max-width: 400px;
    width: 100%;
    color: color(white);
    position: relative;
    z-index: 1;

    @include media($tablet) {
      max-width: initial;
    }
  }

  &-title {
    font-size: rem(28px);
    font-weight: 600;
    color: inherit;
    letter-spacing: -0.034em;
    margin-bottom: 14px;
  }

  &-desc {
    color: inherit;
    font-size: rem(15px);
    line-height: 1.4;
    margin-bottom: 35px;
    
    @include media($mobile) {
      font-size: rem(12);
      line-height: 1.33;
    }
  }

  &-cta {
    font-weight: 400;
    font-size: rem(16px);
    @include pad(8px 24px 8px 16px);
    @include caret(color(combiz_blue));
    border: 1px solid color(combiz_blue);

    &::after {
      margin-left: 8px;
    }
  }
}

.banner-none {

  padding: 200px 0 92px;
  background: $gary_light_orange;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: rem(49);
    margin-bottom: 30px;
    line-height: 1.1;
  }

  .text-wrap {
    max-width: 555px;
  }

  .text {
    font-size: rem(14);
    font-weight: 500;
    color: $dark_very_grayish_red !important;
  }

  &:before {
    content: '';
    display: block;
    width: 425px;
    height: 425px;
    border-radius: 425px;
    background: $orange_bright_2;
    opacity: 0.08;
    position: absolute;
    top: -200px;
    right: 300px;
  }

  @include media($tablet) {
    padding: 50px 20px;
  }
}

.banner-basic {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: '';
    background-color: rgba(color(combiz_blue), 0.98);
    z-index: -1;
  }

  &-inner {
    @include pad(52px 0);
  }

  &-title {
    color: color(white);
    font-size: rem(24px);
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: -0.03em;
    line-height: normal;
  }
}
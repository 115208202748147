.share-wrapper {
  margin: 100px 0;

  .span, a {
    display: inline-block;
    vertical-align: middle;
    @include size(33px);
  }

  .text {
    color: $gray_dark;
    font-size: em(14);
    line-height: 1.57;
    margin-right: 13px;
  }

  a {
    position: relative;
    margin-right: 10px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid $orange_bright_2;
    padding: 4px 0 0 9px;

    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      background: $orange_bright_2;
    }

    .jiathis_txt {
      text-align: center;
      @include position(absolute, 0 0 0 0);
      padding: 0 !important;
      background: none !important;

      &:hover {
        opacity: 1;
      }
    }

    svg, g {
      fill: $orange_bright_2;
    }
  }

  @include media($tablet) {
    margin: 60px 0;
  }

  @include media($mobile) {
    margin: 20px 20px 45px;
    text-align: center;

    .text {
      width: 100%;
      display: block;
      margin: 0 0 10px;
    }
  }
}
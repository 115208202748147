.icon-group {
  margin-bottom: 10px;

  .heading, .icon {
    display: inline-block;
    vertical-align: middle;
  }

  .heading {
    font-size: em(10);
    text-transform: uppercase;
    letter-spacing: 2.55px;
    line-height: 1.2;
    margin: 0 4px 0 0;
    font-weight: normal;

    @include media($desktop) {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .icon {
    position: relative;
    @include size(31px);
    margin-right: 4px;
    border-radius: 50%;
    border: 1px solid $dark_grayish_violet;
    background-repeat: no-repeat;
    background-position: center;
    @include transition(all 333ms ease-out);
    span {
      font-size: em(11);
      line-height: 1.45;
      margin: auto;
      width: 100px;
      text-align: center;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      @include transform(translateX(-35%));
      @include position(absolute, null 0 0 0);
      @include transition(all 222ms ease-out);
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.deactivate {
      opacity: 0.5;
    }

    &.activate {
      background-color: $dark;
      border-color: $dark;

      span {
        opacity: 1;
        visibility: visible;
        bottom: -22px;
      }

      &.icon-upcoming-event {
        background-image: url('../../html_prototype/images/icon-upcoming-event-dark.png');
      }

      &.icon-publication {
        background-image: url('../../html_prototype/images/icon-publication-dark.png');
      }

      &.icon-news {
        background-image: url('../../html_prototype/images/icon-news-dark.png');
      }
    }

    &.icon-upcoming-event {
      background-image: url('../../html_prototype/images/icon-upcoming-event.png');
      background-size: 12.5px auto;
    }

    &.icon-publication {
      background-image: url('../../html_prototype/images/icon-publication.png');
      background-size: 17px auto;
    }

    &.icon-news {
      background-image: url('../../html_prototype/images/icon-news.png');
      background-size: 12.3px auto;
    }
  }
}
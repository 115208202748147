.fancybox-container {

  .fancybox-bg {
    background: $dark_very_grayish_red_3;
  }

  .fancybox-slide {

    @include media($tablet) {
      padding: 0 35px;
    }

    @include media($mobile) {
      padding: 0 10px;
    }
  }

  .fancybox-testimonial {
    @include pad(0 20px);
    margin: auto;
    overflow: hidden;
    background: transparent;

    .wrapper {
      background: $white;
      margin: 0 auto;
      max-width: 750px;
      @include pad(60px 82px);

      @include media($tablet) {
        @include pad(50px);
      }

      @include media($tablet) {
        @include pad(25px);
      }
    }
  }

  .fancybox-close-small {
    @include size(10px);
    background: url('../../images/build/fancy-close.png') no-repeat center;
    background-size: 100% auto;

    &:after {
      content: none;
    }
  }

  .pre-title {
    font-size: em(10);
    text-transform: uppercase;
    color: $dark_very_grayish_red_3;
    margin-bottom: 9px;
    line-height: 1.4;
    letter-spacing: 1.6px;
  }

  h2 {
    color: $dark_very_grayish_red;
    font-size: em(17);
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.3;
    letter-spacing: -0.4px;
  }

  p {
    font-size: em(13);
    line-height: 1.61;
    color: $dark_very_grayish_red_2;
  }

  .card-image-links {
    margin-top: 24px;
  }
}
.vacancy {
  color: color(dark_very_grayish_red_3);
  background-color: color(white);
  border-radius: 20px;
  @include pad(24px 25px 33px);
  transition: all 0.3s ease-in-out;

  @include media($mobile) {
    margin-bottom: rem(16);
    @include pad(24px 16px);
  }

  &:hover {
    color: color(white);
    background-color: color(combiz_blue);

    .vacancy-cta {
      &.is-blue {
        background-color: color(white);
        color: color(combiz_blue);
        @include caret(color(combiz_blue));
      }
    }
  }

  &-department {
    font-size: rem(10px);
    display: block;
    margin-bottom: 1px;
    color: inherit;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    margin-bottom: 9px;
  }

  &-title {
    color: inherit;
    font-size: rem(24px);
    font-weight: 600;
    margin-bottom: 9px;
    letter-spacing: -0.03em;
  }

  &-date {
    font-size: rem(11px);
    display: block;
    margin-bottom: 17px;
    color: inherit;
  }

  &-text {
    font-size: rem(12px);
    line-height: 1.6;
    color: inherit;
  }

  &-cta {
    font-size: rem(16px);
    @include pad(8px 24px 8px 17px);
    @include caret(color(white));
    transition: all 0.3s ease-in-out;

    &::after {
      margin-left: 9px;
      transition: all 0.3s ease-in-out;
    }
  }
}

.view-join-our-team {

  .view-filters {
    position: initial;
  }
  
  @include media($mobile) {
    .wrapper {
      padding: 0;
    }
  }
  .view-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 50px;

    @include media($mobile) {
      display: block;
      padding: 20px 20px 40px !important;
    }
  }
}
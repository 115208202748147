.node-type-event {

  .accessibility {
    .breadcrumb {
      display: none;
    }
  }

  .main-with-sidebar section {
    @include row();
    width: 100%;
    margin-bottom: 100px;

    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 15px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    @include media($mobile) {
      margin-bottom: 40px;

      p {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }

  #practical-info {

    .event-practical-info {
      @include pad(45px 33px);
      @include span-columns(7 of 8);
      background: $gary_light_orange;
    }

    .addeventatc {
      float: right;
      margin-top: 3px;
    }

    .secondary-date {
      @include clearfix();
      margin-top: 5px;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: -0.41px;
      color: $dark_very_grayish_red;
      margin-bottom: 20px;
      padding-left: 25px;
      list-style: none;
      width: 100%;

      &.date {
        background: url('../../images/build/time.png') no-repeat left 2px;
        background-size: 13px auto;
      }

      &.address {
        background: url('../../images/build/address.png') no-repeat left 2px;
        background-size: 12px auto;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .day,
    .time,
    .location {
      display: inline-block;
      vertical-align: middle;
      margin-right: 12px;
    }

    .tertiary-call-action {
      margin-top: 3px;
      font-size: rem(12);

      &:before {
        top: 3px;
      }
    }

    @include media($desktop) {

      .event-practical-info {
        width: 100%;
      }
    }

    @include media($mobile) {

      .event-practical-info {
        @include pad(25px);
      }

      li {
        font-size: rem(13);
        margin-bottom: 12px;
      }
    }
  }

  #speakers {

    h2 {
      margin-bottom: 19px;
      letter-spacing: -0.03em;
    }

    // img {
    //   @include size(119px);
    //   display: inline-block;
    //   vertical-align: middle;
    //   border-radius: 119px;
    // }

    h3 {
      font-size: rem(15);
      font-weight: 600;
      margin-bottom: 3px;
      line-height: 1.3;
      letter-spacing: -0.4px;
    }

    .salutation {
      clear: both;
      color: $dark_very_grayish_red_2;
      font-size: rem(12);
      line-height: 1.65;
    }

    a.fancybox {
      display: block;
    }

    .event-speakers {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(218px, 218px));
      gap: 15px;
    }

    .event-speaker {
      position: relative;
      list-style: none;
      border-radius: 20px;
      overflow: hidden;
      background-color: color(gray_lighter_blue);
    }

    .event-speaker-frame {
      height: 191px;
    }

    .event-speaker-readmore {
      color: color(combiz_orange);
      font-size: rem(12);
      font-weight: 300;
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .text-wrap {
      @include pad(6px 18px 10px);
      font-size: 0;
    }

    .event-speaker-job-title {
      clear: both;
      color: $dark_very_grayish_red_2;
      font-size: rem(12);
      line-height: 1.65;
      margin-bottom: 2px;
    }

    .text {
      font-size: rem(12);
      line-height: 1.58;
    }

    @include media($mobile) {

      img {
        @include size(84px);
      }

      h3 {
        font-size: rem(15);
        margin-bottom: 5px;
      }

      .event-speaker {
        position: relative;
        list-style: none;
        @include pad(16px 0);

        &:last-of-type {
          border-bottom: 0;
        }
      }

      .text-wrap {
        width: calc(100% - 100px);
        padding-left: 15px;
      }

      .event-speaker-job-title {
        font-size: rem(12);
        line-height: 1.4;
        margin-bottom: 0;
      }

      .text {
        display: none;
      }
    }

  }

  #registration {

    h2 {
      margin-bottom: 2px;
    }
  }

  #location {

    #map {
      float: left;
    }

    .event-map-text-wrap {
      float: left;
      width: calc(100% - 395px);
      padding-left: 25px;
    }

    h3 {
      font-size: em(17);
      font-weight: 600;
      margin-bottom: 10px;
      color: $dark_very_grayish_red;
    }

    .event-map-address {
      font-size: em(14);
      line-height: 1.64;
    }

    @include media($mobile) {

      h2 {
        margin-bottom: 20px;
      }

      #map {
        width: 100% !important;
        margin-bottom: 15px;
      }

      .event-map-text-wrap {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  #terms {
    font-size: rem(14);
    font-weight: normal;
    line-height: 1.71;
    color: $dark_very_grayish_red_2;

    h2 {
      color: $dark_grayish_red;
    }

    @include media($mobile) {
      font-size: rem(13);
    }
  }

  .event-external-link {
    margin-top: 20px;
  }
}

/** EVENT PROGRAMMES **/
.event-programme-header {
  padding: 60px 0;

  .pre-title {
    font-size: rem(16);
    font-weight: 600;
  }

  h1 {
    font-size: rem(49);
    margin: -10px 0 0;
  }
}

.event-programme-main {

  padding-top: 40px;

  .event-programme-days-wrap {

    @include clearfix();
    border-bottom: 2px solid $gray_grayish_violet;

    h2 {
      font-size: rem(11);
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: $spaced-letters;
      float: left;
      position: relative;
      top: 10px;

      @include media($mobile) {
        padding-left: 20px;
      }
    }
  }

  .event-programme-days {
    float: left;
    position: relative;
    top: 2px;
    margin-left: 35px;

    li {
      float: left;
      margin-right: 35px;
    }

    .day-wrap,
    .divider {
      display: inline-block;
    }

    .divider {
      padding: 0 5px;
    }

    a {
      display: block;
      font-size: rem(15);
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: -0.05em;
      margin-left: -2px;
      border-left: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      color: #999;
      height: 52px;

      &.active,
      &:hover {
        border-bottom: 2px solid $orange_bright;
        color: $dark_grayish_red;
      }

      .location {
        font-size: rem(12);
        font-weight: 400;
        text-transform: none;
        color: $dark_very_grayish_red
      }
    }
  }

  .event-programme-items {
    padding-top: 30px;

    li {
      overflow: hidden;
      padding: 15px 12px;

      &.color-grey {
        background: $gray_grayish_blue;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .time {
      float: left;
      color: #999;
      font-size: rem(17);
      font-weight: 600;
      line-height: 1.4;
      width: 80px;
      min-height: 1px;
    }

    .text {
      overflow: hidden;
    }

    h2 {
      font-size: rem(17);
      font-weight: 600;
      margin: 0;
    }

    &.hidden {
      display: none;
    }
  }

  .event-schedule-item {

    padding: 10px 0;

    @include media($overmobile) {
      @include span-columns(6);
    }

    .room {
      background: url(../../images/build/marker.png) left top no-repeat;
      background-size: 12px auto;
      padding-left: 20px;
      font-size: rem(15);
      font-weight: 500;
      line-height: 1;
      margin-bottom: 10px;
    }

    .speaker {
      font-size: rem(15);
      margin-bottom: 20px;
      margin-top: 3px;
    }

    .text {
      font-size: rem(13.5);
    }

    a {
      display: inline-block;
      font-size: rem(12);
      font-weight: 500;
      border-left: 2px solid $orange_bright;
      line-height: 1;
      padding-left: 5px;
      margin-top: 15px;
    }
  }

}

.event-programme-detail-wrap {

  position: relative;
  padding: 50px 0;

  .event-programme-detail {
    @include media($overtablet) {
      @include span-columns(8);
    }
  }

  .event-programme-detail-close {
    position: absolute;
    right: 0;
    top: 15px;
    background: none;
    border: 0;

    svg {
      width: 20px;
      height: 20px;
    }

    g {
      stroke: $orange_bright;
    }
  }

  .date-and-room {
    color: #999999;
    font-size: rem(18);
    font-weight: 600;
    margin-bottom: 18px;
  }

  .room {
    padding-left: 20px;
    background: url(../../images/build/marker.png) left center no-repeat;
    background-size: 12px auto;
  }

  h2 {
    font-size: rem(24);
    margin-bottom: 5px;
  }

  .speaker {
    font-size: rem(15);
    font-weight: 500;
    margin-bottom: 20px;
  }

  .speaker-wrap {
    margin-top: 60px;

    h3 {
      font-size: rem(24);
    }
  }

  .speaker-inner {
    clear: both;
    margin-bottom: 30px;
    overflow: hidden;
  }

  .speaker-image {
    float: left;
    width: 119px;
    height: 119px;
    border-radius: 119px;
    overflow: hidden;
    margin-right: 30px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .speaker-text-wrap {
    overflow: hidden;
  }

  h4 {
    font-size: rem(17);
    font-weight: 600;
    margin-bottom: 5px;
  }

  .job-title {
    font-size: rem(14);
    color: $dark_very_grayish_red_2;
    margin-bottom: 8px;
  }

  .bio {
    font-size: rem(12);
    color: $dark_very_grayish_red_2;
  }

  &.hidden {
    display: none;
  }
}

.event-gallery,
.paragraph-gallery {
  a:not(.open-gallery-link) {
    display: block;
    @include span-columns(4);
    @include omega(3n);
    margin-bottom: 13px;
    transition: opacity 0.2s ease-in-out;

    &:nth-child(n+7) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.section-past-events {
  color: $white;
  padding: 92px 0 45px;
  background-color: $orange-bright;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  @include media($overmobile) {
    .section-past-events-block {
      display: inline-block;
      margin-right: 50px;
    }
  }

  .inner {}

  h2 {
    font-size: rem(24);
    margin-bottom: 10px;
  }

  .text {
    font-size: rem(14);
    border-bottom: 1px solid $white;
    padding-bottom: 30px;
    margin-bottom: 30px;
    min-width: 280px;
  }

  @include media($mobile) {
    padding: 53px 25px;
    text-align: center;

    .section-past-events-block {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      font-size: rem(21);
      margin-bottom: 5px;
      line-height: 1.1;
      letter-spacing: -0.61px;
    }

    .text {
      font-size: rem(13);
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 15px;
    }
  }

}

#sponsors {

  h2 {
    margin-bottom: 30px;
  }

  h3 {
    font-weight: 500;
    color: $dark_grayish_red;
  }

  p+h3 {
    margin-top: 50px;
  }

  img {
    margin: 0 40px 20px 0;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  @include media($mobile) {

    img {
      margin: 0 auto 20px;
    }
  }
}

.field-type-youtube,
.field-type-soundcloud {

  .field-item {
    margin-bottom: 30px;
  }

}

.event-programmes {
  .text {
    margin-bottom: 20px;
  }

}

.event-upcoming {
  background-color: color(combiz_light_gray);

  &-inner {
    @include pad(46px 0 44px);

    @include media($mobile) {
      @include pad(24px 0 40px);
    }
  }

  &-title {
    margin-bottom: 21px;
    padding-left: 5px;

    @include media($mobile) {
      font-size: rem(24);
      line-height: 1.3;
      text-align: center;
    }
  }

  &-cards {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 318px 206px;
    grid-template-areas:
      "one two four five"
      "three three six six";
    gap: 20px 18px;
    margin-bottom: 51px;

    @include media($tablet) {
      display: block;

      &.slick-dotted.slick-slider {
        margin-bottom: rem(95);
      }

      .slick-slide {
        min-height: rem(286);
      }

      .slick-dots {
        bottom: rem(-50);

        button:before {
          content: '';
        }
      }
    }


  }

  &-card {
    &:first-of-type {
      grid-area: one
    }

    &:nth-of-type(2) {
      grid-area: two;
    }

    &:nth-of-type(3) {
      grid-area: three;
    }

    &:nth-of-type(4) {
      grid-area: four;
    }

    &:nth-of-type(5) {
      grid-area: five;
    }

    &:nth-of-type(6) {
      grid-area: six;
    }
  }

  &-button-wrap {
    display: flex;
    justify-content: center
  }

  &-cta {
    font-size: rem(16px);
    @include caret(color(white));
    @include pad(8px 24px 8px 17px);

    &::after {
      margin-left: 8px;
    }
  }
}

.event-about {
  background-color: color(white);

  &-inner {
    text-align: center;
    @include pad(59px 0 64px);

    @include media($mobile) {
      text-align: left;
      @include pad(16px 0 24px);
    }
  }

  &-title {
    margin-bottom: 25px;

    @include media($mobile) {
      font-size: rem(28);
    }
  }

  &-desc {
    max-width: 867px;
    width: 100%;
    margin: 0 auto;

    p {
      color: color(combiz_black);
      margin-bottom: 0;
      font-size: rem(15px);
      font-weight: 500;
      line-height: 1.5;

      @include media($mobile) {
        font-size: rem(12);
      }
    }
  }
}

.event-past {
  background-color: color(gray_lighter_blue);

  &-inner {
    @include pad(45px 0 49px);

    @include media($mobile) {
      @include pad(32px 0 48px);
    }
  }

  &-title {
    margin-bottom: 33px;

    &~.text {
      margin-bottom: rem(24);
    }

    @include media($mobile) {
      font-size: rem(28);
      line-height: 1.3;
      margin-bottom: rem(32);
    }
  }

  &-cards {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 10px;
    margin-bottom: 32px;

    .view-past-events {

      .wrapper {
        padding-left: 0;
        padding-right: 0;

        .view-content {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          gap: 26px;
          margin-bottom: 32px;
        }
      }

      .card-past {
        display: block;

        @include media($tablet) {
          max-width: 280px;
          margin: 0 auto;

        }
      }
    }


    @include media($tablet) {
      margin-bottom: rem(95);

      .slick-dots {
        bottom: rem(-50);
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
  }

  &-cta {
    font-size: rem(16px);
    @include caret(color(white));
    @include pad(7px 24px 6px 16px);

    &::after {
      margin-left: 9px;
    }
  }

  .slick-dots {
    button::before {
      content: '';
    }
  }
}

.event-related {
  background-color: color(gray_lighter_blue);
  @include pad(50px 0);

  &-title {
    margin-bottom: 33px;
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    gap: 10px 10px;

    .topic-content {
      min-height: 318px;
    }
  }
}

.sns {
  padding-left: 6px;

  &-list {
    display: flex;
    align-items: center;
    gap: 0 15px;

    a {
      display: block;

    }

    img {
      display: block;
      object-fit: cover;
      width: 24px;
      height: 100%;
    }
  }
}
// Test a Sass list to see if it contains a defined value
// Allows for checking if a list contains several values at once

@function contains($list, $values...) {
  @each $value in $values {
    @if type-of(index($list, $value)) != "number" {
      @return false;
    }
  }

  @return true;
}

.block-commerce-checkout-progress {
  background: $gary_light_orange;
  overflow: hidden;
  color: $gray_dark;

  .block-inner {
    @include outer-container();
    padding: 16px 0 14px;

    @include media($large_desktop) {
      @include outer-container(960px);
    }

    @include media($tablet) {
      padding: 16px 35px 14px;
    }

    @include media($mobile) {
      text-align: center;
      @include outer-container(100%);
      @include pad(9px 20px 7px);
    }
  }

  .content {
    font-size: rem(11);
  }

  .item-list {
    height: 27px;
  }
}

.item-list ul.commerce-checkout-progress {
  counter-reset: progress-counter;
  overflow: hidden;

  li {
    font-weight: 400;
    border: 0;
    margin: 0 19px;
    background: none;

    &.first {
      margin-left: 0;
    }

    &:before {
      content: counter(progress-counter);
      counter-increment: progress-counter;
      background: $gray_very_light;
      color: $white;
      font-size: rem(13.5);
      font-weight: 700;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      padding: 4px;
      margin-right: 10px;
    }

    &.active {
      color: $orange_bright_2;

      &:before {
        background: $orange_bright_2;
      }
    }

    a {
      font-size: rem(11);
      color: $gray_dark;
    }
  }

  @include media($tablet) {

    li {
      font-size: 0;
      font-weight: 500;
      margin: 0 15px;
      padding-right: 5px;

      a {
        font-size: 0;
      }

      &.active {
        font-size: rem(12);
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  @include media($mobile) {
    text-align: center;
    display: inline-block;

    li {
      @include size(27px);
      margin: 0 7.5px;
      padding: 0;
      display: inline-block;

      a,
      &.active {
        font-size: 0;
      }
    }
  }
}

.view-commerce-cart-form {
  background-color: color(combiz_light_gray);
  @include pad(30px 0 62px);

  @include media($desktop) {
    @include pad(30px 20px 62px);
  }

  .view-cart-inner {
    background-color: color(white);
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    @include pad(0 103px);

    @include media($desktop) {
      @include pad(0 50px);
    }

    @include media($mobile) {
      @include pad(0 20px);
    }
  }

  .view-header {
    padding: 50px 0 41px;
  }

  .view-content {
    padding-bottom: 100px;
  }

  .view-content,
  .view-header {
    // @include outer-container();


    @include media($large_desktop) {
      @include outer-container(960px);
    }

    @include media($desktop) {
      @include outer-container(800px);
    }

    @include media($tablet) {
      @include outer-container(680px);

      &.view-header {
        padding: 35px 0;
      }

      &.view-content {
        padding-bottom: 50px;
      }
    }

    @include media($mobile) {
      @include outer-container(100%);

      &.view-header {
        padding: 26px 0 16px;
      }

      &.view-content {
        padding: 0 0 18px;
      }
    }
  }

  .attachment {
    clear: both;

    a {
      display: none;
    }
  }

  form {
    @include span-columns(9);
    position: relative;

    table {
      width: 100%;
      margin: 0 0 31px;
    }

    thead th {
      font-size: em(11);
      text-transform: lowercase;
      color: $dark_very_grayish_red_2;
      line-height: 1.45;
      @include pad(5px 0);
    }

    tbody {

      tr {
        @include pad(0);

        &.type-Free.Ticket {

          .delete-line-item,
          .form-type-textfield {
            display: none;
          }
        }

        &.hide {
          display: none;
        }
      }

      td {
        @include pad(18px 0 15px);
      }
    }

    @include media($desktop) {
      @include span-columns(12);
    }

    @include media($mobile) {

      table {
        margin: 0 0 15px;
      }

      thead th {
        @include pad(0 0 4px);
      }

      .views-field-commerce-total,
      .views-field-views-conditional-1,
      .views-field-views-conditional-2 {
        display: none;
      }

      tbody {

        td {
          @include pad(18px 0 5px);
        }
      }
    }
  }

  h2 {
    font-size: rem(24px);
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.25;
    letter-spacing: -0.7px;

    @include media($mobile) {
      font-size: rem(20);
    }
  }

  h3 {
    font-weight: 500;
    font-size: rem(15);
    margin-bottom: -3px;

    @include media($mobile) {
      font-size: rem(14);
    }
  }

  .ticket-name {
    font-size: rem(15px);
    font-weight: 500;
    letter-spacing: -0.04em;
  }

  input.delete-line-item {
    background: none;
    border: 0;
    color: $orange_bright_2;
    padding: 0;
    font-size: em(12);
    text-transform: lowercase;
    font-weight: normal;
  }

  .views-field-nothing {
    width: 65%;
    min-width: 300px;

    @include media($mobile) {
      min-width: inherit;
      width: calc(100% - 70px);
    }
  }

  .views-field-edit-quantity {
    width: 180px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    .form-item {
      border: 1px solid $gray_grayish_violet;
      padding: 6px 0;
      border-radius: 3px;
      height: 38px;
      width: 100px;
      margin: 0 auto;
    }

    .commerce-quantity-plusminus-link {
      margin-top: -5px;

      a {
        color: $orange_bright_3;
        font-weight: 600;

        &:hover {
          background: 0;
          color: $orange_bright_3;
        }
      }

      &.commerce-quantity-plusminus-link-disabled:not(.commerce-quantity-plusminus-link-increase) {
        opacity: 0.4;

        a {
          cursor: default;
          pointer-events: none;
        }
      }

    }

    .form-text {
      border: 0;
      background: 0;
      border-left: 1px solid $gray_very_light;
      border-right: 1px solid $gray_very_light;
      width: 33.33px;
      text-align: center;
      padding: 1px 0;
    }

    @include media($mobile) {
      width: 50px;
      text-align: left;
      padding: 0;

      .form-item {
        position: relative;
        border: 1px solid $gray_grayish_violet;
        padding: 5px 0;
        height: 40px;
        width: 50px;
      }

      .commerce-quantity-plusminus-link {
        @include position(absolute, 5px 5px null null);
        @include size(14px);

        &.commerce-quantity-plusminus-link-decrease {
          top: inherit;
          bottom: 9px;
        }

        a {
          padding: 0;
          margin: 0;
          font-weight: 400;
          @include size(14px);
          border-radius: 0;
          @include position(absolute, 0 null null 0);
        }
      }

      .form-text {
        border: 0;
        width: 20px;
        margin-left: 8px;
      }
    }
  }

  th.views-field-commerce-total,
  th.views-field-views-conditional-1,
  th.views-field-views-conditional-2 {
    text-align: right;
    padding-right: 20px;
  }

  td.views-field-commerce-total,
  td.views-field-views-conditional-1,
  td.views-field-views-conditional-2 {
    font-weight: 600;
    font-size: rem(16);
    text-align: right;
    color: $dark_grayish_red;
    padding-top: 24px;
    padding-right: 10px;
  }

  td.views-field-edit-quantity {
    padding: 23px 0 10px;
  }

  .membership-or-publication-line-item,
  .type-Discount,
  .Coupon {

    .form-type-value,
    .commerce-quantity-plusminus-link {
      display: none;
    }

    .views-field-edit-quantity .form-item {
      border: 0;
    }

    .views-field-edit-quantity .form-text {
      border-left: 0;
      border-right: 0;
      font-size: 16px;
      pointer-events: none;
      color: #3B3535;
    }

  }

  .line-item-summary {
    margin: 23px 0 0;
    @include position(absolute, null 130px null null);

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .line-item-total-label {
      font-size: em(14);
      font-weight: 500;
      color: $dark_very_grayish_red;
      line-height: 1.4;
      letter-spacing: -0.41px;
      text-transform: lowercase;
    }

    .line-item-total-raw {
      font-size: em(24);
      font-weight: 600;
      line-height: 1.25;
      color: $dark_very_grayish_red_3;
      letter-spacing: -0.7px;
    }

    @include media($mobile) {
      margin: 0;
      @include position(relative, inherit);
      width: calc(100% - 110px);
      display: inline-block;
      vertical-align: middle;
      text-align: left;

      .line-item-total-raw {
        font-size: rem(20);
      }
    }
  }

  .form-actions {
    float: right;
    margin: 17px 0 0 4%;

    @include media($mobile) {
      display: block;
      vertical-align: middle;
      float: none;
      margin: 15px 0 0 0;
      text-align: left;
    }
  }

  #edit-checkout {
    // @extend .primary-call-action;
    background: color(combiz_blue);
    color: $white;
    outline: none;
    border: 0;
    font-size: rem(15px);
    font-weight: 400;
    border: 1px solid color(combiz_blue);

    &:hover {
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
      background: color(white);
      color: color(combiz_blue);
      border: 1px solid color(combiz_blue);
    }
  }

  .visa-mastercard {
    display: block;
    height: 31px;

    @media(min-width: 680px) {
      position: absolute;
      right: 6px;
      bottom: 78px;
    }
  }

  #edit-submit,
  #edit-submit--4 {
    display: none;
  }

  #edit-coupon-code,
  #edit-coupon-code--2 {
    @include span-columns(5);

    .form-item {
      margin: 0;
    }

    .form-submit {
      @extend .secondary-call-action;
      font-weight: 600;
      padding: 11px 14px 9px;
      border: 1px solid $orange_bright_3;
      box-shadow: none;

      &:hover {
        font-weight: 600;
        color: $orange_bright_3;
        //box-shadow: 0 0 0 1px $orange_bright_3;
        box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
      }
    }

    .form-item-input-coupon-code {
      display: inline-block;
      width: 70%;
    }

    label {
      height: auto;
      color: $dark_very_grayish_red_2;
      font-size: em(11);
      font-weight: normal;
      margin-left: 10px;
      position: relative !important;

      .form-required {
        display: none;
      }
    }

    input[type="text"] {
      @include pad(10px 11px);
      font-size: em(14);
      font-weight: 500;
      color: $dark_very_grayish_red;
      line-height: 1.4;
      letter-spacing: -0.41px;
      border: 1px solid $gray_grayish_violet;
      border-radius: 3px;
    }

    @include media($mobile) {
      display: inline-block;
      width: 100%;
      float: none;
      margin: 0 0 15px;

      .form-submit {
        margin-top: 1px;
        float: right;
        min-width: 93px;
      }

      .form-item-input-coupon-code {
        width: calc(100% - 120px);
      }

      label {
        display: none;
      }
    }
  }

  .form-item-buyer-is-guest {
    clear: both;
    font-size: rem(14);
    border: 1px solid $orange_bright_2;
    padding: 20px;
    margin-bottom: 30px;

    @media(min-width: 680px) {
      width: calc(100% - 132px);
      position: relative;
      top: 30px;
    }

    label {
      color: $dark_grayish_red;
      font-size: rem(15);
    }

    .description {
      font-size: rem(12);
      opacity: 0.8;
      padding: 0;
      margin: 0;
      padding-top: 10px;
    }
  }
}

#commerce-checkout-form-review {
  @include outer-container();
  padding: 30px 0;

  #edit-customer-profile-billing {
    @include span-columns(6);
  }

  #edit-cart-contents {
    @include span-columns(4);
    @include shift(2);
    @include omega();
  }
}

.cart-empty-page {
  font-size: em(25);
  font-weight: lighter;
  text-transform: uppercase;
  @include pad(70px 0);
  min-height: calc(100vh - 400px);

  @include outer-container();

  @include media($large_desktop) {
    @include outer-container(960px);
  }

  @include media($tablet) {
    @include outer-container(680px);
  }

  @include media($mobile) {
    @include outer-container(100%);
  }
}

.cart-pricing-info {
  font-size: em(14);
  margin-bottom: 15px;
}

#commerce-checkout-form-complete {

  .no-event .event-message,
  .no-membership .membership-message,
  .no-publication .publication-message {
    display: none;
  }
}

.form-item-commerce-payment-payment-details-credit-card-cardonfile-store {
  overflow: hidden;
  clear: both;
}

.payment-redirect-form {
  background-color: color(combiz_light_gray);
  // @include outer-container();
  padding: 80px 0;
  text-align: center;

  .checkout-help {
    max-width: 550px;
    margin: 0 auto 50px;
  }
}

#commerce-checkout-form-guest-details {

  .checkout-signin-signup-pane {
    padding-bottom: 20px;
  }

  .checkout-signin-signup-pane {

    fieldset {
      margin-bottom: 20px;
      border: 1px solid $orange_bright_4;
      padding: 0;
    }

    legend {
      background: $orange_bright_4;
      color: $white;
      width: calc(100% + 2px);
      padding: 5px 10px;
      font-weight: 600;
      margin-left: -1px;
      margin-right: -1px;
    }

    label {
      font-size: rem(14);
      font-weight: 400;
    }

    .fieldset-wrapper {
      display: flex;
      flex-wrap: wrap;
      border: 0;
      padding: 10px 20px;
    }

    .form-item {
      flex: 0 0 calc(50% - 10px);

      &:nth-child(2n-1) {
        margin-right: 20px;
      }
    }
  }

  .checkout-buttons {
    @include outer-container();
    border: 0;
    padding-bottom: 100px;
    text-align: center;

    input:not(#edit-continue),
    .button-operator {
      display: none;
    }
  }
}
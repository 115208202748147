.node-network-application-page {

  #who-why {
    margin-bottom: 25px;
    padding-bottom: 20px;
  }

  #practical-info {
    padding: 25px 0 65px;
    margin-bottom: 0;

    .practical-information {
      @include pad(44px 50px 39px 36px);
    }

    dt {
      line-height: 1.2;
      letter-spacing: 2.55px;
    }
  }

  #benefits {
    margin-bottom: 0;
    padding: 30px 0 45px;

    .network-application-benefits {
      @include clearfix();
      margin: 0 0 30px;

      li {
        padding: 0;
        margin-top: 24px;
        background: none;
        @include span-columns(6);
        @include omega(2n);

        ul {
          margin: 0;
        }

        li {
          position: relative;
          padding-left: 16px;
          font-size: rem(13);
          line-height: 1.43;
          color: $dark_grayish_red;
          letter-spacing: -0.41px;
          margin: 0 0 1px;
          width: 100%;

          &:before {
            content: '';
            @include size(3px);
            @include position(absolute, 6px null null 5px);
            border-radius: 50%;
            background: $dark_grayish_red;
          }
        }
      }

      h3 {
        font-size: em(10);
        line-height: 1.2;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 9px;
        letter-spacing: 2.55px;
      }
    }
  }

  #options {
    padding: 45px 0 50px;

    .tickets {
      margin: 0;
    }

    .ticket {
      position: relative;
      padding: 51px 0 30px;

      h2 {
        font-size: em(17);
        color: $dark_very_grayish_red;
        font-weight: 600;
        margin-bottom: 5px;
        float: left;
        width: calc(100% - 140px);

        span {
          margin: 0 5px;
        }
      }

      &:first-of-type {
        padding-top: 25px;

        .buy-tickets-form-add-single-to-cart {
          top: 25px;
        }
      }
    }

    .primary-call-action {
      padding: 10px 18px 8px;
      float: right;
    }

    .buy-tickets-form-add-single-to-cart {
      @include position(absolute, 51px 0 null null);
    }
  }

  #more {
    margin: 0;
    padding: 50px 0 0;

    .text {
      margin-bottom: 28px;
    }

    .secondary-call-action {
      margin-right: 15px;
    }
  }
}
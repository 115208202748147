.nav {

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 24px;

    @include media($desktop) {
      gap: 0 8px;
    }
  }

  a {
    font-size: rem(14);
    color: color(combiz_blue);
    position: relative;

    @include media($desktop) {
      font-size: rem(12);
    }

    &.active {
      font-weight: 700;
      color: color(combiz_blue);
    }

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 3px;
      left: 0;
      background-color: color(combiz_blue);
      opacity: 0;
      translate: 0 8px;
      transition-property: opacity, translate;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      &::before {
        opacity: 1;
        translate: 0;
      }
    }
  }
}
.card-event {
  overflow: hidden;
  border-radius: 10px;
  background-color: #F7F7F7;

  &-block {
    display: block;
  }

  &-figure {
    height: 364px;

    @include media($mobile) {
      height: rem(234);
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-content {
    @include pad(24px 25px 10px)
  }

  &-date {
    font-size: rem(16px);
    color: color(combiz_blue);
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
  }

  &-title {
    color: color(combiz_blue);
    font-size: rem(31px);

    @include media($mobile) {
      font-weight: 500;
      font-size: rem(24);
      line-height: 1.5;
    }
  }

  &-desc {}
}
/* ----- PUT ALL YOUR AMAZING VARIABLES IN HERE ----- */
// Font
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

// Color
$dark: #000;
$dark_grayish_violet: #958E9C;
$dark_grayish_red: #3B3535;
$dark_very_grayish_red: #545151;
$dark_very_grayish_red_2: #666262;
$dark_very_grayish_red_3: #474545;

$gray_dark: #999;
$gray_very_dark: #4F4F4F;
$gray_very_dark: #4F4F4F;
$gray_very_light: #EAEAEA;
$gray_grayish_violet: #EAE9EC;
$gray_grayish_blue: #F3F3F4;
$gray_grayish_blue_2: #BBBDBF;
$gray_grayish_blue_3: #7696B3;
$gray_light: #C9C9C9;
$gray_light_1: #EFEFEF;
$gary_light_orange: #FBF9F8;
$gary_light_orange_2: #FAF8F7;
$gray_light_orange_3: #DEDCD9;

$white: #FFF;
$white_2: #DDD;
$white_gray: #FCFCFC;

$orange_bright: #F2743A;
$orange_bright_2: #FC682D;
$orange_bright_3: #FC572D;
$orange_bright_4: #FB763F;
$orange_grayish: #C3BBA8;
$orange_grayish_2: #CAC4B8;

$blue_facebook: #4464A1;
$blue_twitter: #02B5F0;
$blue_in: #0282BD;
$blue_light_grayish: #ECECEF;
$blue_light_grayish_2: #F1F1F2;

// Variable
$color__main: $dark_grayish_red;
$color__input: $dark_very_grayish_red_2;
$color__access: $orange_bright_2;

$color: (
  combiz_blue: #10487a,
  combiz_light_blue: #2ebced,
  combiz_orange: #f37324,
  combiz_light_gray: #dce4ec,
  combiz_black: #041120,
  white: #fff,
  gray: #999,
  gray_light_300: #D9D9D9,
  gray_light_500: #7D7D7D,
  dark_grayish_red: #3B3535,
  dark_grayish_violet: #958E9C,
  dark_very_grayish_red: #545151,
  dark_very_grayish_red_2: #666262,
  dark_very_grayish_red_3: #474545,
  gray_light_blue: #EDF1F7,
  gray_lighter_blue: #F7F9FC,
  gray_grayish_blue: #F3F3F4,
  primary_dark_blue: #0C3159,
);

$breakpoint-desktop: 1200px;

$font__main: 'Poppins', sans-serif;
$spaced-letters: 0.25em;

//primary-call-action
.primary-call-action {
  position: relative;
  display: inline-block;
  font-size: em(14);
  font-weight: 600;
  color: $orange_bright_3;
  line-height: 1;
  letter-spacing: -0.44px;
  text-transform: capitalize;
  border: 0;
  box-shadow: inset 0 0 0 1px $orange_bright_3;
  border-radius: 40px;
  overflow: hidden;
  z-index: 1;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 1px;
  @include pad(14px 19px 12px);
  @include transition(all 130ms ease-out);

  &:before {
    content: '';
    @include position(absolute, null null 0 -1%);
    border-radius: 40px;
    background: $orange_bright_3;
    @include size(0 102%);
    @include transition(all 130ms ease-out);
    z-index: -1;
  }

  @include media($tablet) {
    @include pad(12px 18px 10px);
    font-size: em(12);
  }

  &.white {
    color: $white;
    //border-color: $white;
    box-shadow: inset 0 0 0 1px $white;

    &:before {
      background: $white;
    }
  }

  &.orange {
    background: $orange_bright_2;
    color: $white;
  }

  &.grey {
    //border-color: $gray_dark;
    box-shadow: inset 0 0 0 1px $gray_dark;
    color: $gray_dark;
  }

  &:hover {
    color: $white;
    //box-shadow: 0 0 0 1px $white;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);

    &.white {
      //box-shadow: none;
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
      color: $orange_bright_3;
    }

    &:before {
      @include size(102%);
    }
  }
}

input.primary-call-action {

  &:hover {
    //background: $orange_bright;

    &.white {
      background: $white;
    }

    &.grey {
      color: $dark_grayish_red;
      background: $white;
      //box-shadow: 0 0 0 1px;
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
    }
  }
}

//secondary-call-action
.secondary-call-action {
  position: relative;
  display: inline-block;
  font-size: em(12);
  min-width: 60px;
  font-weight: 600;
  color: $orange_bright_3;
  line-height: 1.167;
  letter-spacing: -0.38px;
  text-transform: capitalize;
  //border: 1px solid $orange_bright_3;
  border: 0;
  box-shadow: inset 0 0 0 1px $orange_bright_3;
  border-radius: 40px;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  background: transparent;
  cursor: pointer;
  @include pad(10px 14px 8px);
  @include transition(all 130ms ease-out);
  margin: 1px;

  &:before {
    content: '';
    @include position(absolute, null null 0 -10px);
    border-radius: 40px;
    background: $orange_bright_3;
    border: 1px solid $orange_bright_3;
    @include size(0 100%);
    @include transition(all 130ms ease-out);
    z-index: -1;
  }

  &.white {
    color: $white;
    box-shadow: inset 0 0 0 1px $white;

    &:before {
      background: $white;
    }
  }

  &:hover {
    color: $white;
    font-weight: 500;
    //box-shadow: 0 0 0 1px $white;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);

    &.white {
      color: $orange_bright_3;
      //box-shadow: none;
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
    }

    &:before {
      @include size(calc(100% + 10px) 100%);
    }
  }

  &:active {
    font-weight: 500;

    &.white:before {
      background: $white_2;
    }

    &:before {
      background: $orange_bright;
    }
  }
}

//ticket-call-action
.ticket-call-action {
  position: relative;
  display: inline-block;
  font-size: rem(14);
  font-weight: 600;
  color: $orange_bright_3;
  line-height: 1;
  letter-spacing: -0.44px;
  text-transform: capitalize;
  border: 1px solid $orange_bright_3;
  border-right: 0;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  @include pad(14px 40px 12px 20px);
  @include transition(all 333ms ease-out);

  @include media($mobile) {
    font-size: rem(13);
    @include pad(10px 30px 8px 15px);
  }

  &:before {
    content: '';
    @include position(absolute, 0 20px null null);
    background-image: linear-gradient(to bottom, $orange_bright_3 50%, $white 50%);
    background-position: left 2.5px;
    background-size: 1px 10px;
    background-repeat: repeat-y;
    @include size(1px 100%);
    z-index: -1;
  }

  &:after {
    content: '';
    @include position(absolute, null null -1% -1%);
    background: $orange_bright_3;
    @include size(0 102%);
    @include transition(all 333ms ease-out);
    z-index: -2;
  }

  .circle {
    @include position(absolute, 0 -11px 0 null);
    @include size(19px);
    margin: auto 0;
    background: transparent;
    border: 1px solid $orange_bright_3;
    border-radius: 50%;
    z-index: 2;

    &:before,
    &:after {
      content: '';
      @include size(1px 100px);
      margin: auto;
      background: $orange_bright_3;
    }

    &:before {
      @include position(absolute, -100px null null 6px);
    }

    &:after {
      @include position(absolute, null null -100px 6px);
    }
  }

  &.white {
    color: $white;
    border-color: $white;

    .circle {
      background: transparent;
      border-color: $white;

      &:before,
      &:after {
        background: transparent;
      }
    }

    &:before {
      background-image: linear-gradient(to bottom, $white 50%, $color__main 50%);
      background-position: left 2.5px;
      background-size: 1px 10px;
      background-repeat: repeat-y;
    }

    &:after {
      background: transparent;
    }
  }

  &:hover {
    color: $white;

    .circle {
      background: $white !important;
    }

    &.white {
      color: $orange_bright_3;
    }

    &.hightlight {
      box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
    }

    &:after {
      @include size(102%);
    }
  }
}

//tertiary-call-action
.tertiary-call-action {
  position: relative;
  font-weight: 500;
  font-size: em(12);
  color: $dark_very_grayish_red;
  line-height: 1.25;
  cursor: pointer;
  @include pad(0 15px 0 9px);

  &:after {
    content: '';
    background-repeat: no-repeat;
    @include position(absolute, 5px 0 null null);
    @include transition(all 222ms ease-out);
  }

  &:before {
    content: '';
    @include position(absolute, 4px null null 0);
    @include size(2px 10px);
    background: $orange_bright_2;
  }

  &.dir-left {

    &:after {
      @include size(11.5px 8px);
      background-image: url('../../images/build/arrow-left.svg');
      background-position: -12px center;
      background-size: 11.5px auto;
    }

    &.white {
      color: $white;

      &:after {
        background-image: url('../../images/build/arrow-left-white.svg');
      }
    }
  }

  &.dir-down {

    &:after {
      @include size(8px 11.5px);
      background-image: url('../../images/build/arrow-down.svg');
      background-position: center 15px;
      background-size: 7px auto;
    }

    &.white {
      color: $white;

      &:after {
        background-image: url('../../images/build/arrow-down-white.svg');
      }
    }
  }

  &:hover {

    &.dir-left:after {
      background-position: center;
    }

    &.dir-down:after {
      background-position: center -1px;
    }
  }
}

// link-normal
.link-normal {
  position: relative;
  display: inline-block;
  font-size: rem(12);
  line-height: 1.25;
  cursor: pointer;
  color: $orange_bright_2;
  @include pad(0 2px 4px);
  text-transform: lowercase;

  &:after {
    content: '';
    @include position(absolute, 100% null null 0);
    @include size(100% 1px);
    opacity: 0;
    background: $orange_bright_2;
    @include transform(translateY(10px));
    @include transition(all 333ms ease-out);
  }

  &.white {

    &:after {
      @include size(100% 2px);
      background: $gray_very_dark;
    }
  }

  &:hover {

    &:after {
      opacity: 1;
      @include transform(translateY(0px));
    }
  }
}

//link-underline
.link-underline {
  position: relative;
  font-size: em(12);
  color: $dark_grayish_red;
  line-height: 1.25;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right,
      $orange_bright,
      $orange_bright 50%,
      $dark_grayish_red 50%);
  background-size: 200% 100%;
  background-position: 100%;
  @include transition(all 333ms ease-out);

  &:after {
    content: '';
    @include size(100% 1px);
    @include position(absolute, null null 0 0);
    background-image: linear-gradient(to right,
        $orange_bright,
        $orange_bright 50%,
        $dark_grayish_red 50%);
    background-size: 201% 100%;
    background-position: 99.9%;
    @include transition(all 333ms ease-out);
  }

  &.white {
    background-image: linear-gradient(to right,
        $orange_bright,
        $orange_bright 50%,
        $white 50%);

    &:after {
      background-image: linear-gradient(to right,
          $orange_bright,
          $orange_bright 50%,
          $white 50%);
    }
  }

  &:hover,
  &:active {
    background-position: 0;

    &:after {
      background-position: 0;
    }
  }
}

//btn-back
.btn-back {
  width: 100%;
  font-size: em(12);
  color: $dark_very_grayish_red_3;
  line-height: 1.25;
  padding-left: 13px;
  background: url('../../html_prototype/images/back-arrow.svg') no-repeat left center;
  background-size: 4.5px auto;
  cursor: pointer;
  display: none;

  @include media($mobile) {
    display: inline-block;
  }
}

//btn-more
.btn-more {
  display: none;
  float: right;
  text-align: right;
  width: 50px;
  font-size: em(12);
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.32px;
  padding-right: 15px;
  margin-top: 6px;
  cursor: pointer;
  background: url('../../images/build/more-arrow.png') no-repeat right center;
  background-size: 9px auto;
}

button {
  outline: none;
}

//profile-tab-link
.profile-tab-link {
  font-size: rem(16);
  font-weight: 400;
  text-transform: capitalize;
  @include pad(7px 23px 7px 15px);
  @include caret(color(white));

  @include media($mobile) {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &::after {
    margin-left: 8px;
  }
}
.checkout_payment_method {
  background-color: color(combiz_light_gray);
}

.cart-checkout {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @include pad(94px 0 94px);

  @include media($desktop) {
    @include pad(94px 20px 94px);
  }

  @include media($mobile) {
    @include pad(45px 20px)
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 103px;

    @include media($desktop) {
      padding-left: 0;
    }

    @include media($tablet) {
      flex-direction: column;
      gap: 20px 0;
    }
  }

  &-col-left {
    flex-shrink: 0;
    max-width: 468px;
    width: 43%;

    @include media($tablet) {
      max-width: 100%;
      width: 100%;
    }
  }

  &-col-right {
    max-width: 383px;
    width: 40.9%;
    background-color: color(white);
    border-radius: 20px;
    // flex-shrink: 0;
    overflow: hidden;
    @include pad(38px 31px 10px);
    min-height: 361px;

    @include media($desktop) {
      @include pad(38px 15px 10px);
    }

    @include media($tablet) {
      max-width: 100%;
      width: 100%;
    }
  }

  &-heading {
    font-size: rem(24px);
    font-weight: 400;
    margin-bottom: 67px;
    text-transform: capitalize;
    letter-spacing: -0.03em;

    .cart-checkout-personal-detail & {
      margin-bottom: 36px;
    }
  }

  &-subheading {
    font-size: rem(11px);
    color: color(combiz_blue);
    text-transform: uppercase;
    border-bottom: 1px solid color(combiz_blue);
    margin-bottom: 17px;
    font-weight: 400;
    letter-spacing: 0.27em;
  }

  &-choices {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    gap: 0 15px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    background-color: color(combiz_blue);
    border-radius: 10px;
    font-size: rem(18px);
    font-weight: 600;
    color: color(white);
    min-height: 113px;
    @include pad(33px 20px 0);
    letter-spacing: -0.03em;
    text-transform: capitalize;

    &-desc {
      color: color(white);
      font-size: rem(11px);
      font-weight: 400;
      letter-spacing: normal;
    }
  }

  .view-block {
    background-color: color(white);
  }

  &-mobile-btn {
    display: none;
    font-size: rem(12);
    color: $dark_very_grayish_red_3;
    cursor: pointer;
    @include position(absolute, 15px 20px null null);

    @include media($mobile) {
      display: block;
    }
  }

  &-mobile-close {
    display: none;
    cursor: pointer;
    font-size: rem(12);
    font-weight: 500;
    color: $dark_very_grayish_red;
    line-height: 1.25;
    padding-right: 15px;
    background: url('../../images/build/close-dark-2.png') no-repeat right center;
    background-size: 9px auto;
    @include position(absolute, 18px 20px null null);

    @include media($mobile) {
      display: block;
    }
  }

  input[type="text"] {
    border-color: color(combiz_blue);
    border-width: 1px;
  }

  .customer_profile_billing .select2 .select2-selection {
    border-color: color(combiz_blue) !important
  }

  .customer_profile_billing .form-item label {
    color: color(combiz_blue) !important;
  }

  .checkout_terms_and_conditions {
    .terms-link {
      color: color(combiz_blue)
    }
  }

  .iradio,
  .icheckbox {
    border-color: color(combiz_blue) !important
  }
}

.checkout_personal_details_opening {
  background-color: color(combiz_light_gray);
  // position: relative;
  // @include outer-container(1200px);
  // @include pad(70px 0 230px);

  // @include media($large_desktop) {
  //   @include outer-container(960px);
  // }

  // @include media($tablet) {
  //   @include outer-container(680px);
  // }

  // @include media($mobile) {
  //   @include outer-container(100%);
  //   @include pad(44px 20px 40px);
  // }

  // &.checkout_personal_details_opening {
  //   @include pad(85px 0 100px);

  //   section h1 {
  //     margin-bottom: 30px;
  //   }

  //   @include media($tablet) {
  //     @include pad(50px 0);
  //   }

  //   @include media($mobile) {
  //     @include pad(43px 20px);

  //     section h1 {
  //       margin-bottom: 10px;
  //     }
  //   }
  // }

  // .cart-mobile-btn {
  //   display: none;
  //   font-size: rem(12);
  //   color: $dark_very_grayish_red_3;
  //   cursor: pointer;
  //   @include position(absolute, 15px 20px null null);

  //   @include media($mobile) {
  //     display: block;
  //   }
  // }

  &.checkout-signin-signup-pane {

    a:not(.close):not(.terms-link):not(.cart-edit-link):not(.contextual-links-trigger) {
      position: relative;
      @include span-columns(3 of 6);
      background: url('../../images/build/use-modal.png') no-repeat center;
      background-size: cover;
      @include pad(34px 20px);
      z-index: 1;
      font-size: em(18);
      color: $white;
      font-weight: 600;
      line-height: 1.24;
      letter-spacing: -0.53px;
      text-align: left;

      &:before {
        content: '';
        @include size(100%);
        @include position(absolute, 0 0 0 0);
        background: rgba(242, 116, 58, 0.93);
        z-index: -1;
      }

      span {
        display: block;
        text-transform: lowercase;
        margin-top: 5px;
        font-size: em(11);
        font-weight: normal;
      }
    }

    @include media($mobile) {

      &>div {
        text-align: center;
      }

      a:not(.close):not(.terms-link):not(.cart-edit-link) {
        width: 205px;
        display: block;
        float: none;
        margin: 0 auto 20px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .checkout-personal-details-pane.offline {

    .checkout_personal_details_middle,
    .commerce_payment {
      margin: 0;
    }
  }

  // section {
  //   @include span-columns(6);

  //   h1 {
  //     font-size: em(24);
  //     margin-bottom: 67px;
  //     line-height: 1.25;
  //     color: $dark_grayish_red;
  //   }

  //   @include media($tablet) {
  //     @include span-columns(6.5);
  //   }

  //   @include media($mobile) {
  //     width: 100%;
  //     margin-right: 0;

  //     h1 {
  //       font-size: em(20);
  //       margin-bottom: 39px;
  //     }
  //   }
  // }

  // aside {
  //   @include span-columns(4);
  //   @include shift(2);
  //   @include omega();
  //   border-top: 2px solid $orange_bright;
  //   @include pad(15px 15px 23px);

  //   .cart-mobile-close {
  //     display: none;
  //     cursor: pointer;
  //   }

  //   @include media($tablet) {
  //     @include span-columns(5);
  //     @include shift(0.5);
  //   }

  //   @include media($mobile) {
  //     display: none;
  //     @include position(absolute, 0 null null 0);
  //     @include size(100%);
  //     margin-left: 0;
  //     background: rgba(251, 249, 248, 0.96);
  //     z-index: 1;
  //     @include pad(54px 20px);

  //     .cart-mobile-close {
  //       display: block;
  //       font-size: rem(12);
  //       font-weight: 500;
  //       color: $dark_very_grayish_red;
  //       line-height: 1.25;
  //       padding-right: 15px;
  //       background: url('../../images/build/close-dark-2.png') no-repeat right center;
  //       background-size: 9px auto;
  //       @include position(absolute, 18px 20px null null);
  //     }
  //   }
  // }

  // h4 {
  //   font-size: em(11);
  //   font-weight: normal;
  //   letter-spacing: 2.8px;
  //   line-height: 1.1;
  //   color: $dark_very_grayish_red_3;
  //   margin-bottom: 17px;
  //   padding-bottom: 3px;
  //   text-transform: uppercase;
  //   border-bottom: 1px solid $gray_light;

  //   @include media($mobile) {
  //     font-size: rem(10);
  //     margin-bottom: 15px;
  //   }
  // }

  .checkout_personal_details_middle {
    margin-top: 30px;
  }

  .customer_profile_billing,
  .commerce_payment {
    @include clearfix();

    &.commerce_payment {
      margin: 17px 0 3px;

      .select2-selection__rendered {
        text-align: center;
        line-height: 30px;
      }

      .form-item-commerce-payment-payment-details-credit-card-owner,
      .form-item-commerce-payment-payment-details-credit-card-number {
        margin-bottom: 18px;
      }

      .commerce-credit-card-expiration,
      .form-item-commerce-payment-payment-details-credit-card-code {
        @include span-columns(3 of 6);
        @include omega(2n);
        margin-bottom: 25px;

        &.commerce-credit-card-expiration {

          .form-item {
            display: inline-block;
            margin-bottom: 0;
            float: none;
            width: calc((100% / 2) - 17.5px);
          }

          .commerce-month-year-divider {
            display: inline-block;
            vertical-align: middle;
            margin: 0 7px 0 5px;
          }

          @include media($mobile) {
            width: 100%;
            float: none;

            .form-item {
              width: calc((100% / 2) - 14px) !important;
            }

            .commerce-month-year-divider {
              display: inline-block;
            }
          }
        }
      }

      .form-item-commerce-payment-payment-details-credit-card-cardonfile-store,
      .form-item-commerce-payment-payment-details-cardonfile-instance-default {
        @include clearfix();
        margin-bottom: 6px;

        input,
        label {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .form-item-commerce-payment-payment-details-cardonfile label {
        display: none;
      }
    }

    .form-item {
      margin: 0 0 17px;

      label {
        padding-left: 10px;
        font-size: em(11);
        color: $dark_very_grayish_red_2;
        font-weight: normal;
        line-height: 1.45;

        &.option {
          margin-top: 3px;
          padding-left: 5px;
          font-size: em(14);
          line-height: 1.3;
          display: inline-block;
          vertical-align: middle;
          color: $dark_very_grayish_red_2;

          @include media($mobile) {
            width: calc(100% - 25px);
            font-size: rem(13);
          }
        }
      }
    }

    .form-wrapper,
    .form-type-select {
      float: left;
      width: 100%;
      margin: 0 0 17px;
    }

    .field-type-text,
    .field-type-email,
    .form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code {
      @include span-columns(3 of 6);

      &.field-name-field-last-name,
      &.field-name-field-company-no-,
      &.field-name-field-contact-number {
        float: right;
        margin-right: 0;
      }
    }

    .select2 {
      width: 100% !important;

      .select2-selection {
        height: 38px;
        @include pad(5px);
        font-size: em(12);
        border-color: $gray_grayish_violet;
      }

      .select2-selection__arrow {
        top: 0;
        bottom: 0;
        right: 5px;
        margin: auto;
      }
    }

    #customer-profile-billing-commerce-customer-address-add-more-wrapper {

      .form-wrapper {
        margin-bottom: 0;
      }

      .form-item {
        margin-bottom: 17px;
      }
    }

    .field-type-list-boolean {
      margin-bottom: 0;
    }

    @include media($mobile) {

      .form-wrapper {
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 0;
      }

      .form-item {
        float: none;
        margin-bottom: 15px;
        width: 100% !important;
      }

      .select2-container {
        left: inherit !important;
      }
    }
  }

  .addressfield-container {

    .form-item:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .commerce_payment {
    @include clearfix();

    #edit-commerce-payment-payment-method {
      display: none;
    }
  }

  .checkout-buttons {
    border: 0;
    padding: 0;
    text-align: center;

    span,
    .checkout-back {
      display: none;
    }
  }

  .checkout-continue {
    background: $orange_bright_2;
    color: $white;

    &:hover {
      background: $white;
      color: $orange_bright_2;
    }
  }

  .checkout_terms_and_conditions {
    margin: 0 0 57px;

    a {
      display: inline-block;
      vertical-align: middle;
      font-size: rem(14);
      line-height: 1;
      padding-left: 5px;
      text-decoration: underline;
      color: $dark_very_grayish_red_2;
    }

    @include media($mobile) {
      margin: 30px 0;

      a {
        font-size: rem(13);
      }
    }
  }

  .icheckbox,
  .iradio {
    @include size(16px);
    border: 1px solid $dark_very_grayish_red_3;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &.checked {
      background: url('../../images/build/check_icon.png') no-repeat center;
      background-size: 10.5px auto;
    }
  }
}

.view-shopping-cart-checkout-form {

  .views-table {
    margin: 0;
  }

  thead th {
    @include pad(0 0 5px);
    text-transform: lowercase;
    font-size: em(11);
    color: $dark_very_grayish_red_2;
    line-height: 1.45;

    &.views-field-commerce-total {
      display: none;
    }
  }

  tbody {

    tr {
      @include pad(0);

      &:last-of-type {
        border-bottom: 0;
      }
    }

    td {
      @include pad(16px 0 32px);
    }

    h3 {
      font-size: em(13);
      font-weight: 500;
      line-height: 1.31;
      letter-spacing: -0.4px;
      margin-bottom: 3px;
    }

    .ticket-name {
      font-size: em(11);
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }

    .ticket-price {
      margin-top: 15px;
      color: $dark_very_grayish_red_3;
      font-weight: 500;
      font-size: 13px;
      line-height: 1.31;
    }

    .views-field-commerce-total {
      display: none;
    }
  }

  .line-item-summary {
    margin: 23px 0 0;
    @include clearfix();

    .links {
      margin: 5px 16px 0 0;
      font-size: em(12);
      text-decoration: underline;
      text-transform: lowercase;
      line-height: 1.25;
      color: $dark_very_grayish_red;

      li {
        padding-left: 0;
      }
    }

    .links,
    .line-item-total {
      float: right;
    }
  }

  .line-item-total-label,
  .line-item-total-raw {
    font-size: em(16);
    line-height: 1.56;
    letter-spacing: -0.38px;
    color: $dark_grayish_red;
    font-weight: 600;
    text-transform: lowercase;
  }

  .cart-edit-link {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    font-size: em(12);
    line-height: 1.25;
    text-decoration: underline;
  }

  .form-actions {
    display: none;
  }

  @include media($mobile) {
    text-align: left;

    thead th {
      padding-bottom: 1px;
    }

    tbody {

      td {
        text-align: left;
        @include pad(13.5px 0 31px);
      }

      h3 {
        font-size: rem(14);
        margin-bottom: 0;
      }
    }

    .line-item-summary {
      margin: 25px 0 0;

      .links {
        margin-right: 18px;
      }
    }
  }
}

#commerce-checkout-form-login {
  background-color: color(combiz_light_gray);

  .checkout-buttons {
    display: none;
  }

  .cart-checkout-inner {
    @include media($mobile) {
      display: block;

      .cart-checkout-col-left {
        width: inherit;
      }

      .cart-checkout-col-right {
        width: inherit;
      }
    }
  }

  .checkout-signin-signup-pane-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 17px;

    @include media($mobile) {
      display: block;
    }

    a {
      background: color(combiz_blue);
      border-radius: rem(10);
      color: $white;
      display: block;
      font-weight: 600;
      font-size: rem(18);
      letter-spacing: -0.526829px;
      line-height: 1.28;
      opacity: 0.93;
      padding: 34px 36px 34px 20px;
      mix-blend-mode: normal;
      // width: rem(225);
      height: rem(113);

      span {
        display: block;
        font-weight: 400;
        font-size: rem(11);
        line-height: 1;
        margin-top: rem(5);
      }

      &:first-of-type {
        // margin-right: rem(16);
      }

      @include media($mobile) {
        margin-right: 0;
        margin-bottom: rem(16);
        width: 100%;
      }
    }
  }
}

.page-checkout.not-logged-in {

  #modalContent,
  #modalBackdrop {
    display: none;
    opacity: 0;
  }

  .checkout-signin-signup-pane {
    h1 {
      color: color(dark_grayish_red);
      font-size: rem(24);
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: -0.7px;
      margin-bottom: rem(64);

      @include media($mobile) {
        font-size: rem(18);
        margin-bottom: rem(32);
      }
    }

    .credentials-form {
      max-width: rem(433);

      form#user-register-form,
      form[id^='user-register-form'],
      form[id*='user-register-form'] {
        >div {
          display: grid;
          grid-template-columns: 1fr 1fr;

          @include media($mobile) {
            grid-template-columns: 1fr;
          }

          .form-wrapper,
          .form-item {
            max-width: rem(186);

            @include media($mobile) {
              max-width: 100%;
            }
          }

          #edit-field-first-name,
          .form-item-mail,
          #edit-field-company-name,
          .form-item-pass-pass1 {
            margin-right: rem(16px);

            @include media($mobile) {
              margin-right: 0;
            }
          }

          div[id^='edit-account'],
          div[id*='edit-account'] {
            display: none;
          }
        }
      }
    }
  }
}

#modalContent {
  top: 50% !important;
  left: 0 !important;
  right: 0;
  margin: auto;
  @include transform(translateY(-50%));

  @include media($mobile) {
    top: 0 !important;
    @include transform(translateY(0));
  }

  .ctools-modal-content {
    position: relative;
    margin: auto;
    border: 0;
    width: 750px !important;

    @include media($tablet) {
      width: 600px !important;
    }

    @include media($mobile) {
      width: 100% !important;
    }
  }

  .modal-content {
    overflow: hidden;
    width: 100% !important;
    z-index: 0;
    @include pad(18px 77px 70px 287px);

    &:after {
      content: '';
      @include size(2500px);
      background: $white;
      border-radius: 50%;
      @include position(absolute, 0 null 0 185px);
      margin: auto;
      z-index: -1;
    }

    [id^='user-login'] {

      .form-item-name {
        margin: 80px 0 32px;
      }

      .form-type-password {
        margin: 0 0 72px;
      }

      label {
        font-size: em(12);
        font-weight: normal;
        line-height: 1.25;
        padding-left: 12px;
        color: $dark_very_grayish_red;
      }

      .description,
      .form-required {
        display: none;
      }

      .form-actions {
        text-align: center;
      }
    }

    *[id^='user-register-form'] {
      @include clearfix();

      *[id^='edit-account'] {
        margin-top: 30px;
      }

      .form-item {
        margin: 0;
      }

      .form-wrapper,
      .form-type-password-confirm {
        @include clearfix();
      }

      .field-type-text,
      .form-type-password,
      .form-type-textfield {
        width: calc(50% - 18px);
        margin: 0 0 20px;
        float: left;
        clear: none;

        &:nth-child(2n) {
          float: right;
        }
      }

      .field-type-text {
        margin: 0;

        .form-item {
          width: 100%;
        }

        &.field-name-field-company-name {
          float: left;
        }
      }

      label {
        font-size: em(12);
        font-weight: normal;
        line-height: 1.25;
        padding-left: 12px;
        color: $dark_very_grayish_red;
      }

      .description,
      .form-required,
      .password-strength,
      .password-confirm:not(.form-text) {
        display: none;
      }

      .form-actions {
        @include clearfix();
        width: 100%;
        float: left;
        text-align: center;
      }
    }

    @include media($tablet) {
      @include pad(18px 45px 70px 195px);

      &:after {
        left: 125px;
      }
    }

    @include media($mobile) {
      @include pad(20px);

      &:after {
        content: none;
      }

      .ajax-register-links {
        width: 100%;
        display: block;
        text-align: left;

        li {
          padding-left: 0;
          margin-left: 0;
          width: 49.3%;
          display: inline-block;
          font-size: rem(11);
        }
      }

      [id^='user-login'] {

        .form-item-name {
          margin: 25px 0 15px;
        }

        .form-type-password {
          margin: 0 0 33px;
        }

        label {
          font-size: rem(11);
          padding-left: 9px;
        }
      }

      *[id^='user-register-form'] {

        *[id^='edit-account'] {
          margin-top: 25px;
        }

        .field-type-text,
        .form-item {
          width: 100%;
          margin: 0 0 15px;

          .form-type-textfield {
            width: 100%;
            margin-bottom: 0;
          }
        }

        label {
          font-size: rem(11);
          padding-left: 10px;
        }

        .form-actions {
          @include clearfix();
          width: 100%;
          float: left;
          text-align: center;
        }
      }
    }
  }
}

.modal-header {
  padding: 0;
  background: url('../../images/build/login-bg.jpg') no-repeat left center;
  background-size: cover;
  @include position(absolute, 0 null null 0);
  @include size(230px 100%);

  &:before {
    content: '';
    background: rgba(242, 116, 58, 0.92);
    @include position(absolute, 0 0 0 0);
    @include size(100%);
  }

  img,
  .modal-title {
    display: none;
  }

  .close {
    margin: 0;
    background: url('../../images/build/close.png') no-repeat center;
    background-size: cover;
    @include size(10px);
    @include position(absolute, 0 -540px null null);
  }

  @include media($tablet) {
    @include size(150px 100%);

    .close {
      right: -470px;
    }
  }

  @include media($mobile) {
    overflow: hidden;
    @include size(100% 50px);
    @include position(relative, inherit);

    &:after {
      content: '';
      @include size(550vw);
      background: $white;
      border-radius: 50%;
      @include position(absolute, 30px null null 50%);
      @include transform(translateX(-50%));
      margin: auto;
    }

    .close {
      top: 10px;
      bottom: inherit;
      right: inherit;
      left: 10px;
      margin: auto;
    }
  }
}
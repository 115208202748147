.focus-area-page {

  main {
    // background: url('../../images/build/bubble.png') repeat-y;
    background-size: 100% auto;

    .container {
      @include pad(124px 0 82px);
    }

    .paragraph {
      @include clearfix();
      padding: 0;
      margin-bottom: 100px;

      &:last-of-type {
        margin: 0;
      }

      h2 {
        color: color(combiz_blue);
      }

      h3 {
        margin-top: 45px;
        font-size: em(17);
        font-weight: 700;
        color: color(combiz_blue);
      }

      .text {
        color: color(combiz_blue);

        p {
          color: inherit;
        }
      }

      .paragraph-list {
        margin: 13px 0 0;
        padding: 0;

        li {
          position: relative;
          list-style: none;
          padding-left: 17px;
          margin-bottom: 15px;
          border: 0;

          &:before {
            content: '';
            @include size(4px);
            background: $gray_grayish_blue_2;
            border-radius: 50%;
            @include position(absolute, 7px null null 0);
          }

          h3 {
            font-size: 14px;
            line-height: 1.7;
            margin: 0;
          }
        }
      }
    }

    .text-wrap {
      // @include span-columns(6.5);
    }

    .image-wrap {
      margin-top: 80px;
      position: relative;
      @include shift(2.5);
      @include span-columns(3);
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      box-shadow: inset 0 0 0 25px rgba(250, 248, 247, 0.54);

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        z-index: -1;
      }

      &:after {
        content: '';
        @include position(absolute, -5px 10px null null);
        @include size(63px);
        background: rgba(251, 118, 63, 0.85);
        border-radius: 50%;
      }
    }

    strong {
      font-size: rem(15);
      font-weight: 500;
      line-height: 1.73;
      letter-spacing: -0.44px;
    }

    @include media($large_desktop) {

      .image-wrap {
        @include shift(1.5);
        @include span-columns(4);
      }
    }

    @include media($tablet) {

      .container {
        @include pad(60px 0);
      }
    }

    @include media($mobile) {

      .container {
        @include pad(25px 20px 44px);
      }

      .paragraph {
        margin-bottom: 45px;

        h2 {
          margin-bottom: 15px;
        }

        h3 {
          margin: 24px 0 15px;
        }

        .paragraph-list {
          margin: 15px 0 0;

          li {

            h3 {
              font-size: 13px;
            }
          }
        }
      }

      .text-wrap {
        float: none;
        width: 100%;
      }

      .image-wrap {
        max-width: 205px;
        width: 80%;
        float: none;
        margin: 45px auto 0 !important;
        box-shadow: inset 0 0 0 15px rgba(250, 248, 247, 0.54);

        &:after {
          top: 0;
          right: 20px;
          @include size(40.5px);
        }
      }
    }
  }

  .focus-area-latest-wrap {
    @include pad(100px 0 72px);
    background: $gary_light_orange;

    h2 {
      line-height: 1.2;
      margin-bottom: 41px;
      letter-spacing: -1.22px;
    }

    @include media($tablet) {
      @include pad(60px 0);

      h2 {
        margin-bottom: 25px;
      }

      .card {
        @include span-columns(6);
      }
    }

    @include media($mobile) {
      @include pad(40px 20px);

      h2 {
        margin-bottom: 15px;
      }

      .card {
        width: 100%;
        float: none;
        margin-right: 0;
      }
    }
  }
}

.focus-area {

  &-main {
    background-color: color(white);

  }

  &-inner {
    @include pad(59px 0 45px);

    @include media($tablet) {
      @include pad(24px 48px 12px);
    }

    @include media($mobile) {
      @include pad(24px 0 12px);
    }
  }

  &-latest {
    background-color: color(combiz_light_gray);
  }

  &-latest-inner {
    @include pad(44px 0 72px);

    @include media($tablet) {
      @include pad(17px 0 72px);
    }
  }

  &-latest-title {
    margin-bottom: 37px;

    @include media($tablet) {
      margin-bottom: 25px;
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 35px;

    @include media($desktop) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    @include media($tablet) {
      margin-bottom: rem(124);

      .card-content {
        height: initial;
      }

      .slick-dots {
        bottom: rem(-60);

        button::before {
          content: '';
        }
      }
    }

    .card {
      @include media($tablet) {
        width: 274px;
        margin: 0 auto;
      }
    }
  }
}
.eye-catch {
  background-color: color(combiz_blue);

  &-inner {
    text-align: center;
    min-height: 325px;
    @include pad(20px 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media($tablet) {
      align-items: flex-start;
    }
  }

  &-title {
    color: color(white);
    font-size: rem(48.83px);
    font-weight: 700;
    margin-bottom: 7px;
    line-height: normal;
    letter-spacing: normal;

    @include media($tablet) {
      font-size: rem(28);
      line-height: 1.29;
      text-align: left;
    }
  }

  &-desc {
    max-width: 859px;
    width: 100%;
    color: color(white);
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.006em;

    @include media($tablet) {
      font-size: rem(18);
      line-height: 1.11;
      text-align: left;
    }

    p {
      color: inherit;
      font: inherit;
      letter-spacing: inherit;
      margin-bottom: 0;
    }
  }
}
.testimonials-slider-wrap {
  position: relative;
}

.testimonials-slider {
  margin: 50px 0;

  .slick-dots {
    bottom: -35px;

    li {
      @include size(6px);
      border-radius: 50%;
      background: $gray_grayish_violet;
      margin: 0 4px;
      @include transition(all 333ms linear);

      &.slick-active {
        @include size(7px);
        background: $orange_bright;
      }

      button {
        display: none;
      }
    }
  }

  @include media($mobile) {
    margin: 25px 0;
  }

  .landing-slide-header {
    display: none;
  }

}

.testimonials-slider-prev, .testimonials-slider-next {
  background: $white;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  border: 0;
  padding: 6px 0;
  text-align: center;
  outline: none;
  @include size(42px);

  svg {
    @include position(absolute, 0 0 0 0);
    margin: auto;
  }

  @include media($mobile) {
    @include size(30px);
  }
}

.testimonials-slider-prev {
  left: 20px;
}

.testimonials-slider-next {
  right: 20px;
  transform: rotate(180deg);
}

.testimonials-slide {
  padding: 40px 75px;
  background: $gary_light_orange;

  h3 {
    font-size: rem(17);
    font-weight: 600;
    margin-bottom: 5px;
  }

  .text {
    font-size: rem(12);
    margin-bottom: 20px;
  }

  .text-full {
    display: none;
  }
}

.testimonials-single-header {
  font-size: rem(10);
  line-height: 1.4;
  color: $dark_very_grayish_red_3;
  margin-bottom: 9px;
  display: none;
}
.card-past {
  background-color: color(white);
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
  transition: 0.3s translate ease-out;
  display: block;

  &:hover {
    translate: 0 -10px;

    .card-past-image {
      scale: 1;
    }
  }

  &-figure {
    height: 178px;
    overflow: hidden;
  }

  &-image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    scale: 1.1;
    transition: 0.3s scale ease-out;

    .node-type-events-landing-page &,
    .view-past-events & {
      filter: grayscale(1);
    }
  }

  &-content {
    @include pad(11px 10px 38px)
  }

  &-date {
    color: color(combiz_blue);
    font-size: rem(16);
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }

  &-title {
    color: #475254;
    font-size: rem(22px);
    font-weight: 500;
    margin-bottom: 11px;
    letter-spacing: normal;
    line-height: 1.35;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }

  &-category {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid color(gray_light_500);
    text-transform: uppercase;
    @include pad(0);
    text-align: center;
    font-size: rem(15px);
    font-weight: 700;
    line-height: 1.4;
    // width: 120px;
    color: rgba(color(gray_light_500), 1);

    &.is-disabled {
      opacity: 0.1;
    }
  }
}
.card-campaign {
  cursor: pointer;
  position: relative;

  // @include clearfix();
  // @include omega(2n);
  // @include span-columns(6);
  // margin-bottom: 50px;

  // &:before {
  //   content: '';
  //   @include size(196px);
  //   background: rgba(0, 0, 0, 0.12);
  //   @include filter(blur(42px));
  //   @include position(absolute, null null -27px 116px);
  // }

  // &:hover {

  //   .container {
  //     box-shadow: 0 50px 40px 0 rgba(0, 0, 0, 0.12);
  //   }

  //   .image {
  //     @include transform(scale(1.1));
  //   }
  // }

  .container {
    @include transition(all 333ms ease-out);
  }

  .image_wrapper {
    float: left;
    width: 180px;
    overflow: hidden;
    position: relative;

    .image {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include transition(all 333ms ease-out);

      &:after {
        content: '';
        @include position(absolute, 0 null null 0);
        @include transition(all 333ms linear);
        @include size(100%);
        background-image: linear-gradient(0deg, rgba(242, 116, 58, 0.89) 42%, rgba(255, 134, 81, 0.56) 100%);
      }

      &.no-bg {
        background: url('../../images/build/campaign-bg.jpg') no-repeat center;
        background-size: cover;

        &:after {
          content: none;
        }
      }
    }

    .logo {
      width: 65px;
      @include position(absolute, 50% 0 null 0);
      @include transform(translateY(-50%));
      margin: auto;
      z-index: 1;
      @include transition(all 333ms ease-out);

      img {
        width: 100%;
      }
    }

    @include media($desktop) {
      width: 160px;
    }

    @include media($tablet) {
      width: 87px;

      .logo {
        width: 50px;
      }
    }

    @include media($mobile) {
      width: 30%;
    }
  }

  .content {
    position: relative;
    float: left;
    overflow: hidden;
    background: $white;
    width: calc(100% - 180px);
    @include pad(28px 30px 60px 28px);

    .focus {
      font-size: em(10);
      text-transform: uppercase;
      margin-bottom: 3px;
      line-height: 1.4;
      letter-spacing: 1.6px;
      color: $dark_very_grayish_red_3;
    }

    .title {
      font-size: em(16);
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: -0.47px;
      color: $color__main;
      margin-bottom: 10px;
    }

    .summary {
      font-size: em(12);
      line-height: 1.58;
      letter-spacing: -0.3px;
      color: $dark_very_grayish_red_2;
      margin-bottom: 40px;
      margin-top: -20px;
    }

    .primary-call-action {
      font-size: em(12);
      line-height: 1.16;
      letter-spacing: -0.38px;
      font-weight: 500;
      @include pad(7px 13px);
      @include position(absolute, null null 20px 28px);
    }

    @include media($desktop) {
      width: calc(100% - 160px);
    }

    @include media($tablet) {
      width: calc(100% - 87px);
    }

    @include media($mobile) {
      width: calc(100% - 30%);
      @include pad(16px 18px 23px);

      .title {
        font-size: rem(15);
      }

      .summary {
        font-size: rem(11);
        margin-bottom: 50px;
      }

      .primary-call-action {
        left: 18px;
      }
    }
  }

  @include media($mobile) {
    width: 100%;
    margin-right: 0;
  }
}

.card-campaign {
  display: block;

  &-inner {
    display: flex;
    min-height: 490px;
    border-radius: 20px;
    overflow: hidden;
    align-items: stretch;

    @include media($mobile) {
      border-radius: 10px;
      min-height: 475px;
    }
  }

  &-left {
    max-width: 666px;
    width: 100%;
    // flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/build/rainbow.jpg');
    @include pad(33px 39px 63px);
    position: relative;
    z-index: 1;
    min-height: 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(color(dark_grayish_red), 0.5);
      z-index: -1;
    }

    @include media($desktop) {
      max-width: 690px;
      @include pad(33px 20px 63px);
    }

    @include media($mobile) {
      max-width: 100%;
      flex-grow: 1;
      width: 55%;
      @include pad(12px 13px 32px);
    }

  }

  &-heading {
    color: color(white);
    font-size: rem(35px);
    line-height: 1.3;
    letter-spacing: normal;
    margin-bottom: 0;

    @include media($tablet) {
      font-size: rem(18);
      line-height: 1.22;
    }
  }

  &-desc {
    font-size: rem(20px);
    line-height: normal;
    letter-spacing: normal;
    color: color(white);
    margin-bottom: 0;

    @include media($tablet) {
      font-size: rem(12);
    }
  }

  &-right {
    flex-grow: 1;
    background-color: color(white);
    @include pad(24px 71px);

    @include media($desktop) {
      @include pad(24px 25px);
      min-width: 300px;
    }

    @include media($mobile) {
      @include pad(16px 9px);
      min-width: auto;
      width: 48%;
    }

  }

  &-label {
    font-size: rem(18px);
    display: block;
    font-weight: 700;
    color: color(combiz_blue);
    margin-bottom: 10px;

    @include media($tablet) {
      font-size: rem(14);
    }

    @include media($mobile) {
      margin-bottom: 12px;
    }
  }

  &-initiatives {
    margin-bottom: 18px;
    max-width: 392px;
    width: 100%;
    overflow: hidden;
    margin-top: 20px;

    p {
      margin-bottom: 5px;
    }

    .summary{
      ul, ol{


        li{
          position: relative;
          padding-left: 20px;
          font-size: 16px;

          &:not(:last-child){
            margin-bottom: 14px;
          }

          &:before{
            content: '';
            background: #10487a;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0;
            top: 8px;
            border-radius: 4px;
          }

          a:last-child{
            color: color(combiz_blue);
            font-weight: 600;
          }
        }
      }
    }
  }

  &-item {
    &:not(:last-of-type) {
      margin-bottom: 13px;
    }
  }

  &-title {
    font-size: rem(16px);
    margin-bottom: 4px;

    @include media($tablet) {
      font-size: rem(14);
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0;
    }
  }

  &-par {
    font-size: rem(12.8px);
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: -0.005em;

    @include media($tablet) {
      font-size: rem(10);
      line-height: 1.4;
    }
  }

  &-cta {
    font-size: rem(16px);
    @include pad(7px 23px 7px 15px);
    @include caret(color(white));
    font-weight: 400;

    @include media($tablet) {
      font-size: rem(13);
      font-weight: 300;
    }

    &::after {
      margin-left: 8px;
    }
  }
}

.node-networks-landing {

  .our-networks {
    // background: $gary_light_orange;
    // @include pad(91px 0);

    .networks-landing-intro {
      @include span-columns(6);

      .text-wrap {
        max-width: 85%;
      }
    }

    .networks-list {
      overflow: hidden;
    }


    .link-wrap {
      margin-top: 60px;
    }
  }

  .our-networks-member {
    @include pad(69px 0 70px);

    .text-wrap {
      @include span-columns(6.5);
    }

    .text {
      margin-bottom: 25px;
    }

    @include media($mobile) {
      @include pad(40px 20px 45px);

      .text-wrap {
        width: 100%;
        float: none;
      }

      .text {
        margin-bottom: 15px;
      }
    }
  }
}

.networks-landing {
  background-color: color(gray_lighter_blue);

  &-networks {
    @include pad(63px 0 103px);

    @include media($desktop) {
      @include pad(24px 0);
    }
  }

  .featured-cards {

    &.count-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.count-2 {
      grid-template-columns: repeat(2, 1fr);
      max-width: 600px;
    }

    &.count-1 {
      grid-template-columns: 1fr;
      max-width: 300px;
    }
  }

  &-inner {
    // padding-right: 86px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-right: 86px;

    @include media($desktop) {
      padding-right: 0;
      margin-bottom: 13px;
    }

    @include media($tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-title {
    line-height: 1.3;

    @include media($tablet) {
      font-size: rem(28);
      margin-bottom: rem(16);
    }
  }

  &-text {
    max-width: 603px;
    width: 100%;

    p {
      color: color(combiz_black);
      line-height: 1.53;
      font-size: rem(15px);

      &:not(:last-of-type) {
        color: color(combiz_blue);
        margin-bottom: 26px;
      }

      strong {
        font-weight: 900;
        font-size: rem(20px);
        line-height: 1.5;
      }

      @include media($tablet) {
        font-size: rem(12);
        line-height: 1.5;

        &:not(:last-of-type) {
          margin-bottom: rem(8);
        }

        strong {
          font-size: rem(12);
          line-height: 1.5;
        }
      }
    }
  }

  &-intro {}

  &-member {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../images/build/team.jpg');

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(color(combiz_blue), 0.8);
    }

    &-inner {
      position: relative;
      @include pad(47px 0 35px);
    }

    &-title {
      color: color(white);
      margin-bottom: 20px;
    }

    &-text {
      max-width: 883px;
      width: 100%;
      margin-bottom: 43px;

      p {
        font-size: rem(15px);
        letter-spacing: -0.023em;
        color: color(white);
        line-height: normal;
        margin-bottom: 0;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    &-cta {
      font-size: rem(16px);
      @include pad(7px 23px 5px 14px);
      @include caret(color(combiz_blue));
      font-weight: 400;

      &::after {
        margin-left: 8px;
      }
    }
  }

  &-cards {
    display: grid;
    // justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 64px;
    flex-wrap: wrap;
    gap: 28px 90px;

    @include media($desktop) {
      padding-right: 0;
      gap: 0 45px;
      // display: block;
    }

    @include media($tablet) {
      gap: 0 15px;
    }

    @include media($tablet) {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    .card-image-cta {
      position: initial;

      &::before {
        content: "";
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .card-image {
      // width: 524px;
      // flex-shrink: 0;
      position: relative;
      overflow: hidden;
      transition: translate 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;

      &:hover {
        background-color: color(combiz_blue);
        color: #fff;
        translate: 0 -15px;
        box-shadow: 0px 0px 24px rgba(25, 32, 56, 0.08);

        .card-image-title,
        .card-image-desc {
          color: #fff;
        }
      }

      @include media($mobile) {
        width: 100%;

        &-title {
          font-size: rem(18);
          line-height: 1.5;
          // margin-top: rem(16);
        }

        &-desc {
          font-size: rem(10);
          line-height: 1.5;
        }

        &-cta {
          display: block;

          @include media($tablet) {
            @include pad(5px 23px 5px 16px)
          }
        }
      }

      &-links {
        margin-top: 24px;
      }
    }
  }
}

.node-type-partners {}

.partners-body {

  &-inner {
    @include pad(40px 0);
  }

  &-content-main {
    text-align: center;
    max-width: 867px;
    width: 100%;
    margin: 0 auto;

    p {
      font-size: rem(15px);
      font-weight: 500;
      color: color(combiz_blue);
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  &-wrap {
    @include pad(68px 0 68px);
    background-color: color(gray_lighter_blue);

    @include media($tablet) {
      @include pad(32px 0 54px);
    }
  
    &-global {
      background: color(combiz_light_gray);
    }
  }

  &-content {
    h2 {
      color: color(combiz_blue);
      font-size: rem(35px);
      font-weight: 600;
      margin-bottom: 3px;
      letter-spacing: -0.016em;
      line-height: 0.86;

      @include media($tablet) {
        font-size: rem(28);
        line-height: 1.3;
      }
    }

    p {
      font-size: rem(14px);
      margin-bottom: 35px;
      letter-spacing: normal;
      line-height: 1.7;
      max-width: 581px;
      width: 100%;

      @include media($mobile) {
        font-size: rem(12);
        line-height: 1.5;
        color: color(combiz_blue);
      }
    }

    ul {
      background-color: color(white);
      border-radius: 20px;
      @include pad(49px 0 40px 138px);
      column-count: 2;
      
      @include media($tablet) {
        column-count: 1;
        @include pad(24px 16px);
      }
    }

    li {
      font-size: rem(14px);
      letter-spacing: -0.024em;
      position: relative;
      padding-left: 16px;

      &::before {
        position: absolute;
        top: 8px;
        left: 0;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #BBBDBF;
      }

      &:not(:last-of-type) {
        margin-bottom: 14px;
      }
    }

    a {
      text-decoration: underline;
      font-size: inherit;
      letter-spacing: inherit;
    }
  }

  .text {
    color: $dark_grayish_red;
    font-size: rem(17);
    font-weight: 500;
    max-width: 490px;
    padding: 81px 0 89px;

    p {
      margin-bottom: 0;
    }
  }


}

.partners-our-parters {
  padding: 80px 0;

  h2 {
    font-size: rem(24);
    line-height: 1.25;
    margin-bottom: 10px;
  }

  .text {
    color: $dark_very_grayish_red_2;
    font-size: rem(14);
  }

  .partners-wrap {
    @include span-columns(6);
    @include shift(0);
  }

  .partners-list {

    li {
      overflow: hidden;
    }

    .text {
      vertical-align: middle;
      display: inline-block;
      width: calc(100% - 170px);
    }

    .image {
      vertical-align: middle;
      display: inline-block;
      width: 141px;
      text-align: center;
      margin: 0 22px 0 0;
    }

    img {
      zoom: 100%;
      max-width: 100%;
    }
  }

  @include media($tablet) {

    .partners-wrap {
      width: 100%;
    }

    .partners-list {

      li {
        margin-bottom: 0;
      }

      .text {
        vertical-align: top;
        width: calc(100% - 105px);
      }

      .image {
        width: 80px;
        vertical-align: top;
        margin: 0 15px 0 0;
      }
    }
  }

  @include media($mobile) {
    padding: 29px 20px 48px;

    h2 {
      font-size: rem(20);
      margin-bottom: 15px;
    }

    .text {
      font-size: rem(13);
      letter-spacing: -0.34px;
      margin-bottom: 45px;
    }

    .partners-list {

      li {
        min-height: 65px;
        margin: 0 0 30px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }

      .text {
        width: calc(100% - 100px);
        font-size: rem(11);
        line-height: 1.63;
        margin-bottom: 0;
      }

      .image {
        width: 85px;
        margin: 0 8px 0 0;

        img {
          zoom: 100%;
        }
      }
    }
  }
}

.partners-page-body {
  padding: 100px 0;

  h2 {
    margin: 0 0 15px;
    font-size: rem(24);
    line-height: 1.25;
  }

  h3 {
    font-size: rem(18);
    font-weight: 600;
    margin: 30px 0 15px;
  }

  ul {
    columns: 2;
    margin-bottom: 50px;

    li {
      position: relative;
      font-size: rem(14);
      font-weight: 500;
      color: $dark_very_grayish_red;
      margin-bottom: 8px;
      padding-left: 18px;

      &:before {
        content: '';
        background: $gray_grayish_blue_2;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        @include position(absolute, 9px null null 0);

      }
    }

    a {
      text-decoration: underline;
    }
  }

  p {
    max-width: 410px;
    margin-bottom: 30px;
  }

  @include media($tablet) {
    padding: 80px 0;
  }

  @include media($mobile) {
    padding: 40px 20px;

    h2 {
      margin: 0 0 15px;
      font-size: rem(20);
    }

    h3 {
      font-size: rem(15);
      margin: 0;
    }

    ul {
      columns: 1;
      margin-bottom: 16px;

      li {
        margin-bottom: 8px;
        padding-left: 12px;
      }
    }

    p {
      font-size: rem(14);
      max-width: 100%;
      margin-bottom: 15px;
    }

    .text>div {
      margin-bottom: 40px;

      h3 {
        cursor: pointer;
        padding: 13px 0;
        background: url('../../images/build/profile-arrow_open.svg') no-repeat right center;
        background-size: 12px auto;
        @include transition(all 333ms linear);
      }

      ul {
        display: none;
        margin-bottom: 0;

        &:first-of-type {
          display: block;
        }
      }
    }
  }

  &-desc {}
}
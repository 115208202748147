.card-basic {
  background-color: color(white);
  overflow: hidden;
  border-radius: 20px;

  &-figure {
    height: 178px;
  }

  &-image {
    display: block;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: color(combiz_blue);
  }

  &-content {
    @include pad(11px 10px 38px)
  }

  &-date {
    color: color(combiz_blue);
    font-size: rem(16px);
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }

  &-title {
    color: #475254;
    font-size: rem(22px);
    font-weight: 500;
    margin-bottom: 11px;
    letter-spacing: normal;
    line-height: 1.35;
  }

  &-categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }

  &-category {
    display: block;
    border-radius: 5px;
    border: 1px solid color(gray_light_500);
    text-transform: uppercase;
    @include pad(0);
    text-align: center;
    font-size: rem(15px);
    font-weight: 700;
    line-height: 1.4;
    width: 120px;
    color: rgba(color(gray_light_500), 1);

    &.is-disabled {
      opacity: 0.1;
    }
  }
}
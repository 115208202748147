p {
  font-size: 14px;
  color: $dark_very_grayish_red_2;
  margin-bottom: 15px;
  line-height: 1.71;

  @include media($tablet) {
    font-size: 13px;
    line-height: 1.69;
  }
}

h2 {
  font-size: em(35);
  line-height: 1.2;
  letter-spacing: -1.23px;
  margin-bottom: 15px;

  @include media($tablet) {
    font-size: em(31);
    line-height: 1.35;
    letter-spacing: -1.09px;
    margin-bottom: 10px;
  }

  @include media($mobile) {
    font-size: em(22);
    letter-spacing: -0.77px;
    margin-bottom: 5px;
  }
}

strong {
  font-size: rem(15);
  font-weight: 600;
  line-height: 1.67;

  @include media($tablet) {
    font-size: rem(14);
    line-height: 1.71;
    letter-spacing: -0.41px;
  }
}

.paragraph {
  @include pad(0 0 100px);

  img {
    max-width: 100%;
    height: auto;
  }

  .introduction {
    font-size: rem(20px);
    line-height: 1.74;
    color: color(combiz_blue);
    font-weight: 700;
    margin-bottom: 35px;

    .node-type-campaign-programme & {
      color: color(dark_grayish_red);
    }

    .page-node-1362 & {
      color: color(combiz_blue);
    }

    .events-landing-page & {
      @include media($tablet) {
        font-size: rem(12px);
      }
    }
  }

  &:first-of-type {
    padding-top: 0;
  }

  /*
  ul:not(.slick-dots) {
    margin: 15px 0;
    padding: 0;

    li:not(.orange-list-style .ticket) {
      list-style: none;
      margin-bottom: 8px;
      color: $dark_very_grayish_red;
      line-height: 1.43;
      font-size: em(14);
      letter-spacing: -0.41px;
      font-weight: 500;
      padding-left: 19px;
      background: url('../../images/build/list-dot.png') no-repeat left center;
      background-size: 4px auto;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  */
}

.paragraph-text {

  .primary-call-action {
    margin-top: 30px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  h3 {
    color: $dark_grayish_red;
    margin-top: 40px;
  }

  .links {
    overflow: hidden;
    padding-top: 30px;

    a {
      // float: left;
      // margin-top: 15px;
      background-color: color(combiz_blue);
      @include caret(color(white));
      border: none;
      box-shadow: none;
      font-size: rem(16px);
      font-weight: 400;
      color: color(white);
      padding: 13px 42px 11px 33px;
      letter-spacing: normal;

      &::after {
        margin-left: 4px;
      }

      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }

}

.paragraph-list {
  margin: 30px 0;
  padding: 0;
  list-style-type: none;

  h3 {
    font-size: rem(15);
    font-weight: 500;
    margin-bottom: 3px;
    line-height: 1.73;
  }

  .text {
    font-size: rem(13);
    margin-bottom: 15px;
  }
}

.paragraph-events {

  .card-horizontal-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: 10px 10px;

    @include media($mobile) {
      display: block;
    }
  }

  &-topic {
    min-height: 318px;

    @include media($tablet) {
      max-width: 286px;
      margin: 0 auto;
    }

    .topic-block {
      @include media($mobile) {
        min-height: 318px;
      }
    }
  }

  .secondary-call-action {
    margin-top: 30px;
  }

  @include media($mobile) {

    .secondary-call-action {
      margin-top: 0;
    }
  }

  .slick-list {
    margin-bottom: 15px;
  }

  .slick-dots {
    button::before {
      content: '';
    }
  }
}

.paragraph-members {

  h2 {
    margin-bottom: 25px;
  }

  ul.member-list {
    margin: 25px 0 23px;

    li {
      position: relative;
      @include size(80px);
      margin-right: 20px;
      display: inline-block;

      img {
        width: 100%;
        height: auto;
        @include position(absolute, 50% 0 null 0);
        @include transform(translateY(-50%));
        margin: auto;
      }
    }
  }

  @include media($mobile) {

    h2 {
      margin-bottom: 15px;
    }

    ul.member-list {
      margin: 15px 0;

      li {
        position: relative;
        @include size(80px);
        margin-right: 40px;

        img {
          width: 80px;
          height: auto;
          @include position(absolute, 50% 0 null 0);
          @include transform(translateY(-50%));
          margin: auto;
        }
      }
    }
  }
}

.paragraph-publications {

  h4 {
    font-size: rem(11);
    margin: 26.5px 0 28px;
    text-transform: uppercase;
    font-weight: normal;
    color: $dark_very_grayish_red_3;
    letter-spacing: 2.8px;
  }

  .paragraph-publications-list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 25px;
    }

    .meta {
      font-size: rem(10);
      margin: 0 3px 0 0;
      text-transform: uppercase;
      font-weight: normal;
      color: $dark_very_grayish_red_3;
      letter-spacing: 1.6px;
    }

    .title {
      font-size: rem(14);
      font-weight: 500;
      color: $dark_very_grayish_red;
      margin-bottom: 5px;
      letter-spacing: -0.41px;
      line-height: 1.4;
    }

    .text {
      font-size: rem(13);
      margin-bottom: 10px;

    }
  }

  @include media($mobile) {

    h4 {
      display: none;
    }
  }
}

.paragraph-gallery {
  @include clearfix();
  text-align: center;

  .item {
    position: relative;
    float: left;
    width: calc((100% - 30px) / 3);
    margin: 0 15px 15px 0;
    overflow: hidden;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 70%;
      background: rgba(255, 255, 255, 0.3);
      z-index: 1;
      @include transition(all 333ms linear);
    }

    &:hover {

      &:before {
        background: rgba(255, 255, 255, 0.1);
      }

      .img {
        @include transform(scale(1.1));
      }
    }
  }

  .img {
    @include position(absolute, 0 0 0 0);
    @include size(100%);
    z-index: -1;
    background-size: cover;
    background-position: center;
    @include transition(all 333ms linear);
  }

  .gallery-count {
    font-size: rem(13);
  }

  .open-gallery-link {
    color: $orange_bright_2;
    font-size: rem(14);
    font-weight: 400;
  }

  @include media($mobile) {

    .item {
      width: calc((100% - 15px) / 2);
      margin: 0 15px 15px 0;

      &:nth-of-type(3n) {
        margin-right: 15px;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 500px) {

    .item {
      width: 100%;
      margin: 0 0 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.orange-list-style {
  margin: 0 0 30px;
  border-left: 1px solid $gray_grayish_violet;
  padding: 0 0 0 15px;
  font-size: rem(13);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    width: 3px;
    height: 15px;
    background: $orange_bright_2;
    position: absolute;
    top: 0;
    left: -1.5px;
  }

  @include media($mobile) {
    padding-left: 11px;
    margin-bottom: 36px;

    &:last-of-type {
      margin-bottom: 15px;
    }

    h3 {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }
}

.practical-information {
  background: $gary_light_orange;
  padding: 40px 36px;

  h2 {
    font-size: rem(24);
    margin-top: 0;
  }

  .text {
    font-size: rem(14);
    margin-bottom: 40px;
  }

  h3 {
    font-size: rem(10);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: $spaced-letters;
    margin-bottom: 20px;
  }

  dl {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  dt {
    font-size: rem(10);
    float: left;
    clear: left;
    margin: 0;
    color: $orange_bright_2;
    border-right: 1px solid $orange_bright_2;
    padding-right: 5px;
    line-height: 1;
  }

  dd {
    font-size: rem(13);
    float: left;
    display: inline-block;
    margin-left: 14px;
    margin-bottom: 12px;
    line-height: 1;

    a {
      color: $orange_bright_2;
    }

    &:last-child,
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.paragraph-text .text ul {
  @include bullet-points();
}

#paragraph-4,
#paragraph-6,
#paragraph-8 {
  & ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
  }

  & li {
    display: inline-block;
    @include pad(7px 20px);
    border-radius: 5px;
    border: 1px solid color(combiz_light_gray);
    background-color: color(white);
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }
}

#paragraph-7 {

  & ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 16px;
    padding-right: 94px;

    @include media($tablet) {
      display: block;
      padding-right: 0;

      li {
        margin-bottom: rem(16);
      }
    }

    h3 {
      margin: 0;
    }
  }

  & li {
    padding-left: 0;
    margin-bottom: 0;

    ul {
      display: none !important;
    }

    &::before {
      display: none;
    }

    a {
      display: block;
      color: color(white);
      background-color: color(combiz_blue);
      min-height: 144px;
      border-radius: 10px;
      font-size: rem(22px);
      font-weight: 500;
      line-height: 1.3;
      @include pad(18px 16px);

    }
  }
}

.paragraph.introduction {
  color: $dark_very_grayish_red;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-size: rem(15);
  line-height: 1.8;
}

.paragraph-details {
  margin: 50px 0;
  background: $gary_light_orange;
  padding: 45px 33px;
  overflow: hidden;

  h2 {
    margin-bottom: 20px;
  }

  dl {
    font-size: rem(14);
  }

  dt,
  dd {
    float: left;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  dt {
    clear: both;
    font-weight: 600;
    width: 120px;
  }

  dd {
    font-weight: 500;
    width: calc(100% - 120px);
  }

}

.paragraph-icon-list {

  h2 {
    margin-bottom: 30px;
  }

  img {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  li {
    margin-bottom: 13px;
  }

  ul {
    font-size: rem(15);
    font-weight: 500;
  }
}

.paragraph-activities {

  h3.section-title {
    color: $dark_very_grayish_red;
    font-size: rem(17);
    margin: 30px 0;
  }

}

.paragraph-speakers {

  img {
    @include size(119px);
    display: inline-block;
    vertical-align: middle;
    border-radius: 119px;
  }

  h3 {
    font-size: rem(17);
    font-weight: 600;
    margin-bottom: 3px;
    line-height: 1.3;
    letter-spacing: -0.4px;
    float: left;
  }

  a.fancybox {
    float: right;
    font-size: rem(12);
    border-left: 2px solid $orange_bright;
    padding-left: 8px;
    line-height: 1;
    display: inline-block;
  }

  .event-speakers {
    margin: 0;
    padding: 0;
  }

  .event-speaker {
    position: relative;
    list-style: none;
    @include pad(17px 10px 14px 28px);
    border-bottom: 1px solid $gray_very_light;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  img {
    margin-right: 30px;
  }

  .text-wrap {
    width: calc(100% - 154px);
    display: inline-block;
    vertical-align: middle;
  }

  .event-speaker-job-title {
    clear: both;
    color: $dark_very_grayish_red_2;
    font-size: rem(14);
    line-height: 1.65;
    margin-bottom: 10px;
  }

  .text {
    font-size: rem(12);
    line-height: 1.58;
  }

  @include media($mobile) {

    img {
      @include size(84px);
    }

    h3 {
      font-size: rem(15);
      margin-bottom: 5px;
    }

    .event-speaker {
      position: relative;
      list-style: none;
      @include pad(16px 0);

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .text-wrap {
      width: calc(100% - 100px);
      padding-left: 15px;
    }

    .event-speaker-job-title {
      font-size: rem(12);
      line-height: 1.4;
      margin-bottom: 0;
    }

    .text {
      display: none;
    }
  }

}

.main-with-sidebar>.youtube-container--responsive {
  margin-bottom: 100px;

  @include media($mobile) {
    margin-bottom: 50px;
  }
}
.report-news-page {
  background: $gary_light_orange;

  .articles {
    @include clearfix();
    margin-bottom: 50px;

    .card {
      @include omega(4n);
      @include span-columns(3 of 12);
    }
  }


  @include media($desktop) {

    .articles {

      .card {
        @include omega-reset(4n);
        @include omega(3n);
        @include span-columns(4 of 12);
      }
    }
  }

  @include media($tablet) {

    .articles {

      .card {
        @include omega-reset(3n);
        @include omega(2n);
        @include span-columns(6 of 12);
        margin-bottom: 20px;
      }
    }
  }

  @include media($mobile) {

    .articles {
      padding: 0 20px;

      .card {
        float: none;
        width: 100%;
      }
    }
  }
}

.external-resources {
  clear: both;
  width: 100%;
  background: color(combiz_light_gray);

  h2 {
    // text-align: center;
    // font-size: rem(28);
    // margin-bottom: 35px;
    font-size: rem(35px);
    margin-bottom: 47px;
    text-align: center;

    @include media($mobile) {
      font-size: rem(24);
    }
  }

  &-title {
    margin-bottom: 47px;
    text-align: center;
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
    gap: 18px;
    margin-bottom: 35px;

    @include media($tablet) {
      margin-bottom: rem(90);

      .slick-dots {
        bottom: rem(-50);

        button:before {
          content: '';
        }
      }
    }

    .topic-content {
      min-height: 318px;
    }

    .topic {
      @include media($tablet) {
        max-width: 286px;
        margin: 0 auto;
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;

    @include media($tablet) {
      margin-top: rem(75);
    }

  }

  &-cta {
    @include pad(7px 23px 6px 15px);
    @include caret(color(white));
    font-size: rem(16px);
    font-weight: 400;

    &::after {
      margin-left: 9px;
    }
  }
}

.external-resources-inner {

  @include media($overmobile) {
    column-count: 2;
    column-gap: 1em;
  }

  @include media($overtablet) {
    column-count: 4;
    column-gap: 1em;
  }

}

.external-resource {
  display: inline-block;
  background: $white;
  padding: 30px 20px 15px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;

  .title {
    font-size: rem(15);
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  .date {
    font-size: rem(11);
    text-transform: uppercase;
    color: $dark_very_grayish_red_2;
  }

}

.node-news-publication,
.event-detail {
  .external-resources {
    padding: 50px 0;
  }
}

.events-landing-page {

  .external-resources {
    padding: 50px 0;
  }

  .share-wrapper {
    margin-bottom: 50px;
  }
}

.node-news-publication {
  .main-wrapper {
    padding-bottom: 0;

    @include media($tablet) {
      padding-top: 40px;
    }
  }
}
.research {

  &-inner {
    @include pad(57px 0 75px);

    @include media($tablet) {
      @include pad(22px 0 21px);
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 15px;
    margin-bottom: 28px;

    @include media($tablet) {
      display: block;
    }
  }

  &-title {
    font-size: rem(25px);
    line-height: 1.5;

    @include media($tablet) {
      margin-bottom: 18px;
    }
  }

  &-desc {
    max-width: 794px;
    width: 100%;
    color: color(combiz_black);
    font-size: rem(18);
    margin-bottom: 0;
    line-height: 1.5;

    @include media($tablet) {
      font-size: rem(16);
      font-weight: 500;
    }
  }

  &-cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 4fr 2.8fr;
    grid-template-rows: repeat(3, 207px);
    grid-template-areas:
      "second first"
      "third first"
      "fourth first";
    gap: 20px;
    margin-bottom: 39px;

    @include media($tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 561px repeat(3, 318px);
      grid-template-areas:
        "first"
        "second"
        "third"
        "fourth";
      margin-bottom: 0;
    }

    .topic-heading {
      -webkit-line-clamp: initial;

      @include media($tablet) {
        -webkit-line-clamp: 40;
      }
    }
  }

  &-topic {
    background-color: #f7f7f7;

    &:first-of-type {
      grid-area: first;
    }

    &:nth-of-type(2) {
      grid-area: second;
    }

    &:nth-of-type(3) {
      grid-area: third;
    }

    &:nth-of-type(4) {
      grid-area: fourth;
    }

    .topic-date{
      color: color(combiz_black);
    }
    .topic-heading {
      color: color(combiz_blue);
    }

    .topic-heading {
      font-size: rem(22px);
    }

    .topic-labels {
      // margin-top: 53px;
    }

    &.is-main {

      .topic-date{
        display: none;
      }
      .topic-frame {
        min-height: 302px;

        @include media($tablet) {
          min-height: 206px;
        }
      }

      .topic-content {
        @include pad(18px 37px 13px 23px)
      }

      .topic-labels {
        margin-top: auto;
      }

      .topic-heading {
        font-size: rem(28px);
        line-height: 1.5;

        @include media($tablet) {
          font-size: rem(24px);
        }
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;

    @include media($tablet) {
      display: none
    }
  }

  &-cta {
    @include pad(7px 23px 7px 15px);
    @include caret(color(white));
    font-size: rem(16px);
    font-weight: 400;

    &::after {
      margin-left: 8px;
    }
  }
}

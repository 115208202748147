.node-vacancy {
  background: color(combiz_light_gray);
  padding: 100px 0;

  .wrapper {
    @include media($mobile) {
      @include pad(0);
    }
  }

  .inner {
    background-color: color(white);
    border-radius: 20px;
    @include pad(30px 78px);
    max-width: rem(898);
    
    @include media($mobile) {
      @include pad(24px 16px 48px 16px);
    }
  }

  .departments {
    list-style-type: none;
    font-size: rem(11);
    text-transform: uppercase;
    letter-spacing: $spaced-letters;
    margin: 0 0 5px;
    padding: 0;
  }

  h1 {
    font-size: rem(35px);
    line-height: 1.2;
    letter-spacing: -1.23px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  h2,
  h3 {
    font-size: rem(18px);
    font-weight: 600;
    margin-bottom: 15px;
  }

  .date {
    font-size: rem(11);
    color: $dark_very_grayish_red_2;
    margin-bottom: 15px;
    display: block;
  }

  .summary {
    font-size: rem(15);
    font-weight: 500;
    color: $dark_grayish_red;
    margin-bottom: 50px;
  }

  .text {
    strong {
      font-size: inherit
    }

    ul {
      @include bullet-points();
    }

    li {
      margin-bottom: 8px;
    }

    .cta {
      font-size: rem(16px);
      @include pad(8px 16px);

      &::after {
        content: url('../../images/build/mail.svg');
        display: inline-block;
        width: 14px;
        height: 11px;
        margin-left: 10px;
      }
    }
  }

  @include media($mobile) {
    padding: 35px 20px 50px;

    .departments {
      font-size: rem(10);
      margin: 0 0 8px;
    }

    h1 {
      font-size: rem(24);
      line-height: 1.25;
      margin-bottom: rem(8);
    }

    h2 {
      font-size: rem(15);

      margin-bottom: 8px;
    }

    .summary {
      font-size: rem(12);
      line-height: 1.25;
      margin-bottom: 1.58;
    }

    .text {

      ul {
        font-size: rem(12);
      }

      li {
        margin-bottom: 5px;
      }
    }
  }
}

.page-eform-submit-apply-now {
  // background: url('../../images/build/bubble.png') repeat-y;
  background-size: 100% auto;

  .field-name-field-markup-title p {
    font-size: rem(24);
    font-weight: 700;
    color: $dark_grayish_red;
    margin-bottom: 60px;
  }

  .entity-entityform-type {
    @include outer-container();

    .content {
      padding: 100px 0 50px;
      max-width: 470px;

      h1 {
        font-size: rem(24);
        margin-bottom: 55px;
      }
    }

    .form-item {
      margin: 0 0 32px;
    }

    .field-type-email,
    .field-widget-text-textfield {
      @include span-columns(6);
    }

    .field-name-field-last-name,
    .field-name-field-contact-number {
      @include omega();
    }

    .field-name-field-resume {
      position: relative;

      .dragdropfile-processed {
        outline: none;

        &:before {
          content: none;
        }
      }

      fieldset {
        border: 0;
        padding: 0;
      }

      .fieldset-legend {
        font-size: rem(11);
        color: $dark_very_grayish_red_2;
        margin-left: 7px;
      }

      .fieldset-wrapper {
        position: relative;
        border: 1px solid $gray_grayish_violet;
      }

      .fieldset-description {
        width: 180px;
        font-size: rem(14);
        line-height: 1.45;
        color: $dark_very_grayish_red_2;
        text-transform: uppercase;
        @include position(absolute, null null 60px 33%);
      }

      .form-type-managed-file {
        position: relative;
        margin: 0;
        padding: 50px 0;

        &:before {
          content: '';
          @include position(absolute, 50% null null 55px);
          @include transform(translateY(-50%));
          background: url('../../images/build/submit-plus.png') no-repeat center;
          background-size: 26px auto;
          @include size(26px);
        }

        label {
          display: none;
        }

        input[type="submit"] {
          font-size: rem(12);
          padding: 10px 18px 8px;
          @include position(absolute, 110px 0 null null);
          //opacity: 0;
          //display: none;
        }

        input[type="file"] {
          font-size: rem(12);
          @include position(absolute, 118px null null 0);
          //width: 100%;
          //opacity: 0;
          //@include position(absolute, 0 null null 0);
          //@include size(130px 100%);
          //z-index: -1;
        }
      }

      .description {
        width: 180px;
        font-size: rem(11);
        line-height: 1.45;
        z-index: -1;
        color: $dark_very_grayish_red_2;
        @include position(absolute, 60% null null 33%);
        @include transform(translateY(-50%));

        strong {
          font-size: rem(11);
          font-weight: normal;
        }
      }

      table {
        margin: 0;

        thead,
        .file-icon,
        .tabledrag-handle {
          display: none;
        }

        tr {
          border: 0;
        }

        td {
          padding: 10px 10px 5px;
        }

        .form-managed-file {
          font-size: rem(12);
          color: $gray_dark;
          text-transform: uppercase;

          a {
            color: $gray_dark;
          }
        }

        input[type="submit"] {
          float: right;
          padding: 0;
          font-size: 0;
          border: 0;
          border-radius: 0;
          @include size(14px);
          background: url('../../images/build/close-dark.png') no-repeat center;
          background-size: 8px auto;
        }
      }

      .tabledrag-toggle-weight-wrapper {
        display: none;
      }
    }

    .field-type-entityreference {
      width: 100%;
      clear: both;
      overflow: hidden;
    }

    select,
    .select2 {
      width: 100% !important;
    }

    .form-actions {
      margin: 70px 0 0;
    }

    @include media($desktop) {
      @include outer-container(800px);

      .content {
        padding: 80px 0 50px;
      }
    }

    @include media($tablet) {
      @include outer-container(680px);

      .content {
        padding: 45px 0;
      }

      .field-name-field-resume {

        .dragdropfile-processed {

          &:before {
            content: none;
          }
        }

        .fieldset-description {
          display: none;
        }

        .form-type-managed-file {
          padding: 15px 10px;

          &:before {
            content: none;
          }

          input[type="file"] {
            @include position(relative, inherit);
            font-size: rem(12);
            opacity: 1;
            z-index: 0;
            width: calc(100% - 80px);
            display: inline-block;
          }

          input[type="submit"] {
            @include position(relative, inherit);
            opacity: 1;
            width: 70px;
            float: right;
            display: inline-block;
            padding: 7px 12px 5px;
          }
        }

        .description {
          clear: both;
          width: 100%;
          padding: 15px 0 0;
          @include position(relative, inherit);
          @include transform(translateY(0));
        }
      }

      .form-actions {
        margin: 30px 0 0;
      }
    }

    @include media($mobile) {
      @include outer-container(100%);

      .content {
        padding: 40px 20px;
        max-width: 100%;

        h1 {
          font-size: rem(22);
          margin-bottom: 25px;
        }
      }

      .form-item {
        margin: 0 0 16px;
      }

      .field-type-email,
      .field-widget-text-textfield {
        width: 100%;
        margin-right: 0;
      }

      .select2-container {
        left: 0 !important;
      }
    }
  }
}
.team {
  position: relative;

  @include media($tablet) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-color: rgba(color(combiz_blue), 0.8);
    width: 100%;
    height: 100%;
  }

  &-inner {
    @include pad(46px 0 81px);
    position: relative;
    z-index: 1;

    @include media($tablet) {
      @include pad(38px 0 26px);
    }
  }

  &-title {
    color: color(white);
    margin-bottom: 21px;

    @include media($tablet) {
      margin-bottom: 25px;
      font-size: rem(28);
      line-height: 1.3;
    }
  }

  &-subtitle {
    display: none;
  }

  &-desc {
    color: color(white);
    font-size: rem(15);
    letter-spacing: -0.025em;
    line-height: 1.5;
    max-width: 815px;
    width: 100%;
    margin-bottom: 47px;

    @include media($tablet) {
      margin-bottom: 51px;
      font-size: rem(12);
      line-height: 1.33;
    }
  }

  &-cta {
    font-weight: 400;
    font-size: rem(16px);
    @include caret(color(combiz_blue));
    @include pad(7px 23px 5px 14px);

    @include media($tablet) {
      @include pad(6px 23px 6px 16px);
    }

    &::after {
      margin-left: 8px;
    }
  }

  &-partner {
    .team {
      &::before {
        background-color: rgba(color(combiz_orange), 0.8);
      }
    }
  }
}
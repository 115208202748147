.partners-bean {
  background-color: color(white);

  &-inner {
    display: flex;
    justify-content: space-between;
    @include pad(77px 55px 34px 0);
    gap: 0 20px;

    @include media($tablet) {
      @include pad(36px 0 0 0);
    }

    @include media($mobile) {
      flex-direction: column;
      @include pad(36px 0 0 0);
    }
  }

  &-left {
    max-width: 604px;
    width: 100%;
    // flex-shrink: 0;

    @include media($mobile) {
      margin-bottom: rem(40);
    }
  }

  &-right {
    max-width: 277px;
    width: 100%;
    flex-shrink: 0;
  }

  &-title {
    margin-bottom: 24px;

    @include media($mobile) {
      font-size: rem(28);
      line-height: 1.3;
      margin-bottom: rem(16);
    }
  }

  &-desc {
    margin-bottom: 35px;

    p {
      font-size: rem(15px);
      color: color(combiz_black);
      margin-bottom: 0;
      line-height: 1.55;

      @include media($mobile) {
        font-size: rem(12);
        line-height: 1.5;
        margin-bottom: rem(16);
      }
    }
  }

  &-cta {
    font-size: rem(16px);
    font-weight: 400;
    @include pad(7px 22px 7px 15px);
    @include caret(color(white));

    &::after {
      margin-left: 9px;
    }
  }

  &-label {
    font-weight: 500;
    font-size: rem(15px);
    color: color(combiz_blue);
    text-transform: uppercase;
    margin-bottom: 33px;
  }

  &-list {}

  &-item {
    margin-bottom: 34px;
  }

  &-img {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;

    img {
      display: block;
      width: 50%;
      object-fit: contain;
    }
  }

  &-par {
    font-size: rem(12px);
    line-height: 1.5;
    margin-bottom: 0;
  }
}

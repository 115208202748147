.column {
  background-color: color(white);
  padding-bottom: 60px;

  &-inner {}

  &-cards {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 318px 206px;
    grid-template-areas:
      "first first second third"
      "first first fourth fourth";
    gap: 20px;

    @include media($desktop) {
      grid-template-rows: auto auto auto;
    }

    @include media($tablet) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "first first"
        "second third"
        "fourth fourth";
    }

    @include media($mobile) {
      display: block;

      & > * {
        &:not(:last-child){
          margin-bottom: 20px;
        }

      }
    }

  }

  &-card {
    position: relative;

    &:first-of-type {
      grid-area: first;
    }

    &:nth-of-type(2) {
      grid-area: second;
    }

    &:nth-of-type(3) {
      grid-area: third;
    }

    &:nth-of-type(4) {
      grid-area: fourth;
    }
  }
}

.newsletter-signup-form {
  display: none;
  position: relative;
  max-width: 750px;
  overflow: visible !important;

  iframe {
    display: none;
  }

  .block {
    margin-left: 230px;
    padding: 20px;
  }
  h2 {
    font-size: rem(24);
    font-weight: 700;
    margin-bottom: 5px;
  }

  .mailchimp-signup-subscribe-form-description {
    font-size: rem(14);
    line-height: 1.4;
  }

  .form-item {
    &.form-item-mergevars-FNAME, &.form-item-mergevars-LNAME {
      @include span-columns(6);
      @include omega(2n);
      position: relative;

      label {
        position: relative;
        &::after {
          content:'*';
          color: #f00;
        }
      }

    }

    &.form-item-mergevars-MMERGE5 {

      label {
        position: relative;
        &::after {
          content:'*';
          color: #f00;
        }
      }

    }

    label {
      font-size: rem(11);
      font-weight: 400;
      text-indent: 10px;
    }
  }

  .nl-mailing-list-select {
    border: 1px solid $gray_grayish_violet;
    border-radius: 3px;
    background: #FFF;
    color: $dark_very_grayish_red;
    padding: 7px 13px;
    font-size: 14px;
    width: 100%;
    outline: none;
    transition: all 333ms linear;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url(../../svg/arrow-down.svg) center center no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
    }

  }
  .nl-mailing-list-select-inner {
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    background: $white;
    border: 1px solid $gray_very_light;
    border-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: none;

    input, label {
      display: inline-block;
    }

    .nl-mailing-list-select-list {
      padding: 0 20px 10px;
      max-height: 180px;
      overflow: auto;

      label {
        font-size: rem(14);
      }
    }
    .checkbox-wrap {
      margin-bottom: 7px;
    }
  }

  .form-item-mailing-list {
    position: relative;
    z-index: 2;
    label {
      position: relative;
      //&::after {
      //  content: '*';
      //  color: #f00;
      //  margin-left: 2px;
      //}
    }
  }
  .nl-mailing-list-actions {
    position: relative;
    z-index: 1;
  }
  .nl-mailing-list-select-actions {
    padding: 12px 10px;
    overflow: hidden;
    border-top: 1px solid $gray_very_light;
  }
  .nl-mailing-list-select-done {
    float: right;
  }
  .nl-mailing-list-actions {
    text-align: center;
    padding: 10px 0 0;
  }

  @include media($mobile) {
    .block {
      margin: 0;
    }
  }
  .primary-call-action {
    &:hover {
      color: $orange_bright;
      box-shadow: inset 0 0 0 1px $orange_bright;
    }
  }

  .form-type-checkboxes > label {
    display: none;
  }

  .missing-fields{
    font-size: rem(13);
    font-weight: 400;
    color: rgb(255, 0, 0);
    margin-top: 15px;
    padding-left: 10px;
  }

}

.page-newsletter {
  #content-area {
    .newsletter-signup-form {
      display: block;
      padding: 50px 0;
      max-width: 800px;

      @include media($overmobile) {
        .block {
          padding-left: 50px;
        }
      }
    }
  }
}

.benefits {
  background-color: color(combiz_light_gray);

  &-inner {
    @include pad(39px 0 80px);

    @include media($tablet) {
      @include pad(21px 0 70px);
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @include media($tablet) {
      display: block;
      margin-bottom: 33px;
    }
  }

  &-title {
    font-size: rem(25px);
    margin-bottom: 0;
    white-space: pre-line;
    line-height: 1.5;

    @include media($tablet) {
      margin-bottom: 13px;
    }
  }

  &-desc {
    max-width: 794px;
    width: 100%;
    font-size: rem(18px);
    font-weight: 400;
    color: color(combiz_black);
    line-height: 1.5;
    margin-bottom: 0;

    @include media($tablet) {
      font-size: rem(16px);
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-columns: repeat(auto-fit, clamp(256px, 50%, 472px));
    gap: 52px 142px;
    // max-width: 1086px;
    width: 100%;

    @include media($desktop) {
      gap: 52px 0;
    }

    @include media($mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    .slick-dots {
      bottom: -41px;

      button:before {
        content: '';
      }
    }
  }

  &-card {
    text-align: center;
    @include pad(0 20px);

    @include media($tablet) {
      @include pad(0 15px)
    }
  }

  &-icon {
    display: inline-block;
    width: 100px;
    height: 61px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../images/build/benefits-icon.png');
    margin-bottom: 9px;

    @include media($tablet) {
      height: 25px;
      width: 40px;
      margin-bottom: 1px;
    }
  }

  &-heading {
    color: color(combiz_blue);
    font-size: rem(25px);
    font-weight: 700;
    margin-bottom: 15px;

    @include media($tablet) {
      font-size: rem(18);
    }
  }

  &-par {
    color: color(combiz_black);
    font-size: rem(16px);
    margin-bottom: 38px;
    line-height: 1.5;
    max-width: 406px;
    width: 100%;
    display: inline-block;

    @include media($tablet) {
      font-size: rem(14);
    }
  }

  &-list {}

  &-item {
    display: block;
    font-size: rem(16px);
    color: color(combiz_blue);
    font-weight: 700;

    @include media($tablet) {
      font-size: rem(14);
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.banner-event {
  background-color: color(combiz_blue);

  &-inner {
    max-width: 1101px;
    width: 100%;
    @include pad(80px 0)
  }

  &-sns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 36px;
  }

  &-share {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0 11px;

    .text {
      color: color(white);
      font-size: rem(18px);
      font-weight: 500;
      letter-spacing: -0.035em;
      margin-right: 9px;

      @include media($tablet) {
        margin-bottom: 0;
      }
    }

    a {
      border-color: color(white);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      @include pad(0);
      margin: 0;
      flex-shrink: 0;

      &.share-fb {
        svg {
          width: 5px;
          height: 10px;
        }
      }

      &.share-twitter {
        svg {
          width: 8px;
          height: 7px;
        }
      }

      &.share-email {
        svg {
          width: 7px;
          height: 6px;
        }
      }

      &.share-linkedin {
        svg {
          width: 9px;
          height: 9px;
        }
      }

      .jtico {
        float: none !important;
        position: static !important;
        margin: 0 !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-size: 0 !important;
        width: 100%;
        height: 100%;
      }

      svg,
      g {
        fill: color(white);
      }
    }
  }

  &-content {
    background-color: rgba(color(white), 0.9);
    border-radius: 15px;
    @include pad(23px 25px);
    min-height: 197px;
  }

  &-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

    &::before {
      display: inline-block;
      content: '';
      width: 26px;
      height: 28px;
      background-image: url('../../images/build/icon-calendar.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 7px;

      @include media($tablet) {
        width: 20px;
        height: 20px;
      }
    }

    &.is-podcasts {
      &::before {
        background-image: url('../../images/build/icon-podcast.png');
        width: 30px;
        height: 30px;

        @include media($tablet) {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &-info {
    font-size: rem(31.25px);
    color: color(combiz_blue);
    text-transform: uppercase;
    font-weight: 500;

    @include media($tablet) {
      font-size: rem(18);
    }
  }

  &-category {
    position: relative;

    &::after {
      content: ' |';
      font-size: inherit;
      color: color(combiz_blue);
    }
  }

  &-title {
    margin-bottom: 9px;
    font-size: rem(48.83px);
    letter-spacing: normal;
    color: color(combiz_blue);
    line-height: 1;

    @include media($tablet) {
      font-size: rem(24);
    }
  }

  &-desc {
    font-size: rem(20px);
    color: color(combiz_black);
    margin-bottom: 0;

    @include media($tablet) {
      font-size: rem(16);
    }
  }
}

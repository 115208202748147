.view-search {
  position: relative;

  #edit-search-api-views-fulltext.error {
    border-color: $orange_bright_2;
  }

  @include media($mobile) {

    .btn-back {
      margin: 0 20px;
      width: calc(100% - 40px);
      margin-bottom: 17px;
      display: none;
    }

    // #edit-search-api-views-fulltext {
    //   border: 0 !important;
    //   background: $gary_light_orange;
    //   padding: 20px 45px 16px 20px;
    // }
  }
}

.search-form-wrap {
  position: fixed;
  top: -50px;
  right: 0;
  height: 0;
  left: 0;
  background: rgba(71, 69, 69, 0.95);
  opacity: 0;
  transition: all 0.5s ease-in-out, top 0.5s ease-in-out, z-index 0.6s step-end, height 0.6s step-end;
  overflow: hidden;
  z-index: 10;

  &.active {
    top: 50px;
    height: calc(100vh - 50px);
    bottom: 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  }
}

.search-form {
  max-width: 668px;
  padding: 20px;
  margin: 145px auto 0;
  position: relative;

  button {
    position: absolute;
    top: 15px;
    right: -20px;
    background: 0;
    border: 0;
  }

  input[type="text"] {
    margin-top: 0;
    height: 65px;
    padding: 20px 40px;
    font-size: 17px;
  }

  input[type="submit"] {
    width: 60px;
    height: 0;
    padding-bottom: 63px;
    overflow: hidden;
    background: #2E2929;
    background: url(../../images/build/search.svg) center center no-repeat;
    text-indent: -9999px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: background 0.2s ease-in-out;

    &:hover {
      background-color: $orange_bright;
    }
  }
}

.view-search {
  background-color: color(gray_light_blue);

  &-filter {
    background-color: color(combiz_blue);
  }

  &-form {
    @include pad(52px 0);
    max-width: 628px;
    width: 100%;
    margin: 0 auto;

    .views-exposed-widget {
      float: none;
      padding: 0;
    }

    .views-submit-button {
      float: none;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .views-exposed-widgets {
      margin-bottom: 0;
      position: relative;
    }

    .form-submit {
      width: 15px;
      height: 0;
      padding-top: 25px;
      overflow: hidden;
      border: 0;
      text-indent: -9999px;
      background: url(../../images/build/search.svg) center center no-repeat;
      margin: 0 !important;
    }
  }

  &-inner {
    @include pad(67px 0 22px)
  }

  &-heading {
    font-size: em(35);
    font-weight: 500;
    margin-bottom: 27px;
    letter-spacing: -0.043em;
    color: color(dark_grayish_red);

    @include media($tablet) {
      font-size: rem(24px);
      font-weight: 400;
    }
  }

  &-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
    gap: 15px 16px;
    margin-bottom: 50px;

    @include media($mobile) {
      display: flex;
      flex-direction: column;
    }

    .result {
      @include media($mobile) {
        @include pad(23px 20px)
      }
    }
  }
}
#user-profile-form {
  position: relative;
  @include outer-container(1440px);
  @include pad(50px 0);
  min-height: 500px;

  // @include media($large_desktop) {
  //   @include outer-container(960px);
  // }

  // @include media($desktop) {
  //   @include outer-container(800px);
  // }

  @include media($tablet) {
    @include outer-container(680px);
  }

  @include media($mobile) {
    @include outer-container(100%);
  }

  label {
    color: color(combiz_blue);
    display: inline-block;
    font-size: rem(12);
    font-weight: 400;
    line-height: 1.25;
    padding-left: 0;
  }

  input {
    margin-bottom: 0;
  }

  .description {
    font-size: rem(11);
  }

  .password-strength,
  .password-confirm {
    font-size: rem(12);
    font-weight: normal;
    margin-top: 0;
  }

  .form-item-name,
  .form-item-mail,
  .form-item-pass-pass1,
  .form-item-pass-pass2,
  .field-name-field-first-name,
  .field-name-field-last-name,
  .field-name-field-contact-number,
  .field-name-field-company-name,
  .form-item-current-pass {
    width: calc((100% / 2) - 30px);
    display: inline-block;
    vertical-align: top;
  }

  .form-item-name,
  .form-item-pass-pass2,
  .field-name-field-contact-number,
  .field-name-field-last-name {
    margin-left: 54px;
  }

  #user-pass {
    .form-item-name {
      margin-left: 0;
      width: 100%;
    }

    .form-actions {
      margin: 0;
    }
  }

  #user-login {
    .form-item-name {
      margin-left: 0;
      width: 100%;
    }

    .form-actions {
      margin: 26px 0 0;
    }
  }

  #user-register {
    .form-actions {
      margin: 27px 0 0;
    }
  }


  .captcha {
    margin: 15px 0;
    width: 100%;
    text-align: left;
  }

  fieldset {
    margin: 30px 0;
    padding: 10px 20px 20px;

    .fieldset-legend {
      text-transform: uppercase;
      font-size: rem(13);
    }

    .form-item {
      margin: 0;
    }

    .description {
      margin-top: 10px;
    }
  }

  .form-file {
    font-size: rem(12);
  }

  .form-actions {
    margin: 45px 0 0;
  }

  @include media($mobile) {
    @include pad(25px 20px);

    .password-strength {
      width: auto;
    }

    .form-item {
      margin: 5px 0;
    }

    .form-item-name,
    .form-item-mail,
    .form-item-pass-pass1,
    .form-item-pass-pass2,
    .field-name-field-contact-number,
    .field-name-field-company-name,
    .field-name-field-first-name,
    .field-name-field-last-name,
    .form-item-current-pass {
      width: 100%;
    }

    .form-item-mail,
    .form-item-pass-pass2,
    .field-name-field-last-name {
      margin-left: 0;
    }

    .messages.error {
      font-size: rem(12);
    }
  }
}
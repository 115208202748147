.partner-header {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &-inner {
    display: flex;
    align-items: center;
    min-height: 431px;

    .partner-header-programmes & {
      min-height: 526px;
    }

    .partner-header-news & {
      min-height: 527px;
    }

    .partner-header-news-resources & {
      min-height: 526px;
    }
  }

  &-content {
    background-color: rgba(color(white), 0.9);
    max-width: 637px;
    border-radius: 14px;
    @include pad(29px 17px 54px 25px);

    @include media($tablet) {
      @include pad(24px 21px);
    }

    .partner-header-news & {
      @include pad(22px 17px 54px 25px);
    }

    .partner-header-news-resources & {
      @include pad(23px 17px 24px 25px);
    }
  }

  &-title {
    color: color(combiz_blue);
    font-size: rem(48);
    margin-bottom: 10px;
    letter-spacing: 0.01em;
    line-height: 1.1;

    @include media($tablet) {
      letter-spacing: normal;
      margin-bottom: 9px;
    }

    @include media($mobile) {
      font-weight: 700;
      font-size: rem(32);
      line-height: 1.19;
    }

    .partner-header-news & {
      line-height: 1.2;
    }

    .partner-header-news-resources & {
      line-height: 1.2;
      margin-bottom: 7px;
    }
  }

  &-desc {
    font-size: rem(20);
    color: color(combiz_black);
    line-height: 1.5;
    margin-bottom: 0;

    p {
      font-size: rem(20);
      color: color(combiz_black);
      line-height: 1.5;
      margin-bottom: 0;
      display: none;

      @include media($mobile) {
        font-size: rem(16);
      }

      &:first-of-type {
        display: block;
      }

      strong {
        font-weight: normal;
        font-size: inherit;

        @include media($tablet) {
          line-height: 1.5;
          letter-spacing: normal;
        }
      }
    }
  }
}

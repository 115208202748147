//anchor-tab
.anchor-tab {
  position: relative;


  @media (min-width: 681px) {
    max-height: calc(100vh - 214px);
    overflow: auto;
  }

  h2 {
    font-size: em(11);
    text-transform: uppercase;
    margin-bottom: 42px;
    color: $dark_very_grayish_red_3;
    letter-spacing: 2.8px;
    font-weight: normal;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // border-left: 3px solid $gray_grayish_violet;

    &.focus {

      li {
        color: $gray_dark;

        &.active {
          color: $dark_very_grayish_red_3;
        }
      }
    }

    li {
      position: relative;
      font-size: rem(15px);
      line-height: 1.7;
      text-transform: capitalize;
      color: $dark_very_grayish_red;
      cursor: pointer;
      @include pad(0 0 18px 0);
      @include transition(all 113ms linear);

      &:last-of-type {
        padding-bottom: 0;
      }

      &.active {
        font-weight: 600;
        color: $dark_very_grayish_red_3;
      }
    }
  }

  .anchor-nav {
    @include position(absolute, 0 null null 0);
    width: 3px;
    height: 25px;
    background: $orange_bright_2;
    @include transition(all 113ms ease-out);
    opacity: 0;
  }
}

//pager
.item-list {
  ul {
    margin-bottom: 0;
  }
}

.item-list .pager {

  li {
    padding: 0 7px;
    margin: 0 5px;
    color: $gray_dark;
    font-weight: 500;
    font-size: rem(15);
    @include size(25px);
    display: inline-block;
    vertical-align: middle;

    a {
      color: $gray_dark;
      @include transition(all 333ms linear);
    }

    &.pager-current,
    &:hover a {
      color: $orange_bright_2;
      font-weight: 600;
    }
  }

  .pager-item,
  .pager-current {
    margin-top: 1px;
  }

  .pager-first,
  .pager-previous,
  .pager-ellipsis,
  .pager-next,
  .pager-last {
    color: transparent;

    a {
      height: 0;
      overflow: hidden;
      display: block;
      padding-top: 30px;
    }

    &:not(.pager-ellipsis) {
      cursor: pointer;
    }
  }

  .pager-first {
    background: url('../../images/build/pager-aw-first.png') no-repeat center;
    background-size: 11px auto;
  }

  .pager-previous {
    background: url('../../images/build/pager-aw-prev.png') no-repeat center;
    background-size: 5px auto;
  }

  .pager-ellipsis {
    background: url('../../images/build/pager-ellipsis.png') no-repeat center;
    background-size: 9px auto;
  }

  .pager-next {
    background: url('../../images/build/pager-aw-next.png') no-repeat center;
    background-size: 5px auto;
  }

  .pager-last {
    background: url('../../images/build/pager-aw-last.png') no-repeat center;
    background-size: 11px auto;
  }

  @include media($mobile) {
    margin: 0 0 40px;

    li {
      margin: 0 3px;
    }

    .pager-first,
    .pager-last {
      display: none;
    }
  }
}
.topic {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  background-color: color(combiz_blue);
  transition: translate 0.3s ease-in-out;
  position: relative;

  @include media($overdesktop) {
    &:hover {
      translate: 0 -5px
    }
  }

  &-block {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-frame {
    min-height: 364px;
    background-color: color(gray_light_300);
    display: none;

    @include media($tablet) {
      min-height: 206px;
    }

    .is-main & {
      display: block;
    }
  }

  &-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;

    @include media($mobile) {
      min-height: rem(206);
      max-height: rem(206);
    }
  }

  &-content {
    height: 100%;
    @include pad(18px 16px 15px 16px);
    display: flex;
    flex-direction: column;

    .is-main & {
      @include pad(24px 37px 18px 24px);
    }
  }

  &-date {
    color: color(white);
    font-size: rem(16px);
    font-weight: 600;
    display: block;
    margin-bottom: 15px;

    .is-main & {
      margin-bottom: 7px;
    }
  }

  &-heading {
    color: color(white);
    font-size: rem(22px);
    font-weight: 500;
    line-height: 1.33;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media($mobile) {
      -webkit-line-clamp: 3;
    }

    .is-main & {
      letter-spacing: normal;
      margin-bottom: 6px;
      -webkit-line-clamp: 2;
    }
  }

  &-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    margin-top: auto;

    @include media($mobile){
      margin-top: 20px;
    }
  }

  &-tag {
    color: $white;
    font-size: rem(15px);
    text-transform: uppercase;
    border: 1px solid $gray_grayish_blue_3;
    background-color: $gray_grayish_blue_3;
    border-radius: 5px;
    font-weight: 700;
    @include pad(0 6px);
    line-height: 1.44;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .is-main & {
      border: none;
    }
  }

  &-category {
    font-size: rem(15px);
    text-transform: uppercase;
    background-color: color(white);
    color: color(combiz_orange);
    border: 1px solid color(combiz_orange);
    border-radius: 5px;
    font-weight: 700;
    @include pad(0 14px);
    line-height: 1.44;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.node-type-training-consulting-landing-page {

  h2 {
    font-size: em(35);
    color: $dark_grayish_red;
    margin-bottom: 30px;
    font-weight: bold;

    @include media($tablet) {
      font-size: em(28);
    }

    @include media($mobile) {
      font-size: em(22);
    }
  }

  .section-training {
    @include pad(100px 0 50px);
    background: $gary_light_orange;
    text-align: center;

    h2 {

    }

    .text {
      max-width: 700px;
      margin: 0 auto 50px;
      font-size: em(14);

      @include media($desktop) {
        margin-bottom: 40px;
      }

      @include media($mobile) {
        font-size: em(14);
      }
    }

    .card-wrapper {
      @include clearfix();
    }

    .card {
      text-align: left;
      @include omega(4n);
    }

    .primary-call-action {
      margin-top: 50px;
    }

    @include media($desktop) {
      @include pad(70px 0 35px);

      .text {
        margin-bottom: 40px;
      }
    }

    @include media($tablet) {

      .card {
        @include span-columns(6);
        @include omega(2n);
      }
    }

    @include media($mobile) {
      @include pad(25px 20px);

      .card {
        width: 100%;
      }
    }
  }

  .section-consulting {
    @include pad(50px 0 100px);
    background: $gary_light_orange;
    text-align: center;

    .text {
      max-width: 700px;
      margin: 0 auto 50px;
      font-size: em(14);
      color: $dark_very_grayish_red_2;

      @include media($mobile) {
        font-size: em(13);
        margin-bottom: 25px;
      }
    }

    .card {
      text-align: left;
    }

    .primary-call-action {
      margin-top: 50px;
    }

    @include media($desktop) {
      @include pad(35px 0 70px);
    }

    @include media($tablet) {

      .card {
        @include span-columns(6);
        @include omega(2n);
      }
    }

    @include media($mobile) {
      @include pad(25px 20px);

      .card {
        width: 100%;
      }
    }
  }

  .orange-overlay {
    @include pad(56px 0 50px);

    .wrapper {
      @include clearfix();
      padding-bottom: 22px;
      border-bottom: 1px solid $white;
      margin-bottom: 30px;
    }

    h2 {
      @include span-columns(4);
      font-size: em(24);
      color: $white;
      line-height: 1.25;
      letter-spacing: -0.7px;
      margin-bottom: 0;
    }

    .text {
      @include shift(1);
      @include span-columns(7);
      font-size: em(14);
      line-height: 1.5;
    }

    @include media($tablet) {

      h2 {
        @include span-columns(5);
      }

      .text {
        @include shift(0);
      }
    }

    @include media($mobile) {
      @include pad(40px 20px 34px);

      .wrapper {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 20px;
      }

      h2 {
        font-size: em(21);
        line-height: 1.19;
        margin-bottom: 5px;
        width: 100%;
      }

      .text {
        font-size: em(13);
        font-weight: 500;
        width: 100%;
      }
    }
  }

  .section-our-impact {
    @include pad(100px 0);
    text-align: center;

    .container {
      max-width: 100%;
    }

    .testimonials-slide {
      outline: none;
      margin: 0 38px;
      padding: 45px 90px;
      background: $gary_light_orange;
      opacity: 0.7;
      @include transition(all 333ms linear);

      &.slick-current {
        opacity: 1;

        .text-full {

          &:before {
            content: '';
            @include position(absolute, -19px null null -21px);
            @include size(48px 38px);
            background: url('../../images/build/quote-left.png') no-repeat center;
            background-size: 100% auto;
            z-index: -1;
          }

          &:after {
            content: '';
            @include position(absolute, null 27px -19px null);
            @include size(48px 38px);
            background: url('../../images/build/quote-right.png') no-repeat center;
            background-size: 100% auto;
            z-index: -1;
          }

          @include media($mobile) {

            &:before,
            &:after {
              content: none;
            }
          }
        }
      }

      .text {
        display: none;
      }

      .text-full {
        display: block;
        position: relative;
        font-size: rem(14);
        color: $gray_dark;
        margin: 0 auto 30px;
        line-height: 1.57;
        z-index: 1;
        max-width: 650px;
      }

      .single-slide-header {
        display: none;
      }

      .landing-slide-header {
        font-size: rem(14);
        font-weight: 500;
        color: $dark_very_grayish_red;
        line-height: 1.42;
        letter-spacing: -0.41px;
      }

      .link-underline {
        display: none;
      }
    }

    .slick-arrow {

      &.testimonials-slider-next {
        right: 10%;
      }

      &.testimonials-slider-prev {
        left: 10%;
      }

      @include media($mobile) {

        &.testimonials-slider-next {
          right: 3%;
        }

        &.testimonials-slider-prev {
          left: 3%;
        }
      }
    }

    @include media($tablet) {
      @include pad(70px 0);

      .testimonials-slide {
        padding: 45px 60px;
      }
    }

    @include media($mobile) {
      @include pad(41px 0 39px);

      h2 {
        margin-bottom: 25px;
      }

      .testimonials-slide {
        margin: 0 10px;
        padding: 30px 10px;

        &.slick-current {
          padding: 30px 20px;
        }

        .text {
          font-size: em(13.5);
        }

        h3 {
          font-size: em(12);
        }
      }
    }
  }
}

// Paragraph
p {
  margin: 0 0 1em;
}

// Heading
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0 0 1em;
}

a,
.fake-link {
  outline: none;
  color: $color__main;
  text-decoration: none;
  -webkit-appearance: none;
}

.main-with-sidebar {

  a:not([class]),
  a[class=""] {
    color: $orange_bright;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: rem(14px);

  }
}

button.no-style {
  background: none;
  border: 0;
  color: $orange_bright;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.main-with-sidebar {
  .text ul {
    @include bullet-points();
  }
}

div.addeventatc {
  box-shadow: none !important;
}
.login-wrapper {
  position: relative;
  z-index: 15;
  opacity: 1;
  visibility: visible;
  margin: 0 auto;
  @include size(750px auto);
  @include position(fixed, 50% 0 null 0);
  @include transform(translateY(-50%));
  @include transition(all 333ms linear);

  &:after {
    content: '';
    margin: auto;
    background: $dark;
    opacity: 0.12;
    @include filter(blur(42px));
    @include size(316px);
    @include position(absolute, null 0 -50px 0);
  }

  .close {
    cursor: pointer;
    background: url('../../html_prototype/images/close.svg') no-repeat center;
    background-size: 100%;
    @include size(10px);
    @include position(absolute, 0 -20px null null);

    img {
      display: none;
    }
  }

  .wrapper {
    overflow: hidden;
    display: table;
  }

  .background {
    position: relative;
    display: table-cell;
    @include size(31% 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    @include filter(blur(2px));

    &:after {
      content: '';
      @include size(100%);
      background: rgba(242,116,58,0.92);
      @include position(absolute, 0 null null 0);
      z-index: -1;
    }
  }

  .container {
    position: relative;
    display: table-cell;
    width: calc(100% - 31%);
    @include pad(28px 77px 67px 54px);
    background: $white;
    z-index: 1;

    &:after {
      content: '';
      @include size(3000px);
      background: $white;
      border-radius: 50%;
      @include position(absolute, 0 null 0 -28px);
      z-index: -1;
      margin: auto;
    }
  }

  .tab {
    margin: 0 0 78px;
    padding: 0;
    list-style: none;
    @include clearfix();

    li {
      width: 50%;
      float: left;
      box-sizing: border-box;
      padding-bottom: 2px;
      border-bottom: 2px solid $gray_grayish_violet;
      cursor: pointer;
      opacity: 0.4;
      @include transition(all 333ms linear);

      span, h4 {
        @include transition(all 333ms linear);
      }

      span {
        font-size: em(10);
        text-transform: uppercase;
        letter-spacing: 2.25px;
        line-height: 1.2;
        color: $dark_very_grayish_red_3;
      }

      h4 {
        font-size: em(17);
        font-weight: 600;
        letter-spacing: -0.4px;
        line-height: 1.29;
        color: $dark_very_grayish_red;
      }

      &.current,
      &:hover {
        opacity: 1;
        border-color: $orange_bright_2;

        span, h4 {
          color: $orange_bright_2;
        }
      }

      &:last-of-type {
        padding-left: 23px;
      }
    }
  }

  .content {
    @include pad(0 22.5px 0 55.5px);
    text-align: center;

    .forget-pwd {
      font-size: em(11);
      color: $dark_very_grayish_red_3;
    }
  }
}
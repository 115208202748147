@mixin selection {
  $before-colons: "";

  @if & {
    $before-colons: "&"
  }

  #{$before-colons}::selection {
    @content;
  }
  #{$before-colons}::-moz-selection {
    @content;
  }
}
.rhm-donation-form-wrap {}

.donate-page {
  position: relative;
  // padding: 100px 0 95px;

  h1 {
    // font-size: rem(49);
    // margin-bottom: 13px;
    // line-height: 1.06;
    // letter-spacing: -1.46px;
  }

  .donate-intro {
    font-size: rem(14);
    max-width: 530px;
    margin-bottom: 52px;
  }

  .bottom-text {
    text-align: center;
    font-size: rem(17);
    margin-top: 45px;
    font-weight: 500;

    p {
      margin-bottom: 0;
    }

    a {
      color: $orange_bright;
    }
  }

  .bottom-text-2 {
    font-size: rem(14);
    text-align: center;
    margin-top: 30px;
  }

  // &:before {
  //   content: '';
  //   @include size(425px);
  //   border-radius: 50%;
  //   @include position(absolute, -325px 10% null null);
  //   background: rgba(252, 104, 45, 0.08);
  //   z-index: -1;
  // }

  // &:after {
  //   content: '';
  //   @include size(375px);
  //   border-radius: 50%;
  //   @include position(absolute, 130px null null 5%);
  //   background: rgba(255, 255, 255, 0.58);
  //   z-index: -1;
  // }

  @include media($tablet) {
    padding: 0 0;
  }

  @include media($mobile) {

    h1 {
      font-size: rem(32);
      margin-bottom: rem(16);
      line-height: 1.15;
      letter-spacing: -0.77px;
    }

    .donate-intro {
      margin-bottom: 35px;
    }

    .bottom-text {
      margin-top: 25px;
      font-size: rem(15);
    }

  }
}

.donate-page-inner {
  @include span-columns(10);
  @include shift(1);

  @include media($tablet) {
    width: 100%;
    margin: 0 !important;
  }
}

.donate-option-monthly {
  clear: both;
  position: relative;
  padding: 58px 45px;
  margin-bottom: 26px;

  &:before {
    content: '';
    opacity: 0.15;
    background: $dark;
    border-radius: 50%;
    @include size(117px);
    margin: auto;
    @include filter(blur(42px));
    @include position(absolute, null 0 -20px 0);
  }

  .inner {
    position: relative;
    z-index: 2;
    @include clearfix();
  }

  h2 {
    float: left;
    margin: 5px 0 0;
    font-size: em(24);
  }

  .monthly-form-wrap {
    text-align: right;
    margin-left: 1%;
    float: right;
  }

  .currency {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: rem(18);
    line-height: 1.39;
    letter-spacing: -0.53px;
    margin-right: 1%;
  }

  .amount-monthly-select-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 40%;
    max-width: 138px;
    height: 52px;
    border-radius: 100px;
    background: $white;
    text-align: center;
  }

  .amount-monthly-select {
    border: 0;
    color: $orange_bright_2;
    font-size: rem(30);
    font-weight: 600;
    padding: 5px 0 0;
    background: $white;
    outline: 0;
    visibility: visible;
  }

  .donate-link {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5%;
    @include pad(14px 21px 11px);
  }

  @include media($mobile) {
    padding: 25px 20px 50px;
    margin-top: 60px;

    h2 {
      width: 40%;
      font-size: em(15);
    }

    .monthly-form-wrap {
      text-align: left;
      width: calc(100% - 41%);
    }

    .currency {
      display: block;
      font-size: rem(18);
      font-weight: 500;
      padding-left: 18px;
      margin-bottom: 5px;
    }

    .amount-monthly-select-wrapper {
      width: 140px;
    }

    .amount-monthly-select {
      font-size: rem(26);
    }

    .donate-link {
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: rem(13);
      font-weight: 600;
      color: $white;
      line-height: 1;
      border: 0;
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

  }
}

.rhm-donation-form {
  background: $white;
  width: 590px;
  max-width: 100%;
  position: relative;
  top: 50%;
  @include transform(translateY(-50%));
  text-align: left;
  padding: 65px 50px 35px;
  background: url('../../images/build/pig.jpg') no-repeat calc(100% - 17px) -5px $white;

  @include media($mobile) {
    padding: 25px;
  }

  .form-header {
    margin-bottom: 30px;
  }

  h2 {
    font-size: rem(18);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .donate-amount {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 30px;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: rem(30);
    color: $orange_bright_2;
    font-weight: 600;
    position: relative;
    text-align: left;
    background: none !important;
  }

  fieldset {
    border: 0;
    max-width: 320px;
    padding: 15px 0;

    @include media($tablet) {
      max-width: 100%;
    }

    @include media($mobile) {
      padding: 10px 0;
    }
  }

  legend {
    font-size: rem(11);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: $spaced-letters;
    border-bottom: 1px solid $gray_light;
    width: 100%;
    color: $dark_very_grayish_red_3;
  }

  .input-wrap {
    margin-bottom: 15px;

    &.half {
      @include span-columns(6);

      @include media($mobile) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.full {
      width: 100%;
    }

    &.omega {
      @include omega();
    }

    input {
      width: 100%;
      border: 1px solid $gray_grayish_violet;
      border-radius: 3px;
      font-size: rem(13);
      padding: 7px 10px;
    }

    @include media($mobile) {
      margin-bottom: 8px;
    }
  }

  label {
    font-size: rem(11);
    font-weight: normal;
    padding-left: 5px;
    color: $dark_very_grayish_red_2;
  }

  input[type="reset"] {
    display: none;
  }
}

.donate-success {
  font-size: rem(22);
  padding: 70px;
}

.donate {

  &-top {
    background-color: color(combiz_light_gray);
  }

  &-top-inner {
    text-align: center;
    @include pad(66px 0 79px);

    @include media($mobile) {
      @include pad(32px 0 48px);
    }
  }

  &-title {
    font-size: rem(48.83px);
    color: color(combiz_blue);
    font-weight: 700;
    margin-bottom: 8px;
    line-height: normal;
  }

  &-desc {
    font-size: rem(20px);
    font-weight: 400;
    color: color(combiz_black);
    max-width: 969px;
    width: 100%;
    margin: 0 auto;

    @include media($mobile) {
      font-size: rem(16);
      line-height: 1.5;
    }

    br {
      display: none;
    }
  }

  &-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 41px;
    @include pad(0 12px);
    margin-top: 86px;

    @include media($tablet) {
      @include pad(0);
      gap: 20px;
    }

    @include media($mobile) {
      display: block;
      margin-top: rem(40);
    }
  }

  &-option {
    position: relative;
    background-color: color(white);
    border-radius: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 329px;
    @include pad(41px 0 0);

    @include media($tablet) {
      min-height: 302px;
      @include pad(20px 0 0);
    }

    @include media($mobile) {
      margin-bottom: rem(16);
    }
  }

  &-icon {
    display: block;
    width: 89px;
    height: 72px;
    flex-shrink: 0;
    margin-bottom: 24px;

    img {
      display: block;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &-amount {
    font-size: rem(32px);
    color: color(combiz_orange);
    font-weight: 700;
    margin-bottom: 10px;
    display: block
  }

  &-value {
    margin-bottom: 29px;
  }

  &-input[type="text"] {
    width: 142px;
    height: 38px;
    border: 1px solid color(combiz_blue);
    border-radius: 3px;
    font-size: rem(12px);
    font-family: $font__main;
    color: color(combiz_blue);
    text-align: center;
    @include pad(0);

    &::placeholder {
      font-size: rem(12px);
      color: color(combiz_blue);
      font-family: $font__main;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &-currency {
    font-size: rem(20px);
    font-weight: 400;
    margin-bottom: 25px;
    display: block
  }

  &-cta {
    font-weight: 400;
    @include pad(8px 23px 6px 16px);
    @include caret(color(white));
    font-size: rem(16px);

    &::after {
      margin-left: 9px;
    }
  }

  &-bottom {
    background-color: color(combiz_blue);
  }

  &-bottom-inner {
    @include pad(72px 0 95px);

    @include media($mobile) {
      @include pad(32px 0 16px);
    }
  }

  &-subtitle {
    font-size: rem(32px);
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 1.5;
    letter-spacing: normal;
    color: color(white);

    @include media($mobile) {
      font-size: rem(28);
      line-height: 1.3;
    }
  }

  &-par {
    p {
      font-size: rem(16px);
      color: color(white);
      margin-bottom: 0;
      line-height: normal;

      @include media($mobile) {
        font-size: rem(12);
        line-height: 1.62;
        letter-spacing: -0.439024px;
      }
    }
  }
}

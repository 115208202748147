.node-type-page {
  // background: url('../../images/build/bubble.png') repeat-y;
  background-size: 100% auto;

  #content-area {
    section {
      margin-bottom: 60px;
      @include clearfix();
    }

    article.template-terms section {
      margin-bottom: 30px;
    }
  }

  article .container {
    @include pad(100px 0);

    @include media($mobile) {
      @include pad(11px 21px 45px);
    }
  }

  article.template-terms .container {
    @include pad(50px 0);
  }

  .text-wrap {
    @include span-columns(6);

    h5 {
      font-size: em(11);
      color: $dark_grayish_red;
      margin-bottom: 13px;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      font-weight: normal;
    }

    p {
      margin-bottom: 25px;

      br {
        display: none;
      }
    }

    a:not(.primary-call-action):not(.secondary-call-action) {
      color: $orange_bright;

      &:hover {
        text-decoration: underline;
      }
    }

    @include media($desktop) {
      @include span-columns(7.5);
    }

    @include media($mobile) {
      float: none;
      width: 100%;
      margin-top: 17px;

      h5 {
        font-size: em(10);
        margin-bottom: 10px;
        letter-spacing: 1.6px;
      }
    }

    .links {
      margin-top: 30px;
    }
  }

  .image-wrap {
    position: relative;
    @include shift(2);
    @include span-columns(3.5);
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 25px rgba(250, 248, 247, 0.54);

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &:after {
      content: '';
      @include position(absolute, 5px 10px null null);
      @include size(63px);
      background: rgba(251, 118, 63, 0.85);
      border-radius: 50%;
    }

    @include media($desktop) {
      @include shift(0.5);
      @include span-columns(4);
    }

    @include media($mobile) {
      float: none;
      margin: 47px auto 45px !important;
      max-width: 205px;
      width: 80%;
      box-shadow: inset 0 0 0 15px rgba(250, 248, 247, 0.54);

      &:after {
        top: 0;
        right: 5px;
        @include size(41px);
      }
    }
  }

  .template-terms {

    .orange-overlay {
      .container {
        padding-top: 0;
        padding-bottom: 0;
      }

      h1 {
        font-size: rem(24);
        margin: 0;
      }
    }

    .text-wrap {
      @include media($overtablet) {
        @include span-columns(9);
      }
    }

    section {

      h2 {
        font-size: rem(18);
        font-weight: 600;
      }

      font-size: rem(14);

    }

  }
}

.basic-page {
  background-color: color(gray_light_blue);

  &-inner {
    @include pad(33px 0);
  }

  &-content {
    background-color: color(white);
    @include pad(40px 103px);
    border-radius: 20px;

    @include media($tablet) {
      @include pad(40px 20px);
    }

    .btn-back {
      @include media($tablet) {
        display: none;
      }
    }
  }

  &-section {
    &:not(:last-of-type) {
      margin-bottom: 28px;
    }
  }

  &-subheading {}

  &-heading {
    font-size: rem(18px);
    font-weight: 600;
    margin-bottom: 13px;
    letter-spacing: -0.03em;
    line-height: normal;
  }

  &-par {
    max-width: 713px;
    width: 100%;

    p {
      font-size: rem(14px);
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
  }

  &-buttons {
    margin-top: 30px;
  }

  &-cta {
    @include pad(9px 24px 9px 16px);
    @include caret(color(white));
    font-weight: 500;
    font-size: rem(12px);

    &::after {
      margin-left: 10px;
      top: -1px
    }
  }
}
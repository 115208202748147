.solution {
  background-color: color(combiz_orange);
  @include pad(41px 0 33px 0);

  @include media($tablet) {
    @include pad(42px 0 33px 0);
  }

  &-heading {
    color: color(white);
    font-size: rem(32);
    margin-bottom: 14px;

    @include media($tablet) {
      font-size: rem(28);
      line-height: 1.25;
    }
  }

  &-desc {
    color: color(white);
    font-size: rem(16);
    line-height: 1.5;

    @include media($tablet) {
      font-size: rem(12);
      margin-bottom: 34px;
    }
  }

  &-cta {
    @include pad(7px 42px 6px 35px);
    font-size: rem(16);
    letter-spacing: normal;
    @include caret(color(combiz_orange));
    font-weight: 400;

    &.is-white {
      color: color(combiz_orange)
    }
  }
}
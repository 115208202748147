.node-type-index-page {

  .accessibility {
    .breadcrumb {
      display: none;
    }
  }

  .breadcrumbs {
    display: none;
  }

  article.index-page {
    section.breadcrumb-container {
      background: $gray_light_1;

      .breadcrumb {
        align-items: center;
        display: flex;
        float: unset;
        height: rem(47);
        margin: 0 auto;
        max-width: rem(1240);
        position: initial;
        text-transform: capitalize;
        width: 100%;

        @include media($desktop) {
          padding-left: rem(24);
        }

        @include media($mobile) {
          padding-left: rem(12);
        }

        a {
          font-size: rem(12);
          font-weight: 400;
          line-height: 1.5;
          text-decoration-line: underline;

          @include media($mobile) {
            font-size: rem(8);
          }
        }

        .separator {
          background-image: url('../../images/build/angle-right');
          background-size: 6px;
          background-repeat: no-repeat;
          width: 10px;
          height: 12px;
          margin: 0 8px;
        }

        .active-trail {
          color: color(combiz_blue);
          font-weight: 700;
          font-size: rem(12);
          line-height: 1.5;
        }
      }
    }

    .view-filters {
      @include media($mobile) {
        background: color(combiz_orange);

        &:not(.mobile-toggled) {
          position: initial;
        }

        &-inner {
          @include pad(31px 0 32px);
        }
      }
    }
  }

  .index-wrap {
    position: relative;
    background: $gary_light_orange;
    @include transition(all 333ms linear);

    &:before {
      content: '';
      opacity: 0;
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: rgba(71, 69, 69, 0.15);
      @include transition(all 333ms linear);
      pointer-events: none;
    }

    .view-content,
    .view-empty {
      @include pad(96px 0 70px);
      @include clearfix();

      @include media($tablet) {
        @include pad(70px 0 45px);
      }

      @include media($mobile) {
        @include pad(24px 0 88px 0);
      }
    }

    &.filter-on-focus {

      &:before {
        opacity: 1;
        z-index: 10;
        pointer-events: auto
      }
    }

    // .card,
    // .email-subscription {
    //   @include span-columns(3 of 12);
    //   @include omega(4n);

    //   @include media($desktop) {
    //     @include omega-reset(4n);
    //     @include span-columns(4 of 12);
    //     @include omega(3n);
    //   }

    //   @include media($tablet) {
    //     @include omega-reset(3n);
    //     @include span-columns(6 of 12);
    //     @include omega(2n);
    //   }

    //   @include media($mobile) {
    //     @include omega-reset(2n);
    //     @include span-columns(12 of 12);

    //     &.email-subscription {
    //       margin: 20px 0 40px;
    //     }
    //   }
    // }
  }

  .view-news-publications {

    .view-content {
      padding: 85px 0 40px;
      position: relative;
      display: block;
      clear: both;
      width: 100% !important;

      @include media($mobile) {
        width: 100% !important;
      }

      .card {

        @include media($mobile) {
          //margin-right: 0;
          transition: none;
          width: calc(100% - 40px) !important;
          margin: 0 20px 20px;
          background: $gary_light_orange;
          clear: both;
          float: none;
        }
      }
    }
  }

  .view-past-events {
    @include pad(0 0 25px);
    background-color: color(white);

    .view-block {
      background-color: color(white);
    }

    .view-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 23px 20px;
    }
  }

  .view-upcoming-events {

    .view-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 23px 20px;
    }
  }

  .view-our-team {
    padding-bottom: 58px;

    .view-filters-inner {
      @include pad(31px 100px 32px);
    }

    .view-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 36px 40px;
      margin: 0 auto;
      max-width: 998px;
      width: 100%;

      @include media($tablet) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 24px;
      }
    }
  }

  // Index page fancybox
  .fancybox-arrow {
    @include size(42px);
    border-radius: 50%;
    opacity: 1;
    background: url('../../images/build/slide-arrow-next.png') no-repeat center $white;
    background-size: 8px auto;

    &:after {
      content: none;
    }

    &.fancybox-arrow--left {
      left: 10%;
    }

    &.fancybox-arrow--right {
      right: 13%;
    }

    @include media($desktop) {

      &.fancybox-arrow--left {
        left: 30px;
      }

      &.fancybox-arrow--right {
        right: 50px;
      }
    }

    @include media($tablet) {

      &.fancybox-arrow--left {
        left: 15px;
      }

      &.fancybox-arrow--right {
        right: 30px;
      }
    }

    @include media($mobile) {
      @include size(35px);

      &.fancybox-arrow--left {
        left: 5px;
      }

      &.fancybox-arrow--right {
        right: 20px;
      }
    }
  }

  // Index page header
  .focus-area-header {

    @include media($mobile) {
      @include pad(140px 30px 32px 20px);

      &:before {
        @include size(774px);
        left: -300px;
        right: inherit;
        top: 450px;
      }

      &:after {
        content: none;
      }

      .content {
        padding: 0;
        width: 100%;
        max-width: 320px;
      }

      p {
        font-size: 12px;
        display: block;
        line-height: 1.5;
      }
    }
  }

  // Empty content
  .empty-index {
    padding: 100px 0 80px;

    p {
      font-size: em(23);
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: lighter;
    }

    @include media($mobile) {
      padding: 45px 20px;

      p {
        font-size: em(15);
      }
    }
  }

  .select2-container--open .select2-dropdown--below,
  .select2-container--open .select2-dropdown--above {
    min-width: 400px !important;
  }
}

.view-empty {
  font-size: rem(23);
  font-weight: 500;
  text-transform: capitalize;

  p {
    font-size: rem(23);
    font-weight: 500;
    text-transform: capitalize;
  }

  @include media($mobile) {
    font-size: rem(18);

    p {
      font-size: rem(18);
    }
  }
}

// Hide 'default' from our team options
.page-node-28 {
  #select2-edit-sort-by-results {
    li:first-child {
      display: none !important;
    }
  }

}

.view-training-consulting {
  .view-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
    gap: 23px 32px;
  }
}

.view-news-publications {
  padding-bottom: 15px;
  background-color: color(gray_lighter_blue);

  &-kards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
    gap: 23px 32px;
  }
}

.view-block {
  background-color: color(gray_lighter_blue);

  .view-our-members & {
    background-color: color(white);
  }
}

.view-our-members {
  background-color: color(white);
  @include pad(0 0 50px);

  .view-filters-inner {
    @include pad(31px 120px 32px);

    @include media($desktop) {
      @include pad(31px 60px 32px);
    }

    @include media($tablet) {
      @include pad(31px 0 32px);
    }
  }

  .view-filters {
    @include media($tablet) {
      position: static;
    }
  }

  .view-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @include media($mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    .node-type-index-page & {
      @include pad(96px 120px 70px);

      @include media($desktop) {
        @include pad(96px 60px 70px);
      }

      @include media($tablet) {
        @include pad(70px 0 45px)
      }

      @include media($mobile) {
        @include pad(45px 54px)
      }
    }
  }
}

.view-sponsorships {
  .view-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}
.faq-container {
  padding: 25px 0 50px;
  margin-bottom: 25px;

  &:first-child {
    padding-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .faq-category {
    font-size: em(24);
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.25;
    letter-spacing: -0.7px;
  }

  .faq-item {
    padding-bottom: 15px;
    border-bottom: 1px solid $gray_light;
    margin-bottom: 15px;
    @include transition(all 333ms linear);

    &:last-of-type {
      margin-bottom: 0;
    }

    &.toggled {
      @include pad(15px 0 30px);

      &:first-of-type {
        @include pad(0 0 30px);
      }

      .question {
        color: $orange_bright_2;

        .btn-toggle {
          background: url('../../html_prototype/images/toggle-close.png') no-repeat center;
          background-size: 100%;
        }
      }

      .answer {
        display: block;
      }
    }
  }

  .question {
    font-size: rem(15);
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 1.73;
    padding-right: 12px;
    cursor: pointer;
    @include transition(all 333ms linear);

    .text {
      display: inline-block;
      width: calc(100% - 65px);
    }

    .btn-toggle {
      @include size(26px);
      @include transition(all 333ms linear);
      background: url('../../html_prototype/images/toggle-open.png') no-repeat center;
      background-size: 100%;
      float: right;
    }
  }

  .answer {
    font-size: rem(14);
    color: $dark_very_grayish_red_2;
    line-height: 1.71;
    margin-top: 10px;
    width: calc(100% - 65px);
    display: none;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  @include media($mobile) {
    padding: 0 0 20px;

    .faq-category {
      font-size: rem(22);
      margin-bottom: 0;
      line-height: 1.3;
      letter-spacing: -0.77px;
    }

    .faq-item {
      padding-bottom: 10px;
      margin-bottom: 10px;

      &.toggled {
        @include pad(10px 0);
      }
    }

    .question {
      font-size: rem(14);

      .text {
        width: calc(100% - 55px);
      }
    }

    .answer {
      font-size: rem(13);
      line-height: 1.6;
      width: calc(100% - 55px);
    }
  }
}
.cart {
  width: 280px;
  border: 1px solid $white_2;
  background: $white;
  @include pad(14px 21px 23px 17px);

  .item {
    @include pad(16px 0 19px);
    margin-bottom: 16px;
    border-top: 1px solid $gray_grayish_violet;
    border-bottom: 1px solid $gray_grayish_violet;
  }

  .title {
    font-size: em(11);
    line-height: 1.45;
    margin-bottom: 5px;
    font-weight: normal;
    color: $dark_very_grayish_red_2;
  }

  .name, .price,
  .description,
  .discount-price {
    display: block;
    font-size: em(13);
    font-weight: 500;
    line-height: 1.31;
    letter-spacing: -0.4px;
    margin-bottom: 3px;
    color: $dark_very_grayish_red_3;

    &.price {
      margin-bottom: -5px;
      line-height: 1;
    }
  }

  .category {
    font-size: em(11);
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.1;
    letter-spacing: 2.8px;
    margin-bottom: 15px;
    color: $dark_very_grayish_red_3;
  }

  a {
    font-size: em(12);
    color: $orange_bright_2;
  }

  .discount {
    margin-bottom: 36px;
  }

  .total-price {
    font-size: em(16);
    font-weight: 600;
    letter-spacing: -0.38px;
    line-height: 1.56;
    text-align: right;
  }
}
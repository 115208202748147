@mixin filter-width($n) {

  &:first-child:nth-last-child(#{$n}),
  &:first-child:nth-last-child(#{$n})~.views-exposed-widget {
    @content;
  }
}

.page-style {

  .view-filters {
    z-index: 10;
    background: color(combiz_orange);
    width: 100%;
    position: relative;
    // @include position(absolute, -35px null null 0);
    // @include transition(all 333ms linear);

    .views-exposed-widgets {
      width: 100%;
      margin-bottom: 0;
    }

    .views-exposed-widget {
      position: relative;
      height: 70px;
      @include pad(18px 28px 10px 24px);
      border-left: 1px solid $gray_very_light;
      background: $white;
      overflow: hidden;
      cursor: pointer;
      @include transition(all 222ms ease-out);

      // Including submit button which is hidden
      @include filter-width(3) {
        width: 50%;
      }

      @include filter-width(4) {
        width: 33.3%;
      }

      @include filter-width(5) {
        width: 25%;
      }

      @include filter-width(6) {
        width: 20%;
      }

      &:before {
        content: '';
        @include position(absolute, -5px null null 0);
        @include size(100% 3px);
        @include transition(all 222ms ease-out);
        background: $orange_bright_2;
        opacity: 0;
      }

      &:first-of-type {
        border: 0;
      }

      &.focus {
        background: $white !important;

        &:before {
          top: 0;
          opacity: 1;
        }
      }

      &:not(.views-submit-button):hover {
        background: $gray_grayish_blue;
      }

      @include media($tablet) {

        &:not(.views-widget-filter-title_field_value) {
          display: none;
        }
      }






    }

    #edit-field-news-type-target-id-wrapper{
      z-index: 50;

      .news-label{
        font-size: 13px;
        margin-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .bef-select-as-checkboxes{
        background: $white;
        padding: 24px;
        position: absolute;
        top: 50px;
        width: 400px !important;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        left: -30px;
        display: none;
        font-family: "Poppins", sans-serif;

        .bef-toggle{
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
        }

        .bef-checkboxes{
          width: 100%;
          display: flex;
          gap: 10px 20px;
          flex-wrap: wrap;
        }

        .form-item{
          width: calc(50% - 20px);
          text-align: left;
          display: flex;
          gap: 0 10px;
          align-items: center;

          label{
            text-transform: none;
            color: #545151;
            font-size: 12px;
            letter-spacing: 0;
          }

        }
      }

      .actions{
        text-align: right;
        margin: 10px 0 -5px;
      }

      &.open{
        overflow: visible;

        .bef-select-as-checkboxes{
          display: block;
        }
      }
    }

    label {
      font-size: rem(10);
      color: $dark_very_grayish_red_3;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      line-height: 1.4;
      font-weight: normal;
      margin-bottom: 3px;
    }

    // Search input
    .views-widget-filter-title_field_value,
    .views-widget-filter-keys {
      position: relative;
      float: right;

      &:after {
        content: '';
        opacity: 1;
        @include size(11px);
        @include position(absolute, 33px 25px null null);
        background: url('../../images/build/search.svg') no-repeat center;
        background-size: 100% auto;
        @include transition(all 222ms ease-out);
      }

      label {
        display: none;
      }

      .form-text {
        border: 0;
        background: transparent;
        outline: none;
      }

      @include media($tablet) {
        width: 100% !important;
        height: 40.5px;
        padding: 0;
        border-left: 0;
        box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.12);

        &:after {
          top: 0;
          bottom: 0;
          margin: auto;
        }

        label {
          display: block;
          @include size(40px);
          font-size: 0;
          margin: 0;
          cursor: pointer;
          background: url('../../images/build/plus.png') no-repeat center;
          background-size: 13px auto;
          @include position(absolute, 0 null null 0);
        }

        .views-widget {
          display: inline-block;
          margin-left: 40px;
          width: calc(100% - 40px);

          &:before {
            content: '';
            @include size(1px 28px);
            margin: auto;
            border-left: 1px solid $gray_very_light;
            @include position(absolute, 0 null 0 40px);
          }

          input {
            @include pad(9px 27px 8px 12px);
          }
        }
      }
    }

    // Filter Order by && Filter Submit button
    .views-submit-button,
    .views-widget-sort-order {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    // Select2
    .select2 {
      width: 100% !important;
      height: auto;
      color: $dark_very_grayish_red;

      .select2-selection {
        border: 0 !important;
        font-weight: 500;
        padding: 0;
        background: transparent;
        outline: none;
        cursor: pointer;

        span.select2-selection__rendered {
          font-size: rem(13);
          line-height: 1.92;
          color: $dark_very_grayish_red;
          font-weight: 500;
          letter-spacing: -0.38px;
          padding: 0;
          width: 100%;
          padding-right: 10px;
          max-height: 20px;
          overflow: hidden;
          @include position(absolute, 0 null null null);
          background: url('../../images/build/sort-arrow.svg') no-repeat right 7px;
          background-size: 6.5px auto;
        }

        ul.select2-selection__rendered {
          width: 9999px;
          padding: 0;

          .select2-selection__choice {
            position: relative;
            font-size: rem(13);
            font-weight: 500;
            line-height: 1.92;
            letter-spacing: -0.38px;
            color: $dark_very_grayish_red;
            border: 0;
            margin: 0 15px 0 0;
            padding: 0;
            background: transparent;
            text-transform: capitalize;
            max-height: 20px;
            overflow: hidden;
            float: left;
          }
        }

        .select2-search__field {
          margin-top: 0;
          opacity: 0.5;
          color: $dark_very_grayish_red;
          font-size: rem(13);
          cursor: pointer;
        }

        .select2-selection__arrow,
        .select2-search.select2-search--inline:not(:only-child),
        .select2-selection__choice__remove {
          display: none;
        }
      }
    }

    @include media($tablet) {
      @include position(absolute, 0 null null 0);

      &.mobile-toggled {
        position: fixed;
        top: 105px;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 680px;

        &:after {
          content: '';
          @include position(fixed, 0 null null 0);
          @include size(100%);
          background: rgba(71, 69, 69, 0.9);
        }

        .views-exposed-widget:not(.views-widget-filter-title_field_value, .views-submit-button) {
          z-index: 1;
          opacity: 1;
          display: block;
          height: auto;
          visibility: visible;
          width: 100% !important;
          padding: 16px 26px 15px;
          border-left: 0;
          border-bottom: 1px solid $gray_very_light;

          &:before {
            content: none;
          }

          label {
            display: inline-block;
            margin-bottom: 0;
          }

          &.views-widget-sort-by {
            border-bottom: 0;
          }

          .views-widget,
          .form-item-sort-by .select2 {
            float: right;
            text-align: right;
            width: calc(100% - 100px) !important;
          }

          .select2-selection--single {
            height: 20px;
          }

          .select2-selection__rendered {
            margin-top: 1px;
            padding-right: 40px;
            background-position: calc(100% - 20px) 9px;
          }
        }

        .views-submit-button {
          z-index: 1;
          opacity: 1;
          display: block;
          height: auto;
          visibility: visible;
          width: 100% !important;
          text-align: center;
          padding: 8px 20px 25px;
          border: 0;

          &:before {
            content: none;
          }

          .form-submit {
            margin: 0 auto;
          }
        }

        .views-widget-filter-title_field_value {
          position: fixed;
          left: 0;
          right: 0;
          margin: auto;
          max-width: 680px;
        }
      }
    }

    @include media($mobile) {
      top: 25px;
      padding: 0 20px;
      background: $gary_light_orange;

      &.mobile-toggled {
        padding: 0 20px;

        .views-widget-filter-title_field_value {
          left: 20px;
          right: inherit;
          width: calc(100% - 40px) !important;
        }
      }
    }
  }
}

// Select 2 container
.select2-container {

  .select2-search--dropdown {
    padding: 0;
  }

  .select2-dropdown {
    //width: 400px !important;
    position: absolute;
    @include pad(16px 0 18px);
    top: 20px;
    background: $white;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    border: 0;
    @include clearfix();
    @include transition(all 333ms linear);

    &.small {
      width: 200px !important;
    }

    &:not(.select2-dropdown--above) {
      &:before {
        content: '';
        @include size(24px);
        @include position(absolute, -12px null null calc(50% - 50px));
        margin: auto;
        background: url('../../images/build/options-arrow.png') no-repeat center top;
        background-size: 24px auto;
      }
    }

    &.select2-dropdown--above {
      margin-top: -50px;
    }

    .select2-search__field {
      display: none;
    }

    .select2-results {

      .select2-results__options {
        max-height: 250px;
      }

      .select2-results__option {
        @include pad(10px 10px 8px 50px);
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        background: url('../../images/build/option-non.png') no-repeat 23px center;
        background-size: 16px auto;
        font-size: rem(12);
        color: $dark_very_grayish_red;
        line-height: 1.35;
        box-sizing: border-box;
        @include transition(all 333ms linear);

        &[aria-selected="true"] {
          background: url('../../images/build/option-active.png') no-repeat 23px center;
          background-size: 16px auto;
          font-size: rem(13);
          font-weight: 500;
          color: $dark_very_grayish_red;
        }

        &:hover {
          font-weight: 500;
        }
      }
    }
  }

  @include media($tablet) {

    .select2-dropdown {
      width: 450px !important;
      max-width: 85vw;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

      &:before {
        content: none;
      }
    }
  }

  @include media($mobile) {
    left: 20px !important;

    .select2-dropdown {

      .select2-results {

        .select2-results__option {
          @include pad(8px 10px 6px 38px);
          background-size: 12px auto;
          background-position: 18px 12px;
          font-size: rem(11);

          &[aria-selected="true"] {
            background-position: 18px 12px;
            background-size: 12px auto;
            font-size: rem(12);
          }
        }
      }
    }
  }
}

.select2-container--classic.select2-container--open {

  .select2-dropdown--below {
    border: 0;

    &:before {
      content: none;
    }
  }
}

// Mobile filter close
.mobile-filter-close {
  @include position(absolute, -30px 0 null null);
  @include size(10px);
  z-index: 1;
  cursor: pointer;
  display: none;
  background: url('../../images/build/close.png') no-repeat center;
  background-size: cover;

  @include media($mobile) {
    right: 20px;
  }
}

// .view-news-publications {

//   .view-filters {
//     background-color: color(combiz_orange);
//     position: static
//   }

//   .view-filters-inner {
//     @include pad(31px 0 32px);
//   }
// }

.view-filters-inner {
  @include pad(31px 0 32px);
}

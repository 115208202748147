body {
  font-family: $font__main;
  color: $color__main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  zoom: 0.9;

  &.locked {
    overflow: hidden;
  }
}

table {
  margin: 30px 0;
  width: 100%;

  th {
    border: 0;
  }

  td,
  th {
    padding: 10px 0;
  }

  tr.even,
  tr.odd {
    background: none;
    border: 0;
    border-bottom: 1px solid $gray_grayish_violet;
  }

  tbody {
    border-top: 1px solid $gray_grayish_violet;
  }

  th {
    font-size: rem(11);
    color: $dark_very_grayish_red_2;
    font-weight: 400;
  }
}

fieldset {
  margin: 0;
}

section.breadcrumb-container {
  background: $gray_light_1;

  .breadcrumb {
    align-items: center;
    display: flex;
    float: unset;
    height: rem(47);
    margin: 0 auto;
    max-width: rem(1200);
    position: initial;
    text-transform: capitalize;
    width: 100%;

    @include media($desktop) {
      padding-left: rem(24);
    }

    @include media($mobile) {
      padding-left: rem(12);
    }

    a {
      font-size: rem(12);
      font-weight: 400;
      line-height: 1.5;
      text-decoration-line: underline;

      @include media($mobile) {
        font-size: rem(8);
      }
    }

    .separator {
      background-image: url('../../images/build/angle-right.svg');
      background-size: 6px;
      background-repeat: no-repeat;
      width: 10px;
      height: 12px;
      margin: 0 8px;
    }

    .active-trail {
      color: color(combiz_blue);
      font-weight: 700;
      font-size: rem(12);
      line-height: 1.5;
    }
  }
}

iframe[title="recaptcha challenge expires in two minutes"] {
  height: 645px !important;
}
iframe[title="reCAPTCHA"] {
  width: 422px !important;
}

@import "typo.scss";
@import "header.scss";
@import "footer.scss";
@import "layout.scss";
@import "slick.scss";

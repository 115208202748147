.buy-tickets-form {

  .tickets {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .ticket {
    background: none;
    border-bottom: 1px solid $gray_grayish_violet;
    padding: 30px 0;
    overflow: hidden;

    .text-wrap {
      float: left;
      width: calc(100% - 140px);
    }

    .quantity {
      position: relative;
      float: right;
      width: 100px;
      border-radius: 3px;
      @include pad(7px 25px);
      border: 1px solid $gray_grayish_violet;
    }

    .buy-tickets-form-add-single-to-cart, .free-publication-download-link {
      float: right;
    }
  }

  .price {
    color: $dark_grayish_red;
    font-weight: 600;
    font-size: rem(16);
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  .ticket-title {
    font-size: rem(11);
    font-weight: normal;
    margin-bottom: 11px;
    color: $dark_very_grayish_red_3;
  }

  .free-tickets-info {
    color: $dark_very_grayish_red_3;
    font-size: rem(13);
  }

  .ticket-text {
    font-size: rem(12);
    font-weight: normal;
    color: $dark_very_grayish_red_2;
    line-height: 1.59;
  }

  input[type=number] {
    border-right: 1px solid $gray_very_light;
    border-left: 1px solid $gray_very_light;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
    padding: 0 5px 0 18px;
    text-align: center;
    margin: 0 auto;
    background: transparent;
  }

  .quantity-nav {
    @include position(absolute, 0 null null 0);
    @include size(100%);

    .quantity-button {
      color: $orange_bright_3;
      font-size: rem(17);
      font-weight: 600;
      line-height: 1;
      @include size(12px 15px);
      margin: auto;
      cursor: pointer;

      &.quantity-up {
        @include position(absolute, 0 9px 0 null);
      }

      &.quantity-down {
        @include position(absolute, 0 null 0 8px);
      }
    }
  }

  .form-actions {
    overflow: hidden;
    padding: 0;
    text-align: right;
    margin: 12px 0;

    a {
      float: right;
      background: $orange_bright_2;
      color: $white;
      outline: none;
      margin: 0 1px 0 18px;

      &.disabled {
        color: $white;
        background: $gray_very_light;
        pointer-events: none;
        box-shadow: none;

      }
    }

    .primary-call-action {
      margin-bottom: 20px;
    }

    input[type="submit"] {
      outline: none;

      &:hover {
        background: $orange_bright_2;
      }
    }
  }

  .remark {
    text-align: left;
    display: block;
    max-width: 390px;
    font-size: em(11);
    color: $dark_very_grayish_red_2;
    line-height: 1.45;
    margin-bottom: 16px;
  }

  .ajax-response {
    margin-top: 8px;
    font-size: rem(15);
    display: block;
    float: left;
  }

  @include media($mobile) {

    .ticket {
      padding: 15px 0;

      .text-wrap {
        float: left;
        width: calc(100% - 70px);
      }

      .quantity {
        @include size(50px 40px);
        @include pad(6px 7px 9px);
      }

      .buy-tickets-form-add-single-to-cart {
        float: right;
      }
    }

    .price {
      margin-bottom: 5px;
    }

    .ticket-title {
      font-size: rem(10);
      margin-bottom: 5px;
    }

    input[type=number] {
      border: 0;
      padding: 0 5px;
    }

    .quantity-nav {
      @include position(absolute, 0 null null 0);
      @include size(100%);

      .quantity-button {
        margin: 0;
        @include size(10px 14px);

        &.quantity-up {
          @include position(absolute, 6px 7px 0 null);
        }

        &.quantity-down {
          @include position(absolute, 22px null null 32px);
        }
      }
    }

    .form-actions {
      margin: 15px 0;
      text-align: left;

      a {
        float: left;
      }

      .primary-call-action {
        float: left;
      }

      input[type="submit"] {
        outline: none;

        &:hover {
          background: $orange_bright_2;
        }
      }
    }

    .remark {
      max-width: 100%;
      margin-bottom: 18px;
    }

    .ajax-response {
      width: 100%;
      margin-top: 0;
      margin-bottom: 17px;
      font-size: rem(14);
    }
  }

  .still-active {
    float: right;
    display: inline-block;
    margin-top: -20px;
    margin-right: 25px;
  }
}
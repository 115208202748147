.spotlight {
  background-color: color(combiz_light_gray);
  @include pad(62px 0 80px);

  @include media($tablet) {
    @include pad(30px 0 40px);
  }

  &-inner {}

  &-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 58px;
  }

  &-title {
    font-size: rem(25px);
    color: color(combiz_blue);
  }

  &-desc {
    width: 791px;
    color: color(combiz_black);
    font-size: rem(18px);
    font-weight: 400;
    line-height: 1.5;
  }

  &-articles {
    .slick-dots {
      button:before {
        content: ''
      }
    }
  }

  &-article {
    @include pad(59px 0 0);
    position: relative;
    display: flex;


    @include media($tablet) {
      display: block;
      @include pad(0)
    }

    &:not(:last-of-type) {
      margin-bottom: 103px;

      @include media($mobile) {
        margin-bottom: 30px;
      }
    }

    &.slick-slide {
      margin-bottom: 0;
      overflow: hidden;
    }

    &-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 520px;
      width: 57.5%;
      border-radius: 15px;
      flex-shrink: 0;
      position: absolute;
      top: 0;
      left: 0;

      @include media($tablet) {
        height: 224px;
        width: 100%;
        position: static;
        margin-bottom: -45px;
      }

      .is-even & {
        left: auto;
        right: 0
      }

      @include media($mobile) {
        width: 100%;
      }
    }

    &-title {
      font-weight: 700;
      line-height: 1.29;
      margin-bottom: 15px;

      @include media($mobile) {
        font-size: rem(28);
      }
    }

    &-desc {
      font-size: rem(16px);
      color: color(combiz_black);
      margin-bottom: 41px;
      line-height: 1.5;
    }
  }

  &-contents {
    background-color: color(white);
    border-radius: 15px;
    width: 45.58%;
    min-height: 539px;
    flex-shrink: 0;
    @include pad(18px 40px 18px 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: -8px;

    @include media($tablet) {
      margin-right: 0;
      width: 96%;
      min-height: auto;
      @include pad(18px 20px);
    }

    .is-even & {
      margin-left: 0;

      @include media($tablet) {
        margin-left: auto;
      }
    }
  }

  &-cta {
    @include caret(color(white));
    @include pad(7px 24px 7px 15px);
    font-size: 16px;

    &::after {
      margin-left: 9px;
    }
  }

  &-details {
    margin-top: 53px;
    width: 100%;
  }

  &-label {
    font-size: rem(16px);
    font-weight: 600;
    color: color(combiz_blue);
    display: inline-block;
    margin-bottom: 13px;
  }

  &-subjects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    gap: 0;

    .is-firefox & {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 0 20px;
    }
  }

  &-subject {
    padding-right: 20px;

    .is-firefox & {
      padding-right: 0;
    }

    @include media($tablet) {
      padding-right: 0;
    }

    &:not(:last-of-type) {
      @include media($tablet) {
        margin-bottom: 15px;
      }
    }

    &-title {
      font-size: rem(16px);
      color: color(combiz_blue);
      font-weight: 400;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
  }

  &-category {
    color: color(combiz_orange);
    font-size: rem(15px);
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid color(combiz_orange);
    display: inline-block;
    line-height: 1.2;
    @include pad(2px 14px 1px);
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  &-date {
    font-size: rem(15px);
    color: color(combiz_black);
    font-weight: 600;
    display: block;
    letter-spacing: 0.03em;
    margin-bottom: 5px;
  }
}

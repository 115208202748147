.company-header {
  position: relative;
  background: color(combiz_blue);
  overflow: hidden;
  min-height: 425px;

  @include media($tablet) {
    min-height: 325px;
  }


  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include pad(87px 0 75px);

    @include media($tablet) {
      display: block;
      @include pad(27px 0)
    }
  }

  &-block {
    max-width: 470px;
    width: 100%;
    flex-shrink: 0;

    @include media($tablet) {
      margin-bottom: 31px;
    }
  }

  &-title {
    font-size: rem(49px);
    font-weight: 400;
    margin-bottom: 0;
    color: color(white);
    letter-spacing: -0.03em;
    line-height: 1.3;

    @include media($tablet) {
      font-size: rem(28);
      letter-spacing: normal;
      margin-bottom: 11px;
    }
  }

  &-text {
    font-size: rem(14);
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.41px;
    color: color(white);

    @include media($tablet) {
      font-size: rem(12);
      letter-spacing: -0.007em;
      line-height: 1.7;
    }
  }

  &-profile {
    background-color: color(white);
    border-radius: 20px;
    width: 244px;
    flex-shrink: 0;
    min-height: 244px;
    text-align: center;
    position: relative;
    @include pad(20px 20px 0);

    @include media($tablet) {
      height: 142px;
      min-height: 100%;
      width: 142px;
      @include pad(10px);
      border-radius: 10px;
    }
  }

  &-button-upload,
  &-button-cancel {
    background: none;
    border: none;
    color: color(combiz_orange)
  }

  &-button-upload {
    font-size: rem(11px);
    width: 100%;
    text-align: center;
  }

  .text-wrap {
    z-index: 3;
    position: relative;
    margin-top: 67px;
    @include span-columns(6);
  }

  .logo-wrap {

    [name="logo_upload_button"],
    .form-file {
      //display: none;
    }

    .upload-logo-button {
      @include media($tablet) {
        position: absolute;
        bottom: 10px;
        left: 0;
      }
    }

    .upload-logo-button,
    .cancel-upload-logo-button {
      font-size: 11px;
      width: 100%;
      text-align: center;
    }

    form,
    .cancel-upload-logo-button {
      display: none;
      overflow: hidden;
    }

    img {
      height: 150px;
      width: 100%;
      object-fit: contain;
      // height: auto;
      margin: auto;

      @include media($tablet) {
        height: 30px;

      }
    }

    &.form-visible {

      form {
        // margin-left: -40px;
        // margin-right: -40px;
        display: block;
      }

      .form-item {
        margin: 0;
      }

      .form-file {
        font-size: rem(13);
        max-width: 100%;
      }

      .form-managed-file .file a {
        font-size: rem(12);
      }

      .form-submit {
        margin-top: 10px;
      }

      .cancel-upload-logo-button {
        display: block;
        margin-top: 10px;
      }

      input[name="logo_upload_button"] {
        display: none;
      }

      img,
      .upload-logo-button {
        display: none;
      }
    }

    @include media($mobile) {

      &.form-visible {
        top: - 30px;
      }

      &:before {
        content: none;
      }

      .upload-logo-button {
        padding: 0;
        // @include position(absolute, null null 12px 0);
      }
    }
  }

  .form-submit {
    @extend .secondary-call-action;

    &:hover {
      background: $orange_bright_2;
      color: $white;
    }
  }

  .company-header-meta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.58);
    padding: 11px 0 7px;
    z-index: 3;
    @include clearfix();

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .company-header-memberships {
      float: left;
      font-weight: 500;

      li {
        margin-right: 15px;
        float: left;
        color: $dark_grayish_violet;
        font-size: em(13);
        opacity: 0.8;
        line-height: 1.21;
        letter-spacing: 0.95px;

        a {
          color: $dark_grayish_violet;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .company-header-industries {
      float: right;
      font-size: rem(10);
      text-transform: uppercase;
      color: $dark_very_grayish_red_3;
      line-height: 1.4;
      margin-top: 1px;
      letter-spacing: 1.6px;

      li {
        display: inline-block;
        @include pad(0 11px);
        border-left: 1px solid $dark_very_grayish_red;

        &:first-of-type {
          border-left: 0;
        }
      }
    }

    @include media($mobile) {
      padding: 11px 20px 9px;

      .company-header-industries,
      .company-header-memberships {
        float: none;
        width: 100%;

        li {
          float: none;
        }
      }

      .company-header-memberships {
        margin-bottom: 5px;

        li a {
          font-size: rem(13);
          color: $gray_dark;
          font-weight: 500;
        }
      }

      .company-header-industries {
        margin-top: 0;

        li {
          letter-spacing: 1.6px;
          padding: 0 4px 0 10px;

          &:first-of-type {
            padding-left: 0;
          }
        }
      }
    }
  }

  // @include media($mobile) {
  //   @include pad(45px 16px 70px 22px);

  //   &:before {
  //     background: rgba(252, 104, 45, 0.08);
  //     @include size(305px);
  //     @include position(absolute, 75px -96px null inherit);
  //     @include transform(translateX(0));
  //   }

  //   &:after {
  //     background: $gary_light_orange_2;
  //     opacity: 1;
  //     @include size(356px);
  //     @include position(absolute, -30px null null -148px);
  //   }
  // }
}
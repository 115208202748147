.node-type-training-consulting {

  .event-single-header {
    background: $gray_grayish_blue;
  }

  .testimonials-slider {
    margin: 30px 0;

    .testimonials-slide {
      @include pad(39px 76px);
    }

    h3 {
      font-size: em(17);
      font-weight: 600;
      margin-bottom: 7px;
      color: $dark_very_grayish_red;
      line-height: 1.3;
      letter-spacing: -0.4px;
    }

    .text {
      font-size: em(12);
      line-height: 1.58;
      margin-bottom: 36px;
      color: $dark_very_grayish_red_2;
    }

    .link-underline {
      text-transform: lowercase;
    }

  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-arrow {
    @include size(35px);
  }

  @include media($tablet) {

    .testimonials-slider {

      .testimonials-slide {
        @include pad(30px 50px);
      }

      .text {
        margin-bottom: 15px;
      }
    }

    .slick-arrow {
      @include size(30px);

      &.testimonials-slider-prev {
        left: 10px;
      }

      &.testimonials-slider-next {
        right: 10px;
      }
    }
  }

  @include media($mobile) {

    .main-with-sidebar {
      padding: 40px 0 45px;
    }

    .paragraph:not(.paragraph-testimonials) {
      padding: 35px 20px 8px;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .paragraph-testimonials {

      h2, .text {
        padding: 0 20px;
      }
    }

    .testimonials-slider {

      .testimonials-slide {
        @include pad(25px 35px);
        margin: 0 20px;
      }

      .text {
        margin-bottom: 41px;
        padding: 0;
      }
    }

    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow {
      @include size(30px);

      &.testimonials-slider-next {
        right: 1%;
      }

      &.testimonials-slider-prev {
        left: 1%;
      }
    }
  }
}
// Bourbon grid display. Comment out to remove display.
$visual-grid: false;
$visual-grid-color: #3f9da8;

// Set to false if you'd like to remove the responsiveness.
$responsive: true;

// Setup the column, grid, and gutter amounts.
$column: 60px;
$gutter: 20px;
$grid-columns: 12;

// We set the max width of the page using the px to em function in Bourbon.
// the first value is the pixel value of the width and the second is the base font size of your theme.
// In this instance we want the max width to be 1088px and on Line 6 of style.scss we have reset the base
// font size to 13px so the first value would be 1088 and the second 13
// ex. em(1088, 13)
$max-width-px: 1200;
$max-width: 960px;
$max-width-fluid: new-breakpoint(max-width $max-width-px + px $grid-columns);

// Define your breakpoints.
$large_desktop: new-breakpoint(max-width 1440px 12);
$overdesktop: new-breakpoint(min-width 1201px 12);
$desktop: new-breakpoint(max-width 1200px 12);
$tablet: new-breakpoint(max-width 768px 12);
$overtablet: new-breakpoint(min-width 769px 12);
$mobile: new-breakpoint(max-width 680px 12);
$overmobile: new-breakpoint(min-width 681px 12);
$pale-orange: #ffd1ba;

.report-cta-page {
  overflow: hidden;
  background: $white;

  h1 {
    font-size: rem(28);
    line-height: 1.3;
    position: relative;
    margin: 43px 0;
    padding-bottom: 43px;
    border-bottom: 1px solid $gray_grayish_blue_2;

    &:before {
      content: '';
      position: absolute;
      left: -40px;
      top: 10px;
      width: 23px;
      height: 46px;
      background-color: $pale-orange;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
      border-left: 0;
    }

  }

  .testimonial {
    padding: 37px 20px 30px;
    outline: none;

    &.slick-current {

      .text:before,
      .text:after {
        opacity: 1;
      }
    }

    .inner {
      background: $gary_light_orange;
      position: relative;
      padding: 60px 20px 32px;
      text-align: center;
    }

    .image {
      border-radius: 50%;
      @include size(75px);
      @include position(absolute, -36px null null 50%);
      @include transform(translateX(-50%));
      background-size: cover;
      background-position: center;

      &:before {
        content: '';
        @include size(18px);
        @include position(absolute, null 0 0 null);
        background: rgba(252, 104, 45, 0.75);
        border-radius: 50%;
      }
    }

    .text {
      z-index: 1;
      position: relative;
      max-width: 633px;
      margin: 10px auto 15px;
      font-size: rem(14);
      color: #999;
      line-height: 1.58;

      &:before,
      &:after {
        content: '';
        opacity: 0;
        @include size(48px 38px);
        z-index: -1;
      }

      &:before {
        @include position(absolute, -19px null null -21px);
        background: url('../../images/build/quote-left.png') no-repeat center;
        background-size: 100% auto;
      }

      &:after {
        @include position(absolute, null 27px -19px null);
        background: url('../../images/build/quote-right.png') no-repeat center;
        background-size: 100% auto;
      }
    }
    .title {
      font-size: rem(14);
      font-weight: 600;
      line-height: 1.78;
    }
    .job-title {
      font-size: rem(12);
      font-weight: 500;
      line-height: 1.67;
    }
  }

  .what-can-be-done {
    background: $pale-orange;
    padding: 60px 0;

    h2 {
      text-align: center;
      font-size: rem(24);
      margin-bottom: 51px;
    }

  }

  .wcbd-links {
    text-align: center;
    padding-bottom: 40px;

    li {
      display: inline-block;
      width: 225px;
      vertical-align: top;
      cursor: pointer;
    }

    .icon {
      width: 63px;
      height: 63px;
      background: $dark_very_grayish_red_3;
      border-radius: 63px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 10px;
      transition: background 0.2s ease-in-out;

      svg {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .link-name {
      font-size: rem(16);
      font-weight: 600;
      line-height: 1.2;
    }

    li.active {

      .icon {
        background: $white;
      }
      path {
        fill: $orange_bright_2;
      }

    }
  }

  .wcbd-content-wrap {
    max-width: 797px;
    margin: 0 auto;
  }

  .wcbd-content {
    padding: 50px 80px;
    background: $white;

    dl {
      max-width: 634px;
      margin: 0 auto;
    }

    dt, dd {
      margin: 0;
      padding: 0;
    }

    dt {
      font-size: rem(16);
      font-weight: 600;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 19px;
        height: 19px;
        border-radius: 19px;
        background: $orange_bright_2;
        position: absolute;
        left: -40px;
        top: 3px;
      }

    }
    dd {
      font-size: rem(14);
      &:not(:last-child) {
        margin-bottom: 40px;
        border-bottom: 1px solid $gray_very_light;
        padding-bottom: 40px;
      }

    }

    &:not(.active) {
      display: none;
    }
  }

  @include media($tablet) {

    h1 {
      font-size: rem(26);
    }

    .what-can-be-done {

      .container {
        max-width: 100%;
        padding: 0 22px;
      }
    }

    .wcbd-content-wrap {
      max-width: 100%;
    }

    .wcbd-content {

      dl {
        max-width: 750px;
      }

    }
  }

  @include media($mobile) {

    h1 {
      font-size: rem(20);
      margin: 20px;
      padding-bottom: 20px;

      &:before {
        left: -17px;
        top: 5px;
        width: 11.5px;
        height: 23px;
      }
    }

    .wcbd-links {
      padding-bottom: 20px;

      li {
        width: auto;
        margin: 0 10px;
      }

      .icon {
        @include size(55px);
        border-radius: 50%;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .link-name {
        display: none;
      }
    }

    .wcbd-content {
      padding: 30px 30px 30px 40px;

      dt {
        font-size: rem(15);

        &:before {
          @include size(10px);
          left: -25px;
          top: 5px;
        }

      }

      dd {
        font-size: rem(14);

        &:not(:last-child) {
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

      }
    }
  }
}
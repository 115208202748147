.node-type-company {

  #company-information {
    margin-bottom: 108px;

    .strong {
      font-weight: 500;
      font-size: rem(14);
      color: $dark_very_grayish_red;
      line-height: 1.42;
      letter-spacing: -0.41px;
      margin-bottom: 21px;
    }

    .text {
      margin-bottom: 30px;
    }
  }

  #company-representatives {

    h2 {
      margin-bottom: 30px;
    }
  }

  .company-representative {
    width: 95%;
    padding-bottom: 28px;
    margin-bottom: 23px;
    border-bottom: 1px solid $gray_very_light;

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .avatar {
      display: inline-block;
      vertical-align: middle;
      @include size(115px);
      background: url('../../images/build/avatar.png') no-repeat center;
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
    }

    .content {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 120px);
      padding-left: 24px;
      max-width: 350px;
    }

    h3 {
      color: $dark_very_grayish_red;
      font-size: rem(17);
      line-height: 1.29;
      margin-bottom: 3px;
    }

    .job-title {
      font-size: rem(14);
      color: $dark_very_grayish_red_2;
      line-height: 1.64;
      margin-bottom: 14px;
    }

    .contact-info {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin-bottom: 4px;
        font-size: rem(12);
        font-weight: normal;
        letter-spacing: -0.3px;
        line-height: 1.58;
        color: $dark_very_grayish_red_2;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
.card-focus {
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  &-frame {
    overflow: hidden;
    flex-shrink: 0;
    width: 41.6%;
  }

  &-image {
    width: 100%;
    min-height: 358px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media($tablet) {
      // width: rem(139);
      min-height: 100%;
    }
  }

  &-content {
    background-color: color(white);
    @include pad(37px 35px 20px 18px);
    display: flex;
    flex-direction: column;

    @include media($desktop) {
      @include pad(20px 35px 11px 18px);
    }

    @include media($mobile) {
      @include pad(8px 16px 24px 16px);
    }
  }

  &-title {
    font-size: rem(24px);
    color: color(combiz_blue);
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin-bottom: -2px;
    margin-bottom: 10px;

    @include media($desktop) {
      font-size: rem(18);
      line-height: 1.3;
      margin-bottom: 4px;
    }
  }

  &-desc {
    @include media($tablet) {
      margin-bottom: 11px;
    }

    p {
      font-size: rem(12px);
      color: color(dark_very_grayish_red_2);
      margin-bottom: 0;
      letter-spacing: -0.024em;
      line-height: 1.6;

      @include media($tablet) {
        font-size: rem(10);
        line-height: 1.2;
        letter-spacing: -0.3px;
      }
    }
  }

  &-buttons {
    margin-top: auto;
  }

  &-icons {
    display: flex;
    align-items: center;
    gap: 0 8px;
    margin-bottom: 10px;
  }

  &-label {
    font-size: rem(10px);
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 0.27em;

    @include media($tablet) {
      font-weight: 400;
    }
  }

  &-icon {
    display: block;
    flex-shrink: 0;
    position: relative;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 1px solid color(dark_grayish_violet);
    background-position: center;
    background-repeat: no-repeat;

    @include media($tablet) {
      width: 16px;
      height: 16px;
    }

    @include media($overdesktop) {
      &:hover {
        span {
          opacity: 1;
          translate: 0 -18px
        }
      }
    }

    span {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      pointer-events: none;
      font-size: rem(9px);
      white-space: nowrap;
      opacity: 0;
      transition: translate 0.3s ease-out, opacity 0.3s ease-out;
    }

    &.icon-upcoming-event {
      background-image: url('../../html_prototype/images/icon-upcoming-event.png');
      background-size: 12.5px auto;

      @include media($tablet) {
        background-size: 6.5px auto;
      }
    }

    &.icon-publication {
      background-image: url('../../html_prototype/images/icon-publication.png');
      background-size: 17px auto;

      @include media($tablet) {
        background-size: 10px auto;
      }
    }

    &.icon-news {
      background-image: url('../../html_prototype/images/icon-news.png');
      background-size: 12.3px auto;

      @include media($tablet) {
        background-size: 8px auto;
      }
    }
  }

  &-cta {
    @include caret(color(white));
    @include pad(7px 23px 6px 15px);
    font-size: rem(16px);

    @include media($desktop) {
      @include pad(7px 22px 7px 16px);
    }

    &::after {
      margin-left: 9px;
    }
  }
}
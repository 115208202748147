.involved {
  background-color: color(combiz_orange);
  overflow: hidden;
  &-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include media($mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &-content {
    @include pad(47px 0 48px);

    @include media($mobile) {
      @include pad(26px 0 22px)
    }
  }

  &-title {
    color: color(white);
    font-size: rem(24px);
    margin-bottom: 60px;
    font-weight: 500;

    @include media($mobile) {
      margin-bottom: 14px;
    }
  }

  &-desc {
    color: color(white);
    font-size: rem(16px);
    max-width: 351px;
    width: 100%;
    line-height: normal;
    margin-bottom: 56px;

    @include media($mobile) {
      max-width: 100%;
      font-size: rem(14);
      margin-bottom: 11px;
    }
  }

  &-cta {
    font-weight: 400;
    @include caret(color(combiz_blue));
    @include pad(8px 23px 6px 15px);
    font-size: rem(15px);

    &::after {
      margin-left: 8px;
    }
  }

  &-frame {
    position: relative;
    height: 100%;

    @include media($mobile) {
      height: 222px;
    }
  }

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 56vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/build/get-involved.jpg');
    height: 100%;
    background-color: color(gray_grayish_blue);

    .is-firefox & {
      width: 50vw;

      @include media($mobile) {
        width: 100vw;
      }
    }

    @include media($mobile) {
      width: 111vw;
      left: 50%;
      translate: -50% 0;
    }
  }
}
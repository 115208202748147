.programme-header {
  z-index: 1;
  overflow: hidden;
  position: relative;

  &-background {
    @include position(absolute, 0 0 auto auto);
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &-inner {
    min-height: 527px;
    display: flex;
    align-items: center;
  }

  &-content {
    background-color: rgba(color(white), 0.9);
    max-width: 637px;
    border-radius: 14px;
    @include pad(28px 25px 25px);
    flex-grow: 1;
  }

  &-heading {
    font-size: rem(49);
    color: color(combiz_blue);
    margin: 0 0 9px;
    line-height: 1.06;
    letter-spacing: -0.003em;
  }

  &-desc {
    font-size: rem(20);
    color: color(combiz_black);
    line-height: 1.45;
    margin-bottom: 0;
  }

  .logo-wrap {
    @include position(absolute, 0 null null 70%);
    @include size(160px);
    background: $white;
    border-radius: 50%;

    img {
      @include position(absolute, 0 0 0 0);
      margin: auto;
      width: 70%;
      height: auto;
    }
  }

  .btn-back {
    width: inherit;
    @include position(absolute, 15px null null 20px);
  }

  @include media($desktop) {


    .logo-wrap {
      left: 65%;
    }
  }

  @include media($tablet) {

    .logo-wrap {
      top: 0;
      bottom: 0;
      left: 72%;
      margin: auto;
      @include size(155px);
    }
  }

  @include media($mobile) {
    .logo-wrap {
      @include size(91px);
      top: -140px;
      right: -20px;
      bottom: inherit;
    }
  }

  .programme-header-sponsors {

    margin-bottom: -85px;

    .sponsors-title {
      font-size: rem(10);
      text-transform: uppercase;
      letter-spacing: $spaced-letters;
      margin-top: 35px;
      margin-bottom: 5px;
      font-weight: 500;
    }

    .sponsors-logos {
      margin-bottom: -55px;

      img {
        display: inline-block;
        max-height: 150px;
      }

      &.two-lines {

        .sponsors-logo {
          display: block;
          text-align: left;
        }
      }
    }

    .sponsors-logo {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-right: 30px;
      margin-top: 20px;
    }
  }
}
